// hooks
import { useRoutes } from 'react-router-dom';
// pages
import ProtectedRoute from 'routes/ProtectedRoute';
// landing
import Landing from 'pages/landing';
// initial
import InitalPage from 'pages/initialPage';
// auth
import Unauthorized from 'pages/auth/unauthorized';
import SignIn from 'pages/auth/signIn';
import Email from 'pages/auth/email';
import Success from 'pages/auth/OAuth/Success';
import Failure from 'pages/auth/OAuth/Failure';
// player page
import MeasurementMain from 'pages/player/measurement/main';
import Analysis from 'pages/player/measurement/analysis';
import EightRoundReport from 'pages/player/eightRound/personalReport';
import Join from 'pages/player/join';
import PlayerReport from 'pages/player/report';
import PlayerMypage from 'pages/player/mypage';
import PlayerVideo from 'pages/player/video';
import ControlMain from 'pages/player/control/main';
import ControlMainSeason from 'pages/player/control/main/season';
import ControlTraining from 'pages/player/control/training';
import ControlTrainingDetail from 'pages/player/control/training/[id]';
// staff-control
import LineUp from 'pages/staff/control/lineUp';
import LineUpId from 'pages/staff/control/lineUp/[id]';
// import StaffPlayerDetail from 'pages/staff/control/playerDetail';
import Injury from 'pages/staff/control/lineUp/[id]/injury';
import Career from 'pages/staff/control/lineUp/[id]/career';
import Season from 'pages/staff/control/lineUp/[id]/season';
import Rating from 'pages/staff/control/rating';
import RatingId from 'pages/staff/control/rating/[id]';
import Training from 'pages/staff/control/training';
import TrainingDiaryDetail from 'pages/staff/control/training/diary/[id]';
import TrainingSessionDetail from 'pages/staff/control/training/session/[id]';
import PlayerDiary from 'pages/staff/control/training/playerDiary/[id]';
import Event from 'pages/staff/control/event';
import EventDetail from 'pages/staff/control/event/[id]';

import StaffMypage from 'pages/staff/mypage';
import Schedule from 'pages/staff/control/schedule';
import ReportPost from 'pages/staff/control/schedule/report/post';
import ReportRead from 'pages/staff/control/schedule/report/read';
import Medical from 'pages/staff/medical';
// staff-measurement
import Control from 'pages/staff/measurement/control';
import DribbleOneDetail from 'pages/staff/measurement/control/[skill]/DribbleOneDetail';
import StaffReport from 'pages/staff/measurement/report';
import StaffGroupReport from 'pages/staff/measurement/report/groupReport';
import Registration from 'pages/staff/measurement/registration';
// admin
import UserControl from 'pages/admin/userControl';
import PlayerControlDetail from 'pages/admin/mypage/playerDetail';
import TeamControlDetail from 'pages/admin/mypage/teamDetail';
import MeasurementDashboard from 'pages/admin/measurement/main';
import AnalysisAdmin from 'pages/admin/measurement/analysis';
import Report from 'pages/admin/report';
import ReportPrint from 'pages/admin/report/reportPrint';
import PersonalReport from 'pages/admin/report/reportPrint/personalReport';
import Feedback from 'pages/admin/feedback';
import Video from 'pages/admin/video';
import AdminMypage from 'pages/admin/mypage';
// hand
import HandSignIn from 'pages/hand/auth/signIn';
import HandEmail from 'pages/hand/auth/email';
import HandInitial from 'pages/hand/initialPage';
import HandRecords from 'pages/hand/handRecords';
import HandMeasurement from 'pages/hand/measurement';
import HandReport from 'pages/hand/report';
import HandUnauthorized from 'pages/hand/unauthorized';
// test
import Test from 'pages/staff/control/training/components/fabricCanvas';
export const routesConfig = [
    { path: '/test', element: <Test /> },

    { path: '/', element: <SignIn /> },
    { path: '/signIn', element: <SignIn /> },
    { path: '/landing', element: <Landing /> },
    { path: '/signIn/email', element: <Email /> },
    { path: '/login/oauth', element: <Success /> },
    { path: '/login/authentication-failure', element: <Failure /> },
    { path: '/initial', element: <InitalPage /> },
    {
        path: '/player',
        element: <ProtectedRoute allowedRoles={['PLAYER']} />,
        children: [
            { path: 'measurement', element: <MeasurementMain /> }, // 기본 경로
            { path: 'measurement/:id', element: <MeasurementMain /> }, // 동적 경로
            { path: 'measurement/analysis/:measurementId', element: <Analysis /> }, // 분석 동적 경로
            { path: 'measurement/report/:measurementId', element: <PlayerReport /> }, // 기본 경로
            { path: 'eightRound/report/:playerId', element: <EightRoundReport /> }, // 기본 경로
            { path: 'join', element: <Join /> }, // 기본 경로
            { path: 'video', element: <PlayerVideo /> }, // 기본 경로
            { path: 'mypage', element: <PlayerMypage /> }, // 분석 동적 경로
            { path: 'control/main', element: <ControlMain /> }, // 분석 동적 경로
            { path: 'control/main/seasson', element: <ControlMainSeason /> }, // 분석 동적 경로
            { path: 'control/training', element: <ControlTraining /> }, // 기본 경로
            { path: 'control/training/:id', element: <ControlTrainingDetail /> }, // 기본 경로
        ],
    },
    {
        path: '/staff',
        element: <ProtectedRoute allowedRoles={['TEAM_STAFF']} />,
        children: [
            { path: 'mypage', element: <StaffMypage /> },
            {
                path: 'control',
                element: <ProtectedRoute allowedStaffRoles={['COACH', 'MANAGER', 'OFFICE']} />,
                children: [
                    { path: 'lineUp', element: <LineUp /> },
                    { path: 'lineUp/:id', element: <LineUpId /> },
                    {
                        path: 'lineUp/:id/playerDetail/:playerId',
                        element: <PlayerControlDetail />,
                    },
                    { path: 'lineUp/:id/season', element: <Season /> },
                    { path: 'lineUp/:id/injury', element: <Injury /> },
                    { path: 'lineUp/:id/career', element: <Career /> },
                    { path: 'rating', element: <Rating /> },
                    { path: 'rating/:id', element: <RatingId /> },
                    { path: 'training', element: <Training /> },
                    { path: 'training/diary', element: <Training /> },
                    { path: 'training/session', element: <Training /> },
                    { path: 'training/diary/:id', element: <TrainingDiaryDetail /> },
                    { path: 'training/session/:id', element: <TrainingSessionDetail /> },
                    {
                        path: 'training/diary/:id/player/:playerDiaryId',
                        element: <PlayerDiary />,
                    },
                    { path: 'event', element: <Event /> },
                    { path: 'event/:id', element: <EventDetail /> },

                    { path: 'schedule', element: <Schedule /> },
                    { path: 'schedule/:id', element: <ReportPost /> },
                    { path: 'schedule/read/:id', element: <ReportRead /> },
                    { path: 'medical', element: <Medical /> },
                    { path: 'registration', element: <Registration /> },
                ],
            },
            {
                path: 'measurement',
                element: <ProtectedRoute allowedStaffRoles={['COACH', 'MANAGER', 'OFFICE']} />,
                children: [
                    { path: 'control', element: <Control /> },
                    { path: 'control/:skillId', element: <DribbleOneDetail /> },
                    {
                        path: 'control/dashboard/:playerId',
                        element: <MeasurementDashboard />, // admin & staff 공통 컴포넌트
                    },
                    {
                        path: 'control/eightRound/report/:playerId',
                        element: <EightRoundReport />,
                    }, // 기본 경로

                    { path: 'report', element: <StaffReport /> },
                    {
                        path: 'report/dashboard/:playerId',
                        element: <MeasurementDashboard />, // admin & staff 공통 컴포넌트
                    },
                    { path: 'report/group', element: <StaffGroupReport /> },
                    { path: 'report/personal/:measurementId', element: <PlayerReport /> },
                    {
                        path: 'report/analysis/:measurementId',
                        element: <Analysis />, // Analysis 는 선수 폴더에
                    },
                    { path: 'registration', element: <Registration /> },
                ],
            },
        ],
    },
    {
        path: '/admin',
        element: <ProtectedRoute allowedRoles={['SUPER_ADMIN', 'SYSTEM_ADMIN', 'DATA_ANALYST']} />,
        children: [
            { path: 'control', element: <UserControl /> },
            {
                path: 'control/playerDetail/:playerId',
                element: <PlayerControlDetail />,
            },
            { path: 'control/teamDetail/:teamId', element: <TeamControlDetail /> },
            {
                path: 'control/teamDetail/dribbleOne',
                element: <DribbleOneDetail />,
            },
            {
                path: 'control/measurement/:playerId',
                element: <MeasurementDashboard />,
            },
            {
                path: 'control/eightRound/report/:playerId',
                element: <EightRoundReport />,
            }, // 기본 경로
            {
                path: 'control/measurement/report/:measurementId',
                element: <PlayerReport />, // PlayerReport는 선수 폴더에 있음, PersonalReport 는 어드민 폴더에 있는 것 아직 두 페이지가 거의 같아서 혼용 가능(영상보기 버튼 제외)
            },
            {
                path: 'control/measurement/analysis/:measurementId',
                element: <Analysis />, // Analysis 는 선수 폴더에
            },
            { path: 'feedback', element: <Feedback /> },
            { path: 'feedback/:measurementId', element: <PlayerReport /> }, // 수정필요 0114
            { path: 'report', element: <Report /> },
            { path: 'report/:reportType', element: <ReportPrint /> },
            { path: 'video', element: <Video /> },
            { path: 'mypage', element: <AdminMypage /> },
        ],
    },

    {
        path: '/hand',
        children: [
            { path: 'signIn', element: <HandSignIn /> },
            { path: 'signIn/email', element: <HandEmail /> },
            { path: 'initial', element: <HandInitial /> },
        ],
    },
    {
        path: '/hand',
        element: <ProtectedRoute allowedRoles={['SUPER_ADMIN', 'SYSTEM_ADMIN', 'DATA_ANALYST']} />,
        children: [
            { path: 'records', element: <HandRecords /> },
            {
                path: 'measurement',
                element: <HandMeasurement />,
            },
            {
                path: 'measurement/:measurementId',
                element: <PlayerReport />, // PlayerReport는 선수 폴더에 있음, PersonalReport 는 어드민 폴더에 있는 것 아직 두 페이지가 거의 같아서 혼용 가능(영상보기 버튼 제외)
            },
            {
                path: 'measurement/analysis/:measurementId',
                element: <Analysis />, // Analysis 는 선수 폴더에
            },
            { path: 'report', element: <HandReport /> },
            { path: 'unauthorized', element: <HandUnauthorized /> },
        ],
    },
    {
        path: '*',
        element: <Unauthorized />,
    },
];

const AppRoutes = () => {
    const routes = useRoutes(routesConfig);
    return routes;
};
export default AppRoutes;
