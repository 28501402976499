// hooks
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// hooks
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// commons
import { showToast } from 'components/toast/Default';
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
import Dropdown from 'components/dropdown/Default';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { ENDPOINT_TEAM_DIARY } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// training components
import TrainingSubHeader from 'pages/staff/control/training/TrainingSubHeader';
import TrainingInfo from 'pages/staff/control/training/diary/[id]/TrainingInfo';
import TrainingSession from 'pages/staff/control/training/diary/[id]/TrainingSession';
import AttendancePlayer from 'pages/staff/control/training/diary/[id]/AttendancePlayer';
import AbsentPlayer from 'pages/staff/control/training/diary/[id]/AbsencePlayer';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const TrainingDetail = () => {
    const today = new Date().toISOString();
    const location = useLocation()?.pathname?.split('/');
    const defaultTab = location[4];
    const { id } = useParams();
    const { staffRole, role } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const [savedTime, setSavedTime] = useState(today);
    const [saveCommand, setSaveCommand] = useState(false);
    const [curDropDownState, setCurDropDownState] = useState<string | undefined>('teamDiary');
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    // useApiQuery
    const getTeamDiaryId = useApiQuery(['getTeamDiaryId', `${id}`], ENDPOINT_TEAM_DIARY.diaryId(id), 'get', {
        enabled: !!id,
    });
    const getPlyaerDiaries = useApiQuery(['getPlyaerDiaries', `${id}`], ENDPOINT_TEAM_DIARY.playerDiaries(id), 'get', {
        enabled: !!id,
    });
    const [trainingInfo, setTrainingInfo] = useState<TrainingInfoType>(getTeamDiaryId?.data);
    const [saveToast, setSaveToast] = useState(false);
    useEffect(() => {
        if (getTeamDiaryId?.data) setTrainingInfo(getTeamDiaryId?.data);
    }, [getTeamDiaryId?.data]);
    // 자동저장
    const patchTrainingDiary = useApiMutation(ENDPOINT_TEAM_DIARY.diaryId(id), 'patch', {
        onSuccess: (data) => {
            if (saveToast) showToast('훈련일지가 수정되었습니다', 'success');
            const now = new Date().toISOString();
            if (setSavedTime) setSavedTime(now);
            setSaveToast(false);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '훈련일지 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
            setSaveToast(false);
        },
    });
    const submitDiary = () => {
        if (!patchTrainingDiary.isPending) {
            patchTrainingDiary.mutate(trainingInfo);
        }
    };

    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };
    const debouncedSubmitReport = useMemo(
        () =>
            debounce(() => {
                submitDiary();
            }, 1000), // 1초 뒤에 실행
        [patchTrainingDiary] // 의존성에서 필요 요소만 포함
    );

    useEffect(() => {
        if (
            trainingInfo?.location === getTeamDiaryId?.data?.location &&
            trainingInfo?.trainingDateTime === getTeamDiaryId?.data?.trainingDateTime &&
            trainingInfo?.trainingIntensity === getTeamDiaryId?.data?.trainingIntensity &&
            trainingInfo?.weather === getTeamDiaryId?.data?.weather
        ) {
            return;
        }
        if (trainingInfo) {
            debouncedSubmitReport();
        }
        return () => {
            debouncedSubmitReport.cancel();
        };
    }, [trainingInfo]);
    const playerDiaryOptions = handleDropdownOptions(getPlyaerDiaries?.data?.playerDiaries);
    const handleDropdownNavigate = (value: string | number) => {
        if (value === 'teamDiary') handleNavigate(`/staff/control/training/diary/${id}`);
        else handleNavigate(`/staff/control/training/diary/${id}/player/${value}`);
    };
    const handleDropdownChange = (value: string) => {
        setCurDropDownState(value);
    };

    return (
        <div className="wrapper">
            {getTeamDiaryId?.isPending && <SoccerBallLodaing />}
            <TrainingSubHeader
                title="훈련 일지 상세"
                handleNavigate={() => handleNavigate('/staff/control/training')}
            />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <Tabs
                    tabs={tabData}
                    defaultActiveTab={tabMatcher(defaultTab)}
                    onTabChange={(idx: number) => handleNavigate(`/staff/control/training/${tabData[idx]?.key}`)}
                    className={'w-fit'}
                    colorType="black"
                />
                <div className="flex gap-[20px] my-[20px] items-center justify-between">
                    <div className="flex gap-[20px] items-center">
                        <Dropdown
                            className="w-[200px]"
                            options={playerDiaryOptions}
                            value={curDropDownState}
                            onChange={handleDropdownChange}
                            activeFunc={handleDropdownNavigate}
                        />
                    </div>

                    <div className="text-secondary-lGrayDark">{`${formatDateTimeMatcher(savedTime)} 최종수정`}</div>
                </div>
                <TrainingInfo
                    trainingInfo={trainingInfo}
                    setTrainingInfo={setTrainingInfo}
                />
                <TrainingSession
                    saveCommand={saveCommand}
                    setSaveCommand={setSaveCommand}
                />
                <AttendancePlayer
                    attendances={getTeamDiaryId?.data?.attendances.filter(
                        (a: AbsentPlayerType) => a.attendanceStatus === 'PARTICIPANT'
                    )}
                    playerDiaries={getPlyaerDiaries?.data?.playerDiaries}
                />
                <AbsentPlayer
                    attendances={getTeamDiaryId?.data?.attendances.filter(
                        (a: AbsentPlayerType) => a.attendanceStatus === 'ABSENT'
                    )}
                />
                <div className="my-[20px] justify-center gap-[10px] flex text-context-bold">
                    <button
                        onClick={() => handleNavigate('/staff/control/training')}
                        className="rounded-[8px] text-primary-white bg-secondary-dGrayLight px-[20px] py-[12px]"
                    >
                        취소
                    </button>
                    {staffRole !== 'OFFICE' && (
                        <button
                            onClick={() => {
                                showToast('훈련 일지를 수정하는 중입니다', 'info');
                                setSaveToast(true);
                                submitDiary();
                                setSaveCommand(true);
                            }}
                            className="rounded-[8px] text-primary-white bg-primary-purple px-[20px] py-[12px]"
                        >
                            업로드
                        </button>
                    )}
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default TrainingDetail;

export const handleRows = (rows: any[]) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        trainingDateTime: `${row?.trainingDateTime} `,
        location: row?.location,
        weather: row?.weather,
        trainingIntensity: row?.trainingIntensity,
        link: `/admin/feedback/${row?.measurementId}?measurementId=${row?.measurementId}&dateTime=${row?.measuredAt}`,
    }));
};
export const tabData = [
    {
        label: '일지',
        key: 'diary',
    },
    {
        label: '세션',
        key: 'session',
    },
];
export const handleDropdownOptions = (rows: PlayerDiaries[]) => {
    if (rows?.length > 0) {
        return [
            {
                value: 'teamDiary',
                label: '팀 훈련 일지',
            },
            ...rows?.map((row) => ({
                value: `${row?.playerDiaryId}`,
                label: `${row?.playerName}`,
            })),
        ];
    } else {
        return [
            {
                value: 'teamDiary',
                label: '팀 훈련 일지',
            },
        ];
    }
};
export const tabMatcher = (tab: string) => {
    if (tab === 'diary') return 0;
    else if (tab === 'session') return 1;
    else return 0;
};

interface AbsentPlayerType {
    attendanceId: number;
    playerId: number;
    playerName: string;
    uniformNumber: number;
    attendanceStatus: 'PARTICIPANT' | 'ABSENT';
    trainingAttitudeScore: number;
    technicalSkillScore: number;
}

interface PlayerDiaries {
    playerDiaryId: number;
    goodPoints: null | number;
    lackingPoints: null | number;
    personalTrainingIntensity: null | number;
    playerId: number;
    playerName: string;
    questionsPoints: null | number;
}
interface TrainingInfoType {
    location?: string;
    trainingDateTime?: string;
    trainingIntensity?: string;
    weather?: string;
    savedTime?: string;
    setSavedTime?: (time: string) => void;
}
