// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { ENDPOINT_PLAYER_DIARY } from 'utils/consts/apiEndpoint';
// components
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import TrainingSubHeader from 'pages/player/control/training/TrainingSubHeader';
import EmptyBar from 'components/emptyBar';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';

// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const ControlTraining = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    // useApiQuery
    const getTeamLogs = useApiQuery(
        ['getTeamLogs', paramAgeGroup, `${page}`, `${pagInfo.size}`],
        `${ENDPOINT_PLAYER_DIARY.playerDiary}?page=${page}&size=${pagInfo.size}`,
        'get'
    );

    useEffect(() => {
        if (getTeamLogs?.isSuccess && getTeamLogs?.data && getTeamLogs?.data?.pageInfo?.page === 1) {
            setPageInfo(getTeamLogs?.data?.pageInfo);
        }
    }, [getTeamLogs?.isSuccess, getTeamLogs?.data]);
    const customizedRow = handleRows(getTeamLogs?.data?.data) || [];
    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };

    return (
        <div className="wrapper">
            <TrainingSubHeader
                navigateTitle="관리"
                title={'훈련'}
            />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <div className="flex gap-[20px] items-center justify-between">
                    <div className="flex gap-[5px] text-[20px] font-semibold">
                        <span className="text-secondary-dGrayMid text-subtitle-medium-bold">{'개인훈련'}</span>
                    </div>
                </div>
                <EmptyBar customStyle={'h-[20px]'} />
                <div className="w-full h-fit ">
                    <CustomTable
                        columns={columns}
                        rows={customizedRow}
                        equalWidth={true}
                    />
                    {getTeamLogs?.isPending && (
                        <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
                    )}

                    <Pagination
                        page={page}
                        size={pagInfo?.size}
                        totalElement={pagInfo?.totalElements}
                        totalPages={pagInfo?.totalPages}
                        onPageChange={setPage}
                    />
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default ControlTraining;

export const handleRows = (rows: any[]) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        trainingDateTime: `${formatDateTimeMatcher(row?.trainingDateTime)} `,
        location: `${row?.location || ''}`,
        weather: `${weatherMatcher(row?.weather)}`,
        trainingIntensity: row?.trainingIntensity,
        link: `/player/control/training/${row.id}`,
    }));
};
export const columns = [
    { key: 'index', label: '번호' },
    { key: 'trainingDateTime', label: '일시' },
    {
        key: 'location',
        label: '장소',
    },
    {
        key: 'weather',
        label: '날씨',
        sortable: false,
    },
    { key: 'trainingIntensity', label: '본인이 느끼는 훈련강도' },
    { key: 'link', label: '상세보기' },
];
