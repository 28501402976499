import * as fabric from 'fabric';
import { MutableRefObject } from 'react';

export const useShapeStyleTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const updateActiveObjectLineWidth = (width: number) => {
        const canvas = fabricRef.current;
        if (!canvas) return;

        const activeObject = canvas.getActiveObject();
        if (!activeObject) return;

        // 제외 대상: 이미지, 텍스트박스
        const isStyleApplicable = (obj: fabric.Object) =>
            obj.type !== 'image' && obj.type !== 'textbox' && 'strokeWidth' in obj;

        if (activeObject.type === 'group') {
            (activeObject as fabric.Group).getObjects().forEach((obj) => {
                if (isStyleApplicable(obj)) {
                    obj.set({ strokeWidth: width });
                }
            });
        } else if (isStyleApplicable(activeObject)) {
            activeObject.set({ strokeWidth: width });
        }
        if (setUnSaved) setUnSaved(true);
        canvas.requestRenderAll();
    };

    return {
        updateActiveObjectLineWidth,
    };
};
