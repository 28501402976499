// training components
import AbsencePlayerEl from 'pages/staff/control/training/diary/[id]/AbsencePlayerEl';

const AbsencePlayer = ({ attendances }: AbsencePlayerListType) => {
    return (
        <div className="flex h-full border-b border-secondary-lGrayMid">
            <div className="w-[150px] p-[24px] border border-3 shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                훈련 불참 선수
            </div>
            <div className="p-[24px]">
                <div className="grid grid-cols-2 gap-x-[18px] gap-y-[10px] text-context-regular">
                    <div className="flex items-center w-full text-center gap-[10px]">
                        <div className="w-[120px]">선수</div>
                        <div className="w-[394px]">사유</div>
                    </div>
                    <div className="flex items-center w-full text-center gap-[10px]">
                        <div className="w-[120px]">선수</div>
                        <div className="w-[394px]">사유</div>
                    </div>
                    {attendances?.map((el) => (
                        <AbsencePlayerEl
                            key={el?.playerId}
                            {...el}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AbsencePlayer;
interface AbsencePlayerType {
    attendanceId: number;
    playerId: number;
    playerName: string;
    uniformNumber: number;
    attendanceStatus: 'PARTICIPANT' | 'ABSENT';
    absenceReason: string | null;
    trainingAttitudeScore: number;
    technicalSkillScore: number;
}

interface AbsencePlayerListType {
    attendances: AbsencePlayerType[];
}
