interface LineButtonProps {
  state: string;
  isActive?: boolean;
  icon?: React.ReactNode;
  onClick?: () => void;
}

const LineButton = ({ icon, onClick, state, isActive }: LineButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`rounded-[8px] bg-[#4E4E4E] w-[56px] h-[30px] flex justify-center items-center ${
        isActive
          ? "text-primary-green border border-primary-green"
          : "text-white"
      }`}
    >
      {icon}
    </button>
  );
};

export default LineButton;
