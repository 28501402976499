// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import Footer from 'components/footer/Default';
import MainSubHeader from 'pages/player/components/MainSubHeader';
import EmptyBar from 'components/emptyBar';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import Dashboards from 'pages/player/measurement/main/Dashboards';
// utils
import { DASHBOARD } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const MeasurementMain = () => {
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const getPlayerDashboard = useApiQuery(['getPlayerDashboard', `${id}`], DASHBOARD.player(`${id}`), 'get', {
        enabled: !!id,
    });
    const getDashboardDetail = useApiQuery(['getDashboardDetail', `${id}`], DASHBOARD.playerDetail(`${id}`), 'get', {
        enabled: !!id,
    });
    const { playerInfo, rankingInfo } = getPlayerDashboard?.data || {};

    return (
        <div className="wrapper wrapperHeight">
            {(getPlayerDashboard?.isPending || getDashboardDetail?.isPending) && <SoccerBallLodaing />}
            <MainSubHeader rankingInfo={rankingInfo} />
            <EmptyBar customStyle={'h-[35px]'} />
            <Dashboards
                dashboardData={getPlayerDashboard?.data}
                dashboardDetailData={getDashboardDetail?.data}
            />

            <EmptyBar customStyle="h-[20px]" />
            <Footer />
        </div>
    );
};

export default MeasurementMain;
