// hooks
import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
// hooks
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_MANAGEMENT_PROFILE } from 'utils/consts/apiEndpoint';
import { debounce } from 'utils/debounce';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
import { matchTypeMatcher } from 'utils/matchers/reportMatcher';
// commons
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import ControlMainSubHeader from 'pages/player/control/main/ControlMainSubHeader';
import ProfileCard from 'pages/staff/control/lineUp/[id]/ProfileCard';
import InjuryCard from 'pages/staff/control/lineUp/[id]/InjuryCard';
import MoreNavBtn from 'components/button/MoreNavBtn';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const ControlMain = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const location = useLocation().pathname;
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';

    const [moreSeasonData, setMoreSeasonData] = useState(false);
    const seasonColumns = [
        { key: 'competitionName', label: '경기명' },
        { key: 'matchType', label: '경기종류', sortable: false },
        { key: 'appearances', label: '출장', sortable: false },
        { key: 'goals', label: '득점' },
        { key: 'assists', label: '도움' },
        { key: 'averageRating', label: '평균 평점', sortable: false },
    ];
    const careerColumns = [
        { key: 'teamName', label: '팀명' },
        { key: 'totalMatches', label: '총 경기', sortable: false },
        { key: 'totalGoals', label: '득점' },
        { key: 'totalAssists', label: '도움' },
        { key: 'averageRating', label: '평균 평점', sortable: false },
    ];
    const handleMoreBtnNavigate = (url: string) => {
        handleParamNavigate({}, false, url);
    };

    // react qury
    const getMyProfile = useApiQuery(['getMyProfile', paramPlayerId], ENDPOINT_MANAGEMENT_PROFILE.my, 'get');
    const customizeSeasonRow = handleSeasonRows(getMyProfile?.data?.seasonStats?.competitionStats) || [];
    const customizedCareerRow = handleRows(getMyProfile?.data?.careerInfo) || [];

    useEffect(() => {
        if (paramPlayerId && !getMyProfile?.isLoading) {
            // 사용 예시
            const debouncedRefetch = debounce(() => {
                getMyProfile.refetch(); // refetch를 지연시킴
            }, 300); // 300ms 후 실행

            debouncedRefetch(); // 이 함수가 실행되는 부분
        }
    }, [paramPlayerId]);
    return (
        <div className="wrapper">
            {getMyProfile?.isPending && <SoccerBallLodaing />}

            <ControlMainSubHeader
                playerInfo={getMyProfile?.data?.playerInfo}
                seasonStats={getMyProfile?.data?.seasonStats}
            />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="flex gap-[20px] relative">
                <ProfileCard
                    playerInfo={getMyProfile?.data?.playerInfo}
                    detailNavigateUrl={'/player/mypage'}
                />
                {/* 시즌통계 */}
                <div
                    className={`w-full bg-white max-w-[690px]  rounded-[10px] p-[30px] flex flex-col gap-[20px]
                ${moreSeasonData ? 'h-[760px] absolute right-0' : 'h-[370px]'}
                `}
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-cent first-line:er gap-[10px]">
                            <div className="text-subtitle-medium-bold text-secondary-dGrayMid">시즌통계</div>
                            <MoreNavBtn
                                type="white"
                                direction={moreSeasonData ? 'up' : 'down'}
                                activeFunc={() => setMoreSeasonData(!moreSeasonData)}
                            />
                        </div>

                        <div className="flex gap-[10px] items-center">
                            <div className="flex gap-[15px]">
                                <span className="text-secondary-lGrayMid">출장</span>
                                <span className="text-secondary-dGrayMid">
                                    {getMyProfile?.data?.seasonStats?.totalStats?.totalAppearances}
                                </span>
                                {/* <span className="text-secondary-lGrayMid">평점</span>
                                <span className="text-secondary-dGrayMid">
                                    {getMyProfile?.data?.seasonStats?.totalStats?.totalAverageRating}
                                </span> */}
                                <span className="text-secondary-lGrayMid">득점</span>
                                <span className="text-secondary-dGrayMid">
                                    {getMyProfile?.data?.seasonStats?.totalStats?.totalGoals}
                                </span>
                                <span className="text-secondary-lGrayMid">도움</span>
                                <span className="text-secondary-dGrayMid">
                                    {getMyProfile?.data?.seasonStats?.totalStats?.totalAssists}
                                </span>
                            </div>
                            <MoreNavBtn
                                type="purple"
                                activeFunc={() => handleMoreBtnNavigate(`/player/control/main/seasson`)}
                            />
                        </div>
                    </div>
                    <div className="w-full ">
                        {/* 시즌통계 테이블 */}
                        <CustomTable
                            columns={seasonColumns} // getMyProfile?.data?.seasonStats?.totalStats.competitionStats
                            rows={moreSeasonData ? customizeSeasonRow : customizeSeasonRow?.slice(0, 4)}
                            equalWidth={false}
                            // minWidth={'min-w-[116px]'}
                        />
                    </div>
                </div>
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
            <div className="flex gap-[20px]">
                <InjuryCard
                    injuryInfo={getMyProfile?.data?.injuryInfo}
                    detailBtnHide={true}
                />
                {/* 경력정보 */}
                <div className="w-full bg-white max-w-[690px] h-[370px] rounded-[10px] p-[30px] flex flex-col gap-[20px]">
                    <div className="flex items-center justify-between">
                        <div className="text-subtitle-medium-bold text-secondary-dGrayMid">경력정보</div>

                        {/* <MoreNavBtn
                            type="purple"
                            activeFunc={() => handleMoreBtnNavigate('career')}
                        /> */}
                    </div>
                    <div className="w-full h-[370px] overflow-y-scroll">
                        {/* overflow-y-scroll */}
                        <CustomTable
                            columns={careerColumns}
                            rows={customizedCareerRow?.slice(0, 4)}
                            equalWidth={true}
                            // minWidth={'min-w-[116px]'}
                        />
                    </div>
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default ControlMain;

export const handlePlayerDropdownOpts = (rows: any[]) => {
    return rows?.map((row) => ({
        value: row?.myId,
        label: `${row?.uniformNumber}. ${row?.name}`,
    }));
};

export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        teamName: row?.teamName,
        age: `${row?.age}세 (${ageMatcher(row?.age)})`,
        totalMatches: row?.totalMatches,
        totalGoals: row?.totalGoals,
        totalAssists: row?.totalAssists,
        averageRating: row?.averageRating,
    }));
};

export const handleSeasonRows = (rows: any[]) => {
    return rows?.map((row) => ({
        competitionName: `${row?.competitionName}`,
        matchType: matchTypeMatcher(row?.matchType),
        appearances: row?.appearances,
        goals: row?.goals,
        assists: row?.assists,
        averageRating: row?.averageRating,
    }));
};

interface PlayerDropdownOptsType {
    value: string;
    label: string;
}
