// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import { regionOptions, majorFootOptions, positions } from 'utils/consts/userInfo';
import { yearOptions } from 'utils/consts/years';
import TeamSearchModal from 'components/modals/TeamSearchModal';
import { showToast } from 'components/toast/Default';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import DatePicker from 'components/datePicker';
import PlayerAddModal from 'pages/admin/userControl/playerControl/PlayerAddModal';
// utils
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
import { regionMatcher, majorFootMatcher, ageMatcher } from 'utils/matchers/userInfoMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { playerSortMatcher } from 'utils/matchers/sortInfoMatcher';
import Dropdown from 'components/dropdown/Default';
import { paramFormChanger } from 'utils/formChanger';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';

const PlayerControl = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 20,
        totalElements: 1,
        totalPages: 1,
    });
    const [sortType, setSortType] = useState('');
    const [playerNameInput, setPlayerNameInput] = useState('');
    const [options, setOptions] = useState<OptionsType>({
        birthYearFrom: '',
        birthYearTo: '',
        positions: [],
        heightFrom: '',
        heightTo: '',
        weightFrom: '',
        weightTo: '',
        majorFoot: '',
        measuredAtFrom: '',
        measuredAtTo: '',
        overallScoreFrom: '',
        overallScoreTo: '',
        teamId: '',
        region: '',
        playerName: '',
    });
    const initOptions = () => {
        showToast('옵션을 초기화합니다.', 'info');
        setOptions({
            birthYearFrom: '',
            birthYearTo: '',
            positions: [],
            heightFrom: '',
            heightTo: '',
            weightFrom: '',
            weightTo: '',
            majorFoot: '',
            measuredAtFrom: '',
            measuredAtTo: '',
            overallScoreFrom: '',
            overallScoreTo: '',
            teamId: '',
            region: '',
            playerName: '',
        });
        setPage(1);
        setTeamName('');
    };
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = playerSortMatcher(key, sort);
        setSortType(customizedSort);
    };

    const handleOptions = (updateData: any) => {
        setOptions((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    const [teamName, setTeamName] = useState('');
    const handleTeam = (teamInfo: teamType | '', type?: string) => {
        if (teamInfo) {
            setTeamName(teamInfo?.teamName);
            handleOptions({ teamId: teamInfo?.teamId });
        }
        if (type === 'delete') {
            setTeamName('');
            handleOptions({ teamId: '' });
        }
    };
    const handlePosition = (selectPos: string) => {
        // options.positions 배열에서 선택한 포지션이 이미 존재하는지 확인
        const isPositionExists = options.positions.includes(selectPos);
        // 존재 여부에 따라 추가하거나 제거
        const updatedPositions = isPositionExists
            ? options.positions.filter((pos) => pos !== selectPos) // 제거
            : [...options.positions, selectPos]; // 추가

        // 업데이트된 배열을 options에 반영
        handleOptions({ positions: updatedPositions });
    };
    // useApiMutation
    //
    const getInitTop20 = useApiQuery(['getInitTop20'], ENDPOINT_ADMIN.top20, 'get');
    const getAdminPlayer = useApiQuery(
        [
            'getAdminPlayer',
            sortType,
            options?.teamId,
            options?.region,
            options?.majorFoot,
            JSON.stringify(options?.positions),
            `${page}`,
            `${pagInfo.size}`,
        ],
        ENDPOINT_ADMIN.playerParams(
            paramFormChanger({
                ...options,
                sortType: sortType,
                page: page,
                size: pagInfo?.size,
            })
        ),
        'get'
    );
    // Debounced function
    const [isFirstRender, setIsFirstRender] = useState(true);
    const debouncedAdminRequest = useMemo(
        () =>
            debounce(() => {
                if (!isFirstRender) {
                    // showToast('조건 검색중입니다.');
                    getAdminPlayer.refetch();
                }
            }, 300), // 3초 딜레이
        [getAdminPlayer, isFirstRender] // 의존성으로 리팩쳐 사용
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    useEffect(() => {
        debouncedAdminRequest();
        return () => {
            debouncedAdminRequest.cancel(); // 컴포넌트가 언마운트되면 디바운스 취소
        };
    }, [
        options?.playerName,
        options?.birthYearFrom,
        options?.birthYearTo,
        options?.weightFrom,
        options?.weightTo,
        options?.heightFrom,
        options?.heightTo,
        options?.measuredAtFrom,
        options?.measuredAtTo,
        options?.overallScoreFrom,
        options?.overallScoreTo,
    ]);

    useEffect(() => {
        if (getAdminPlayer?.isSuccess && getAdminPlayer?.data && getAdminPlayer?.data?.pageInfo?.page === 1) {
            setPageInfo(getAdminPlayer?.data?.pageInfo);
        }
    }, [getAdminPlayer?.isSuccess, getAdminPlayer?.data]);
    const initTop20Row = handleRows(getInitTop20?.data, navigate) || [];
    const customizedRow = handleRows(getAdminPlayer?.data?.data, navigate);
    const searchPlayer = () => {
        showToast('선수 검색중입니다', 'info');
        setPage(1);
        handleOptions({ playerName: playerNameInput });
    };

    return (
        <div className="flex flex-col gap-[30px] w-[1340px] m-auto">
            {getAdminPlayer?.isPending && <SoccerBallLodaing />}
            <div className="flex gap-y-[20px] gap-x-[10px] flex-wrap w-full">
                <div className="flex items-center gap-[10px] w-[650px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">출생연도</div>
                    <div className="items-center flex gap-[10px] w-full">
                        <Dropdown
                            placeholder="시작 연도"
                            options={yearOptions}
                            value={options?.birthYearFrom}
                            onChange={(value) => handleOptions({ birthYearFrom: value })}
                            className="w-[275px]"
                        />
                        <span>~</span>
                        <Dropdown
                            placeholder="종료 연도"
                            options={yearOptions}
                            value={options?.birthYearTo}
                            onChange={(value) => handleOptions({ birthYearTo: value })}
                            className="w-[275px]"
                        />
                    </div>
                </div>
                <div className="flex items-center gap-[10px] w-[650px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">측정일자</div>
                    <div className="items-center flex gap-[10px]">
                        <input
                            type="datetime-local"
                            placeholder="시작 날짜"
                            className="flex items-center justify-between  p-[10px] gap-[14px] bg-secondary-mGrayLight rounded-[8px]"
                            value={options?.measuredAtFrom}
                            onChange={(e) => handleOptions({ measuredAtFrom: e.target.value })}
                        />
                        <span>~</span>
                        <input
                            type="datetime-local"
                            placeholder="종료 날짜"
                            className="flex items-center justify-between p-[10px] gap-[14px] bg-secondary-mGrayLight rounded-[8px]"
                            value={options?.measuredAtTo}
                            onChange={(e) => handleOptions({ measuredAtTo: e.target.value })}
                        />
                    </div>
                </div>
                <div className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 w-[60px] text-center text-context-bold">키</div>
                    <div className="items-center flex gap-[5px]">
                        <input
                            placeholder="cm"
                            value={options?.heightFrom}
                            onChange={(e) => handleOptions({ heightFrom: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                        <span>~</span>
                        <input
                            placeholder="cm"
                            value={options?.heightTo}
                            onChange={(e) => handleOptions({ heightTo: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>
                <div className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">몸무게</div>
                    <div className="items-center flex gap-[5px]">
                        <input
                            placeholder="kg"
                            value={options?.weightFrom}
                            onChange={(e) => handleOptions({ weightFrom: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                        <span>~</span>
                        <input
                            placeholder="kg"
                            value={options?.weightTo}
                            onChange={(e) => handleOptions({ weightTo: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>

                <div className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">종합점수</div>
                    <div className="items-center flex gap-[5px]">
                        <input
                            placeholder="종합점수"
                            value={options?.overallScoreFrom}
                            onChange={(e) => handleOptions({ overallScoreFrom: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                        <span>~</span>
                        <input
                            placeholder="종합점수"
                            value={options?.overallScoreTo}
                            onChange={(e) => handleOptions({ overallScoreTo: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>
                <button className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">팀</div>

                    <TeamSearchModal
                        mdoalBtn={
                            <div className="inputDefaultStyle w-[230px] relative text-secondary-lGrayDark items-center flex pl-[12px] pr-[30px]  h-[44px] text-context-regular rounded-[8px]">
                                <div>{options?.teamId ? teamName : '팀 검색'}</div>
                                {teamName && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleTeam('', 'delete');
                                        }}
                                    >
                                        <CloseIcon className="text-secondary-lGrayDark w-[20px] h-[20px]" />
                                    </button>
                                )}
                                <SearchIcon className="absolute top-[12px] right-[12px]" />
                            </div>
                        }
                        handleTeam={handleTeam}
                    />
                </button>
                <div className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">지역</div>
                    <Dropdown
                        placeholder="지역"
                        options={regionOptions}
                        value={options?.region}
                        onChange={(value) => handleOptions({ region: value })}
                        className="w-[250px]"
                    />
                </div>
                <div className="flex items-center w-[320px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">주발</div>
                    <Dropdown
                        placeholder="주발"
                        options={majorFootOptions}
                        value={options?.majorFoot}
                        onChange={(value) => handleOptions({ majorFoot: value })}
                        className="w-[250px]"
                    />
                </div>

                <div className="flex items-center w-[1340px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">포지션</div>
                    <div className="flex gap-[5px]">
                        {positions?.map((el) => (
                            <button
                                className={`${
                                    options?.positions?.includes(el)
                                        ? 'bg-secondary-dGrayDark text-white'
                                        : 'bg-secondary-mGrayLight text-primary-black'
                                } rounded-[80px] px-[12px] py-[4px] text-center text-context-regular`}
                                onClick={() => handlePosition(el)}
                                key={el}
                            >
                                {el}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            <div className="flex gap-[20px] items-center">
                <div
                    className="w-[500px] relative"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            searchPlayer();
                        }
                    }}
                >
                    <input
                        value={playerNameInput}
                        onChange={(e) => setPlayerNameInput(e.target.value)}
                        placeholder="선수명을 검색해주세요"
                        className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                    />
                    <button
                        onClick={searchPlayer}
                        className="absolute right-[12px] top-[12px]"
                    >
                        <SearchIcon />
                    </button>
                </div>
                <button
                    onClick={initOptions}
                    className="flex gap-[5px] items-center text-secondary-lGrayDark border-secondary-mGrayMid hover:border-primary-purple hover:text-primary-purple rounded-[10px] border px-[10px] py-[6px]"
                >
                    <span>초기화</span>
                    <ResetIcon className="w-[14px] h-[14px]" />
                </button>
            </div>
            <CustomTable
                columns={columns}
                rows={customizedRow && customizedRow.length > 0 ? customizedRow : initTop20Row}
                equalWidth={false}
                sortInfoFunc={handleSort}
            />
            {/* {(role === 'SYSTEM_ADMIN' || role === 'SUPER_ADMIN') && ( */}
            <PlayerAddModal
                modalBtn={
                    <button className="w-full h-[44px] mt-[10px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center">
                        <PlusIcon />
                        유저 가입
                    </button>
                }
            />
            {/* )} */}
            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default PlayerControl;

export const columns = [
    { key: 'playerName', label: '이름', sortable: true },
    { key: 'birthYear', label: '출생연도', sortable: true },
    { key: 'grade', label: '학년' },
    { key: 'position', label: '포지션' },
    { key: 'height', label: '키', sortable: true },
    { key: 'weight', label: '몸무게', sortable: true },
    { key: 'majorFoot', label: '주발', sortable: true },
    { key: 'teamName', label: '팀명', sortable: true },
    { key: 'region', label: '지역', sortable: true },
    { key: 'lastMeasuredAt', label: '측정일자', sortable: true },
    { key: 'overallScore', label: '종합점수', sortable: true },
    { key: 'link', label: '측정보기' },
    { key: 'eightRound', label: '에잇라운드' },

    // "lastMeasurementId": 4,
    // "measurementDate": "2025-01-02T07:00:00",
];

interface OptionsType {
    birthYearFrom: string;
    birthYearTo: string;
    positions: string[]; // positions는 string 배열
    heightFrom: string;
    heightTo: string;
    weightFrom: string;
    weightTo: string;
    majorFoot: string;
    measuredAtFrom: string;
    measuredAtTo: string;
    overallScoreFrom: string;
    overallScoreTo: string;
    teamId: string;
    region: string;
    playerName: string;
    sortType?: string;
}

export const handleRows = (rows: any[], navigate: any) => {
    return rows?.map((row) => ({
        playerName: (
            <button
                onClick={() => navigate(`/admin/control/playerDetail/${row?.playerId}`)}
                className="relative flex items-center justify-between m-auto "
            >
                {row?.playerName}
                <EditPenIcon className="w-[20px] h-[20px] text-secondary-mGrayDark hover:text-primary-purple" />
            </button>
        ),
        birthYear: row?.birthYear,
        grade: `${ageMatcher(new Date().getFullYear() - row?.birthYear)}`,
        position: <div className="">{row?.positions?.join(',')}</div>,
        height: row?.height,
        weight: row?.weight,
        majorFoot: majorFootMatcher(row?.majorFoot),
        teamName: row?.teamName,
        region: regionMatcher(row?.region || ''),
        lastMeasuredAt: formatDateTimeMatcher(row?.lastMeasuredAt)?.slice(0, 10),
        overallScore: row?.overallScore,
        link: `/admin/control/measurement/${row?.playerId}`, // 상세보기 링크
        eightRound: (
            <button
                onClick={() => navigate(`/admin/control/eightRound/report/${row?.playerId}`)}
                className="relative m-auto text-primary-purple text-context-bold w-fit"
            >
                에잇라운드
                <span className="absolute bottom-0 left-0 w-full m-auto border-b border-primary-purple"></span>
            </button>
        ),
    }));
};

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.teamId,
        label: `${arr?.teamName}${arr?.ageGroup ? `(${arr?.ageGroup})` : ''} `, //${arr?.type}
    }));
};

interface teamType {
    ageGroup: string;
    bestSkill: null | string;
    measurementDate: null | string;
    memberCount: null | number;
    overallScore: null | number;
    region: null | string;
    teamId: string;
    teamName: string;
    type: null | string;
    worstSkill: null | string;
}
