// hooks
import { SketchPicker } from 'react-color';
// assets
import { Draw } from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import { useState } from 'react';
interface ItemSelectorProps {
    state: string;
    isActive?: boolean;
    onClick?: () => void;
    colorState: string;
    setColorState: (colorState: string) => void;
    lineWidthState: number;
    enableDrawingMode: (colorState: string, lineWidthState: number) => void;
}

const ItemSelector = ({
    onClick,
    state,
    isActive,
    colorState,
    setColorState,
    lineWidthState,
    enableDrawingMode,
}: ItemSelectorProps) => {
    const [isSelectorShow, setSelectorShow] = useState(false);

    const handleOpenModal = () => {
        setSelectorShow(true);
        if (onClick) onClick(); //
    };

    return (
        <div className="relative">
            <button
                onClick={handleOpenModal}
                className={`rounded-[8px] bg-[#4E4E4E] w-[56px] h-[65px] flex justify-center items-center flex-col gap-[6px] ${
                    isActive ? 'text-primary-green border border-primary-green' : 'text-white'
                }`}
            >
                <Draw className=" w-[25px] h-[26px]" />

                <span
                    className="w-[10px] h-[10px] rounded-[80px] absolute bottom-[4px] right-[4px]"
                    style={{ background: colorState }}
                ></span>
            </button>
            {isSelectorShow && (
                <div
                    className="fixed z-[0] left-0 top-0  w-full h-full"
                    onClick={() => setSelectorShow(false)}
                ></div>
            )}

            {isSelectorShow && (
                <div className="absolute z-[10] w-[220px] p-[12px] top-[-345px] left-[-75px]">
                    <SketchPicker
                        color={colorState}
                        onChangeComplete={(color: any) => {
                            const selectedColor = color.hex;
                            setColorState(selectedColor);
                            enableDrawingMode(selectedColor, lineWidthState);
                            //   setSelectorShow(false);
                        }}
                        disableAlpha
                    />
                </div>
            )}
        </div>
    );
};

export default ItemSelector;
//   const [recentColors, setRecentColors] = useState<string[]>([]);

//   const handleColorChange = (color: any) => {
//     const selectedColor = color.hex;
//     setColorState(selectedColor);
//     enableDrawingMode(selectedColor, lineWidthState);

//     // 최근 색상 업데이트
//     setRecentColors((prev) => {
//       const updated = [
//         selectedColor,
//         ...prev.filter((c) => c !== selectedColor),
//       ];
//       return updated.slice(0, 6); // 최대 6개까지 저장
//     });
//   };
