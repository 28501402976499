// hooks
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// utils
import { ENDPOINT_INJURY } from 'utils/consts/apiEndpoint';
import { handleDateTime } from 'utils/inputHandleFunc/handleTimeValue';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// commons
import DatePicker from 'components/datePicker';
import { showToast } from 'components/toast/Default';

export const History = ({
    id,
    playerName,
    dataIdx,
    injuryDescription,
    treatmentDescription,
    injuryDate,
    returnDate,
    updatedAt,
}: any) => {
    const queryClient = useQueryClient();
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';
    const [editingId, setEditingId] = useState<number | null>(null); // 현재 수정 중인 행의 ID
    const [editForm, setEditForm] = useState({
        injuryDescription: injuryDescription,
        treatmentDescription: treatmentDescription,
    });
    const [editInjuryDate, setEditInjuryDate] = useState(injuryDate);
    const [editReturnDate, setEditReturnDate] = useState(returnDate);

    const patchInjuryHistory = useApiMutation(ENDPOINT_INJURY.history(String(editingId || '')), 'patch', {
        onSuccess: (data) => {
            showToast('부상이력이 변경되었습니다.', 'success');
            setEditingId(null);
        },
        onError: (error) => {
            const message = error?.data?.detailedMessage;
            showToast(message || `부상이력 변경 실패: ${error.message}`, 'error');
        },
    });
    const deleteInjuryHistory = useApiMutation(ENDPOINT_INJURY.history(String(editingId || '')), 'delete', {
        onSuccess: (data) => {
            // 캐시된 데이터에서 해당 ID를 가진 항목을 제거
            queryClient.setQueryData(['injuries', paramPlayerId], (oldData: any) => {
                return {
                    pages: oldData.pages.map((page: any) => ({
                        ...page,
                        data: page.data.filter((item: InjuryHistoryType) => item.id !== editingId),
                    })),
                    pageParams: oldData.pageParams,
                };
            });
            setEditingId(null);
            showToast('부상 이력이 삭제되었습니다.', 'success');
        },
        onError: (error) => {
            const message = error?.data?.detailedMessage;
            showToast(message || '현재 부상이력을 삭제 할 수 없습니다.', 'error');
        },
    });

    // handle
    const updateInjuryHistory = () => {
        if (!editingId) {
            showToast(`부상 이력을 선택해주세요`, 'error');
            return;
        } else if (!patchInjuryHistory.isPending) {
            const injuryBody = {
                injuryDescription: editForm.injuryDescription,
                treatmentDescription: editForm.treatmentDescription,
                injuryDate: editInjuryDate,
                returnDate: editReturnDate,
            };
            showToast(`부상 이력을 수정 중입니다.`, 'info');
            patchInjuryHistory.mutate(injuryBody);
        }
    };

    const removeInjuryHistory = () => {
        if (!deleteInjuryHistory.isPending && editingId) {
            showToast('부상 이력 삭제중입니다.', 'info');
            deleteInjuryHistory.mutate({});
        }
    };
    return (
        <div
            key={id}
            className={`grid grid-cols-6 text-secondary-dGrayMid h-[50px] ${
                dataIdx % 2 === 1 ? 'bg-secondary-mGrayLight' : ''
            }`}
        >
            <textarea
                readOnly
                className={`bg-transparent focus:outline-none resize-none p-[14px] text-center ${
                    editingId === id && 'text-primary-purple font-bold'
                }`}
                value={editingId === id ? '수정중' : handleDateTime(updatedAt)}
            />
            <textarea
                className="bg-transparent focus:outline-none resize-none p-[14px] text-center"
                readOnly={editingId !== id}
                defaultValue={injuryDescription}
                onChange={(e) => setEditForm((prev) => ({ ...prev, injuryDescription: e.target.value }))}
            />
            <textarea
                className="bg-transparent focus:outline-none resize-none p-[14px] text-center"
                readOnly={editingId !== id}
                defaultValue={treatmentDescription}
                onChange={(e) => setEditForm((prev) => ({ ...prev, treatmentDescription: e.target.value }))}
            />

            <div>
                {editingId !== id ? (
                    <div className="flex items-center justify-center h-full p-[10px] gap-[14px]">
                        {editInjuryDate || injuryDate || '부상일'}
                        <CalendarIcon />
                    </div>
                ) : (
                    <DatePicker
                        modalBtn={
                            <div className="flex items-center justify-center h-[50px] gap-[14px]">
                                {editInjuryDate || injuryDate || '부상일'}
                                <CalendarIcon />
                            </div>
                        }
                        selected={editInjuryDate}
                        setSelected={setEditInjuryDate}
                    />
                )}
            </div>
            <div>
                {editingId !== id ? (
                    <div className="flex items-center justify-center h-full gap-[14px]">
                        {editReturnDate || returnDate || '복귀일'}
                        <CalendarIcon />
                    </div>
                ) : (
                    <DatePicker
                        modalBtn={
                            <div className="flex items-center justify-center h-[50px]  gap-[14px]">
                                {editReturnDate || returnDate || '복귀일'}
                                <CalendarIcon />
                            </div>
                        }
                        selected={editReturnDate}
                        setSelected={setEditReturnDate}
                    />
                )}
            </div>
            <div className="flex items-center justify-center">
                {editingId === id ? (
                    <div className="flex gap-[4px]">
                        <button
                            onClick={updateInjuryHistory}
                            className="bg-primary-purple rounded-[8px] text-primary-white w-[52px] h-[44px] text-context-bold"
                        >
                            저장
                        </button>
                        <button
                            onClick={removeInjuryHistory}
                            className="bg-secondary-lGrayDark rounded-[8px] text-primary-white w-[52px] h-[44px] text-context-bold"
                        >
                            삭제
                        </button>
                    </div>
                ) : (
                    <button onClick={() => setEditingId(id)}>
                        <EditPenIcon className="text-primary-purple" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default History;
interface InjuryHistoryType {
    id: number;
    injuryDate: string;
    injuryDescription: string;
    playerId: number;
    playerName: string;
    returnDate: string;
    treatmentDescription: string;
    updatedAt: string;
}
