// hooks
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// icons
import { ReactComponent as SortUpIcon } from 'assets/svgs/sortUp.svg';
import { ReactComponent as SortDownIcon } from 'assets/svgs/sortDown.svg';

interface Column {
    key: string;
    label: string | React.ReactNode;
    sortable?: boolean;
    fixedWidth?: string;
}

interface Row {
    [key: string]: any;
}

interface CustomTableProps {
    columns: Column[];
    rows: Row[];
    equalWidth?: boolean; // true: n분의1, false: 콘텐츠에 맞게
    isPartfixedWidth?: boolean;
    rowHeight?: string;
    minWidth?: string;
    sortInfoFunc?: (key: string, sort: 'ASC' | 'DESC') => void;
}

const CustomTable: React.FC<CustomTableProps> = ({
    columns,
    rows,
    equalWidth = true,
    rowHeight = '',
    minWidth = '',
    isPartfixedWidth = false,
    sortInfoFunc,
}) => {
    const navigate = useNavigate();

    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        if (sortInfoFunc) {
            sortInfoFunc(key, sort);
        }
    };

    return (
        <div className="overflow-x-auto ">
            <table
                className={`${
                    rows?.length === 0 ? 'w-full' : isPartfixedWidth ? 'w-fit' : 'w-full'
                } text-center text-context-regular text-secondary-dGrayMid`}
            >
                <thead className="">
                    <tr className="bg-primary-black text-primary-white h-[50px] rounded-[8px]">
                        {columns.map((column) => (
                            <th
                                key={column.key}
                                className={`${
                                    isPartfixedWidth
                                        ? 'w-fit'
                                        : equalWidth
                                        ? 'w-[calc(100%/_columns.length)]'
                                        : minWidth
                                } ${column === columns[0] ? 'rounded-tl-[8px] rounded-bl-[8px]' : ''} ${
                                    column === columns[columns.length - 1] ? 'rounded-tr-[8px] rounded-br-[8px]' : ''
                                }`}
                                style={{
                                    width: equalWidth ? `${100 / columns.length}%` : '',
                                }}
                            >
                                <div className="flex items-center w-fit px-[10px] m-auto gap-[5px]">
                                    {column.label}
                                    {column.sortable && (
                                        <button className="flex items-center  flex-col gap-[3px] text-primary-white ">
                                            {/* {sortConfig?.key === column.key && ( */}
                                            <SortUpIcon
                                                onClick={() => handleSort(column.key, 'DESC')}
                                                className="hover:text-primary-green"
                                            />
                                            <SortDownIcon
                                                onClick={() => handleSort(column.key, 'ASC')}
                                                className="hover:text-primary-green"
                                            />
                                            {/* )} */}
                                        </button>
                                    )}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="">
                    {rows?.map((row, index) => (
                        <tr
                            key={`columns-${columns.keys}-${index}`}
                            className={`${index % 2 === 1 ? ' rounded-[8px] bg-secondary-mGrayLight' : ''} ${
                                rowHeight ? rowHeight : 'h-[50px]'
                            }`}
                        >
                            {columns.map((column, idx) =>
                                column.key === 'link' ? (
                                    // button 도 추가하는것이 어떨까?
                                    <td
                                        key={`column-${column.key}-${idx}`}
                                        className={`
                                        ${idx === 0 ? 'rounded-bl-[8px] rounded-tl-[8px]' : ''}
                                        ${idx === columns.length - 1 ? 'rounded-br-[8px] rounded-tr-[8px]' : ''}
                                    `}
                                    >
                                        <button
                                            onClick={() => {
                                                navigate(row[column.key]);
                                            }}
                                            className={
                                                column.key === 'link'
                                                    ? 'text-primary-purple relative text-context-bold'
                                                    : ''
                                            }
                                        >
                                            {column.label}
                                            {column.key === 'link' && (
                                                <span className="absolute bottom-0 left-0 w-full border-b border-primary-purple"></span>
                                            )}
                                        </button>
                                    </td>
                                ) : (
                                    <td
                                        className={`
                                        ${column.fixedWidth && `${column.fixedWidth} `}
                                    ${idx === 0 ? 'rounded-bl-[8px] rounded-tl-[8px]' : ''}
                                    ${idx === columns.length - 1 ? 'rounded-br-[8px] rounded-tr-[8px]' : ''}
                                `}
                                    >
                                        {row[column.key]}
                                    </td>
                                )
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default CustomTable;

{
    /* <CustomTable columns={columns} rows={rows} equalWidth={true} /> */
}
