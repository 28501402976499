const DataSummary = ({ analysisData }: any) => {
    return (
        <div>
            <div className="text-subtitle-medium-bold text-secondary-dGrayDark">측정 요약</div>

            <div className="flex gap-[12px] w-full">
                <div className="w-full flex justify-center gap-[8px] items-center bg-[#F8F8F8] h-[65px] rounded-[14px]">
                    <div className="text-context-regular">가장 우수한 기술은</div>
                    <span className="bg-primary-green font-bold text-[14px] text-secondary-dGrayMid py-[4px] px-[12px] rounded-[80px]">
                        {analysisData?.best || '측정필요'}
                    </span>
                </div>
                <div className="w-full flex justify-center gap-[8px] items-center bg-[#F8F8F8] h-[65px] rounded-[14px]">
                    <div className="text-context-regular">가장 필요한 기술은</div>
                    <span className="bg-status-error font-bold text-[14px] text-primary-white py-[4px] px-[12px] rounded-[80px]">
                        {analysisData?.worst || '측정필요'}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default DataSummary;
