// icons
import { ReactComponent as EmptyFileIcon } from 'assets/svgs/emptyFile.svg';
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
// player component
import ProgressBar from 'components/progressBar';

const ProgressBarCard = ({ isPrint, firstTouchAnalysis }: any) => {
    const {
        failCount = 0,
        failRate = 0,
        finalScore = 0,
        firstTouchId,
        touchAndTurnCount = 0,
        touchAndTurnRate = 0,
        touchOnlyCount = 0,
        touchOnlyRate = 0,
    } = firstTouchAnalysis || {};
    const totalCount = failCount + touchAndTurnCount + touchOnlyCount || 10;
    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex items-center justify-between">
                <div className="flex gap-[8px] items-center mr-[5px] justify-center">
                    <span className="flex gap-[5px] items-center text-secondary-lGrayDark">
                        <EmptyFileIcon />총 점수
                    </span>
                    <span className="flex gap-[5px] items-center">
                        <span className="text-[34px] font-bold text-primary-black">{finalScore}</span>
                        <span className="text-secondary-lGrayDark">점</span>
                    </span>
                </div>
                {!isPrint && (
                    <button className="cursor-default text-secondary-lGrayDark text-context-regular">
                        <div className="flex gap-[10px] p-[10px] rounded-[8px] border border-secondary-lGrayMid ml-[2px]">
                            <MiniVideoIcon />
                            측정필요
                        </div>
                    </button>
                )}
            </div>

            <div className="flex gap-[15px]">
                <div className="w-[550px] flex flex-col gap-[10px] justify-center">
                    <ProgressBar
                        label="성공"
                        targetRate={totalCount}
                        achievementRate={touchAndTurnCount}
                        customColor="#5651E3"
                    />

                    <ProgressBar
                        label="부분성공"
                        targetRate={totalCount}
                        achievementRate={touchOnlyCount}
                        customColor="#19DE93"
                    />
                    <ProgressBar
                        label="실패"
                        targetRate={totalCount}
                        achievementRate={failCount}
                        customColor="#79788E"
                    />
                </div>

                <div className="grid grid-cols-3 h-[100px] w-[725px] bg-[#F8F8F8] rounded-[14px]">
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-secondary-dGrayLight">성공률</div>
                        <div>
                            <span className="text-title-small-bold text-primary-purple">{touchAndTurnRate}</span>
                            <span className="text-[#7c7c7c] text-context-bold">%</span>
                        </div>
                    </div>

                    <div className="flex flex-col items-center justify-center">
                        <div className="text-secondary-dGrayLight">부분성공률</div>
                        <div>
                            <span className="text-title-small-bold text-status-correct">{touchOnlyRate}</span>
                            <span className="text-[#7c7c7c] text-context-bold">%</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-secondary-dGrayLight">실패율</div>
                        <div>
                            <span className="text-title-small-bold text-secondary-mGrayDark">{failRate}</span>
                            <span className="text-[#7c7c7c] text-context-bold">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProgressBarCard;
