import React from 'react';
import { Helmet } from 'react-helmet-async';
interface SEOType {
    title?: string;
    description?: string;
    keywords?: string;
    image?: string;
    url?: string;
}

const SEO = ({ title, description, keywords, image, url }: SEOType) => {
    return (
        <Helmet>
            {/* 기본 메타 태그 */}
            <title>{title}</title>
            <meta
                name="description"
                content={description}
            />
            {keywords && (
                <meta
                    name="keywords"
                    content={keywords}
                />
            )}

            {/* Open Graph 태그 */}
            <meta
                property="og:title"
                content={title}
            />
            <meta
                property="og:description"
                content={description}
            />
            {image && (
                <meta
                    property="og:image"
                    content={image}
                />
            )}
            {url && (
                <meta
                    property="og:url"
                    content={url}
                />
            )}

            {/* Twitter 태그 */}
            <meta
                name="twitter:title"
                content={title}
            />
            <meta
                name="twitter:description"
                content={description}
            />
            {image && (
                <meta
                    name="twitter:image"
                    content={image}
                />
            )}
        </Helmet>
    );
};

SEO.defaultProps = {
    title: 'AISoccer',
    description:
        '인공지능 기술을 통해 축구 선수의 퍼포먼스를 과학적으로 측정하고 분석하며, 데이터를 기반으로 맞춤형 성장 계획과 팀 퍼포먼스 향상 전략을 제공합니다.',
    keywords:
        'AISoccer, 축구 데이터 분석, 축구훈련관리, AI 훈련, 축구 AI 솔루션, AISoccer, AI 축구, 갤로핑, 갤로핑 AI, AI Soccer, AI-Soccer, 축구 훈련 분석, 개인화 훈련 피드백, 축구 데이터 분석',
    image: 'https://aisoccer.ai/aisoccer512.png',
    url: 'https://aisoccer.ai/',
};

export default SEO;
