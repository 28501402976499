// hooks
import { useState, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import { showToast } from 'components/toast/Default';
// admin components
import ReportSubHeader from 'pages/admin/report/ReportHeader';
// api
import axiosInstance from 'api/axiosInstance';
// common
import EmptyBar from 'components/emptyBar';
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// utils
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
// admin components
import ReportRowElement from 'pages/admin/report/ReportRowElement';
const Report = () => {
    const { ref, inView } = useInView();
    const navigate = useNavigate();
    const [checkAll, setCheckAll] = useState(false);
    const [measurementIds, setMeasurementIds] = useState<string[]>([]);
    const [playerName, setPlayerName] = useState('');
    const [fixedPlayerName, setFixedPlayerName] = useState('');

    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
        queryKey: ['getAdminGroupReport', fixedPlayerName],
        queryFn: ({ pageParam = 1 }) =>
            axiosInstance
                .get(`${ENDPOINT_ADMIN.groupReport}?playerName=${fixedPlayerName}&page=${pageParam}&size=10`)
                .then((response) => response.data),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
    });
    const flattenedRows = data?.pages.flatMap((page) => page.data) || [];

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);

    const handleAllSelect = (measurementId: string) => {
        setMeasurementIds((prev) => [...prev, measurementId]);
    };
    useEffect(() => {
        if (!checkAll) setMeasurementIds([]);
    }, [checkAll]);
    const searchName = () => {
        showToast('이름 검색중입니다', 'info');
        setFixedPlayerName(playerName);
    };
    return (
        <div className="wrapper">
            <ReportSubHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                <div
                    className="w-[500px] relative"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            searchName();
                        }
                    }}
                >
                    <input
                        placeholder="이름을 검색해주세요"
                        value={playerName}
                        onChange={(e) => setPlayerName(e.target.value)}
                        className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                    />
                    <button
                        onClick={searchName}
                        className="absolute right-[12px] top-[12px]"
                    >
                        <SearchIcon />
                    </button>
                </div>

                <div className="flex flex-col">
                    {/* <div className="flex gap-[10px] mb-[10px] items-center">
                        <SqureCheckbox
                            checked={checkAll}
                            onChange={(value) => setCheckAll(value)}
                        />
                        <div className="text-context-bold">전체선택</div>
                    </div> */}
                    {flattenedRows?.map((el, idx) => (
                        <ReportRowElement
                            {...el}
                            key={el.playerId}
                            idx={idx}
                            measurementIds={measurementIds}
                            setMeasurementIds={setMeasurementIds}
                            checkAll={checkAll}
                            allSelecter={handleAllSelect}
                        />
                    ))}
                    <div
                        ref={ref}
                        className="h-4"
                    />
                    {isFetching && !isFetchingNextPage && (
                        <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
                    )}
                    {isFetchingNextPage && (
                        <div className="py-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                    )}
                    {!isFetching && !isFetchingNextPage && !hasNextPage && (
                        <div className="py-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                    )}
                </div>
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default Report;
