// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// commons
import Dropdown from 'components/dropdown/Default';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// report components
import GoalsElement from 'pages/staff/control/schedule/report/read/reportPlayer/GoalsElement';
import { GoalsElementType } from 'pages/staff/control/schedule/report/read/reportPlayer/GoalsElement';

const Goals = () => {
    const location = useLocation();
    const { id } = useParams();

    // api
    const getGoals = useApiQuery(['getGoals'], ENDPOINT_REPORT.goals(id), 'get');

    return (
        <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                득점
            </div>
            <div className="flex flex-col gap-[10px]">
                <div>
                    <div className="flex gap-[10px] text-context-regular w-full bg-red">
                        <div className="w-[500px] text-center ">득점</div>
                        <div className="w-[381px] text-center ">도움</div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col gap-[10px]">
                        {getGoals?.data &&
                            getGoals?.data?.map((el: GoalsElementType) => (
                                <GoalsElement
                                    key={el?.goalId}
                                    {...el}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Goals;

interface SubstitutionProps {
    lineup: LineupType[];
    activeLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
    inactiveLineup: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        positions: string[];
    }[];
}
interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};
