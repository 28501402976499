export const ENDPOINT = {
    player: {
        profile: '/player/profile',
        search: '/api/user/player/search',
    },
    user: {
        register: '/api/user/register', // 회원가입
        profile: '/api/user/player/profile', // 선수 프로필 등록
        staffPlayerDetail: (signedId: string) => `/api/user/staff/player/${signedId}/detail`, //팀 스태프에 의한 선수 프로필 등록
        role: (userId: string) => `/api/user/${userId}/role`, // 사용자 역할 변경
        staffProfile: '/api/user/staff/profile', // 스태프 프로필 수정
        profileImage: (playerId: string) => `/api/user/player/${playerId}/profile-image`, // 코치나 감독이 프로필이미지 변경하는 것
        detail: (playerId: string) => `/api/user/player/${playerId}/detail`, // 어드민이 권한 요청 변경하는 것
        self: '/api/user/role/self', //자신의 권한 변경 요청
        password: '/api/user/password',
        password_initial: '/api/user/password/initial',
        user: 'api/user', // 내 프로필 조회
    },
    authentication: {
        login: '/api/login',
    },
    email: {
        send: '/api/email/send',
        verify: '/api/email/verify',
    },
};
export const DASHBOARD = {
    player: (playerId?: string) => `/api/measurement/dashboard/${playerId}`,
    playerDetail: (playerId?: string) => `/api/measurement/dashboard/${playerId}/detail`,
    measurement: (measurementId?: string) => `/api/measurement/analysis/${measurementId}`,
};
export const ENDPOINT_TEAM = {
    admin: {
        super: { team: '/api/team' },
    },
    staff: {
        team: {
            request: '/api/team/membership/request/team',
            membership: '/api/team/membership',
            ageGroups: '/api/team/age-groups',
            player: (playerId: string) => `/api/team/membership/${playerId}`,
            playerParams: (params: any) => {
                const query = new URLSearchParams(params).toString();
                return `/api/team/membership/player?${query}`;
            },
            release: (playerId: string) => `/api/team/membership/${playerId}/release`,
        },
    },
    player: {
        request: (requestId?: string) =>
            requestId ? `/api/team/membership/request/${requestId}` : `/api/team/membership/request`,
        membership: '/api/team/membership/request/player',
    },
    teamSearchParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/team/search?${query}`;
    },
    emblem: (teamId: string) => `/api/team/${teamId}/emblem`,
};
export const ENDPOINT_MANAGEMENT_PROFILE = {
    player: (playerId: string) => `/api/management/player/profile/${playerId}`, // 어드민이 권한 요청 변경하는 것
    my: `/api/management/player/profile/my`,
    season: {
        team: (seasonYear: string, params?: { [key: string]: string | number | undefined }) => {
            const baseUrl = `/api/management/team/profile/season/${seasonYear}`;
            if (!params) return baseUrl;
            const queryString = new URLSearchParams(
                Object.entries(params)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, String(value)])
            ).toString();

            return `${baseUrl}?${queryString}`;
        },
        players: (seasonYear: string, params?: { [key: string]: string | number | undefined }) => {
            const baseUrl = `/api/management/team/profile/season/${seasonYear}/players`;
            if (!params) return baseUrl;
            const queryString = new URLSearchParams(
                Object.entries(params)
                    .filter(([_, value]) => value !== undefined)
                    .map(([key, value]) => [key, String(value)])
            ).toString();

            return `${baseUrl}?${queryString}`;
        },
    },
};

export const ENDPOINT_CAREER = {
    career: `/api/management/career`,
    history: (historyId?: string) => `/api/management/career/${historyId}`,
    player: (playerId?: string) => `/api/management/career/player/${playerId}`,
};
export const ENDPOINT_INJURY = {
    current: (playerId?: string) =>
        playerId ? `/api/management/injury/current/${playerId}` : `/api/management/injury/current`,
    history: (historyId?: string) =>
        historyId ? `/api/management/injury/history/${historyId}` : `/api/management/injury/history`,
    playerHistory: (playerId?: string) => `/api/management/injury/history/player/${playerId}`,
};
export const ENDPOINT_EVALUATION = {
    evaluation: (evaluationId?: string) =>
        evaluationId ? `/api/management/evaluation/${evaluationId}` : `/api/management/evaluation`,
    period: (periodId?: string, params?: { [key: string]: string | number | undefined }) => {
        const baseUrl = periodId
            ? `/api/management/evaluation/period/${periodId}`
            : `/api/management/evaluation/period`;
        if (!params) return baseUrl;
        const queryString = new URLSearchParams(
            Object.entries(params)
                .filter(([_, value]) => value !== undefined)
                .map(([key, value]) => [key, String(value)])
        ).toString();

        return `${baseUrl}?${queryString}`;
    },
    team: (teamId: string) => `/api/management/evaluation/period/team/${teamId}`,
};
export const ENDPOINT_REPORT = {
    report: (reportId?: string) =>
        reportId ? `/api/management/match/report/${reportId}` : `/api/management/match/report`,
    season: (seasonYear?: string) => `/api/management/match/report/season/${seasonYear}`,
    seasonStat: (seasonYear?: string, params?: { [key: string]: string | number | undefined }) => {
        const baseUrl = seasonYear
            ? `/api/management/match/report/season-stat/${seasonYear}`
            : `/api/management/evaluation/period`;
        if (!params) return baseUrl;
        const queryString = new URLSearchParams(
            Object.entries(params)
                .filter(([_, value]) => value !== undefined)
                .map(([key, value]) => [key, String(value)])
        ).toString();

        return `${baseUrl}?${queryString}`;
    },
    lineup: (reportId?: string) => `/api/management/match/report/${reportId}/lineup`,
    lineupId: (reportId?: string, lineupId?: string) => `/api/management/match/report/${reportId}/lineup/${lineupId}`,
    inactive: (reportId?: string) => `/api/management/match/report/${reportId}/lineup/inactive`,
    active: (reportId?: string) => `/api/management/match/report/${reportId}/lineup/active`,
    starting: (reportId?: string) => `/api/management/match/report/${reportId}/lineup/starting`,
    substitution: (reportId?: string) => `/api/management/match/report/${reportId}/lineup/substitution`,
    substitutionId: (reportId?: string, substitutionId?: string) =>
        `/api/management/match/report/${reportId}/lineup/substitution/${substitutionId}`,
    substitutions: (reportId?: string) => `/api/management/match/report/${reportId}/lineup/substitutions`,
    goal: (reportId?: string) => `/api/management/match/report/${reportId}/goal`,
    goalId: (reportId?: string, goalId?: string) => `/api/management/match/report/${reportId}/goal/${goalId}`,
    goals: (reportId?: string) => `/api/management/match/report/${reportId}/goals`,
    shootout: (reportId?: string) => `/api/management/match/report/${reportId}/shootout`,
    shootouts: (reportId?: string) => `/api/management/match/report/${reportId}/shootouts`,
    shootoutId: (reportId?: string, shootoutId?: string) =>
        `/api/management/match/report/${reportId}/shootout/${shootoutId}`,
    review: (reportId?: string) => `/api/management/match/report/${reportId}/review`,
    record: (reportId?: string, playerId?: string) =>
        `/api/management/match/report/${reportId}/player/${playerId}/record`,
};
export const ENDPOINT_MATCH_RECORD = {
    season: (playerId?: string, seasonYear?: string) =>
        `/api/management/match/report/player/${playerId}/season/${seasonYear}`,
};
export const ENDPOINT_TEAM_DIARY = {
    diary: `/api/management/team-training/diary`,
    diaryId: (diaryId?: string) => `/api/management/team-training/diary/${diaryId}`,
    playerDiaries: (diaryId?: string) => `/api/management/team-training/diary/${diaryId}/player-diaries`,
};
export const ENDPOINT_PLAYER_DIARY = {
    playerDiary: `/api/management/team-training/player-diary`,
    diaryId: (diaryId?: string) => `/api/management/team-training/player-diary/${diaryId}`,
    staffPlayerDiary: (diaryId?: string) => `/api/management/team-training/player-diary/staff/${diaryId}`,
};

export const ENDPOINT_TEAM_ATTENDANCE = {
    trainingScores: (attendanceId?: string) =>
        `/api/management/team-training/attendance/${attendanceId}/training-scores`,
    status: (attendanceId?: string) => `/api/management/team-training/attendance/${attendanceId}/status`,
    absenceReason: (attendanceId?: string) => `/api/management/team-training/attendance/${attendanceId}/absence-reason`,
};
export const ENDPOINT_TEAM_TRAINING_SESSION = {
    session: (diaryId?: string) => `/api/management/team-training/diary/${diaryId}/session`,
    sessionId: (sessionId?: string) => `/api/management/team-training/diary/session/${sessionId}`,
    sequence: (sessionId?: string) => `/api/management/team-training/diary/session/${sessionId}/sequence`,
    reset: (sessionId?: string) => `/api/management/team-training/diary/session/${sessionId}/reset`,
};
export const ENDPOINT_TEAM_SESSION_TEMPLATES = {
    sessionTemplate: `/api/management/team-training/session-template`,
    sessionTemplateId: (sessionTemplateId?: string) =>
        `/api/management/team-training/session-template/${sessionTemplateId}`,
};

export const ENDPOINT_MANAGEMENT = {
    teamLog: `/api/management/team-log`,
    teamLogId: (logId?: string) => `/api/management/team-log/${logId}`,
    search: `/api/management/team-log/search`,
    file: `/api/file/team-log`,
};

export const EXTERNAL_ENDPOINT = {
    googleForm:
        'https://docs.google.com/forms/u/0/d/e/1FAIpQLSdJciNY7U1MtvHqZ9mQ1XnYhh_mOvcGICIJlOXqAiRQ3jP1Bw/formResponse', // 폼의 제출 URL
    termsOfUse: 'https://www.notion.so/1512884832fc800cb8f3f5b6ffad95e9',
    privacyPolicy: 'https://www.notion.so/1512884832fc80b3bb8ad6dcbfbfb8f0',
    videoStreaming: (videoId: string, token: string) =>
        `https://dev.aisoccer.ai/api/measurement/dribble-one/video/${videoId}/stream?token=${token}`,
};

export const ENDPOINT_ADMIN = {
    player: '/api/admin/player',
    playerParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/admin/player?${query}`;
    },
    top20: `/api/admin/player/top20`,
    team: '/api/admin/team',
    teamParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/admin/team?${query}`;
    },
    teamStaffParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/admin/team-staff?${query}`;
    },
    admin: '/api/admin/admin',
    adminParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/admin/admin?${query}`;
    },
    approval: (userId: string) => `/api/admin/admin/${userId}/approval`,
    groupReport: '/api/admin/player/group-report',
    measurement: '/api/admin/measurement',
};

export const ENDPOINT_MEASUREMENT = {
    measurement: (measurementId?: string) => (measurementId ? `/api/measurement/${measurementId}` : `/api/measurement`),
    measurementTimeParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/time?${query}`;
    },
    measurementDateParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/date?${query}`;
    },
    feedback: (measurementId?: string) =>
        measurementId ? `/api/measurement/${measurementId}/feedback` : `/api/measurement/feedback`,
    player: (playerId: string) => `/api/measurement/player/${playerId}`,
    playerMeasurement: (measurementId: string) => `/api/measurement/${measurementId}/player`, // 측정 선수 변경
    physical: (physicalId: string) => `/api/measurement/physical/${physicalId}`,
    physicalParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/physical/record?${query}`;
    },
    physicalReset: (physicalId: string) => `/api/measurement/physical/${physicalId}/reset`,
    // 드리블 1 : 에잇라운드 관련 API
    dribbleOneHistory: (dribbleOneId: string) => `/api/measurement/dribble-one/${dribbleOneId}/history`, // 아래 dribbleOneList 로 대체되었음. 나중에 이 엔드포인트 삭제 가능
    dribbleOneList: (playerId: string) => `/api/measurement/dribble-one/player/${playerId}/list`,
    dribbleOneLatest: (playerId: string) => `/api/measurement/dribble-one/player/${playerId}/latest`,

    dribbleOneDetail: (dribbleOneId: string) => `/api/measurement/dribble-one/${dribbleOneId}/detail`,
    dribbleOneStream: (videoInfoId: string) => `/api/measurement/dribble-one/video/${videoInfoId}/stream`,
    dribbleOneToken: (videoInfoId: string) => `/api/measurement/dribble-one/video/${videoInfoId}/token`,
    dribbleTwo: (dribbleTwoId: string) => `/api/measurement/dribble-two/${dribbleTwoId}`,
    dribbleTwoParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/dribble-two/record?${query}`;
    },
    dribbleTwoReset: (dribbleTwoId: string) => `/api/measurement/dribble-two/${dribbleTwoId}/reset`,
    firstTouch: (firstTouchId: string) => `/api/measurement/first-touch/${firstTouchId}`,
    firstTouchParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/first-touch/record?${query}`;
    },
    firstTouchReset: (firstTouchId: string) => `/api/measurement/first-touch/${firstTouchId}/reset`,
    passOne: (passOneId: string) => `/api/measurement/pass-one/${passOneId}`,
    passOneParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/pass-one/record?${query}`;
    },
    passOneReset: (passOneId: string) => `/api/measurement/pass-one/${passOneId}/reset`,
    passTwo: (passTwoId: string) => `/api/measurement/pass-two/${passTwoId}`,
    passTwoParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/pass-two/record?${query}`;
    },
    passTwoReset: (passTwoId: string) => `/api/measurement/pass-two/${passTwoId}/reset`,
    shootingOne: (shootingOneId: string) => `/api/measurement/shooting-one/${shootingOneId}`,
    shootingOneParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/shooting-one/record?${query}`;
    },
    shootingOneReset: (shootingOneId: string) => `/api/measurement/shooting-one/${shootingOneId}/reset`,
    shootingTwo: (shootingTwoId: string) => `/api/measurement/shooting-two/${shootingTwoId}`,
    shootingTwoParams: (params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/shooting-two/record?${query}`;
    },
    shootingTwoReset: (shootingTwoId: string) => `/api/measurement/shooting-two/${shootingTwoId}/reset`,

    // 측정완료
    complete: (measurementId: string) => `/api/measurement/${measurementId}/complete`,
};

export const ENDPOINT_MASUREMENT_STATS = {
    team: (teamId: string) => `/api/measurement/team-stats/${teamId}`,
    players: (teamId: string, params: any) => {
        const query = new URLSearchParams(params).toString();
        return `/api/measurement/team-stats/${teamId}/player?${query}`;
    },
    dribbleOne: (teamId: string) => `/api/measurement/team-stats/${teamId}/dribble-one`,
    playerGroupReport: `/api/measurement/team-stats/group-report/player`,
};
