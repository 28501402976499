// hooks
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM_SESSION_TEMPLATES } from 'utils/consts/apiEndpoint';
// training components
import TrainingSubHeader from 'pages/staff/control/training/TrainingSubHeader';
import SessionTemplateInfo from 'pages/staff/control/training/session/[id]/SessionTemplateInfo';
import TemplateSession from 'pages/staff/control/training/session/[id]/TemplateSession';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const SessionDetail = () => {
    const { id } = useParams();
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    // useApiQuery
    const getSessionTemplateId = useApiQuery(
        ['getSessionTemplateId', `${id}`],
        ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplateId(id),
        'get',
        {
            enabled: !!id,
        }
    );
    // 세션 템플릿저장
    const [sessionTemplateInfo, setSessionTemplateInfo] = useState<SessionTemplateInfoType>(getSessionTemplateId?.data);
    const [sessionTemplateJSON, setSessionTemplateJSON] = useState<SessionJSONInfoType>({
        id: '',
        tacticalBoardJson: '',
    });
    useEffect(() => {
        if (getSessionTemplateId?.data) {
            setSessionTemplateInfo(getSessionTemplateId?.data);
            setSessionTemplateJSON({
                id: getSessionTemplateId?.data?.id,
                tacticalBoardJson: getSessionTemplateId?.data?.tacticalBoardJson,
            });
        }
    }, [getSessionTemplateId?.data]);

    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };

    return (
        <div className="wrapper">
            {getSessionTemplateId?.isPending && <SoccerBallLodaing />}
            <TrainingSubHeader
                title="훈련 세션 상세"
                handleNavigate={() => handleNavigate('/staff/control/training')}
            />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <Tabs
                    tabs={tabData}
                    defaultActiveTab={1}
                    onTabChange={(idx: number) => handleNavigate(`/staff/control/training/${tabData[idx]?.key}`)}
                    className={'w-fit'}
                    colorType="black"
                />
                <div className="h-[20px]"></div>
                <SessionTemplateInfo
                    sessionTemplateInfo={sessionTemplateInfo}
                    setSessionTemplateInfo={setSessionTemplateInfo}
                />
                <TemplateSession
                    sessionTemplateJSON={sessionTemplateJSON}
                    setSessionTemplateJSON={setSessionTemplateJSON}
                    handleNavigate={handleNavigate}
                    sessionTemplateInfo={sessionTemplateInfo}
                    setSessionTemplateInfo={setSessionTemplateInfo}
                />
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default SessionDetail;

export const handleRows = (rows: any[]) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        name: `${row?.name} `,
        sessionPoint: row?.sessionPoint,
        createdAt: row?.createdAt,
        updatedAt: row?.updatedAt,
        link: `/admin/feedback/${row?.measurementId}?measurementId=${row?.measurementId}&dateTime=${row?.measuredAt}`,
    }));
};
export const tabData = [
    {
        label: '일지',
        key: 'diary',
    },
    {
        label: '세션',
        key: 'session',
    },
];

interface SessionTemplateInfoType {
    id: number;
    name: string | null;
    tacticalBoardJson: string | null;
    sessionPoint: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
}

interface SessionJSONInfoType {
    id: string | null;
    tacticalBoardJson: string | null;
}
