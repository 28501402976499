// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams, useNavigate } from 'react-router-dom';
import { useApiQuery, useApiMutation, useApiFormDataMutation } from 'hooks/useDynamicApi';
// commons
import Dropdown from 'components/dropdown/Default';
import { showToast } from 'components/toast/Default';
import EmptyBar from 'components/emptyBar';
import EventSubHeader from '../components/EventSubHeader';
import FileUploadBox from 'pages/staff/control/event/components/FileUploadBox';
// utils
import { eventOptions } from 'utils/consts/diaryInfo';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_MANAGEMENT } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const EventDetail = () => {
    const navigate = useNavigate();
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const { id } = useParams();
    const handleParamNavigate = useParamNavigate();
    // useApiQuery
    const getTeamLogsId = useApiQuery(['getTeamLogsId', `${id}`], ENDPOINT_MANAGEMENT.teamLogId(`${id}`), 'get', {
        enabled: !!id,
    });
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [uploadedFileIds, setUploadedFileIds] = useState<string[]>([]);
    useEffect(() => {
        if (getTeamLogsId?.data) {
            setCategory(getTeamLogsId.data.category);
            setTitle(getTeamLogsId.data.title);
            setContent(getTeamLogsId.data.content);

            const mappedFiles: UploadFile[] =
                getTeamLogsId.data.files?.map((f: any) => ({
                    id: f.id.toString(),
                    file: null,
                    fileName: f.originalFilename,
                    url: f.s3Url,
                    progress: 100,
                    isUploaded: true,
                })) || [];

            setFiles(mappedFiles);
            setUploadedFileIds(mappedFiles.map((f) => f.id)); // ✅ 기존 파일들도 fileIds에 포함
        }
    }, [getTeamLogsId?.data]);

    // useApiMutation
    const patchTeamLog = useApiMutation(ENDPOINT_MANAGEMENT.teamLogId(`${id}`), 'patch', {
        onSuccess: (data) => {
            // showToast('기록이 수정되었습니다.', 'success');
            navigate(-1);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '기록 수정 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateTeamLog = () => {
        if (!patchTeamLog.isPending) {
            // showToast('기록 수정중입니다.', 'info');
            patchTeamLog.mutate({
                title,
                content,
                category,
                fileIds: uploadedFileIds, // ✅ 여기에 파일 ID 배열 들어감
            });
        }
    };

    const postTeamLogFile = useApiFormDataMutation(ENDPOINT_MANAGEMENT.file, 'post', {
        onSuccess: (data) => {
            // showToast("팀로그 파일이 업로드되었습니다.", "success");
        },

        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '팀로그 파일 업로드 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const handleUploadFile = (file: File, fileIdCallback: (id: string) => void) => {
        const formData = new FormData();
        formData.append('file', file);

        postTeamLogFile.mutate(formData, {
            onSuccess: (res: { id: string }) => {
                setUploadedFileIds((prev) => [...prev, res.id]);
                fileIdCallback(res.id); // 개별 파일 상태에도 반영 가능
            },
        });
    };

    return (
        <div className="wrapper">
            <EventSubHeader title={'기록 상세'} />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full flex flex-col gap-[10px] bg-white rounded-[10px] p-[30px] ">
                <div className="flex gap-[10px]">
                    <Dropdown
                        disabled={staffRole === 'OFFICE'}
                        placeholder="카테고리"
                        options={eventOptions}
                        value={category}
                        onChange={setCategory}
                        className="w-[130px]"
                    />
                    <div className="w-[500px]">
                        <input
                            readOnly={staffRole === 'OFFICE'}
                            className="inputCommonStyle inputDefaultStyle "
                            placeholder="제목"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                </div>
                <textarea
                    readOnly={staffRole === 'OFFICE'}
                    placeholder="내용을 입력해주세요"
                    className="resize-none bg-secondary-mGrayLight focus:outline-none rounded-[8px] p-[12px] w-[1340px] h-[250px]"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <FileUploadBox
                    files={files}
                    setFiles={setFiles}
                    onUpload={handleUploadFile}
                />
                <div className="flex justify-center gap-[10px]">
                    <button
                        onClick={() => navigate(-1)}
                        className="px-[20px] py-[12px] bg-secondary-dGrayLight text-white text-context-bold rounded-[8px]"
                    >
                        취소
                    </button>
                    <button
                        onClick={updateTeamLog}
                        className="px-[20px] py-[12px] bg-primary-purple text-white text-context-bold rounded-[8px]"
                    >
                        업로드
                    </button>
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default EventDetail;

interface UploadFile {
    id: string; // 서버 file ID
    file: File | null; // 새로 업로드한 경우만 File 객체 존재
    fileName: string; // 공통 이름
    url?: string; // 기존 파일의 s3 URL
    progress: number; // 0~100
    isUploaded?: boolean; // 기존 서버 파일 여부
}
