// hooks
import { useState } from 'react';
// assets
import {
    ball_black,
    ball_blue,
    cone_blue,
    cone_red,
    cone_yellow,
    flag_red,
    post,
    ring_blue,
    ring_red,
    ring_yellow,
    safetyCone,
    stick_red,
} from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import { TriangleTip } from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
interface ItemSelectorProps {
    state: string;
    isActive?: boolean;
    label?: string;
    addImageFromUrl?: (url: string) => void;
    onClick?: () => void;
}

const ItemSelector = ({ onClick, addImageFromUrl, state, label, isActive }: ItemSelectorProps) => {
    const [isSelectorShow, setSelectorShow] = useState(false);
    const [curItem, setCurItem] = useState(ball_black);
    const handleOpenModal = () => {
        setSelectorShow(true);
        if (onClick) onClick(); //
    };
    return (
        <div className="relative">
            <button
                onClick={handleOpenModal}
                className={`rounded-[8px] bg-[#4E4E4E] w-[56px] h-[65px] flex justify-center items-center flex-col gap-[6px] ${
                    isActive ? 'text-primary-green border border-primary-green' : 'text-white'
                }`}
            >
                <img
                    src={curItem}
                    alt={'curItem'}
                    className="w-[24px] h-[24px]"
                />
                {label && <span className="text-[12px] w-[56px] text-center">{label}</span>}
            </button>
            {isSelectorShow && (
                <div
                    className="fixed z-[0] left-0 top-0  w-full h-full"
                    onClick={() => setSelectorShow(false)}
                ></div>
            )}
            {isSelectorShow && (
                <div className="absolute z-[10] flex flex-wrap gap-[8px] w-[208px] h-[272px] p-[12px] bg-[#212121] rounded-[8px] top-[-300px] left-[-75px]">
                    {imageList?.map((el) => (
                        <div
                            key={el.title}
                            onClick={() => {
                                setCurItem(el.imgSrc);
                                if (addImageFromUrl) addImageFromUrl(el.imgSrc);
                                setSelectorShow(false);
                            }}
                            className="w-[56px] h-[56px] bg-[#4E4E4E] rounded-[8px] flex justify-center items-center"
                        >
                            <img
                                src={el.imgSrc}
                                alt={el.title}
                                className={el.size}
                            />
                        </div>
                    ))}
                </div>
            )}
            {isSelectorShow && (
                <div className="absolute  left-0 flex justify-center w-full  h-fit top-[-45px]">
                    <TriangleTip className="" />
                </div>
            )}
        </div>
    );
};

export default ItemSelector;

export const imageList = [
    { title: 'post', imgSrc: post, size: 'w-[40px] h-[33px]' },
    { title: 'ball_black', imgSrc: ball_black, size: 'w-[24px] h-[24px]' },
    { title: 'ball_blue', imgSrc: ball_blue, size: 'w-[24px] h-[24px]' },
    { title: 'ring_red', imgSrc: ring_red, size: 'w-[38px] h-[22px]' },
    { title: 'ring_blue', imgSrc: ring_blue, size: 'w-[38px] h-[22px]' },
    { title: 'ring_yellow', imgSrc: ring_yellow, size: 'w-[38px] h-[22px]' },
    { title: 'safetyCone', imgSrc: safetyCone, size: 'w-[27px] h-[33px]' },
    { title: 'flag_red', imgSrc: flag_red, size: 'w-[26px] h-[39px]' },
    { title: 'stick_red', imgSrc: stick_red, size: 'w-[6px] h-[40px]' },
    { title: 'cone_red', imgSrc: cone_red, size: 'w-[24px] h-[18px]' },
    { title: 'cone_blue', imgSrc: cone_blue, size: 'w-[24px] h-[18px]' },
    { title: 'cone_yellow', imgSrc: cone_yellow, size: 'w-[24px] h-[18px]' },
];
