// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
import Diary from 'pages/staff/control/training/diary';
import Session from 'pages/staff/control/training/session';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// training components
import TrainingSubHeader from 'pages/staff/control/training/TrainingSubHeader';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const Training = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const location = useLocation()?.pathname?.split('/');
    const defaultTab = location[4] || 'diary';

    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [curTabInfo, setCurTabInfo] = useState(tabData[0]);
    useEffect(() => {
        if (defaultTab) {
            defaultTab === 'diary' ? setCurTabInfo(tabData[0]) : setCurTabInfo(tabData[1]);
        }
    }, [defaultTab]);
    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };
    return (
        <div className="wrapper">
            <TrainingSubHeader title={curTabInfo?.header} />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <Tabs
                    tabs={tabData}
                    defaultActiveTab={tabMatcher(defaultTab)}
                    onTabChange={(idx: number) => handleNavigate(`/staff/control/training/${tabData[idx]?.key}`)}
                    className={'w-fit mb-[20px]'}
                    colorType="black"
                />
                <div className="flex gap-[20px] items-center justify-between">
                    <div className="flex gap-[5px] text-[20px] font-semibold">
                        <span className="text-secondary-dGrayMid text-subtitle-medium-bold">{curTabInfo?.title}</span>
                    </div>
                </div>
                <EmptyBar customStyle={'h-[20px]'} />

                {defaultTab === 'diary' ? <Diary /> : <Session />}
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Training;

export const handleRows = (
    rows: any[],
    setDeleteId: (id: number | null) => void,
    setIsDeleteShow: (show: boolean) => void
) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        trainingDateTime: `${formatDateTimeMatcher(row?.trainingDateTime)} `,
        location: row?.location,
        weather: weatherMatcher(row?.weather),
        trainingIntensity: row?.trainingIntensity,
        link: `/staff/control/training/diary/${row.id}`,
        delete: (
            <button
                onClick={() => {
                    setDeleteId(row.id);
                    setIsDeleteShow(true);
                }}
            >
                <DeleteIcon className="text-status-error" />
            </button>
        ),
    }));
};

export const tabData = [
    {
        label: '일지',
        key: 'diary',
        header: '훈련',
        title: '훈련일지',
    },
    {
        label: '세션',
        key: 'session',
        header: '훈련 세션',
        title: '훈련세션',
    },
];

export const tabMatcher = (tab: string) => {
    if (tab === 'diary') return 0;
    else if (tab === 'session') return 1;
    else return 0;
};
