// hooks
import { useSortable, defaultAnimateLayoutChanges, AnimateLayoutChanges } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactComponent as HamburgerIcon } from 'assets/svgs/hamburger.svg';

const SortableSessionItem = ({ session, index, isActive, onClick, editSessionName, setEditSessionName }: any) => {
    const animateLayoutChanges: AnimateLayoutChanges = (args) =>
        defaultAnimateLayoutChanges({ ...args, wasDragging: true });
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: session.id,
        animateLayoutChanges,
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        zIndex: isDragging ? 99 : 'auto',
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
        >
            <div
                onClick={onClick}
                className={`${
                    isActive ? 'bg-[#FFFFFF] border-2 border-primary-purple' : 'bg-[#F8F8F8]'
                } min-w-[130px] cursor-pointer px-[10px] h-[56px] flex items-center gap-[10px] justify-center rounded-[8px] text-context-regular text-[#111111]`}
            >
                {isActive ? (
                    <input
                        value={editSessionName}
                        className="w-[80px] focus:outline-none"
                        onChange={(e) => setEditSessionName(e.target.value)}
                    />
                ) : (
                    <span className="w-[80px] overflow-hidden text-ellipsis whitespace-nowrap">{session?.name}</span>
                )}
                <span
                    className=" p-[5px]"
                    {...attributes}
                    {...listeners}
                >
                    <HamburgerIcon />
                </span>
            </div>
        </div>
    );
};

export default SortableSessionItem;
