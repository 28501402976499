import * as fabric from 'fabric';

import { MutableRefObject } from 'react';

export const useTextTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const addText = (colorState: string = '#000000') => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        const textbox = new fabric.Textbox('New Text', {
            left: fabricRef.current.getWidth() / 2 - 50,
            top: fabricRef.current.getHeight() / 2 - 20,
            fontSize: 48,
            fill: colorState,
            fontFamily: 'Arial',
            editable: true,
            selectable: true,
            hasControls: true,
            hasBorders: true,
        });
        // ✅ 스타일 속성은 반드시 개별적으로 설정하세요
        textbox.borderColor = '#ac14ff';
        textbox.cornerColor = '#ffffff';
        textbox.cornerStrokeColor = '#ac14ff';
        textbox.cornerSize = 7;
        textbox.transparentCorners = false;
        textbox.borderScaleFactor = 2;

        // 🗑 삭제 커스텀 컨트롤 추가
        textbox.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: -10,
            offsetX: 10,
            cursorStyle: 'pointer',
            mouseUpHandler: (eventData, transform) => {
                const target = transform.target;
                canvas.remove(target);
                canvas.requestRenderAll();
                return true;
            },
            render: (ctx, left, top, styleOverride, fabricObject) => {
                const size = 20;
                ctx.save();
                ctx.fillStyle = '#ff5b74'; // 배경색
                ctx.beginPath();
                ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
                ctx.fill();

                // X 아이콘
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2;
                ctx.beginPath();
                ctx.moveTo(left - 4, top - 4);
                ctx.lineTo(left + 4, top + 4);
                ctx.moveTo(left + 4, top - 4);
                ctx.lineTo(left - 4, top + 4);
                ctx.stroke();
                ctx.restore();
            },
        });

        fabricRef.current.add(textbox);
        fabricRef.current.setActiveObject(textbox);
        fabricRef.current.renderAll(); // 즉시 렌더링
        if (setUnSaved) setUnSaved(true);
        requestAnimationFrame(() => fabricRef.current?.renderAll()); // 한 프레임
    };

    return {
        addText,
    };
};
