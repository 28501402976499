// hooks
import React, { useRef, useState, MouseEvent, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// commons
import EmptyBar from 'components/emptyBar';
import DatePicker from 'components/datePicker';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import InfiniteDropdown from 'components/dropdown/InfiniteDropdown';

// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// player components
import ProfileCard from 'pages/player/report/personalReport/profileCard';
import DataSummaryCard from 'pages/player/report/personalReport/dataSummaryCard';
import DribbleCard from 'pages/player/report/personalReport/dribbleCard';
import FirstTouchCard from 'pages/player/report/personalReport/firstTouchCard';
import PassCard from 'pages/player/report/personalReport/passCard';
import ShootingCard from 'pages/player/report/personalReport/shootingCard';
import EightRoundHeader from 'pages/player/eightRound/EightRoundHeader';
// utils
import { DASHBOARD, ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const EightRoundReport = () => {
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const { measurementId = '' } = useParams();
    const dateTime = searchParams.get('dateTime') || '';
    const curDribbleOneId = searchParams.get('dribbleOneId') || '';

    const { playerId = '' } = useParams();

    const [department, setDepartment] = useState('판교점');
    const [isPrint, setIsPrint] = useState(false);
    const [selectedDateTime, setSelectedDateTime] = useState<any>(dateTime);
    const contentRef = useRef<HTMLDivElement>(null);
    const reactToPrintFn = useReactToPrint({
        contentRef, // 이렇게 화살표 함수로 변경
        onBeforePrint: () => {
            setIsPrint(true);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setIsPrint(false);
            return Promise.resolve();
        },
    });
    useEffect(() => {
        if (isPrint) {
            reactToPrintFn();
        }
    }, [isPrint, reactToPrintFn]);
    const handleNavigate = ({ measuredAt, dribbleOneId }: any) => {
        const playerUrl = `/player/eightRound/report/${playerId}`;
        const staffUrl = `/staff/measurement/control/eightRound/report/${playerId}`;
        const adminUrl = `/admin/control/eightRound/report/${playerId}`;
        handleParamNavigate(
            { playerId: `${playerId}` || '', dribbleOneId: dribbleOneId || '', dateTime: measuredAt || '' },
            false,
            role === 'PLAYER' ? playerUrl : role === 'TEAM_STAFF' ? staffUrl : adminUrl
        );
    };
    const handlePrintClick = () => {
        setIsPrint(true);
    };
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const getPlayerDashboard = useApiQuery(
        ['getPlayerDashboard', `${playerId}`],
        DASHBOARD.player(`${playerId}`),
        'get',
        {
            enabled: !!playerId,
        }
    );

    const getLatestDribbleOne = useApiQuery(
        ['getLatestDribbleOne', `${playerId}`],
        ENDPOINT_MEASUREMENT?.dribbleOneLatest(`${playerId}`),
        'get',
        {
            enabled: !!playerId || !!curDribbleOneId,
        }
    );
    const { dribbleOneId, measuredAt = '' } = getLatestDribbleOne?.data || {};
    useEffect(() => {
        // 그냥 이렇게 해도 파라미터 값만 바뀜
        if (playerId && measuredAt)
            handleParamNavigate({
                playerId: playerId,
                dateTime: measuredAt,
                dribbleOneId: dribbleOneId,
            });
    }, [playerId, measuredAt]);

    const getDribbleOne = useApiQuery(
        ['getDribbleOne', `${dribbleOneId}`, `${curDribbleOneId}`],
        `/api/measurement/dribble-one/${curDribbleOneId}`,
        'get',
        {
            enabled: !!curDribbleOneId,
        }
    );
    return (
        <div>
            <EightRoundHeader playerName={getPlayerDashboard?.data?.playerInfo?.playerName} />
            <EmptyBar customStyle="h-[35px]" />
            <div className="wrapper p-[30px] rounded-[10px] bg-primary-white ">
                {getLatestDribbleOne?.data ? (
                    <div ref={contentRef}>
                        <div className="flex flex-col gap-[20px]">
                            <div className="flex justify-between">
                                <div className="text-subtitle-medium-bold text-secondary-dGrayDark flex gap-[20px] items-center">
                                    <div className="text-subtitle-large-bold">에잇라운드 결과</div>
                                </div>
                                {/* {!isPrint && (
                                    <button
                                        onClick={() => setIsPrint(true)}
                                        className="flex justify-center items-center gap-[10px] w-[110px] h-[44px] rounded-[8px] bg-primary-green text-context-bold"
                                    >
                                        <span>출력하기</span>
                                        <PrintIcon />
                                    </button>
                                )} */}
                            </div>

                            <div className="grid grid-cols-2 gap-[20px]">
                                <div className="flex items-center w-full ">
                                    <span className="w-[100px] text-context-regular">측정 일시</span>
                                    <InfiniteDropdown
                                        requestUrl={`/api/measurement/dribble-one/player/${playerId}/list`}
                                        btn={
                                            <div
                                                className={`flex items-center text-context-bold justify-between p-[10px]  ${
                                                    isPrint ? 'w-full' : 'w-[550px]'
                                                } border border-primary-purple rounded-[8px]`}
                                            >
                                                {formatDateTimeMatcher(selectedDateTime || measuredAt) || '평가날짜'}
                                                <CalendarIcon className="text-primary-purple" />
                                            </div>
                                        }
                                        value={selectedDateTime || measuredAt}
                                        onChange={setSelectedDateTime}
                                        activeFunc={handleNavigate}
                                    />
                                </div>
                                <div className="flex items-center">
                                    <span className="w-[100px] text-context-regular">지점</span>
                                    <input
                                        className="inputCommonStyle inputDisabledStyle"
                                        readOnly
                                        value={department}
                                    />
                                </div>
                            </div>

                            <ProfileCard
                                playerInfo={getPlayerDashboard?.data?.playerInfo}
                                physicalInfo={{ height: '', weight: '', bodyFatPercentage: '' }}
                            />
                            {/*   <DataSummaryCard
                                skillAnalysis={skillAnalysis}
                                isPrint={isPrint}
                            /> */}
                        </div>
                        <EmptyBar customStyle="h-[48px]" />
                        <div className={`flex flex-col ${isPrint ? 'gap-[43px]' : 'gap-[62px]'}`}>
                            <DribbleCard
                                isPrint={isPrint}
                                dribbleAvgScore={
                                    getDribbleOne?.data?.averageScore || getLatestDribbleOne?.data?.averageScore
                                }
                                dribbleOneAnalysis={getDribbleOne?.data || getLatestDribbleOne?.data}
                                // dribbleTwoAnalysis={dribbleTwoAnalysis}
                                playerId={playerId}
                                measuredAt={measuredAt}
                            />
                            <FirstTouchCard
                                isPrint={isPrint}
                                // firstTouchAnalysis={firstTouchAnalysis}
                                // firstTouchScore={skillAnalysis?.firstTouchScore}
                            />
                            {isPrint && <EmptyBar customStyle="h-[30px]" />}

                            <PassCard
                                isPrint={isPrint}
                                // passAvgScore={skillAnalysis?.passAvgScore}
                                // passOneAnalysis={passOneAnalysis}
                                // passTwoAnalysis={passTwoAnalysis}
                            />
                            <ShootingCard
                                isPrint={isPrint}
                                // shootingAvgScore={skillAnalysis?.shootingAvgScore}
                                // shootingOneAnalysis={shootingOneAnalysis}
                                // shootingTwoAnalysis={shootingTwoAnalysis}
                            />
                        </div>
                    </div>
                ) : getLatestDribbleOne?.isError ? (
                    <div>완료되지 않은 측정입니다.</div>
                ) : (
                    <SoccerBallLodaing />
                )}
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default EightRoundReport;

export const columns = [
    { key: 'index', label: 'No.' },
    { key: 'name', label: '이름' },
    { key: 'ranking', label: '랭크' },
    { key: 'total', label: '전체 점수' },
    { key: 'footAction', label: '잔발' },
    { key: 'bestSpeed', label: '최고 속도' },
    { key: 'averageSpeed', label: '평균 속도' },
    { key: 'accuracy', label: '볼터치' },
    { key: 'bounce', label: '정확도' },
];

export const average = [
    {
        index: '',
        name: '팀평균',
        ranking: '',
        total: 70,
        footAction: 59.1,
        bestSpeed: 59.1,
        averageSpeed: <span className="text-primary-purple">80.5</span>,
        accuracy: <span className="text-status-error">80.5</span>,
        bounce: 80.6,
    },
];
