import React from "react";

interface ItemButtonProps {
  state: string;
  isActive?: boolean;
  icon?: React.ReactNode;
  label?: string;
  onClick?: () => void;
  // onFileChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // acceptFileTypes?: string;
}

const ItemButton: React.FC<ItemButtonProps> = ({
  state,
  isActive,
  icon,
  label,
  onClick,
  // onFileChange,
  // acceptFileTypes = 'image/*',
}) => {
  // 파일 업로드용 input을 감추고 label 감싸기 방식
  // return
  // onFileChange ? (
  //     <label>
  //         <div className="rounded-[8px] bg-[#4E4E4E] w-[56px] h-[65px] flex justify-center items-center flex-col gap-[6px] cursor-pointer">
  //             {icon}
  //             {label && <span className="text-[#ffffff] text-[12px] w-[56px] text-center">{label}</span>}
  //             <input
  //                 type="file"
  //                 accept={acceptFileTypes}
  //                 onChange={onFileChange}
  //                 style={{ display: 'none' }}
  //             />
  //         </div>
  //     </label>
  // ) : (

  return (
    <button
      onClick={onClick}
      className={`rounded-[8px] bg-[#4E4E4E] w-[56px] h-[65px] flex justify-center items-center flex-col gap-[6px] ${
        isActive
          ? "text-primary-green border border-primary-green"
          : "text-white"
      }`}
    >
      {icon}
      {label && (
        <span className="text-[12px] w-[56px] text-center">{label}</span>
      )}
    </button>
  );
  // );
};

export default ItemButton;
