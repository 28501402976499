import * as fabric from 'fabric';
import { MutableRefObject } from 'react';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';

export const useDrawTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const enableDrawingMode = (color: string = '#ffffff', lineWidthState: number = 2) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;

        canvas.isDrawingMode = true;

        const brush = new fabric.PencilBrush(canvas);
        brush.color = color;
        brush.width = lineWidthState;
        canvas.freeDrawingBrush = brush;

        // ⚠ 기존 path:created 이벤트 제거 후 재등록
        canvas.off('path:created');
        canvas.on('path:created', (e) => {
            const path = e.path;

            // 스타일 설정
            // path.borderColor = '#ac14ff';
            // path.cornerColor = '#ffffff';
            // path.cornerStrokeColor = '#ac14ff';
            // path.cornerSize = 7;
            // path.transparentCorners = false;
            // path.borderScaleFactor = 2;
            // path.hasControls = true;
            // path.hasBorders = true;
            applyFabricDefaultsToObject(path);
            canvas.setActiveObject(path); // 👉 선택되게 하면 corner 색 보임
            canvas.renderAll();
            if (setUnSaved) setUnSaved(true);
        });
    };

    const enableEraserMode = (lineWidthState: number = 20) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;

        canvas.discardActiveObject();
        canvas.renderAll();

        if (setUnSaved) setUnSaved(true);

        canvas.isDrawingMode = true;
        canvas.freeDrawingBrush = undefined; // 커서 꼬임  방지!!
        // 💥 커서 강제 세팅
        canvas.upperCanvasEl.style.cursor = 'crosshair';

        const brush = new fabric.PencilBrush(canvas);
        brush.width = lineWidthState;
        brush.color = '#90c434';
        canvas.freeDrawingBrush = brush;

        canvas.off('path:created');
        canvas.on('path:created', (e) => {
            const path = e.path;

            const toDelete: fabric.Object[] = [];

            canvas.getObjects().forEach((obj) => {
                if (obj === path) return;
                if ((path as any).intersectsWithObject?.(obj)) {
                    toDelete.push(obj);
                }
            });

            toDelete.forEach((obj) => canvas.remove(obj));
            canvas.remove(path);
        });
    };

    const disableDrawingMode = () => {
        if (!fabricRef.current) return;
        fabricRef.current.isDrawingMode = false;
    };

    return {
        enableDrawingMode,
        enableEraserMode,
        disableDrawingMode,
    };
};
