import React, { useImperativeHandle, useState, forwardRef, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as fabric from 'fabric';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';
import { usePressKey } from 'pages/staff/control/training/components/fabricCanvas/hooks/usePressKeyTools';
import { useShapeStyleTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useShapeStyleTools';
// hooks
import { useImageTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useImageTools';
import { useTextTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useTextTools';
import { useDrawTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useDrawTools';
import { useArrowTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useArrowTools';
import { useLineTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useLineTools';
import { useHistoryTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useHistoryTools'; // 경로는 맞게 설정!
import ConfirmModal from 'components/modals/ConfirmModal';
// assets
import {
    ArrowCurve,
    ArrowStraight,
    ArrowTip,
    CircleBig,
    CircleMideum,
    CircleSmall,
    Eraser,
    LineCurve,
    ArrowDot,
    LineOneCurve,
    LineStaright,
    Move,
    Text,
    // ball_black,
} from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import { ReactComponent as CircleBackArrowIcon } from 'assets/svgs/circleBackArrow.svg';
import { ReactComponent as CircleNextArrowIcon } from 'assets/svgs/circleNextArrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
import stadium from 'assets/imgs/trainingLogEl/stadium.png';
// components
import ItemButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/ItemButton';
import LineButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/LineButton';
import ItemSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ItemSelector';
import ColorSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ColorSelector';
import DiagramSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/DiagramSelector';

const SessionCanvas = ({ editSessionData, setEditSessionData, saveCommand }: SessionCanvasProps) => {
    const { id } = useParams();
    const canvasDomRef = useRef<HTMLCanvasElement | null>(null);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const [savedJSON, setSavedJson] = useState<string>('');
    const [clickState, setClickState] = useState('move');
    const [colorState, setColorState] = useState('#000000');
    const [lineWidthState, setLineWidthState] = useState(2);
    const [deleteModal, setDeleteModal] = useState(false);
    const { addImageFromUrl } = useImageTools(fabricRef);
    const { addText } = useTextTools(fabricRef);
    const { enableDrawingMode, enableEraserMode, disableDrawingMode } = useDrawTools(fabricRef);
    const { addArrow } = useArrowTools(fabricRef);
    const { addLine } = useLineTools(fabricRef);
    const { undo, redo, reset, saveHistory } = useHistoryTools(fabricRef);
    const { updateActiveObjectLineWidth } = useShapeStyleTools(fabricRef);

    usePressKey(fabricRef);

    useEffect(() => {
        const canvasEl = canvasDomRef.current;
        if (!canvasEl || fabricRef.current) return;

        let isCancelled = false;

        const ensureCanvasReady = () =>
            new Promise<void>((resolve) => {
                const check = () => {
                    const context = canvasEl.getContext?.('2d');
                    if (context) resolve();
                    else requestAnimationFrame(check);
                };
                check();
            });

        const optimizePath = (parsed: any) => {
            if (parsed?.objects && Array.isArray(parsed.objects)) {
                parsed.objects = parsed.objects.map((obj: any) => {
                    if (obj.type === 'path' && Array.isArray(obj.path)) {
                        if (obj.path.length > 300) {
                            obj.path = obj.path.filter((_: any, idx: number) => idx % 3 === 0);
                        }
                    }
                    return obj;
                });
            }
            return parsed;
        };

        const drawBackground = async (canvas: fabric.Canvas) => {
            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;
            canvas.requestRenderAll();
        };

        const initializeCanvas = async () => {
            await ensureCanvasReady();
            if (isCancelled || fabricRef.current) return;

            const canvas = new fabric.Canvas(canvasEl, {
                width: 1142,
                height: 808.34,
            });
            fabricRef.current = canvas;

            try {
                const json = editSessionData?.tacticalBoardJson;
                if (json) {
                    const parsed = optimizePath(JSON.parse(json));
                    if (parsed?.objects?.length) {
                        await new Promise<void>((resolve) => {
                            canvas.loadFromJSON(parsed, () => {
                                canvas.renderAll();

                                const waitUntilLoaded = () => {
                                    const objs = canvas.getObjects();
                                    if (objs.length > 0) {
                                        objs.forEach((obj) => {
                                            obj.selectable = true;
                                            obj.evented = true;
                                            obj.hasControls = true;
                                            obj.hasBorders = true;
                                            applyFabricDefaultsToObject(obj);
                                        });
                                        resolve();
                                    } else {
                                        setTimeout(waitUntilLoaded, 100);
                                    }
                                };

                                waitUntilLoaded();
                            });
                        });
                    }
                }
                await drawBackground(canvas);
            } catch (e) {
                console.warn('⚠️ JSON 파싱 실패', e);
            }
            await drawBackground(canvas);
        };

        if (editSessionData) {
            initializeCanvas();
        }

        return () => {
            isCancelled = true;
            fabricRef.current?.dispose();
            fabricRef.current = null;
            sessionStorage.removeItem('canvasReloaded');
        };
    }, [editSessionData]);

    useEffect(() => {
        if (savedJSON) {
            setTimeout(() => {
                importJSON(savedJSON);
            }, 100);
        }
    }, [savedJSON]);
    const exportJSON = () => {
        if (!fabricRef.current) return;
        const jsonObj = fabricRef.current.toJSON();
        // delete jsonObj.backgroundImage; // ✅ 배경 이미지 제거
        const json = JSON.stringify(jsonObj, null, 2);
        setSavedJson(json);
        setEditSessionData((prev: any) => ({
            ...prev,
            tacticalBoardJson: json,
        }));
    };

    const importJSON = async (stringifiedData?: string) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        const jsonData = stringifiedData || savedJSON;
        if (!jsonData) return;

        try {
            const parsed = JSON.parse(jsonData);
            if (!parsed.objects || !Array.isArray(parsed.objects)) {
                alert('올바른 JSON 형식이 아닙니다.');
                return;
            }

            // 1️⃣ 먼저 캔버스 비우고
            canvas.clear();

            // 2️⃣ 배경 이미지 로드
            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;

            // 3️⃣ JSON 오브젝트 최적화
            parsed.objects = parsed.objects.map((obj: any) => {
                if (obj.type === 'path' && Array.isArray(obj.path) && obj.path.length > 300) {
                    obj.path = obj.path.filter((_: any, idx: number) => idx % 3 === 0);
                }
                return obj;
            });

            // 4️⃣ JSON 로드 후 렌더링
            canvas.loadFromJSON(parsed, () => {
                canvas.getObjects().forEach((obj) => {
                    obj.selectable = true;
                    obj.hasControls = true;
                    obj.hasBorders = true;
                    applyFabricDefaultsToObject(obj); // 🔥 deleteControl 붙이기
                });
                canvas.requestRenderAll();
            });

            setSavedJson(jsonData);
        } catch (error) {
            alert('JSON 파싱에 실패했습니다.');
            console.error('[importJSON 에러]', error);
        }
    };

    useEffect(() => {
        if (clickState !== 'draw' && clickState !== 'eraser') {
            disableDrawingMode();
        }
    }, [clickState]);

    useEffect(() => {
        if (clickState === 'draw') {
            enableDrawingMode(colorState, lineWidthState); // ✅ 선 다시 적용
        }
        if (clickState === 'eraser') {
            enableEraserMode(lineWidthState); // ✅ 지우개도 다시 적용
        }
        if (
            clickState === 'arrow_curve' ||
            clickState === 'arrow_dot' ||
            clickState === 'arrow_straight' ||
            clickState === 'arrow_tip'
        ) {
            const arrowType = (clickState?.slice(6) || 'straight') as 'curve' | 'straight' | 'dot' | 'tip';
        }
    }, [lineWidthState]);
    // 저장 명령
    useEffect(() => {
        if (saveCommand) {
            exportJSON();
        }
    }, [saveCommand]);
    return (
        <div className="text-center mt-[18px] border-2 relative">
            <ConfirmModal
                isShow={deleteModal}
                setIsShow={() => setDeleteModal(!deleteModal)}
                title={
                    <div>
                        해당 세션 삭제시, <span className="font-bold text-status-error">복구할 수 없습니다.</span>
                    </div>
                }
                activeFunc={importJSON}
                deleteBtnText={'초기화'}
            />
            <div className="flex items-center justify-end gap-[8px] absolute top-[-70px] right-0">
                <button
                    onClick={undo}
                    className="w-[40px] hover:border hover:rounded-[4px] h-[40px] flex justify-center items-center"
                >
                    <CircleBackArrowIcon />
                </button>
                <button
                    onClick={redo}
                    className="w-[40px] h-[40px] hover:border hover:rounded-[4px] flex justify-center items-center"
                >
                    <CircleNextArrowIcon />
                </button>

                <button
                    onClick={() => setDeleteModal(true)}
                    className="w-[40px] h-[40px] text-status-error flex justify-center items-center"
                >
                    <DeleteIcon />
                </button>
            </div>
            {
                <div className="relative  w-[1142px] h-[808.34px]">
                    <canvas ref={canvasDomRef} />
                    <div className="absolute bottom-0 flex gap-[10px] justify-center items-center  text-[#ffffff] bg-gradient-to-b from-[#939393] to-[#1C1C1C] border border-[#ffffff] rounded-t-[30px] m-auto h-[94px] w-[1142px]">
                        <ItemButton
                            state="move"
                            isActive={clickState === 'move'}
                            icon={<Move className=" w-[26px] h-[36px]" />}
                            onClick={() => setClickState('move')}
                        />

                        <ColorSelector
                            onClick={() => {
                                setClickState('draw');
                                enableDrawingMode(colorState, lineWidthState);
                            }}
                            state="draw"
                            isActive={clickState === 'draw'}
                            colorState={colorState}
                            lineWidthState={lineWidthState}
                            setColorState={setColorState}
                            enableDrawingMode={enableDrawingMode}
                        />
                        <ItemButton
                            state="eraser"
                            isActive={clickState === 'eraser'}
                            icon={<Eraser className=" w-[25px] h-[26px]" />}
                            onClick={() => {
                                setClickState('eraser');
                                enableEraserMode();
                            }}
                        />
                        <div className="grid grid-cols-2 grid-rows-2 gap-[5px]">
                            <LineButton
                                state="line_staright"
                                isActive={clickState === 'line_staright'}
                                icon={<LineStaright className="" />}
                                onClick={() => {
                                    setClickState('line_staright');
                                    addLine('straight', colorState, lineWidthState);
                                }}
                            />
                            <LineButton
                                state="line_curve"
                                isActive={clickState === 'line_curve'}
                                icon={<LineCurve className="" />}
                                onClick={() => {
                                    setClickState('line_curve');
                                    addLine('curve', colorState, lineWidthState);
                                }}
                            />
                            <LineButton
                                state="line_oneCurve"
                                isActive={clickState === 'line_oneCurve'}
                                icon={<LineOneCurve className="" />}
                                onClick={() => {
                                    setClickState('line_oneCurve');
                                    addLine('oneCurve', colorState, lineWidthState);
                                }}
                            />
                            <DiagramSelector
                                state={'diagram'}
                                isActive={clickState === 'diagram'}
                                onClick={() => {
                                    setClickState('diagram');
                                }}
                                addLine={addLine}
                                // colorState={colorState}
                                lineWidthState={lineWidthState}
                            />
                        </div>
                        <div className="flex flex-col gap-[5px] ">
                            <button
                                onClick={() => {
                                    setLineWidthState(2);
                                    updateActiveObjectLineWidth(2);
                                }}
                                className={`${
                                    lineWidthState === 2 && 'text-primary-green border border-primary-green'
                                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                            >
                                <CircleSmall />
                            </button>
                            <button
                                onClick={() => {
                                    setLineWidthState(5);
                                    updateActiveObjectLineWidth(5);
                                }}
                                className={`${
                                    lineWidthState === 5 && 'text-primary-green border border-primary-green'
                                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                            >
                                <CircleMideum />
                            </button>
                            <button
                                onClick={() => {
                                    setLineWidthState(8);
                                    updateActiveObjectLineWidth(8);
                                }}
                                className={`${
                                    lineWidthState === 8 && 'text-primary-green border border-primary-green'
                                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                            >
                                <CircleBig />
                            </button>
                        </div>
                        <div className="grid grid-cols-2 grid-rows-2 gap-[5px] ">
                            <LineButton
                                state="arrow_curve"
                                isActive={clickState === 'arrow_curve'}
                                icon={<ArrowCurve />} // ← UI용
                                onClick={() => {
                                    setClickState('arrow_curve');
                                    addArrow('curve', colorState, lineWidthState);
                                }}
                            />
                            <LineButton
                                state="arrow_dot"
                                isActive={clickState === 'arrow_dot'}
                                icon={<ArrowDot className="" />}
                                onClick={() => {
                                    setClickState('arrow_curve');
                                    addArrow('dot', colorState, lineWidthState);
                                }}
                            />
                            <LineButton
                                state="arrow_straight"
                                isActive={clickState === 'arrow_straight'}
                                icon={<ArrowStraight className="" />}
                                onClick={() => {
                                    setClickState('arrow_straight');
                                    addArrow('straight', colorState, lineWidthState);
                                }}
                            />
                            <LineButton
                                state="arrow_tip"
                                isActive={clickState === 'arrow_tip'}
                                icon={<ArrowTip className="" />}
                                onClick={() => {
                                    setClickState('arrow_tip');
                                    addArrow('tip', colorState, lineWidthState);
                                }}
                            />
                        </div>

                        <ItemSelector
                            state={'item'}
                            isActive={clickState === 'item'}
                            label={'Item'}
                            onClick={() => setClickState('item')}
                            addImageFromUrl={addImageFromUrl}
                        />

                        <ItemButton
                            state="text"
                            isActive={clickState === 'text'}
                            icon={<Text className="text-white w-[20px] h-[20px]" />}
                            label="Text"
                            onClick={() => {
                                setClickState('text');
                                addText(colorState); // 텍스트 추가 실행
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    );
};

export default SessionCanvas;

interface SessionCanvasProps {
    editSessionData: any;
    setEditSessionData: (info: any) => void;
    saveCommand: boolean;
}
