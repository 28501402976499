// commons
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';
// utils
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';

const SessionTemplate = ({ sessionTemplateInfo, setSessionTemplateInfo }: SessionTemplatePropsType) => {
    const handleInfo = (updateData: any) => {
        setSessionTemplateInfo((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    return (
        <div className="h-[154px] border-y border-secondary-lGrayMid flex">
            <div className="w-[150px] shrink-0 h-full bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                세션 정보
            </div>
            <div className="p-[24px]">
                <div className="flex gap-[24px] flex-wrap">
                    <div className="flex items-center gap-[10px] ">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            이름
                        </div>

                        <input
                            type="text"
                            value={sessionTemplateInfo?.name || ''}
                            className="inputDefaultStyle p-[12px] w-[750px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center"
                            onChange={(e) => handleInfo({ name: e.target.value })}
                        />
                    </div>
                    <div className="flex items-center gap-[10px] ">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            생성일자
                        </div>

                        <div className="inputDefaultStyle p-[12px] w-[310px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                            {formatDateTimeMatcher(sessionTemplateInfo?.createdAt || '')}
                        </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            수정일자
                        </div>
                        <div className="inputDefaultStyle p-[12px] w-[310px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                            {formatDateTimeMatcher(sessionTemplateInfo?.updatedAt || '')}
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />
            </div>
            <EmptyBar customStyle="h-[18px]" />
        </div>
    );
};

export default SessionTemplate;

interface SessionTemplateInfoType {
    id: number;
    name: string | null;
    tacticalBoardJson: string | null;
    sessionPoint: string | null;
    createdAt?: string | null;
    updatedAt?: string | null;
}

interface SessionTemplatePropsType {
    sessionTemplateInfo: SessionTemplateInfoType;
    setSessionTemplateInfo: (info: any) => void;
}
