// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import ConfirmModal from 'components/modals/ConfirmModal';

// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM_SESSION_TEMPLATES } from 'utils/consts/apiEndpoint';
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// training components
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const Diary = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteShow, setIsDeleteShow] = useState(false);
    const paramAgeGroup = searchParams.get('ageGroup') || '';

    // useApiQuery
    const getSessionTemplates = useApiQuery(
        ['getSessionTemplates', paramAgeGroup, `${page}`, `${pagInfo.size}`],
        `${ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplate}?ageGroup=${
            staffRole === 'OFFICE' ? paramAgeGroup : ''
        }&page=${page}&size=${pagInfo.size}`,
        'get'
    );
    // useApiMutation
    const postInitSessionTemplate = useApiMutation(ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplate, 'post', {
        onSuccess: (data) => {
            handleNavigate(`/staff/control/training/session/${data.id}`);
            showToast('훈련 세션이 생성되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '훈련 세션 생성에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const createSessionTemplate = () => {
        if (!postInitSessionTemplate?.isPending) {
            showToast('훈련 세션 생성중입니다.', 'info');
            postInitSessionTemplate.mutate({
                name: '새로운 세션',
                sessionPoint: '세션템플릿',
            });
        }
    };
    const deleteMeasurement = useApiMutation(
        (props) => ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplateId(props?.sessionId),
        'delete',
        {
            onSuccess: (data) => {
                showToast('훈련세션 삭제했습니다.', 'success');
            },
            onError: (error) => {
                const errorMessage = error?.data?.detailedMessage || error || '훈련세션 삭제에 실패했습니다.';
                showToast(errorMessage, 'error');
            },
        }
    );

    const removeDiary = (id: number) => {
        if (!deleteMeasurement?.isPending) {
            showToast('훈련세션 삭제중입니다.', 'info');
            deleteMeasurement?.mutate({ sessionId: id });
        }
    };
    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };
    useEffect(() => {
        if (
            getSessionTemplates?.isSuccess &&
            getSessionTemplates?.data &&
            getSessionTemplates?.data?.pageInfo?.page === 1
        ) {
            setPageInfo(getSessionTemplates?.data?.pageInfo);
        }
    }, [getSessionTemplates?.isSuccess, getSessionTemplates?.data]);
    const customizedRow = handleRows(getSessionTemplates?.data?.data, setDeleteId, setIsDeleteShow) || [];

    return (
        <div className="w-full h-fit ">
            <ConfirmModal
                isShow={isDeleteShow}
                setIsShow={setIsDeleteShow}
                title={
                    <div>
                        <div>
                            훈련 세션
                            <span className="font-bold text-status-error"> 삭제하시겠습니까?</span>
                        </div>
                    </div>
                }
                content={<div></div>}
                deleteBtnText="삭제"
                activeFunc={() => deleteId && removeDiary(deleteId)}
            />

            <CustomTable
                columns={columns}
                rows={customizedRow}
                equalWidth={true}
            />
            {getSessionTemplates?.isPending && (
                <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
            )}
            <button
                onClick={createSessionTemplate}
                className="w-full h-[44px] my-[20px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
            >
                <PlusIcon />
                {'세션 생성'}
            </button>
            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default Diary;

export const handleRows = (
    rows: any[],
    setDeleteId: (id: number | null) => void,
    setIsDeleteShow: (show: boolean) => void
) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        name: row?.name,
        sessionPoint: row?.sessionPoint,
        createdAt: `${formatDateTimeMatcher(row?.createdAt)} `,
        link: `/staff/control/training/session/${row.id}`,
        delete: (
            <button
                onClick={() => {
                    setDeleteId(row.id);
                    setIsDeleteShow(true);
                }}
            >
                <DeleteIcon className="text-status-error" />
            </button>
        ),
    }));
};
export const columns = [
    { key: 'index', label: '번호' },
    { key: 'name', label: '이름' },
    {
        key: 'sessionPoint',
        label: '세션포인트',
    },
    {
        key: 'createdAt',
        label: '생성일시',
        sortable: false,
    },
    { key: 'link', label: '상세보기' },
    { key: 'delete', label: '삭제' },
];
