// hooks
import { useParams, useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';
// commons
import Dropdown from 'components/dropdown/Default';
import StatusLabel from 'components/label/Default';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// utils
import { sortings } from 'utils/consts/measurement';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
import { dribbleOneHistoryOptions } from 'utils/consts/measurement';

const RecordCard = ({ recordData, sortType, setSortType, sliceInfo, page, setPage }: any) => {
    const [searchParams] = useSearchParams();
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const { measurementId } = useParams();
    const dribbleOneId = searchParams.get('dribbleOneId') || '';

    const handleParamNavigate = useParamNavigate();
    const handleNavigate = (navigateUrl: string, selectedDribbleOneId?: string) => {
        handleParamNavigate(
            {
                dribbleOneId: selectedDribbleOneId,
            },
            false,
            navigateUrl
        );
    };
    const handlePage = (move: 'prev' | 'next') => {
        if (move === 'prev' && sliceInfo?.page > 1) {
            setPage(page - 1);
        } else if (move === 'next' && sliceInfo?.hasNext) {
            setPage(page + 1);
        }
    };
    return (
        <div className="rounded-[10px] bg-primary-white p-[30px] w-[1400px] relative">
            <div className="flex justify-between mb-[8px]">
                <div className="text-subtitle-medium-bold">기록</div>
                <Dropdown
                    options={dribbleOneHistoryOptions}
                    value={sortType}
                    placeholder="정렬"
                    onChange={setSortType}
                    className="w-[130px]"
                />
            </div>
            <div className="flex gap-[12px] items-center">
                <button
                    onClick={() => handlePage('prev')}
                    className={`${
                        sliceInfo?.page === 1 ? 'border-secondary-mGrayDark' : 'border-primary-purple'
                    } border rounded-[80px] w-[36px] h-[36px] flex justify-center items-center`}
                >
                    <CircleArrowIcon
                        className={sliceInfo?.page === 1 ? 'text-secondary-mGrayDark' : 'text-primary-purple'}
                    />
                </button>
                <div className="gap-[12px] grid grid-cols-7 w-[1240px]">
                    {recordData?.map((el: any) => (
                        <button
                            onClick={() => {
                                if (role === 'PLAYER') {
                                    handleNavigate(
                                        `/player/measurement/analysis/${el?.measurementId || el?.dribbleOneId}`,
                                        el?.dribbleOneId
                                    );
                                } else if (role === 'TEAM_STAFF') {
                                    handleNavigate(
                                        `/staff/measurement/report/analysis/${el?.measurementId || el?.dribbleOneId}`,
                                        el?.dribbleOneId
                                    );
                                } else {
                                    handleNavigate(
                                        `/admin/control/measurement/analysis/${el?.measurementId || el?.dribbleOneId}`,
                                        el?.dribbleOneId
                                    );
                                }
                            }}
                            className={`
                            
                ${
                    el?.measurementId && Number(measurementId) === el?.measurementId
                        ? 'border border-primary-purple bg-white'
                        : el?.dribbleOneId && Number(dribbleOneId) === el?.dribbleOneId
                        ? 'border border-primary-purple bg-white'
                        : 'bg-[#f8f8f8]'
                }
                flex flex-col gap-[12px] items-center justify-center h-[246px] group rounded-[14px] `}
                        >
                            <div>
                                <div className="text-context-regular text-[#868686]">
                                    {el?.standalone ? (
                                        <div className="h-[22px]">에잇라운드</div>
                                    ) : (
                                        <div className="h-[22px]">측정</div>
                                    )}
                                </div>
                                <div className="flex gap-[8px] items-center">
                                    <div className=" text-context-regular text-[#868686]">
                                        {el?.standAloneMeasuredAt
                                            ? el?.standAloneMeasuredAt?.slice(0, 10)?.replaceAll('-', '.')
                                            : ''}
                                    </div>
                                    {el?.sequenceNumber ? (
                                        <div className="text-center text-context-bold">{`${el?.sequenceNumber} 차`}</div>
                                    ) : el?.standalone ? (
                                        <div className="text-center text-context-bold">{`${el?.standaloneSequence} 차`}</div>
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                            </div>

                            <div
                                className={`${
                                    measurementId === el?.measurementId
                                        ? 'text-title-medium-bold'
                                        : 'text-title-medium-regular '
                                } `}
                            >
                                {el?.dribbleOneScore}
                            </div>

                            <div>
                                <div className="px-[12px] bg-[#5E6EFF] text-primary-white text-[14px] font-bold rounded-[80px] py-[4px]">
                                    {/* <StatusLabel status={el?.percentile > 0 ? 'up' : 'down'}> */}
                                    {`상위 ${el?.percentile > 0 ? el?.percentile : '-'}%`}
                                    {/* </StatusLabel> */}
                                </div>
                                <div className="h-[20px] text-[#5E6EFF] font-bold text-context-regular mt-[5px]">{`${el?.ranking} 등`}</div>{' '}
                            </div>
                        </button>
                    ))}
                </div>
                <button
                    onClick={() => handlePage('next')}
                    className={`
          ${!sliceInfo?.hasNext ? 'border-secondary-mGrayDark' : 'border-primary-purple'}
           border rounded-[80px] w-[36px] h-[36px] flex justify-center items-center rotate-180`}
                    // className="border-primary-purple border rounded-[80px] w-[36px] h-[36px] flex justify-center items-center "
                >
                    <CircleArrowIcon
                        className={!sliceInfo?.hasNext ? 'text-secondary-mGrayDark' : 'text-primary-purple'}
                    />
                </button>
            </div>
        </div>
    );
};

export default RecordCard;
