import * as fabric from 'fabric';
import { MutableRefObject } from 'react';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';
export const useImageTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const positionHandler: fabric.Control['positionHandler'] = (dim, finalMatrix, fabricObject, control) => {
        return fabric.util.transformPoint(
            {
                x: control.x * fabricObject.width!,
                y: control.y * fabricObject.height!,
            },
            fabric.util.multiplyTransformMatrices(
                fabricObject.calcTransformMatrix(),
                fabric.util.invertTransform(fabricObject.group?.calcTransformMatrix() || fabric.iMatrix)
            )
        );
    };
    const addImageFromUrl = async (curItem: string) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        const url = curItem;

        // ✅ 안전한 positionHandler 직접 정의
        const positionHandler = (dim: any, finalMatrix: any, fabricObject: any, control: any) => {
            return new fabric.Point(
                fabricObject.oCoords?.tr?.x + (control.offsetX || 0) || 0,
                fabricObject.oCoords?.tr?.y + (control.offsetY || 0) || 0
            );
        };

        try {
            const img = await fabric.Image.fromURL(url);
            img.set({
                left: 150,
                top: 150,
                scaleX: 0.5,
                scaleY: 0.5,
                angle: 0,
                selectable: true,
                hasControls: true,
                hasBorders: true,
            });

            // // ✨ 스타일 설정
            // img.borderColor = '#ac14ff';
            // img.cornerColor = '#ffffff';
            // img.cornerStrokeColor = '#ac14ff';
            // img.cornerSize = 7;
            // img.transparentCorners = false;
            // img.borderScaleFactor = 2;

            applyFabricDefaultsToObject(img); // ✅ 여기도 마찬가지

            canvas.add(img);
            canvas.setActiveObject(img);
            canvas.renderAll();
            if (setUnSaved) setUnSaved(true);
        } catch (err) {
            console.error('❌ 이미지 로딩 실패:', err);
        }
    };
    const addImageFromFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file || !fabricRef.current) return;

        const reader = new FileReader();
        reader.onload = async () => {
            const dataUrl = reader.result as string;
            try {
                const img = await fabric.Image.fromURL(dataUrl);
                img.set({
                    left: 150,
                    top: 150,
                    scaleX: 0.5,
                    scaleY: 0.5,
                    angle: 0,
                    selectable: true,
                    hasControls: true,
                    hasBorders: true,
                });
                // ✅ 스타일 속성은 반드시 개별적으로 설정하세요
                // img.borderColor = '#ac14ff';
                // img.cornerColor = '#ffffff';
                // img.cornerStrokeColor = '#ac14ff';
                // img.cornerSize = 7;
                // img.transparentCorners = false;
                // img.borderScaleFactor = 2;

                applyFabricDefaultsToObject(img); // ✅ 로드 후 호출

                fabricRef.current?.add(img);
                fabricRef.current?.setActiveObject(img);
                fabricRef.current?.renderAll();
                if (setUnSaved) setUnSaved(true);
            } catch (err) {
                console.error('❌ 이미지 로딩 실패:', err);
            }
        };

        reader.readAsDataURL(file);
    };

    return {
        addImageFromFile,
        addImageFromUrl,
    };
};
