// hooks
import { useEffect, useState } from 'react';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
import { useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// icons
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
// commons
import Pagination from 'components/pagenation';
// utils
import { paramFormChanger } from 'utils/formChanger';
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
const TeamStaffSearchModal = ({ mdoalBtn, handleTeam }: TeamStaffSearchModalType) => {
    const [isShow, setIsShow] = useState(false);
    const [teamStaffName, setTeamStaffName] = useState('');
    const [fixedTeamStaffName, setFixedTeamStaffName] = useState('');
    const [page, setPage] = useState(1);
    const { ref, inView } = useInView();
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const getAdminTeamStaff = useApiQuery(
        ['getAdminTeamStaff', fixedTeamStaffName, String(page)],
        ENDPOINT_ADMIN.teamStaffParams(paramFormChanger({ name: fixedTeamStaffName, page: page, size: pagInfo?.size })),
        'get'
    );
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isSuccess, isFetchingNextPage, status } =
        useInfiniteQuery({
            queryKey: ['InfiniteDropdown', fixedTeamStaffName],
            queryFn: ({ pageParam = 1 }) =>
                axiosInstance
                    .get(
                        ENDPOINT_ADMIN.teamStaffParams(
                            paramFormChanger({ name: fixedTeamStaffName, page: pageParam, size: pagInfo?.size })
                        )
                    )
                    .then((response) => response.data),
            initialPageParam: 1,
            getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
        });
    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    useEffect(() => {
        if (getAdminTeamStaff?.isSuccess && getAdminTeamStaff?.data && getAdminTeamStaff?.data?.pageInfo?.page === 1) {
            setPageInfo(getAdminTeamStaff?.data?.pageInfo);
        }
    }, [getAdminTeamStaff?.isSuccess, getAdminTeamStaff?.data]);

    const handleClick = (el: teamStaffType) => {
        handleTeam(el);
        setIsShow(false);
    };

    const handleSearch = (teamStaffName: string) => {
        setFixedTeamStaffName(teamStaffName);
    };
    return (
        <div>
            <button
                onClick={() => setIsShow(!isShow)}
                className="w-full"
            >
                {mdoalBtn}
            </button>
            {isShow && (
                <div className="fixed bg-[#00000050] z-[10] flex justify-center items-center top-0 left-0 w-screen h-screen">
                    <div className="bg-primary-white rounded-[10px] p-[30px] w-[550px]">
                        <div className="flex items-center justify-between text-subtitle-small-bold">
                            팀 스태프 검색
                            <button onClick={() => setIsShow(false)}>
                                <CloseIcon className="text-secondary-lGrayDark w-[30px] h-[30px]" />
                            </button>
                        </div>

                        <div className="relative my-[20px]">
                            <input
                                value={teamStaffName}
                                onChange={(e) => setTeamStaffName(e.target.value)}
                                placeholder="팀명을 검색해주세요"
                                className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                            />

                            <button
                                onClick={() => handleSearch(teamStaffName)}
                                className="absolute right-[12px] top-[12px]"
                            >
                                <SearchIcon />
                            </button>
                        </div>
                        <div className="grid grid-cols-3 h-[40px] bg-primary-black text-white text-center items-center rounded-[10px]">
                            <div>이름</div>
                            <div>이메일</div>
                            <div>전화번호 끝 4자리</div>
                        </div>
                        <div className="h-[350px] overflow-y-scroll">
                            {data?.pages.map((page, pageIdx) =>
                                page.data.map((el: any) => (
                                    <div
                                        onClick={() => handleClick(el)}
                                        key={el?.teamId}
                                        className="grid grid-cols-3 text-center h-[40px] items-center hover:bg-secondary-mGrayLight rounded-[10px]"
                                    >
                                        <div>{el?.name}</div>
                                        <div>{el?.email}</div>
                                        <div>{el?.lastFourDigits}</div>
                                    </div>
                                ))
                            )}
                            {/* {getAdminTeamStaff?.isPending ? (
                            <div className="mt-[10px] text-secondary-lGrayDark mx-auto"> 검색중입니다.</div>
                        ) : getAdminTeamStaff?.data?.data?.length > 0 ? (
                            getAdminTeamStaff?.data?.data?.map((el: any) => (
                                <div
                                    onClick={() => handleClick(el)}
                                    key={el?.teamId}
                                    className="grid grid-cols-3 text-center h-[40px] items-center hover:bg-secondary-mGrayLight rounded-[10px]"
                                >
                                    <div>{el?.name}</div>
                                    <div>{el?.email}</div>
                                    <div>{el?.lastFourDigits}</div>
                                </div>
                            ))
                        ) : (
                            <div className="mt-[10px] text-secondary-lGrayMid"> 선수명을 검색해주세요.</div>
                        )} */}
                            <div
                                ref={ref}
                                className="h-4"
                            />
                            {isFetching && !isFetchingNextPage && (
                                <div className="pb-2 text-center text-secondary-lGrayDark">Loading...</div>
                            )}
                            {isFetchingNextPage && (
                                <div className="pb-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                            )}
                            {!isFetching && !isFetchingNextPage && !hasNextPage && (
                                <div className="pb-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TeamStaffSearchModal;

interface TeamStaffSearchModalType {
    mdoalBtn: React.ReactNode;
    handleTeam: (value: teamStaffType, type?: string) => void;
}
interface teamStaffType {
    userId: number;
    email: string | null;
    name: string | null;
    lastFourDigits: string | null;
}
