// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import ConfirmModal from 'components/modals/ConfirmModal';

// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM_DIARY } from 'utils/consts/apiEndpoint';
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// training components
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const Diary = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteShow, setIsDeleteShow] = useState(false);
    const paramAgeGroup = searchParams.get('ageGroup') || '';

    // useApiQuery
    const getTeamDiary = useApiQuery(
        ['getTeamDiary', paramAgeGroup, `${page}`, `${pagInfo.size}`],
        `${ENDPOINT_TEAM_DIARY.diary}?ageGroup=${staffRole === 'OFFICE' ? paramAgeGroup : ''}&page=${page}&size=${
            pagInfo.size
        }`,
        'get'
    );
    // useApiMutation
    const postInitDiary = useApiMutation(ENDPOINT_TEAM_DIARY.diary, 'post', {
        onSuccess: (data) => {
            handleNavigate(`/staff/control/training/diary/${data.id}`);
            showToast('훈련 일지가 생성되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '훈련 일지 생성에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const createDiary = () => {
        const today = new Date().toISOString();
        if (!postInitDiary?.isPending) {
            showToast('훈련 일지 생성중입니다.', 'info');
            postInitDiary.mutate({
                trainingDateTime: today,
            });
        }
    };
    const deleteMeasurement = useApiMutation((props) => ENDPOINT_TEAM_DIARY.diaryId(props?.diaryId), 'delete', {
        onSuccess: (data) => {
            showToast('훈련일지를 삭제했습니다.', 'success');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '훈련일지 삭제에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });

    const removeDiary = (id: number) => {
        if (!deleteMeasurement?.isPending) {
            showToast('훈련일지를 삭제중입니다.', 'info');
            deleteMeasurement?.mutate({ diaryId: id });
        }
    };
    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };
    useEffect(() => {
        if (getTeamDiary?.isSuccess && getTeamDiary?.data && getTeamDiary?.data?.pageInfo?.page === 1) {
            setPageInfo(getTeamDiary?.data?.pageInfo);
        }
    }, [getTeamDiary?.isSuccess, getTeamDiary?.data]);
    const customizedRow = handleRows(getTeamDiary?.data?.data, setDeleteId, setIsDeleteShow) || [];

    return (
        <div className="w-full h-fit ">
            <ConfirmModal
                isShow={isDeleteShow}
                setIsShow={setIsDeleteShow}
                title={
                    <div>
                        <div>
                            훈련일지를
                            <span className="font-bold text-status-error"> 삭제하시겠습니까?</span>
                        </div>
                    </div>
                }
                content={<div></div>}
                deleteBtnText="삭제"
                activeFunc={() => deleteId && removeDiary(deleteId)}
            />
            <CustomTable
                columns={columns}
                rows={customizedRow}
                equalWidth={true}
            />
            {getTeamDiary?.isPending && <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>}
            <button
                onClick={createDiary}
                className="w-full h-[44px] my-[20px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
            >
                <PlusIcon />
                {'훈련 생성'}
            </button>
            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default Diary;

export const handleRows = (
    rows: any[],
    setDeleteId: (id: number | null) => void,
    setIsDeleteShow: (show: boolean) => void
) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        trainingDateTime: `${formatDateTimeMatcher(row?.trainingDateTime)} `,
        location: row?.location,
        weather: weatherMatcher(row?.weather),
        trainingIntensity: row?.trainingIntensity,
        link: `/staff/control/training/diary/${row.id}`,
        delete: (
            <button
                onClick={() => {
                    setDeleteId(row.id);
                    setIsDeleteShow(true);
                }}
            >
                <DeleteIcon className="text-status-error" />
            </button>
        ),
    }));
};
export const columns = [
    { key: 'index', label: '번호' },
    { key: 'trainingDateTime', label: '일시' },
    {
        key: 'location',
        label: '장소',
    },
    {
        key: 'weather',
        label: '날씨',
        sortable: false,
    },
    { key: 'trainingIntensity', label: '훈련 강도' },
    { key: 'link', label: '상세보기' },
    { key: 'delete', label: '삭제' },
];
