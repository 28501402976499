// hooks
import { useState } from 'react';
import { ReactComponent as TwinkleIcon } from 'assets/svgs/twinkle.svg';

interface RatingCardType {
    isEdit: boolean;

    evaluationDetail: EvaluationDetailInfoType;
    handleEvaluationDetail: (value: any) => void;
    aiAnalysisInfo: string | null;
}

const RatingCard = ({ isEdit, evaluationDetail, handleEvaluationDetail, aiAnalysisInfo }: RatingCardType) => {
    // textarea 상태 관리
    return (
        <div className="flex flex-col gap-[10px]">
            <div className="flex items-center justify-between">
                <div className="w-[103px] flex font-bold">
                    <div className="w-[28px]">
                        <span className="w-[24px] h-[24px] text-primary-purple ">
                            <TwinkleIcon />
                        </span>
                    </div>
                    <div
                        className={'text-primary-purple w-[75px]'}
                        style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                    >
                        AI 인공 지능 분석
                    </div>
                </div>
                <div
                    className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] min-h-[44px] p-[12px] text-context-regular `}
                >
                    {aiAnalysisInfo
                        ? aiAnalysisInfo
                        : '현재 개발중입니다. (ex: 압박 상황에서 판단력이 다소 흔들리는 경향이 있음. 포텐셜이 높은 플레이어로, 꾸준한 훈련과 멘탈 관리가 성장의 열쇠.)'}
                </div>
            </div>

            <div className="flex items-center justify-between">
                <div
                    className={'text-[#868686] w-[103px] pl-[28px]'}
                    style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                >
                    장점
                </div>
                <textarea
                    className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] p-[12px] text-context-regular ${
                        isEdit
                            ? 'focus:outline-primary-purple focus:bg-primary-white'
                            : 'bg-secondary-mGrayLight focus:outline-none'
                    }
                        ${evaluationDetail?.strengths?.length < 45 ? 'h-[44px]' : 'h-auto'}
                        `}
                    maxLength={300}
                    readOnly={!isEdit} // 읽기 전용 설정
                    value={evaluationDetail?.strengths}
                    onChange={(e) => handleEvaluationDetail({ strengths: e.target.value })} // 값 변경 처리
                />
            </div>

            <div className="flex items-center justify-between">
                <div
                    className={'text-[#868686] w-[103px] pl-[28px]'}
                    style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                >
                    단점
                </div>
                <textarea
                    className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] p-[12px] text-context-regular ${
                        isEdit
                            ? 'focus:outline-primary-purple focus:bg-primary-white'
                            : 'bg-secondary-mGrayLight focus:outline-none'
                    }
                        ${evaluationDetail?.weaknesses?.length < 45 ? 'h-[44px]' : 'h-auto'}
                        `}
                    maxLength={300}
                    readOnly={!isEdit} // 읽기 전용 설정
                    value={evaluationDetail?.weaknesses}
                    onChange={(e) => handleEvaluationDetail({ weaknesses: e.target.value })} // 값 변경 처리
                />
            </div>

            <div className="flex items-center justify-between">
                <div
                    className={'text-[#868686] w-[103px] pl-[28px]'}
                    style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                >
                    인성
                </div>
                <textarea
                    className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] p-[12px] text-context-regular ${
                        isEdit
                            ? 'focus:outline-primary-purple focus:bg-primary-white'
                            : 'bg-secondary-mGrayLight focus:outline-none'
                    }
                        ${evaluationDetail?.personality?.length < 45 ? 'h-[44px]' : 'h-auto'}
                        `}
                    maxLength={300}
                    readOnly={!isEdit} // 읽기 전용 설정
                    value={evaluationDetail?.personality}
                    onChange={(e) => handleEvaluationDetail({ personality: e.target.value })} // 값 변경 처리
                />
            </div>

            <div className="flex items-center justify-between">
                <div
                    className={'text-[#868686] w-[103px] pl-[28px]'}
                    style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} // 한국어 띄어쓰기 단위로 줄바꿈
                >
                    총평
                </div>
                <textarea
                    className={`w-[1217px] text-secondary-dGrayDark resize-none bg-secondary-mGrayLight rounded-[8px] p-[12px] text-context-regular ${
                        isEdit
                            ? 'focus:outline-primary-purple focus:bg-primary-white'
                            : 'bg-secondary-mGrayLight focus:outline-none'
                    }
                        ${evaluationDetail?.overallReview?.length < 45 ? 'h-[44px]' : 'h-auto'}
                        `}
                    maxLength={300}
                    readOnly={!isEdit} // 읽기 전용 설정
                    value={evaluationDetail?.overallReview}
                    onChange={(e) => handleEvaluationDetail({ overallReview: e.target.value })} // 값 변경 처리
                />
            </div>
        </div>
    );
};

export default RatingCard;

export interface EvaluationDetailInfoType {
    overallReview: string;
    personality: string;
    strengths: string;
    weaknesses: string;
}
