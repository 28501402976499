// hooks
import { useEffect, useState } from 'react';
// commons
import SqureCheckbox from 'components/checkbox/SqureCheckbox';
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// reudx
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의
interface ReportRowElement {
    playerId: string;
    playerName: string | null;
    age: number | null;
    measurements: {
        measurementId: string;
        measuredAt: string;
    }[];
    idx: number;
    measurementIds: string[];
    setMeasurementIds: (measurementIds: string[]) => void;
    checkAll: boolean;
    allSelecter: (measurementIds: string) => void;
}
const ReportRowElement = ({
    playerId,
    playerName,
    age = 0,
    measurements,
    idx,
    measurementIds,
    setMeasurementIds,
    checkAll,
    allSelecter,
}: ReportRowElement) => {
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [curMeasurement, setCurMeasurement] = useState('');
    const [curMeasurementTime, setCurMeasurementTime] = useState<any>('');
    const handleParamNavigate = useParamNavigate();

    const handleNavigate = (navigateUrl: string) => {
        handleParamNavigate(
            {
                measurementId: curMeasurement,
                playerId: playerId,
                dateTime: curMeasurementTime?.measuredAt,
            },
            false,
            navigateUrl
        );
    };

    useEffect(() => {
        if (measurements?.length > 0) setCurMeasurement(measurements[0]?.measurementId);
    }, []);
    useEffect(() => {
        if (checkAll && curMeasurement) allSelecter(curMeasurement);
    }, [checkAll]);
    const handleCheck = () => {
        if (measurementIds.includes(curMeasurement)) {
            // 기존 데이터 제거
            setMeasurementIds(measurementIds.filter((id) => id !== curMeasurement));
        } else {
            // 데이터 추가
            setMeasurementIds([...measurementIds, curMeasurement]);
        }
    };
    const handleDropdownChange = (measurementId: string) => {
        // curMeasurement에 저장된 상태를 반영
        if (measurementIds.includes(curMeasurement)) {
            setMeasurementIds([...measurementIds.filter((id) => id !== curMeasurement), measurementId]);
            setCurMeasurement(measurementId);
        } else {
            setCurMeasurement(measurementId);
        }
    };
    useEffect(() => {
        if (curMeasurement) {
            const measurementDateTime = measurements?.find((el: any) => el?.measurementId === curMeasurement);
            setCurMeasurementTime(measurementDateTime);
        }
    }, [curMeasurement]);
    return (
        <div
            key={playerId}
            className={`${
                idx % 2 === 0 ? 'bg-secondary-mGrayLight rounded-[8px]' : ''
            } flex items-center h-[64px] p-[10px]`}
        >
            {/* <div className="w-[80px] text-center">
        <SqureCheckbox
          checked={measurementIds?.includes(curMeasurement) || false}
          onChange={handleCheck}
        />
      </div> */}
            <div className="m-auto w-[315px] text-center">{playerName}</div>
            <div className="m-auto w-[315px] text-center ">{`${age}세 (${ageMatcher(age || 0)})`}</div>
            <Dropdown
                className="w-[315px]"
                options={handleDropdownOpts(measurements)}
                value={curMeasurement}
                placeholder="날짜"
                onChange={(value) => handleDropdownChange(value)}
            />
            <div className="w-[315px] text-center">
                {/* grid grid-cols-2  */}
                <button
                    onClick={() =>
                        role === 'TEAM_STAFF'
                            ? handleNavigate(`/staff/measurement/report/dashboard/${playerId}`)
                            : handleNavigate(`/admin/control/measurement/${playerId}`)
                    }
                    className="relative m-auto text-primary-purple text-context-bold w-fit"
                >
                    측정보기
                    <span className="absolute bottom-0 left-0 w-full m-auto border-b border-primary-purple"></span>
                </button>
                {/* <button
          onClick={() => {
            const NavigateURL =
              role === "PLAYER"
                ? `/player/measurement/analysis/${curMeasurement}`
                : role === "TEAM_STAFF"
                ? `/staff/measurement/report/analysis/${curMeasurement}`
                : `/admin/control/measurement/analysis/${curMeasurement}`;

            handleNavigate(NavigateURL);
          }}
          className="relative m-auto text-primary-purple text-context-bold w-fit"
        >
          에잇라운드
          <span className="absolute bottom-0 left-0 w-full m-auto border-b border-primary-purple"></span>
        </button> */}
            </div>
        </div>
    );
};

export default ReportRowElement;

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.measurementId,
        label: formatDateTimeMatcher(arr?.measuredAt), //${arr?.type}
    }));
};
