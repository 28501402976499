// hooks
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// commons
import Tabs from 'components/tab/Default';
import StatusLabel from 'components/label/Default';
import AreaChartTip from 'components/tooltip/AreaChartTip';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { skills, skillTab } from 'utils/consts/measurement';
// apexChart
import ApexChart from 'components/chart/ApexChart';
import { barOptions } from 'pages/player/measurement/main/barOpt';
import { areaOptions } from 'pages/player/measurement/main/areaOpt';
import { coupleBarOptions, coupleBarSeries } from 'pages/player/measurement/main/coupleBarOpt';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';

const Dashboards = ({ dashboardData, dashboardDetailData }: any) => {
    const { role, id = '' } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const { playerInfo, rankingInfo, recentMeasurements }: PlayerDashboardType = dashboardData || {};
    const { skillComparison, skillHistories, overallAnalysis }: DashboardDetailType = dashboardDetailData || {};
    const [toolTipShow, setToolTipShow] = useState(true);
    const [tab, setTab] = useState(0);

    const reversedRecentMeasurements = recentMeasurements?.slice()?.reverse();
    const customizedXaxis = handleXaxis(reversedRecentMeasurements) || [];
    const customizedAreaData = handleAreaData(reversedRecentMeasurements) || [];
    const customizedBarData = handleBarData(skillHistories || []);

    const customizedBarOption = {
        ...barOptions,
        xaxis: {
            ...barOptions?.xaxis, // 기존 옵션 병합
            categories: customizedXaxis || [],
        },
        // dataLabels: {
        //     ...barOptions?.dataLabels,
        //     formatter: function (value: number, { dataPointIndex, w }: any) {
        //         const currentSkill = skillHistories[tab]; // 현재 선택된 스킬 데이터 가져오기
        //         if (!currentSkill || !currentSkill?.records) return '';

        //         const record = currentSkill?.records?.slice()?.reverse()[dataPointIndex] || []; // 해당 index의 데이터 가져오기
        //         const diff = Number(record?.scoreDiff || 0);
        //         // if (!record) return '';
        //         // let sign = record?.scoreDiff > 0 ? '+' : record?.scoreDiff < 0 ? '-' : ''; // 부호 설정
        //         // return `${sign}${Math.abs(record?.scoreDiff).toFixed(1)}`; // `scoreDiff` 값 그대로 사용
        //         let sign = diff >= 0 ? '+' : diff < 0 ? '' : '';
        //         return `${sign}${diff.toFixed(2)}`; // 증감 표시
        //     },
        // },
        annotations: {
            points:
                (customizedXaxis &&
                    customizedXaxis
                        .map((_, index) => {
                            const currentSkill = skillHistories?.[tab];
                            if (!currentSkill?.records || !Array.isArray(currentSkill.records)) return null;

                            const record = currentSkill?.records?.slice()?.reverse()[index] || {};
                            const diff = Number(record?.scoreDiff || 0);
                            const sign = diff >= 0 ? '+' : '';
                            const color = diff >= 0 ? '#EE2C36' : '#19DE93';
                            return {
                                x: _,
                                y: record?.score || 0, // 막대의 실제 값
                                marker: {
                                    size: 0, // 마커 크기를 0으로 설정하여 숨김
                                },
                                label: {
                                    text: `${sign}${diff?.toFixed(2)}`,
                                    borderWidth: 0, // border 두께를 0으로 설정하여 제거
                                    borderColor: 'transparent', // 혹은 투명하게 설정
                                    style: {
                                        color: color,
                                        background: 'transparent',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        borderWidth: 1, // 테두리 두께 (픽셀 단위)
                                    },
                                    offsetY: -10, // 텍스트 위치 조정
                                },
                            };
                        })
                        .filter((point) => point !== null)) ||
                [],
        },
    };
    const handleParamNavigate = useParamNavigate();
    const handleNavigate = (navigateUrl: string, dateTime?: string) => {
        handleParamNavigate(
            { playerId: `${playerInfo?.playerId}` || '', dateTime: dateTime || '', isEightRound: 'false' },
            false,
            navigateUrl
        );
    };
    const customizedCoupleBarData = skillComparison
        ? handleCoupleBarData(skillComparison?.previousScores, skillComparison?.currentScores)
        : handleCoupleBarData(initialSkillComparison?.previousScores, initialSkillComparison?.currentScores);

    const handleAnalysisBtn = () => {
        const adminUrl = `/admin/control/measurement/report`;
        const staffUrl = `/staff/measurement/report/personal`;
        const playerUrl = `/player/measurement/report`;
        const recentMeasurementsLen = reversedRecentMeasurements?.length - 1;
        if (!playerInfo?.playerId || recentMeasurementsLen < 0) return;
        handleNavigate(
            `${role === 'PLAYER' ? playerUrl : role === 'TEAM_STAFF' ? staffUrl : adminUrl}/${
                reversedRecentMeasurements[recentMeasurementsLen]?.measurementId
            }`,
            reversedRecentMeasurements[recentMeasurementsLen]?.measuredAt
        );
    };

    // ✅ 모바일 툴팁 이벤트
    const [isMobile, setIsMobile] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

    const handleChartClick = (event: any, chartContext: any, config: any) => {
        if (config.dataPointIndex !== undefined) {
            setSelectedIndex((prev) => (prev === config.dataPointIndex ? null : config.dataPointIndex));
        }
    };
    const tooltipCustom = ({ series, seriesIndex, dataPointIndex, w }: any) => {
        if (!series?.[seriesIndex]?.[dataPointIndex]) return '';

        const value = series[seriesIndex][dataPointIndex];
        const xPosition = w.globals.pointsArray[seriesIndex][dataPointIndex][0];

        return selectedIndex === dataPointIndex
            ? `
              <div style="position: absolute; left:${xPosition}px; top: -50px;
                  background: #ffffff; border: 2px solid #6B5BFF; padding: 8px 12px; border-radius: 10px;
                  font-size: 14px; font-weight: bold; color: #6B5BFF; text-align: center;">
                <span>${value}점</span>
                <button style="
                  background: #6B5BFF; color: #ffffff; font-size: 14px; font-weight: bold;
                  border: none; border-radius: 10px; padding: 8px 16px; cursor: pointer;
                  margin-top: 6px;">
                  분석하기
                </button>
              </div>
            `
            : '';
    };
    useEffect(() => {
        const viewCheck = window.innerWidth < 768;
        setIsMobile(viewCheck);
    }, []);
    // ✅ 초기 로드시 마지막 데이터 포인트 선택 (마운트 후 100ms 딜레이)
    useEffect(() => {
        setTimeout(() => setToolTipShow(true), 100);
    }, []);
    const customizedAreaOption = {
        ...areaOptions,
        xaxis: {
            ...areaOptions?.xaxis, // 기존 옵션 병합
            type: customizedXaxis?.length ? 'date' : 'category', // 데이터가 없으면 category로 변경
            categories: customizedXaxis || [],
        },
        markers: {
            ...areaOptions?.markers,
            discrete:
                customizedXaxis?.length === 1
                    ? [
                          {
                              // 데이터가 있을 때만 discrete 마커 설정
                              seriesIndex: 0,
                              dataPointIndex: 0,
                              fillColor: '#FFFFFF',
                              strokeColor: '#5e6eff',
                              size: 3,
                              shape: 'circle',
                          },
                      ]
                    : [],
        },
        tooltip: {
            ...areaOptions?.tooltip,
            enabled: customizedXaxis?.length === 1 ? false : true, // 기본 툴팁 완전 비활성화
            shared: customizedXaxis?.length === 1 ? false : true, // 하단 기본 툴팁 비활성화
        },
        chart: {
            ...areaOptions?.chart,
            events: {
                mouseMove: function () {
                    // 초기 설정: 마지막 데이터 포인트에 툴팁 표시
                    if (toolTipShow && customizedXaxis?.length > 1) {
                        setToolTipShow(false);
                    } else return;
                },
                // markerClick: function (event: any, chartContext: any, { seriesIndex, dataPointIndex }: any) {
                //     const adminUrl = `/admin/control/measurement/report`;
                //     const staffUrl = `/staff/measurement/report/personal`;
                //     if (!playerInfo?.playerId) return;
                //     handleNavigate(
                //         `${role === 'TEAM_STAFF' ? staffUrl : adminUrl}/${
                //             reversedRecentMeasurements[dataPointIndex]?.measurementId
                //         }`,
                //         reversedRecentMeasurements[dataPointIndex]?.measuredAt
                //     );
                // },
                click: (event: any, chartContext: any, { seriesIndex, dataPointIndex = 0 }: any) => {
                    const defaultIdx = dataPointIndex < 0 ? 0 : dataPointIndex;

                    const playerUrl = `/player/measurement/report/${reversedRecentMeasurements[defaultIdx]?.measurementId}`;
                    const adminUrl = `/admin/control/measurement/report/${reversedRecentMeasurements[defaultIdx]?.measurementId}`;
                    const staffUrl = `/staff/measurement/report/personal/${reversedRecentMeasurements[defaultIdx]?.measurementId}`;

                    handleNavigate(
                        `${role === 'PLAYER' ? playerUrl : role === 'TEAM_STAFF' ? staffUrl : adminUrl}`,
                        reversedRecentMeasurements[defaultIdx]?.measuredAt
                    );
                },
            },
        },
    };
    const customizedMobileAreaChartOptions = {
        ...areaOptions,
        tooltip: {
            enabled: false, // 모바일에서는 기본 툴팁 비활성화
            custom: tooltipCustom,
        },
        chart: {
            type: 'area',
            events: {
                click: handleChartClick,
            },
        },
    };
    return (
        <div className="flex flex-col gap-[20px] ">
            <div className="flex justify-between w-full">
                <div
                    id="main-area-chart"
                    className="w-[927px] h-[360px] bg-white rounded-[10px] flex"
                >
                    <div className="text-subtitle-medium-bold text-[20px] pl-[30px] py-[30px] shrink-0">
                        종합 측정 추이
                    </div>
                    <div className="p-[12px] relative cursor-pointer w-[800px] flex justify-center items-center mt-[5px]">
                        <ApexChart
                            customType={'area'}
                            customOptions={customizedAreaOption}
                            customSeries={customizedAreaData[0] ? [customizedAreaData[0]] : []}
                            height={310}
                        />
                        {reversedRecentMeasurements?.length > 0 && toolTipShow && (
                            <div
                                onClick={handleAnalysisBtn}
                                className={`absolute top-[20px] z-[20]  ${
                                    reversedRecentMeasurements?.length > 1 ? 'right-[-52px]' : 'ml-[23px]'
                                }`}
                            >
                                <AreaChartTip
                                    value={
                                        reversedRecentMeasurements[reversedRecentMeasurements?.length - 1]?.overallScore
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="relative w-[453px] h-[360px] bg-white rounded-[10px] py-[30px]">
                    <div className="absolute text-subtitle-medium-bold px-[30px]">이전 측정과 비교</div>
                    <div
                        id="main-coupleBar-chart"
                        className="w-[400px] mt-[10px] ml-[17px]"
                    >
                        {coupleBarOptions && customizedCoupleBarData && (
                            <ApexChart
                                customType={'bar'}
                                customOptions={coupleBarOptions}
                                customSeries={customizedCoupleBarData}
                                height={250}
                            />
                        )}
                    </div>
                    <div className="absolute bottom-[30px] w-[393px] flex justify-between items-center ml-[30px]">
                        <div className="text-[14px] text-secondary-lGrayDark">
                            <div className="flex gap-[10px] items-center">
                                <div className="w-[14px] h-[14px] rounded-[4px] bg-[#B1B1B1]"></div>
                                <div>이전 측정</div>
                            </div>
                            <div className="flex gap-[10px] items-center">
                                <div className="w-[14px] h-[14px] rounded-[4px] bg-primary-purple"></div>
                                <div>최근 측정</div>
                            </div>
                        </div>
                        <div className="text-context-regular flex items-center gap-[6px]">
                            이전 측정에 대한 개선도
                            <StatusLabel status={Number(skillComparison?.averageGrowthScore || 0) >= 0 ? 'up' : 'down'}>
                                {`${skillComparison?.averageGrowthScore || '-'} 점` || '측정필요'}
                            </StatusLabel>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex justify-between w-full">
                <div className=" bg-white rounded-[10px] mb-[15px] h-[374px] w-[927px]">
                    <div className="text-subtitle-medium-bold text-[20px] pl-[30px] pr-[30px] pt-[30px] pb-[20px] shrink-0 flex justify-between items-center">
                        <span>기술별 점수 추이</span>
                        <Tabs
                            tabs={skillTab}
                            defaultActiveTab={0}
                            onTabChange={(index) => setTab(index)}
                        />
                    </div>
                    <div className="flex items-center justify-between ">
                        <div
                            id="main-bar-chart"
                            className="w-[905px] pl-[15px] mt-[-10px]"
                        >
                            {customizedXaxis?.length > 0 &&
                                customizedBarData?.length > 0 &&
                                tab >= 0 &&
                                tab < customizedBarData.length &&
                                customizedBarData[tab] && (
                                    <ApexChart
                                        customType={'bar'}
                                        customOptions={customizedBarOption}
                                        customSeries={[customizedBarData[tab]]}
                                        height={270}
                                    />
                                )}
                        </div>
                    </div>
                </div>
                <div className="w-[453px] h-[374px] bg-white p-[30px] rounded-[10px]">
                    <div className="text-subtitle-medium-bold mb-[4px]">AI 요약</div>
                    <div className="rounded-[14px] bg-[#f8f8f8] p-[18px] grid grid-cols-2">
                        <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                            <div>가장 우수한 기술은</div>
                            <div className="px-[12px] py-[4px] bg-primary-purple text-primary-white text-context-bold rounded-[80px]">
                                {overallAnalysis?.bestSkill || '측정필요'}
                            </div>
                        </div>
                        <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                            <div>개선이 필요한 기술은</div>
                            <div className="px-[12px] py-[4px] bg-secondary-dGrayLight text-primary-white text-context-bold rounded-[80px]">
                                {overallAnalysis?.weakestSkill || '측정필요'}
                            </div>
                        </div>
                        <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                            <div>가장 많이 성장한 기술은</div>
                            <div className="px-[12px] py-[4px] bg-status-correct text-primary-white text-context-bold rounded-[80px]">
                                {overallAnalysis?.mostImprovedSkill || '측정필요'}
                            </div>
                        </div>
                        <div className="h-[111px] flex flex-col items-center justify-center gap-[8px]">
                            <div>이전 측정과 비교</div>

                            {skillComparison && skillComparison?.averageGrowthScore ? (
                                <StatusLabel
                                    status={Number(skillComparison?.averageGrowthScore || 0) >= 0 ? 'up' : 'down'}
                                    customColor={
                                        Number(skillComparison?.averageGrowthScore || 0) >= 0
                                            ? 'bg-status-correct text-primary-white'
                                            : 'bg-[#D2D2D2] text-[#111111]'
                                    }
                                >
                                    {`${skillComparison?.averageGrowthScore} %
                                    ${Number(skillComparison?.averageGrowthScore || 0) >= 0 ? '상승' : '하락'}`}
                                </StatusLabel>
                            ) : (
                                <div className="px-[12px] py-[4px] bg-status-correct text-primary-white text-context-bold rounded-[80px]">
                                    {'측정필요'}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboards;

// const handleBarData = (skillHistories: SkillHistory[] = []): ChartData[] => {
//     return (
//         skillHistories?.map((record) => ({
//             name: record?.skillType,
//             data:
//                 record?.records
//                     ?.map((rec) => rec?.score)
//                     ?.slice()
//                     ?.reverse() || [], // records에서 score 값만 가져오기
//         })) || []
//     );
// };
const handleBarData = (skillHistories: SkillHistory[] = []): ChartData[] => {
    return skillHistories.map((record) => ({
        name: record?.skillType || 'Unknown',
        data: (record?.records || []).map((rec) => rec?.score ?? 0).reverse(),
    }));
};

const handleXaxis = (measurements: RecentMeasurementsType[]): any[] => {
    const customizedXaxis = measurements?.map((rec) => rec?.measuredAt); // records에서 measuredAt 값만 가져오기
    return customizedXaxis;
};

const handleAreaData = (measurements: RecentMeasurementsType[]) => {
    if (!measurements || measurements.length === 0) return [];
    return [
        {
            name: '종합 측정 추이', // 시리즈 이름
            data: measurements.map((measurement) => measurement.overallScore), // overallScore 배열로 변환
        },
    ];
};
const handleCoupleBarData = (previousScores: ComparisonType, currentScores: ComparisonType) => {
    return [
        {
            name: '이전측정',
            data: [
                previousScores?.dribbleAvg || 0,
                previousScores?.passAvg || 0,
                previousScores?.shootingAvg || 0,
                previousScores?.firstTouch || 0,
            ],
        },
        {
            name: '최근측정',
            data: [
                currentScores?.dribbleAvg || 0,
                currentScores?.passAvg || 0,
                currentScores?.shootingAvg || 0,
                currentScores?.firstTouch || 0,
            ],
        },
    ];
};

interface PlayerDashboardType {
    playerInfo: {
        playerId: number;
        playerName: string;
        birthday: string | null;
        profileImageUrl: string | null;
    };
    rankingInfo: {
        overallRank: {
            currentRank: number | null;
            previousRank: number | null;
            totalCount: number | null;
            rankChange: number | null;
        };
        sameAgeRank: {
            currentRank: number | null;
            previousRank: number | null;
            totalCount: number | null;
            rankChange: number | null;
        };
        bestScore: {
            score: number | null;
            topPercentile: number | null;
        };
    };
    recentMeasurements: [
        {
            measurementId: number;
            overallScore: number;
            measuredAt: string;
        }
    ];
}

interface DashboardDetailType {
    skillComparison: {
        currentScores: {
            dribbleAvg: number;
            passAvg: number;
            shootingAvg: number;
            firstTouch: number;
        };
        previousScores: {
            dribbleAvg: number;
            passAvg: number;
            shootingAvg: number;
            firstTouch: number;
        };
        totalGrowthRate?: number;
        averageGrowthScore?: number;
    } | null;
    skillHistories: [
        {
            skillType: string;
            records: [
                {
                    score: number;
                    measuredAt: string;
                    scoreDiff?: string;
                }
            ];
        }
    ];
    overallAnalysis: {
        bestSkill: string;
        weakestSkill: string;
        mostImprovedSkill: string;
        mostImprovedScoreGap: string;
    };
}
interface Record {
    score: number;
    measuredAt: string;
}

interface SkillHistory {
    skillType: string;
    records: Record[];
    scoreDiff?: string[];
}

interface ChartData {
    name: string;
    data: number[];
    scoreDiff?: string[];
}

interface ComparisonType {
    dribbleAvg: number;
    passAvg: number;
    shootingAvg: number;
    firstTouch: number;
}

interface RecentMeasurementsType {
    measuredAt: string;
    measurementId: number;
    overallScore: number;
}
export const initialSkillComparison = {
    currentScores: {
        dribbleAvg: 0,
        passAvg: 0,
        shootingAvg: 0,
        firstTouch: 0,
    },
    previousScores: {
        dribbleAvg: 0,
        passAvg: 0,
        shootingAvg: 0,
        firstTouch: 0,
    },
};
