// hooks
import { useApiQuery } from 'hooks/useDynamicApi';
import { useReactToPrint } from 'react-to-print';
// commons
import { useEffect, useState, useRef } from 'react';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { skills, skillTab } from 'utils/consts/measurement';
import { ENDPOINT, ENDPOINT_MEASUREMENT } from 'utils/consts/apiEndpoint';
import { skillDescriptions } from 'utils/consts/measurement';
// player components
import SkillAvgCard from 'pages/player/measurement/analysis/analysisCard/SkillAvgCard';
import AnalysisDetail from 'pages/player/measurement/analysis/analysisCard/AnalysisDetail';
import AnalyzeVideo from 'pages/player/measurement/analysis/analysisCard/AnalyzeVideo';
import FeedbackCard from 'pages/player/measurement/analysis/analysisCard/FeedbackCard';
// admin components
import AdminFeedbackPostCard from 'pages/admin/measurement/analysis/AdminFeedbackPostCard';
// icons
import { ReactComponent as PrintIcon } from 'assets/svgs/print.svg';
import { ReactComponent as WarningBoxIcon } from 'assets/svgs/warningBox.svg';

const AnalyzeCard = ({ dribbleOneId }: any) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const { best = '측정필요', bestFeedback = '측정필요', worst = '측정필요', worstFeedback = '측정필요' } = {};
    const [showBestInfoCard, setShowBestInfoCard] = useState(false);
    const [showWorstInfoCard, setShowWorstInfoCard] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);

    const getDribbleOneDetail = useApiQuery(
        ['getDribbleOneDetail', `${dribbleOneId}`],
        ENDPOINT_MEASUREMENT.dribbleOneDetail(`${dribbleOneId}`),
        'get',
        { enabled: !!dribbleOneId }
    );
    const { scoresBySecond, bestPerformingSkill, worstPerformingSkill } =
        getDribbleOneDetail?.data?.videoChartData || {};
    const { cctvVideoId, cctvVideoPath, skeletonVideoId, skeletonVideoPath, analysisVideoId, analysisVideoPath } =
        getDribbleOneDetail?.data?.videoSource || {};
    // 총 초 계산
    const totalDuration = scoresBySecond ? Math.max(...Object.keys(scoresBySecond).map(Number)) : 0;
    // react qury
    const getCctvVideoToken = useApiQuery(
        ['getCctvVideoToken', `${cctvVideoId}`],
        ENDPOINT_MEASUREMENT.dribbleOneToken(`${cctvVideoId}`),
        'get',
        { enabled: !!cctvVideoId }
    );
    const getSkeletonVideoToken = useApiQuery(
        ['getSkeletonVideoToken', `${skeletonVideoId}`],
        ENDPOINT_MEASUREMENT.dribbleOneToken(`${skeletonVideoId}`),
        'get',
        { enabled: !!skeletonVideoId }
    );
    //   console.log(getDribbleOneDetail?.data);
    const [isPrint, setIsPrint] = useState(false);

    const reactToPrintFn = useReactToPrint({
        contentRef, // 이렇게 화살표 함수로 변경
        onBeforePrint: () => {
            setIsPrint(true);
            return Promise.resolve();
        },
        onAfterPrint: () => {
            setIsPrint(false);
            return Promise.resolve();
        },
    });
    useEffect(() => {
        if (isPrint) {
            reactToPrintFn();
        }
    }, [isPrint, reactToPrintFn]);
    return (
        <div
            className=""
            ref={contentRef}
        >
            {getDribbleOneDetail?.data ? (
                <div className={` flex flex-col gap-[20px] rounded-[10px] bg-primary-white p-[30px]`}>
                    <div className="flex flex-col gap-[10px]">
                        <div className="flex items-center justify-between text-subtitle-medium-bold">
                            <span>기술별 분석</span>
                            {/* <Tabs
            tabs={skillTab}
            defaultActiveTab={0}
            onTabChange={(index) => console.log(`Selected tab: ${index}`)}
          /> */}
                            {!isPrint && (
                                <button
                                    onClick={() => setIsPrint(true)}
                                    className="flex justify-center items-center gap-[10px] w-[110px] h-[44px] rounded-[8px] bg-primary-green text-context-bold"
                                >
                                    <span>출력하기</span>
                                    <PrintIcon />
                                </button>
                            )}
                        </div>
                        <div className="grid grid-cols-2 gap-[20px] h-[65px]">
                            <div className="">
                                {showBestInfoCard ? (
                                    <FeedbackCard
                                        title={'가장 우수한 기능은'}
                                        type={'best'}
                                        skill={bestPerformingSkill}
                                        defaultfeedback={bestFeedback}
                                        showInfoCard={showBestInfoCard}
                                        setShowInfoCard={setShowBestInfoCard}
                                    />
                                ) : (
                                    <div className="bg-[#f8f8f8] rounded-[14px] w-full flex h-[65px] justify-center items-center gap-[8px]">
                                        <span className="text-context-regular">가장 우수한 기능은</span>
                                        <span className="text-[14px] font-bold bg-primary-green text-primary-black rounded-[80px] px-[12px] py-[4px]">
                                            {bestPerformingSkill}
                                        </span>
                                        <button onClick={() => setShowBestInfoCard(!showBestInfoCard)}>
                                            <WarningBoxIcon />
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="">
                                {showWorstInfoCard ? (
                                    <FeedbackCard
                                        title={'가장 필요한 기능은'}
                                        type={'worst'}
                                        skill={worstPerformingSkill}
                                        defaultfeedback={bestFeedback}
                                        showInfoCard={showWorstInfoCard}
                                        setShowInfoCard={setShowWorstInfoCard}
                                    />
                                ) : (
                                    <div className="bg-[#f8f8f8] rounded-[14px] w-full flex h-[65px] justify-center items-center gap-[8px]">
                                        <span>가장 필요한 기능은</span>
                                        <span className="text-[14px] font-bold bg-status-error text-primary-white  rounded-[80px] px-[12px] py-[4px]">
                                            {worstPerformingSkill}
                                        </span>
                                        <button onClick={() => setShowWorstInfoCard(!showWorstInfoCard)}>
                                            <WarningBoxIcon />
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <SkillAvgCard
                        isPrint={isPrint}
                        averageScores={getDribbleOneDetail?.data?.averageScores}
                    />
                    <div className="flex flex-col gap-[10px]">
                        <div className="text-subtitle-medium-bold">분석영상</div>
                        {cctvVideoId && skeletonVideoId && (
                            <AnalyzeVideo
                                dribbleOneId={dribbleOneId} // ✅ dribbleOneId 전달
                                cctvVideoId={cctvVideoId}
                                skeletonVideoId={skeletonVideoId}
                                cctvVideoToken={getCctvVideoToken?.data}
                                skeletonVideoToken={getSkeletonVideoToken?.data}
                                // analysisVideoId={analysisVideoId}
                                isPlaying={isPlaying}
                                setIsPlaying={setIsPlaying}
                                progress={progress}
                                setProgress={setProgress}
                                // duration={duration}
                                // setDuration={setDuration}
                                totalDuration={totalDuration}
                                getCctvVideoToken={getCctvVideoToken}
                                getSkeletonVideoToken={getSkeletonVideoToken}
                            />
                        )}
                    </div>
                    {isPrint && <div className="h-[200px]" />}
                    <div className="flex flex-col gap-[10px]">
                        <div className="text-subtitle-medium-bold">세부항목</div>
                        {scoresBySecond && totalDuration && (
                            <AnalysisDetail
                                isPrint={isPrint}
                                detailPart={scoresBySecond}
                                totalDuration={totalDuration}
                                progress={progress}
                            />
                        )}
                    </div>
                </div>
            ) : getDribbleOneDetail?.isError ? (
                <div className="rounded-[10px] bg-primary-white p-[30px]">완료되지 않은 측정입니다.</div>
            ) : (
                <SoccerBallLodaing />
            )}
        </div>
    );
};

export default AnalyzeCard;
