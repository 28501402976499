import * as fabric from 'fabric';
import type { Control } from 'fabric/fabric-impl'; // fabric.Control 타입 정의용

export const applyFabricDefaultsToObject = (obj: fabric.Object) => {
    const commonStyle: Partial<fabric.Object> = {
        borderColor: '#ac14ff',
        cornerColor: '#ffffff',
        cornerStrokeColor: '#ac14ff',
        cornerSize: 7,
        transparentCorners: false,
        borderScaleFactor: 2,
    };

    const deleteControl: Control = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: -10,
        offsetX: 10,
        cursorStyle: 'pointer',
        mouseUpHandler: (eventData, transform) => {
            const target = transform.target;
            const canvas = target.canvas;
            canvas?.remove(target);
            canvas?.requestRenderAll();
            return true;
        },
        render: (ctx, left, top) => {
            const size = 20;
            ctx.save();
            ctx.fillStyle = '#ff5b74';
            ctx.beginPath();
            ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
            ctx.fill();

            ctx.strokeStyle = 'white';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.moveTo(left - 4, top - 4);
            ctx.lineTo(left + 4, top + 4);
            ctx.moveTo(left + 4, top - 4);
            ctx.lineTo(left - 4, top + 4);
            ctx.stroke();
            ctx.restore();
        },
    });

    obj.set(commonStyle);
    obj.controls = {
        ...obj.controls,
        deleteControl,
    };
};
