export const barOptions = {
    chart: {
        type: 'area',
        toolbar: {
            show: false,
        },
        zoom: {
            enabled: false, // 확대 비활성화
        },
        // background: '#0D172C',
        // background: 'repeating-linear-gradient(45deg, #12203D 0px, #12203D 10px, #ffffff 10px, #ffffff 20px)', // 스트라이프 배경
    },
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '14px',
            borderRadius: 4,
            colors: {
                hover: {
                    // opacity: 1, // 호버 시 opacity 변경
                },
            },
        },
    },
    states: {
        hover: {
            filter: {
                type: 'none', // hover 시 흐려짐 효과 제거
            },
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none', // 선택 시 흐려짐 효과 제거
            },
        },
    },
    forecastDataPoints: {},
    dataLabels: {
        enablewd: true, // 데이터 점 숫자 제거
        position: 'top', // ✅ 막대의 상단에 배치
        formatter: function (value: number, { dataPointIndex, w }: any) {
            // 20240210_백엔드 성능문제로 점수 안주는 경우, 여기 활성화 시키면 됨
            // const prevValue = dataPointIndex > 0 ? w.config.series[0].data[dataPointIndex - 1] : null;
            // let diff = prevValue !== null ? value - prevValue : 0;
            // let sign = diff >= 0 ? '+' : diff < 0 ? '-' : '';
            // return `${sign}${diff.toFixed(1)}`; // 증감 표시
        },
        style: {
            fontSize: '12px',
            colors: ['#5E6EFF'], // 색상 설정
            fontWeight: 'bold',
        },
        offsetY: 100, // 🔹 막대 위로 더 올리고 싶으면 음수 값 증가
    },
    stroke: {
        // width: 5,
        curve: 'smooth',
        // colors: ['#5E6EFF'], // 선 색상 변경
        dashArray: 1, // 점선 제거
    },
    xaxis: {
        // type: 'datetime',
        categories: [
            '2024-01-14', // 날짜 형식 변경
            '2024-01-15', // 날짜 형식 변경
            '2024-01-16',
            '2024-01-17',
            '2024-01-18',
            '2024-01-19',
            '2024-01-20',
        ],
        tooltip: {
            enabled: true,
        },
        labels: {
            show: true,
            formatter: function (value: string) {
                // xaxis의 categories 변환
                // '2024-07-04' -> '07.04'로 변환
                const date = new Date(value);
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                return `${month}.${day}`;
            },
            style: {
                colors: '#000000', // 텍스트 색상
                fontSize: '10px',
            },
        },
    },
    yaxis: {
        min: 0,
        max: 100, // Y축 최대값
        tickAmount: 5, // 0, 20, 40, 60, 80, 100 표시
        labels: {
            formatter: function (value: any) {
                return value.toFixed(0); // 정수로 표시
            },
        },
    },
    fill: {
        type: 'fill', // 단색 설정
        colors: ['#5E6EFF'], // 단색 채우기 색상
        gradient: {
            // shade: 'dark',
            // gradientToColors: ['#5E6EFF'], // 끝 색상 (주황색)
            // gradientFromColors: ['#5E6EFF'], // 시작 색상 (주황색)
            // shadeIntensity: 1,
            // type: 'vertical',
            // opacityFrom: 0.8, // 시작 투명도
            // opacityTo: 0, // 끝 투명도
        },
    },

    title: {
        show: false, // 제목 숨김
    },
    grid: {
        row: {
            // opacity: 0.5,
        },
        borderColor: '#DCDCDC', // 그리드 선 제거
        colors: ['#ccc234', 'transparent'], // takes an array which will be repeated on columns
    },
    markers: {
        show: false, // 데이터 점 제거
    },
    tooltip: {
        enabled: true,

        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            // 툴팁에 표시할 값 가져오기
            const value = series[seriesIndex][dataPointIndex];

            return `
            <div>
                <div
                style="
                    position: relative;
                    display: inline-block;
                     background: #111111;
                    color: white;
                    padding: 8px 10px;
                    border-radius: 8px;
                    font-size: 14px;
                    font-weight: bold;
                    text-align: center;
                    white-space: nowrap;
                ">
                    <span>${value}</span>

                </div>
                <!-- 삼각형 화살표 -->
                <div
                style="margin-top:-5px; margin-left:13px;
                ">
                <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.61747 8.77598C7.81877 9.87418 6.18122 9.87418 5.38253 8.77598L1.31007 3.17634C0.348657 1.8544 1.29297 -2.44633e-07 2.92754 -1.01734e-07L11.0725 6.10318e-07C12.707 7.53217e-07 13.6513 1.85441 12.6899 3.17634L8.61747 8.77598Z" fill="#111111"/>
</svg>
                </div>
                </div>
            `;
        },

        // theme: "dark", // 'dark' 또는 'light' 옵션으로 테마 변경
        // style: {
        //   fontSize: "12px", // 툴팁 글자 크기
        //   // fontFamily: 'Arial, sans-serif', // 툴팁 글꼴
        //   background: "", // 툴팁 배경 색상
        //   // color: '#fff', // 툴팁 글자 색상
        //   borderRadius: "5px", // 툴팁의 라운드 효과
        // },
        // marker: {
        //   show: false, // 툴팁에 마커 표시 여부
        // },
        // x: {
        //   show: false, // X축 툴팁 표시
        //   formatter: (value: string) => value, // 기본 포맷 유지
        // },
        // y: {
        //   title: {
        //     formatter: (seriesName: any) => "", // y축 제목 포맷 수정
        //   },
        // },
        // offsetX: 0, // 중앙에 위치
        // offsetY: -20, // 막대 위로 약간 올림
        // fillSeriesColor: true, // 툴팁의 꼬리 제거
        // 다른 툴팁 속성 (예: 텍스트 스타일 등)도 추가 가능합니다.
    },
};
