// icons
import { ReactComponent as MiniVideoIcon } from 'assets/svgs/miniVideo.svg';
import { ReactComponent as EmptyFileIcon } from 'assets/svgs/emptyFile.svg';
import { ReactComponent as LapTimeIcon } from 'assets/svgs/lapTime.svg';
// player component
import ProgressBar from 'components/progressBar';
const RadialBarChart = ({ dribbleTwoAnalysis }: any) => {
    // conePassRate
    // dribbleTwoId
    // failCount
    // finalScore
    // lapTime
    // successCount
    const totalCont = dribbleTwoAnalysis?.successCount + dribbleTwoAnalysis?.failCount;
    return (
        <div className="flex flex-col gap-[20px]">
            <div className="flex items-center justify-between">
                <div className="text-subtitle-small-bold text-secondary-dGrayDark item">지그재그</div>
                <div className="text-secondary-lGrayDark text-context-regular flex gap-[15px] items-center">
                    <div className="flex gap-[8px] items-center mr-[5px]">
                        <span className="flex gap-[5px] items-center">
                            <EmptyFileIcon />총 점수
                        </span>
                        <span className="flex gap-[5px] items-center">
                            <span className="text-[34px] font-bold text-primary-black">
                                {dribbleTwoAnalysis?.finalScore || 0}
                            </span>
                            <span>점</span>
                        </span>
                    </div>
                    <div className="flex gap-[8px] items-center">
                        <span className="flex gap-[5px] items-center">
                            <LapTimeIcon />
                            랩타임
                        </span>
                        <span className="flex gap-[5px] items-center">
                            <span className="text-[34px] font-bold text-primary-black">
                                {dribbleTwoAnalysis?.lapTime || 0}
                            </span>
                            <span>sec</span>
                        </span>
                    </div>
                    <button className="cursor-default flex gap-[10px] p-[10px] rounded-[8px] border border-secondary-lGrayMid ml-[2px]">
                        <MiniVideoIcon />
                        측정필요
                    </button>
                </div>
            </div>
            <div className="flex gap-[15px]">
                <div className="w-full flex flex-col gap-[10px] justify-center">
                    <ProgressBar
                        label="성공"
                        targetRate={totalCont}
                        achievementRate={dribbleTwoAnalysis?.successCount || 0}
                        customColor="#5651E3"
                    />
                    <ProgressBar
                        label="실패"
                        targetRate={totalCont}
                        achievementRate={dribbleTwoAnalysis?.failCount || 0}
                        customColor="#79788E"
                    />
                </div>
                <div className="w-[419px] grid grid-cols-2 h-[100px] bg-[#F8F8F8] rounded-[14px]">
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-secondary-dGrayLight">성공률</div>
                        <div>
                            <span className="text-title-small-bold text-primary-purple">
                                {dribbleTwoAnalysis?.conePassRate || 0}
                            </span>
                            <span className="text-[#7c7c7c] text-context-bold">%</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-center">
                        <div className="text-secondary-dGrayLight">실패율</div>
                        <div>
                            <span className="text-title-small-bold text-secondary-mGrayDark">
                                {100 - dribbleTwoAnalysis?.conePassRate || 0}
                            </span>
                            <span className="text-[#7c7c7c] text-context-bold">%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RadialBarChart;
