import { MutableRefObject, useEffect } from 'react';
import * as fabric from 'fabric';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';

export const usePressKey = (fabricRef: MutableRefObject<fabric.Canvas | null>) => {
    let clipboard: fabric.Object | null = null;

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const canvas = fabricRef.current;
            if (!canvas) return;

            const activeObject = canvas.getActiveObject();

            // ✅ Ctrl + C (복사)
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'c') {
                if (activeObject) {
                    activeObject.clone().then((cloned: fabric.Object) => {
                        clipboard = cloned;
                    });
                }
            }

            // ✅ Ctrl + V (붙여넣기)
            if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'v') {
                if (clipboard) {
                    clipboard.clone().then((clonedObj: fabric.Object) => {
                        clonedObj.set({
                            left: (clipboard!.left ?? 0) + 20,
                            top: (clipboard!.top ?? 0) + 20,
                        });
                        // ✅ 삭제 버튼, 컨트롤 등 기본값 적용
                        applyFabricDefaultsToObject(clonedObj);
                        canvas.add(clonedObj);
                        canvas.setActiveObject(clonedObj);
                        canvas.requestRenderAll();
                    });
                }
            }

            // ✅ Delete 또는 Backspace (삭제)
            if (e.key === 'Delete' || e.key === 'Backspace') {
                if (activeObject && activeObject.type !== 'textbox') {
                    canvas.remove(activeObject);
                    canvas.discardActiveObject();
                    canvas.requestRenderAll();
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [fabricRef]);
};
