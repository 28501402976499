// hooks
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_PLAYER_DIARY } from 'utils/consts/apiEndpoint';
// training components
import TrainingDiarySubHeader from 'pages/player/control/training/[id]/TrainingDiarySubHeader';
import TrainingInfo from 'pages/player/control/training/[id]/TrainingInfo';
import TrainingSession from 'pages/player/control/training/[id]/TrainingSession';
import TrainingEvaluation from 'pages/player/control/training/[id]//TrainingEvalutaion';
import SoccerDiary from 'pages/player/control/training/[id]/SoccerDiary';

const TrainingDetail = () => {
    const { playerDiaryId, id } = useParams();
    const handleParamNavigate = useParamNavigate();

    // useApiQuery
    const getStaffPlayerDiaryId = useApiQuery(
        ['getStaffPlayerDiaryId', `${playerDiaryId}`, `${id}`],
        ENDPOINT_PLAYER_DIARY.staffPlayerDiary(`${playerDiaryId}`),
        'get',
        {
            enabled: !!playerDiaryId,
        }
    );
    // 업로드
    const [editableData, setEditableData] = useState({
        personalTrainingIntensity: null,
        goodPoints: '',
        lackingPoints: '',
        questionsPoints: '',
        footballDiary: '',
    });
    useEffect(() => {
        if (getStaffPlayerDiaryId?.data) {
            setEditableData({
                personalTrainingIntensity: getStaffPlayerDiaryId?.data?.personalTrainingIntensity,
                goodPoints: getStaffPlayerDiaryId?.data?.goodPoints,
                lackingPoints: getStaffPlayerDiaryId?.data?.lackingPoints,
                questionsPoints: getStaffPlayerDiaryId?.data?.questionsPoints,
                footballDiary: getStaffPlayerDiaryId?.data?.footballDiary,
            });
        }
    }, [getStaffPlayerDiaryId?.data]);
    const handleNavigate = (url: string, data?: any) => {
        handleParamNavigate({}, false, `${url}`, data);
    };
    return (
        <div className="wrapper">
            {getStaffPlayerDiaryId?.isPending && <SoccerBallLodaing />}
            <TrainingDiarySubHeader
                navigateTitle="관리"
                title="훈련 상세"
                // handleNavigate={() => handleNavigate('/staff/control/training')}
            />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <TrainingInfo playerDiaryIdData={getStaffPlayerDiaryId?.data} />
                {getStaffPlayerDiaryId?.data?.trainingSessions && (
                    <TrainingSession trainingSession={getStaffPlayerDiaryId?.data?.trainingSessions} />
                )}
                <TrainingEvaluation
                    editableData={editableData}
                    setEditableData={setEditableData}
                />
                {/* <SoccerDiary
                    editableData={editableData}
                    setEditableData={setEditableData}
                /> */}
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default TrainingDetail;

export const handleRows = (rows: any[]) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        name: `${row?.name} `,
        sessionPoint: row?.sessionPoint,
        createdAt: row?.createdAt,
        updatedAt: row?.updatedAt,
        link: `/admin/feedback/${row?.measurementId}?measurementId=${row?.measurementId}&dateTime=${row?.measuredAt}`,
    }));
};

interface PlayerDiaryInfoType {
    id: number | null;
    trainingDateTime: string | null;
    location?: string | null;
    weather: string | null;
    trainingIntensity: number | null;
    trainingSessions: {
        id: number;
        tacticalBoardJson: string | null;
        sessionPoint: string | null;
        sequence: 1;
    }[];
    personalTrainingIntensity: 3;
    goodPoints: string | null;
    lackingPoints: string | null;
    questionsPoints: string | null;
    footballDiary: string | null;
}
