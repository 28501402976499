// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
import { ENDPOINT_MANAGEMENT_PROFILE, ENDPOINT_EVALUATION } from 'utils/consts/apiEndpoint';
import { stringDropDownFormChanger } from 'utils/formChanger';
import { gradeDescriptionMatcher } from 'utils/matchers/reportMatcher';
// staff components
import ControlRatingSubHeader from 'pages/staff/control/rating/RatingSubHeader';
import PeriodDropDown from 'pages/staff/control/rating/PeriodDropDown';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
//icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';

const Rating = () => {
    const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();
    const toDay = new Date();
    const toDayYear = String(toDay.getFullYear());
    const { staffRole } = useSelector((state: RootState) => state.userInfo);

    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [period, setPeriod] = useState({ label: '', value: '' });
    const paramYear = searchParams.get('year') || toDayYear;

    const columns = [
        { key: 'playerName', label: '이름' },
        { key: 'age', label: '나이' },
        { key: 'grade', label: '학년' },
        { key: 'uniformNumber', label: '배번' },
        { key: 'evaluationHistory', label: '등급 평가 기록' },
        // { key: "rank", label: "등급" },
        // { key: "leagueParticipant", label: "리그 출전" },
        // { key: "history", label: "직전 소속" },
        { key: 'link', label: '상세보기' },
        {
            key: 'status',
            label: '상태',
        },
    ];
    // react qury
    const getManagementTeamProfile = useApiQuery(
        ['getManagementTeamProfile', paramYear, paramAgeGroup, staffRole ? staffRole : ''],
        ENDPOINT_MANAGEMENT_PROFILE.season.team(`${paramYear}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
            year: paramYear || undefined,
        }),
        'get',
        { enabled: !!staffRole }
    );

    const getEvaluationPeriodId = useApiQuery(
        ['getEvaluationPeriodId', period?.value, staffRole ? staffRole : ''],
        ENDPOINT_EVALUATION.period(`${period?.value}`, {
            ageGroup: staffRole === 'OFFICE' && paramAgeGroup ? paramAgeGroup : undefined,
        }),
        'get',
        { enabled: !!staffRole && !!period?.value }
    );
    const handleRows = (rows: any[]) => {
        return rows?.map((row) => ({
            evaluationId: row?.evaluationId,
            playerId: row?.playerId,
            uniformNumber: `${row?.uniformNumber} 번`,
            playerName: row?.playerName,

            age: `${row?.age} 세`,
            grade: ageMatcher(row?.age),
            link: `/staff/control/rating/${period?.value}?player=${
                row?.playerId
            }&ageGroup=${paramAgeGroup}&year=${paramYear}${
                row?.evaluationId ? `&evaluation=${row?.evaluationId}` : ''
            }`,
            status:
                row?.status === 'NOT_EXISTS' ? (
                    <button
                        className="m-auto"
                        onClick={() =>
                            navigate(
                                `/staff/control/rating/${period?.value}?player=${
                                    row?.playerId
                                }&ageGroup=${paramAgeGroup}&year=${paramYear}${
                                    row?.evaluationId ? `&evaluation=${row?.evaluationId}` : ''
                                }`
                            )
                        }
                    >
                        <EditPenIcon className=" text-secondary-lGrayDark" />
                    </button>
                ) : (
                    <span className="rounded-[80px] bg-[#D2D2D2] m-auto text-[#111111] py-[4px] px-[12px] w-fit">
                        완료
                    </span>
                ),
            evaluationHistory: (
                <div className="flex gap-[10px] justify-center">
                    {row?.evaluationHistory?.map((el: any, idx: number) => (
                        <span
                            key={idx}
                            className="text-context-regular"
                        >
                            <span className="text-secondary-lGrayMid">{`${el?.year}.${el?.periodNumber}`}</span>
                            <span>{gradeDescriptionMatcher(el?.grade)}</span>
                        </span>
                    ))}
                </div>
            ),
        }));
    };

    const customizedRow = handleRows(getEvaluationPeriodId?.data?.playerEvaluations || []);

    return (
        <div className="wrapper">
            {getEvaluationPeriodId?.isPending ||
                getManagementTeamProfile?.isPending ||
                (!!period?.value && getEvaluationPeriodId?.isPending && <SoccerBallLodaing />)}
            <ControlRatingSubHeader period={period} />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px]">
                {getManagementTeamProfile?.data?.teamInfo?.teamId && (
                    <PeriodDropDown
                        currentPeriodInfo={getEvaluationPeriodId?.data?.currentPeriodInfo}
                        paramTeamId={getManagementTeamProfile?.data?.teamInfo?.teamId}
                        period={period}
                        setPeriod={setPeriod}
                        className="w-[140px]"
                    />
                )}

                <EmptyBar customStyle={'h-[20px]'} />

                <div className="w-full">
                    {getEvaluationPeriodId?.data?.playerEvaluations?.length > 0 ? (
                        <CustomTable
                            columns={columns}
                            rows={customizedRow}
                            equalWidth={false}
                            minWidth={'min-w-[80px]'}
                        />
                    ) : (
                        <div className="text-center p-[30px]">목록이 없습니다.</div>
                    )}
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Rating;
