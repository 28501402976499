import React, { useEffect, useRef, useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
// import VideoPlayer from 'pages/player/measurement/analysis/videoPlayer';
// icons
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
import { ReactComponent as StopIcon } from 'assets/svgs/stop.svg';
import { ReactComponent as PlayIcon } from 'assets/svgs/play.svg';
// commons
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import LongSoccerBallLoading from 'components/loading/LongSoccerBallLoading';
import VideoLoading from 'components/loading/VideoLoading';
import VideoPlayer from 'pages/player/measurement/analysis/videoPlayer/DefaultVideo';
import MobileVideo from 'pages/player/measurement/analysis/videoPlayer/MobileVideo';

interface AnalyzeVideoType {
    dribbleOneId: string;
    cctvVideoId?: string;
    skeletonVideoId?: string;
    cctvVideoToken: string;
    skeletonVideoToken: string;
    isPlaying: boolean;
    setIsPlaying: (value: boolean) => void;
    progress: number;
    setProgress: (value: number) => void;
    totalDuration: number;
    getCctvVideoToken: any;
    getSkeletonVideoToken: any;
}

const AnalyzeVideo: React.FC<AnalyzeVideoType> = ({
    dribbleOneId,
    cctvVideoId,
    skeletonVideoId,
    cctvVideoToken,
    skeletonVideoToken,
    isPlaying,
    setIsPlaying,
    progress,
    setProgress,
    totalDuration,
    getCctvVideoToken,
    getSkeletonVideoToken,
}) => {
    const navigate = useNavigate();
    const cctvRef = useRef<HTMLVideoElement>(null);
    const skeletonRef = useRef<HTMLVideoElement>(null);
    const [cctvReady, setCctvReady] = useState(false);
    const [skeletonReady, setSkeletonReady] = useState(false);
    const [canPlay, setCanPlay] = useState(false);

    // 두 비디오가 모두 준비되면 canPlay를 true로 설정
    useEffect(() => {
        if (cctvReady && skeletonReady) {
            setCanPlay(true);
        }
    }, [cctvReady, skeletonReady]);

    // 비디오 progress 업데이트 및 totalDuration 체크
    useEffect(() => {
        if (!canPlay) return;

        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (!cctvVideo || !skeletonVideo) {
            console.warn('Video elements are not initialized.');
            return;
        }

        const handleTimeUpdate = () => {
            const currentTime = Math.min(cctvVideo.currentTime || 0, skeletonVideo.currentTime || 0);
            setProgress(Math.ceil(currentTime));

            // 비디오가 totalDuration에 도달하면 멈추고 초기화
            if (currentTime >= totalDuration) {
                setIsPlaying(false);
                cctvVideo.pause();
                skeletonVideo.pause();
                cctvVideo.currentTime = 0;
                skeletonVideo.currentTime = 0;
                setProgress(1); // progress를 1로 설정
                setTimeout(() => setIsPlaying(true), 100); // 짧은 지연 후 자동 재생
            }
        };

        cctvVideo.addEventListener('timeupdate', handleTimeUpdate);
        skeletonVideo.addEventListener('timeupdate', handleTimeUpdate);

        return () => {
            cctvVideo.removeEventListener('timeupdate', handleTimeUpdate);
            skeletonVideo.removeEventListener('timeupdate', handleTimeUpdate);
        };
    }, [canPlay, totalDuration, setProgress, setIsPlaying]);
    // 재생/일시정지 처리
    useEffect(() => {
        if (!canPlay) return;

        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (!cctvVideo || !skeletonVideo) return;

        const syncPlay = async () => {
            try {
                if (isPlaying) {
                    await Promise.all([cctvVideo.play(), skeletonVideo.play()]);
                } else {
                    cctvVideo.pause();
                    skeletonVideo.pause();
                }
            } catch (error) {
                console.error('Error playing videos:', error);
                setIsPlaying(false);
            }
        };

        syncPlay();
    }, [canPlay, isPlaying, setIsPlaying]);
    // 비디오 싱크 확인 및 조정 (새롭게 추가된 useEffect)
    useEffect(() => {
        if (!canPlay) return;

        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (!cctvVideo || !skeletonVideo) return;

        const syncVideos = () => {
            const timeDifference = Math.abs(cctvVideo.currentTime - skeletonVideo.currentTime);

            if (timeDifference > 0.5) {
                const minTime = Math.min(cctvVideo.currentTime, skeletonVideo.currentTime);
                cctvVideo.currentTime = minTime;
                skeletonVideo.currentTime = minTime;
            }
        };

        const interval = setInterval(syncVideos, 100);

        return () => clearInterval(interval);
    }, [canPlay]);
    // 비디오 토큰 만료 로직
    useEffect(() => {
        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (!cctvVideo || !skeletonVideo) return;
        if (!cctvVideoId || !skeletonVideoId) return;
        const handleVideoError = async () => {
            console.error('비디오 로드 오류 발생. 토큰을 갱신합니다.');
            try {
                if (cctvVideoId) getCctvVideoToken.refetch();
                if (skeletonVideoId) getSkeletonVideoToken.refetch();
                alert('비디오가 없거나, 재생시간이 만료되었습니다. 다시 갱신합니다.');
            } catch (error) {
                console.error('토큰 갱신 실패:', error);
                alert('비디오 로드 실패. 이전 페이지로 이동합니다.');
                navigate(-1); // 뒤로가기 처리
            }
        };

        cctvVideo.addEventListener('error', handleVideoError);
        skeletonVideo.addEventListener('error', handleVideoError);

        return () => {
            cctvVideo.removeEventListener('error', handleVideoError);
            skeletonVideo.removeEventListener('error', handleVideoError);
        };
    }, [getCctvVideoToken, getSkeletonVideoToken, navigate]);
    // ✅ dribbleOneId가 변경될 때 기존 상태 초기화
    useEffect(() => {
        if (!dribbleOneId) return;
        if (!cctvVideoId || !skeletonVideoId) return;

        console.log(`🔄 dribbleOneId 변경됨: ${dribbleOneId}`);
        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (cctvVideo && skeletonVideo) {
            // 비디오 정지 및 초기화
            cctvVideo.pause();
            skeletonVideo.pause();
            cctvVideo.currentTime = 0;
            skeletonVideo.currentTime = 0;

            // 상태 초기화
            setProgress(0);
            setIsPlaying(false);
            setCanPlay(false); // 비디오가 준비될 때까지 조작 비활성화
            setCctvReady(false);
            setSkeletonReady(false);
        }

        // ✅ 새로운 dribbleOneId가 들어오면 토큰 갱신 후 재생 가능하게 변경
        // if (cctvVideoId) getCctvVideoToken.refetch();
        // if (skeletonVideoId) getSkeletonVideoToken.refetch();
    }, [dribbleOneId]);

    const handleProgressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!canPlay) return;

        const time = Number(e.target.value);
        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (!cctvVideo || !skeletonVideo) {
            console.warn('Video elements are not initialized.');
            return;
        }

        const wasPlaying = isPlaying;
        if (wasPlaying) {
            setIsPlaying(false);
        }

        cctvVideo.currentTime = time;
        skeletonVideo.currentTime = time;
        setProgress(time);

        if (wasPlaying) {
            setIsPlaying(true);
        }
    };
    // Reset 함수
    const resetVideos = () => {
        const cctvVideo = cctvRef.current;
        const skeletonVideo = skeletonRef.current;

        if (cctvVideo && skeletonVideo) {
            // 비디오를 처음으로 돌림
            cctvVideo.currentTime = 0;
            skeletonVideo.currentTime = 0;

            // 상태 초기화
            setProgress(0);
            setIsPlaying(true);

            // 재생
            Promise.all([cctvVideo.play(), skeletonVideo.play()]).catch((error) => {
                console.error('Error while resetting and playing videos:', error);
            });
        }
    };

    return (
        <div className="flex flex-col gap-4">
            <div className="flex gap-[20px] relative">
                {(!cctvReady || !skeletonReady) && cctvVideoToken && skeletonVideoToken && (
                    <div className="absolute w-full pt-[100px] ">
                        <VideoLoading
                            isLoadingBar={true}
                            isFixed={false}
                        />
                    </div>
                )}
                <div className={`bg-[#000000] w-full rounded-[14px]  h-[400px] `}>
                    {cctvVideoToken && cctvVideoId && (
                        <VideoPlayer
                            ref={cctvRef}
                            token={cctvVideoToken}
                            videoId={cctvVideoId}
                            showProgressBar={false}
                            onReady={() => setCctvReady(true)}
                        />
                    )}
                </div>
                {/* {cctvVideoId && (
                    <VideoPlayer
                        ref={cctvRef}
                        token={cctvVideoToken}
                        videoId={`${cctvVideoId}`}
                        showProgressBar={false}
                        onReady={() => setCctvReady(true)}
                    />
                )} */}
                <div className={`bg-[#000000] w-full rounded-[14px]  h-[400px] `}>
                    {skeletonVideoToken && skeletonVideoId && (
                        <VideoPlayer
                            ref={skeletonRef}
                            token={skeletonVideoToken}
                            videoId={skeletonVideoId}
                            showProgressBar={false}
                            onReady={() => setSkeletonReady(true)}
                        />
                    )}
                </div>
                {/* <MobileVideo 
                dribbleOneId={dribbleOneId}
                /> */}
            </div>

            <div className="flex items-center gap-4 px-4">
                <div className="flex gap-[5px]">
                    <button
                        onClick={() => canPlay && setIsPlaying(!isPlaying)}
                        className={`w-[60px] h-[40px] flex justify-center items-center rounded-[8px] text-primary-white
                    ${canPlay ? 'bg-primary-purple' : 'bg-gray-400'}`}
                        disabled={!canPlay}
                    >
                        {isPlaying ? (
                            <StopIcon className="w-[20px] h-[20px]" />
                        ) : (
                            <PlayIcon className="w-[20px] h-[20px]" />
                        )}
                    </button>
                    <button
                        onClick={resetVideos}
                        className="w-[40px] h-[40px] bg-gray-400 rounded-[8px] flex justify-center items-center text-primary-white"
                    >
                        <ResetIcon className="w-[20px] h-[20px]" />
                    </button>
                </div>{' '}
                <input
                    type="range"
                    min="0"
                    max={totalDuration}
                    value={progress}
                    onChange={handleProgressChange}
                    className="flex-1"
                    disabled={!canPlay}
                />
                <span className="text-primary-purple w-[60px]">
                    {Math.ceil(progress)} / {Math.ceil(totalDuration)}
                </span>
            </div>
        </div>
    );
};

export default AnalyzeVideo;
