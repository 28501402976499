// player components
import ScoreCard from 'pages/player/report/personalReport/dribbleCard/ScoreCard';
import ProgessBarChart from 'pages/player/report/personalReport/dribbleCard/ProgessBarChart';
// icons
import { ReactComponent as LineChartIcon } from 'assets/svgs/lineChart.svg';
// commons
import EmptyBar from 'components/emptyBar';
import Lock from 'components/lock/Default';
const DribbleCard = ({
    isPrint,
    dribbleAvgScore,
    dribbleScoreDiff,
    dribbleOneAnalysis,
    dribbleTwoAnalysis,
    playerId,
    measuredAt,
}: any) => {
    return (
        <div className="relative p-[25px] rounded-[14px] border-2 border-secondary-dGrayMid">
            <div className="absolute top-[-32px] w-full">
                <div className="w-[426px] rounded-[80px] text-context-bold  bg-secondary-dGrayMid h-[64px] text-primary-white  py-[8px] justify-center text-center top-0 m-auto flex gap-[30px] items-center">
                    <span className="text-subtitle-large-bold ">드리블</span>
                    <div className="flex items-center gap-[8px] ">
                        <div className="flex items-center gap-[5px]">
                            <LineChartIcon />
                            <span className="text-context-regular">평균 점수</span>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <span className="text-subtitle-large-bold ">{dribbleAvgScore?.toFixed(2)}</span>
                            <span className="text-[#868686]">점</span>
                            <span
                                className={`text-context-bold ${
                                    dribbleScoreDiff >= 0 ? 'text-[#19DE93]' : 'text-[#B4B4B4]'
                                }`}
                            >
                                {dribbleScoreDiff > 0
                                    ? `+${dribbleScoreDiff}`
                                    : dribbleScoreDiff < 0
                                    ? `${dribbleScoreDiff}`
                                    : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyBar customStyle="h-[15px]" />
            <ScoreCard
                isPrint={isPrint}
                dribbleOneAnalysis={dribbleOneAnalysis}
                playerId={playerId}
                measuredAt={measuredAt}
            />
            <EmptyBar customStyle="h-[35px]" />
            <div className="relative">
                <ProgessBarChart dribbleTwoAnalysis={dribbleTwoAnalysis} />
                {!dribbleTwoAnalysis && (
                    <Lock
                        content="측정이 필요합니다."
                        customStyle="rounded-[10px] text-primary-purple"
                    />
                )}
            </div>
        </div>
    );
};

export default DribbleCard;
