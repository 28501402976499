// hooks
import { useNavigate, useSearchParams } from 'react-router-dom';
// utils
import { skillStateMatcher } from 'utils/matchers/measurementMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// assets
import profile from 'assets/logos/symbol/profile.webp';

interface MeasurementDataSummaryType {
    rankingInfo: {
        overallRank: number | null;
        percentile: number | null;
        totalTeams: number | null;
    };
    lastCalculatedAt: string;
    topPlayers: {
        overallTop: any;
        dribbleTop: any;
        firstTouchTop: any;
        passTop: any;
        shootingTop: any;
    };
    skillAnalysis: {
        bestSkill: string | null;
        worstSkill: string | null;
    };
}

const MeasurementDataSummary = ({
    rankingInfo,
    lastCalculatedAt,
    topPlayers,
    skillAnalysis,
}: MeasurementDataSummaryType) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const paramYear = searchParams.get('year');
    const paramAgeGroup = searchParams.get('ageGroup') || '';

    const topPlayerLabels = [
        { label: '드리블 1위', key: 'dribbleTop' },
        { label: '패스 1위', key: 'passTop' },
        { label: '슈팅 1위', key: 'shootingTop' },
        { label: '퍼스트터치 1위', key: 'firstTouchTop' },
    ] as const;
    return (
        <div className="w-full bg-white rounded-[10px] p-[30px] flex gap-[20px]">
            <div className="w-[313px] flex flex-col gap-[10px]">
                <div className="flex gap-[10px] h-[40px] text-secondary-dGrayMid items-center">
                    <div className="text-subtitle-medium-bold">순위</div>
                    <div className="text-context-regular text-[#868686]">최근</div>

                    <div className="text-context-bold">{formatDateTimeMatcher(lastCalculatedAt) || ''}</div>
                </div>

                <div className="m-auto  w-[313px] flex flex-col gap-[20px] justify-center ">
                    <div className="flex gap-[30px] justify-center">
                        <div className="flex h-[114px] flex-col items-center">
                            <div className="bg-primary-purple text-primary-white px-[12px] py-[4px] w-[85px] text-center rounded-[80px] m-auto">
                                전국상위
                            </div>
                            <div className="text-[50px] font-bold flex items-center gap-[5px] mt-[-15px]">
                                {rankingInfo?.percentile?.toFixed(2) || 0}
                                <span className="text-[22px] text-[#7c7c7c7]">%</span>
                            </div>
                        </div>
                        <div className="flex h-[114px] flex-col items-center">
                            <div className="bg-primary-purple text-primary-white px-[12px] py-[4px] w-[85px] text-center rounded-[50px] m-auto">
                                랭킹
                            </div>
                            <div className="text-[50px] font-bold flex items-center gap-[5px] mt-[-15px]">
                                {rankingInfo?.overallRank || 0}
                                <span className="text-[22px] text-[#7c7c7c7]">위</span>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#f8f8f8] rounded-[14px] flex flex-col gap-[12px] p-[18px] items-center justify-center">
                        <div className="flex flex-col gap-[8px]">
                            <span className="text-context-regular">가장 우수한 기술은</span>
                            <span className="text-[14px] font-bold bg-primary-green text-primary-black w-fit rounded-[80px] px-[12px] py-[4px] m-auto">
                                {skillStateMatcher(skillAnalysis?.bestSkill || '측정필요')}
                            </span>
                        </div>
                        <div className="flex flex-col gap-[8px]">
                            <span>개선이 필요한 기술은</span>
                            <span className="text-[14px] font-bold bg-status-error text-primary-white w-fit rounded-[80px] px-[12px] py-[4px] m-auto">
                                {skillStateMatcher(skillAnalysis?.worstSkill || '측정필요')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-[1007px] flex flex-col gap-[10px]">
                <div className="text-subtitle-medium-bold ">기술별 1위</div>

                <div className=" h-[300px]  flex gap-[20px] ">
                    {topPlayers ? (
                        topPlayerLabels?.map((el) => {
                            const playerData = topPlayers[el?.key] || {};
                            return (
                                <button
                                    onClick={() =>
                                        navigate(
                                            `/staff/measurement/report/dashboard/${playerData?.playerId}?year=${paramYear}&ageGroup=${paramAgeGroup}&year=${paramYear}`
                                        )
                                    }
                                    key={el?.key}
                                    className="p-[34px] flex flex-col gap-[20px] justify-center items-center w-[242.5px] h-[300px]
                        bg-[#f8f8f8f8] rounded-[14px] hover:bg-white hover:border-[2px] hover:border-primary-purple
                        "
                                >
                                    <img
                                        src={playerData?.profileImageUrl ? playerData?.profileImageUrl : profile}
                                        alt="profile"
                                        className="w-[100px] h-[100px] object-cover rounded-[8px] "
                                    />
                                    <div className="flex flex-col gap-[8px]">
                                        <div className="text-title-small-bold">{`${
                                            playerData?.uniformNumber ? `${playerData?.uniformNumber} . ` : ''
                                        } ${playerData?.name ? playerData?.name : '미정'}`}</div>
                                        <div className="text-[14px] font-bold px-[12px] h-[29px] bg-primary-purple rounded-[80px] justify-center items-end flex text-center text-primary-white m-auto py-[4px]">
                                            {el?.label}
                                        </div>
                                    </div>
                                </button>
                            );
                        })
                    ) : (
                        <div className="flex items-center justify-center w-full h-full text-secondary-lGrayDark">
                            측정 기록이 없습니다.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MeasurementDataSummary;
