// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const SoccerDiary = ({ editableData, setEditableData }: SoccerDiaryPropsType) => {
    const { role } = useSelector((state: RootState) => state.userInfo);

    const handleInfo = (updateData: any) => {
        setEditableData((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    return (
        <div className="flex border-b border-secondary-lGrayMid">
            <div className="w-[150px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                축구 일기
            </div>
            <div className="p-[24px] ">
                <div className="mb-[10px] flex gap-[10px] items-center">
                    <span className=" text-secondary-dGrayMid text-subtitle-medium-bold">오늘의 나에게 한 마디</span>
                    <span className="className text-label-regular text-primary-purple">
                        * 이 항목은 작성자만 확인 가능합니다. 감독님&코치님은 내용을 볼 수 없습니다.
                    </span>
                </div>
                <textarea
                    readOnly={role !== 'PLAYER'}
                    className="p-[12px] rounded-[8px] w-[1126px] h-[210px] resize-none bg-secondary-mGrayLight text-secondary-dGrayDark placeholder:text-secondary-lGrayDark"
                    onChange={(e) => handleInfo({ footballDiary: e.target.value })}
                    value={editableData?.footballDiary || ''}
                    placeholder={`인간이란, 남에게는 따뜻하게 위로를 잘 하면서, 가장 소중한 자기 자신에게는 남에게도 할 수 없는 모진 비난을 스스로하곤 합니다. 친한 친구나 후배가 지금 내 감정을 똑같이 느끼고 있다면 나는 뭐라고 해줄 건가요? 응원과 위로, 조언, 비판 뭐든 좋습니다. 오늘의 나에게 진심을 담아 한 마디 해주세요.`}
                />
            </div>
        </div>
    );
};

export default SoccerDiary;

interface SoccerDiaryType {
    footballDiary?: string | null;
}

interface SoccerDiaryPropsType {
    editableData: SoccerDiaryType;
    setEditableData: (data: any) => void;
}
