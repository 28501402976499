// hooks
import { useState, useMemo, useEffect } from 'react';
import { useApiMutation } from 'hooks/useDynamicApi';
import { debounce } from 'lodash';
// utils
import { ENDPOINT_TEAM_ATTENDANCE } from 'utils/consts/apiEndpoint';
// commons
import { showToast } from 'components/toast/Default';
// icons
import { ReactComponent as PlusBox } from 'assets/svgs/plusBox.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const AbsencePlayerEl = ({
    attendanceId,
    playerId,
    playerName,
    uniformNumber,
    attendanceStatus,
    absenceReason,
    trainingAttitudeScore,
    technicalSkillScore,
}: AbsencePlayerType) => {
    const { staffRole, role } = useSelector((state: RootState) => state.userInfo);

    const [absenceReasonEdit, setAbsenceReasonEdit] = useState(absenceReason);
    // useApiMutation
    const patchPlayerAttendance = useApiMutation(ENDPOINT_TEAM_ATTENDANCE.status(`${attendanceId}`), 'patch', {
        onSuccess: (data) => {},
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '선수 출석상태 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateAttendance = () => {
        if (!patchPlayerAttendance?.isPending) {
            showToast('선수 출석 상태를 수정하는 중입니다.', 'info');
            patchPlayerAttendance.mutate({
                attendanceStatus: 'PARTICIPANT',
            });
        }
    };
    // 자동저장
    const patchAbsenceReason = useApiMutation(ENDPOINT_TEAM_ATTENDANCE.absenceReason(`${attendanceId}`), 'patch', {
        onSuccess: (data) => {},
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '불참사유 입력에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const submitReport = () => {
        if (!patchAbsenceReason.isPending && staffRole !== 'OFFICE') {
            patchAbsenceReason.mutate({
                absenceReason: absenceReasonEdit,
            });
        }
    };

    const debouncedSubmitReport = useMemo(
        () =>
            debounce(() => {
                submitReport();
            }, 1000), // 1초 뒤에 실행
        [patchAbsenceReason] // 의존성에서 필요 요소만 포함
    );
    useEffect(() => {
        if (absenceReasonEdit !== null && absenceReasonEdit !== absenceReason) {
            debouncedSubmitReport();
        }
        return () => {
            debouncedSubmitReport.cancel();
        };
    }, [absenceReasonEdit]);
    return (
        <div className="flex gap-[10px] items-center h-[44px]">
            <div className="inputDefaultStyle p-[12px] w-[120px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                {`${uniformNumber}번 ${playerName}`}
            </div>
            <input
                readOnly={staffRole === 'OFFICE'}
                type="text"
                value={absenceReasonEdit || ''}
                placeholder="불참 사유를 입력해주세요"
                onChange={(e) => {
                    setAbsenceReasonEdit(e.target.value);
                }}
                className="inputDefaultStyle p-[12px] w-[394px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center"
            />
            {staffRole !== 'OFFICE' && (
                <button
                    onClick={updateAttendance}
                    type="button"
                >
                    {<PlusBox />}
                </button>
            )}
        </div>
    );
};

export default AbsencePlayerEl;

interface AbsencePlayerType {
    attendanceId: number;
    playerId: number;
    playerName: string;
    uniformNumber: number;
    attendanceStatus: 'PARTICIPANT' | 'ABSENT';
    absenceReason: string | null;
    trainingAttitudeScore: number;
    technicalSkillScore: number;
}
