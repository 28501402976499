export const seasonYearOptions = [
    // 이건 조회가 없나요?
    { value: '2025', label: '2025' },
    { value: '2024', label: '2024' },
    { value: '2023', label: '2023' },
    { value: '2022', label: '2022' },
    { value: '2021', label: '2021' },
    { value: '2020', label: '2020' },
];

export const ageGroupOptions = [
    { value: 'U10', label: 'U10' },
    { value: 'U12', label: 'U12' },
    { value: 'U15', label: 'U15' },
    { value: 'U18', label: 'U18' },
    { value: 'UNIVERSITY', label: '대학' },
    { value: 'ADULT', label: '성인' },
    { value: 'PRO', label: '프로' },
    { value: 'NONE', label: 'NONE' },
];

export const playerProfileSortType = [
    { value: 'PLAYER_UNIFORM_NUMBER_ASC', label: '유니폼 번호 오름차순' },
    { value: 'PLAYER_UNIFORM_NUMBER_DESC', label: '유니폼 번호 내림차순' },
    { value: 'PLAYER_NAME_ASC', label: '선수 이름 오름차순' },
    { value: 'PLAYER_NAME_DESC', label: '선수 이름 내림차순' },
    { value: 'PLAYER_AGE_ASC', label: '선수 나이 오름차순' },
    { value: 'PLAYER_AGE_DESC', label: '선수 나이 내림차순' },
    { value: 'PLAYER_RATING_ASC', label: '선수 평가 점수 오름차순' },
    { value: 'PLAYER_RATING_DESC', label: '선수 평가 점수 내림차순' },
    { value: 'PLAYER_APPEARANCES_ASC', label: '선수 출전 횟수 오름차순' },
    { value: 'PLAYER_APPEARANCES_DESC', label: '선수 출전 횟수 내림차순' },
    { value: 'PLAYER_GOALS_ASC', label: '선수 득점 수 오름차순' },
    { value: 'PLAYER_GOALS_DESC', label: '선수 득점 수 내림차순' },
    { value: 'PLAYER_ASSISTS_ASC', label: '선수 어시스트 수 오름차순' },
    { value: 'PLAYER_ASSISTS_DESC', label: '선수 어시스트 수 내림차순' },
];
