// hooks
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
// common
import StatusLabel from 'components/label/Default';
import Dropdown from 'components/dropdown/Default';
// utils
import { seasonYearOptions } from 'utils/consts/controlInfo';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM, ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
import { dropDownFormChanger, dropDownFormChangerType } from 'utils/formChanger';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

interface ControlSubHeaderType {
    wins: number;
    draws: number;
    losses: number;
    winRate: number;
    winRateChange: number;
}
const ControlSubHeader = ({ seasonStats }: { seasonStats: ControlSubHeaderType | null }) => {
    const handleParamNavigate = useParamNavigate();
    const { wins = 0, draws = 0, losses = 0, winRate = 0.0, winRateChange = 0.0 } = seasonStats || {};
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();
    const today = new Date();
    const toDayYear = String(today.getFullYear()); // 현재 연도 출력
    const [teamName, setTeamName] = useState('TEAM');
    const [seasonYear, setSeasonYear] = useState(toDayYear);
    const [ageGroup, setAgeGroup] = useState('');
    const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>([]);
    useEffect(() => {
        if (ageGroup && seasonYear)
            handleParamNavigate({
                year: seasonYear,
                ageGroup: ageGroup,
            });
    }, [ageGroup, seasonYear]);
    // react useApiQuery
    const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
        ['ageGroup'],
        ENDPOINT_TEAM.staff.team.ageGroups,
        'get'
    );
    const getSeasonStat = useApiQuery(
        ['getSeasonStat', seasonYear],
        ENDPOINT_REPORT.seasonStat(seasonYear, {
            ageGroup: staffRole === 'OFFICE' ? ageGroup : '',
        }),
        'get',
        { enabled: !!staffRole }
    );

    useEffect(() => {
        if (isSuccess && data) {
            const { teamName, ageGroups } = data;

            setTeamName(teamName);
            setAgeGroup(ageGroups[0]);
            const newAgeGroupOpts = dropDownFormChanger(ageGroups);
            setAgeGroupsOpts(newAgeGroupOpts);
        }
    }, [data]);

    const handleHomeNavigate = () => {
        if (ageGroup && seasonYear)
            handleParamNavigate(
                {
                    ageGroup: ageGroup,
                    year: seasonYear,
                },
                false,
                `/staff/control/lineUp`
            );
    };

    return (
        <div className="h-[80px] ">
            <div className="flex items-center justify-between">
                {staffRole !== 'OFFICE' ? (
                    <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                        <button onClick={handleHomeNavigate}>
                            <HomeIcon className="text-white mr-[6px]" />
                        </button>
                        <div className="flex gap-[10px]">
                            <div className="text-primary-white text-[26px] font-bold">선수단</div>
                            <div className="text-[26px] text-primary-green font-bold">{ageGroup}</div>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                        <button onClick={handleHomeNavigate}>
                            <HomeIcon className="text-white mr-[6px]" />
                        </button>
                        <div className="flex items-center gap-[6px]">
                            <Dropdown
                                options={ageGroupOpts}
                                value={ageGroup}
                                placeholder="연령"
                                onChange={setAgeGroup}
                                className=" text-label-regular border rounded-[8px] border-primary-white"
                                innerClassName="px-[6px] py-[8px]"
                            />
                            <div className="text-[26px] text-white font-bold">선수단</div>
                        </div>
                    </div>
                )}
                <div className="flex  gap-[80px]">
                    <div className="min-w-[311px] flex gap-[18px]  h-[50px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <Dropdown
                            defaultValue={toDayYear}
                            options={seasonYearOptions}
                            value={seasonYear}
                            onChange={setSeasonYear}
                            className={'w-[95px] border rounded-[8px] border-primary-white'}
                        />
                        <div className="flex gap-[15px] items-center">
                            <div
                                className="w-[88px] font-semibold leading-[24px] text-[20px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {`${seasonYear}시즌 승률`}
                            </div>
                            <div className="flex items-center gap-[12px]">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-white font-bold">
                                        {winRate.toFixed(1)}
                                    </span>
                                    <span className="text-[22px]">{'%'}</span>
                                </div>
                                <div>
                                    <StatusLabel
                                        status={'up'}
                                        customColor="bg-primary-green text-primary-black font-bold  text-[16px]"
                                    >
                                        {winRateChange.toFixed(1)}%
                                    </StatusLabel>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-[311px] flex gap-[18px]  h-[48px] text-[#868686]">
                        <div className="w-[5px] bg-primary-green rounded-[5px]"></div>
                        <div className="flex gap-[15px] items-center">
                            <div
                                className="w-[72px] font-bold leading-[24px] text-[20px]"
                                style={{ wordBreak: 'keep-all', overflowWrap: 'break-word' }} //한국어 띄어쓰기 단위로 줄바꿈
                            >
                                {'총 시즌 정보'}
                            </div>
                            <div className="flex items-center gap-[25px]">
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-green font-bold">
                                        {wins}
                                        {/* getSeasonStat?.data?. */}
                                    </span>
                                    <span className="text-[22px]">{'승'}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-primary-white font-bold">
                                        {draws}
                                        {/* getSeasonStat?.data?. */}
                                    </span>
                                    <span className="text-[22px]">{'무'}</span>
                                </div>
                                <div className="flex items-center gap-[5px]">
                                    <span className="text-[50px] text-status-error font-bold">
                                        {losses}
                                        {/* getSeasonStat?.data?. */}
                                    </span>
                                    <span className="text-[22px]">{'패'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ControlSubHeader;
