import React, { useImperativeHandle, useState, forwardRef, useEffect, useRef } from 'react';
import * as fabric from 'fabric';
import { setFabricDefaults } from 'pages/staff/control/training/components/fabricCanvas/components/style/Default';
import { usePressKey } from 'pages/staff/control/training/components/fabricCanvas/hooks/usePressKeyTools';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';
import { useShapeStyleTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useShapeStyleTools';
// hooks
import { useImageTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useImageTools';
import { useTextTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useTextTools';
import { useDrawTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useDrawTools';
import { useArrowTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useArrowTools';
import { useLineTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useLineTools';
import { useHistoryTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useHistoryTools'; // 경로는 맞게 설정!
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import ConfirmModal from 'components/modals/ConfirmModal';
// assets
import {
    ArrowCurve,
    ArrowStraight,
    ArrowTip,
    CircleBig,
    CircleMideum,
    CircleSmall,
    Eraser,
    LineCurve,
    ArrowDot,
    LineOneCurve,
    LineStaright,
    Move,
    Text,
    // ball_black,
} from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import { ReactComponent as CircleBackArrowIcon } from 'assets/svgs/circleBackArrow.svg';
import { ReactComponent as CircleNextArrowIcon } from 'assets/svgs/circleNextArrow.svg';
import { ReactComponent as CircleResetArrowIcon } from 'assets/svgs/circleResetArrow.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
import stadium from 'assets/imgs/trainingLogEl/stadium.png';
// components
import { showToast } from 'components/toast/Default';
import ItemButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/ItemButton';
import LineButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/LineButton';
import ItemSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ItemSelector';
import ColorSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ColorSelector';
import DiagramSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/DiagramSelector';
// utils
import { ENDPOINT_TEAM_SESSION_TEMPLATES, ENDPOINT_TEAM_TRAINING_SESSION } from 'utils/consts/apiEndpoint';
// setFabricDefaults();

const TrainingCanvas = ({
    focusedSessionState,
    focusedSessionData,
    openDeleteModal,
    editSessionName,
    editSessionPoint,
    setUnSaved,
    saveCommand,
    setSaveCommand,
}: TrainingCanvasProps) => {
    const canvasDomRef = useRef<HTMLCanvasElement | null>(null);
    // 모든 객체의 기본 테두리 색상을 전역으로 설정
    const [isFirstRender, setIsFirstRender] = useState(true);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const [savedJSON, setSavedJson] = useState<string>('');
    const [clickState, setClickState] = useState('move');
    const [colorState, setColorState] = useState('#000000');
    const [lineWidthState, setLineWidthState] = useState(2);
    const [deleteModal, setDeleteModal] = useState(false);
    const { addImageFromUrl } = useImageTools(fabricRef, setUnSaved);
    const { addText } = useTextTools(fabricRef, setUnSaved);
    const { enableDrawingMode, enableEraserMode, disableDrawingMode } = useDrawTools(fabricRef, setUnSaved);
    const { addArrow } = useArrowTools(fabricRef, setUnSaved);
    const { addLine } = useLineTools(fabricRef, setUnSaved);
    const { undo, redo, reset, saveHistory, removeAllStack } = useHistoryTools(fabricRef);
    const { updateActiveObjectLineWidth } = useShapeStyleTools(fabricRef, setUnSaved);
    usePressKey(fabricRef);

    const patchTemplateId = useApiMutation(
        ENDPOINT_TEAM_TRAINING_SESSION.sessionId(`${focusedSessionData?.id}`),
        'patch',
        {
            onSuccess: (data) => {
                showToast('팀 훈련 세션이 저장되었습니다.', 'success');
                setSaveCommand(false);
            },
            onError: (error) => {
                // 에러 메시지 추출
                const errorMessage = error?.data?.detailedMessage || error || '팀 훈련 세션이 저장되었습니다.';
                showToast(`${errorMessage}`, 'error');
                setSaveCommand(false);
            },
        }
    );
    const updateTemplate = (html: string) => {
        if (!editSessionName) {
            showToast('팀 훈련 세션이름을 작성해주세요.', 'error');
        }
        if (!editSessionPoint) {
            showToast('팀 훈련 세션을 포인트를 작성해주세요.', 'error');
        }
        if (!patchTemplateId.isPending) {
            showToast('팀 훈련 세션을 수정중입니다.', 'info');
            patchTemplateId.mutate({
                name: editSessionName,
                tacticalBoardJson: html,
                sessionPoint: editSessionPoint,
            });
        }
    };

    // INIT SETTING
    useEffect(() => {
        if (!canvasDomRef.current) return;
        if (fabricRef.current) {
            fabricRef.current.dispose();
        }

        const canvas = new fabric.Canvas(canvasDomRef.current, {
            width: 1142,
            height: 808.34,
        });

        fabricRef.current = canvas;

        fabric.Image.fromURL(stadium).then((img) => {
            img.scaleToWidth(canvas.width ?? 1142);
            img.scaleToHeight(canvas.height ?? 808.34);
            img.selectable = false;
            canvas.backgroundImage = img;
            canvas.requestRenderAll();
        });

        return () => {
            canvas.dispose();
        };
    }, []);
    useEffect(() => {
        setIsFirstRender(true);
        if (!fabricRef.current || !focusedSessionData?.tacticalBoardJson) return;

        const tacticalJson = focusedSessionData.tacticalBoardJson;

        try {
            const parsed = JSON.parse(tacticalJson);

            // 🎯 objects 배열이 비어있으면 배경만 세팅하고 초기화 상태 저장
            if (!parsed.objects || parsed.objects.length === 0) {
                const canvas = fabricRef.current;
                canvas.clear();
                fabric.Image.fromURL(stadium).then((bg) => {
                    bg.scaleToWidth(canvas.width ?? 1142);
                    bg.scaleToHeight(canvas.height ?? 808.34);
                    bg.selectable = false;

                    canvas.backgroundImage = bg;
                    canvas.renderAll();
                });

                return;
            }

            // 🎯 객체가 있으면 JSON 전체 로드
            importJSON(tacticalJson);
        } catch (e) {
            console.error('JSON 파싱 에러', e);
        }
    }, [focusedSessionData?.tacticalBoardJson]);

    const exportJSON = () => {
        if (!focusedSessionData?.id) {
            showToast('저장을 원하시는 세션을 선택해주세요', 'error');
            return;
        }
        if (!fabricRef.current) return;

        const jsonObj = fabricRef.current.toJSON();
        // delete jsonObj.backgroundImage; // ✅ 배경 이미지 제거
        const json = JSON.stringify(jsonObj, null, 2);

        updateTemplate(json);
        setSavedJson(json);
        setUnSaved(false); // ✅ 저장 후 변경사항 없음으로 간주
    };

    const importJSON = async (stringifiedData?: string) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        if (!stringifiedData && !savedJSON) return;

        try {
            const parsed = JSON.parse(stringifiedData || savedJSON);
            if (!parsed.objects || !Array.isArray(parsed.objects)) {
                alert('올바른 JSON 형식이 아닙니다.');
                return;
            }
            canvas.clear();
            parsed.objects = parsed.objects.map((obj: any) => {
                if (obj.type === 'path' && Array.isArray(obj.path)) {
                    if (obj.path.length > 300) {
                        obj.path = obj.path.filter((_: any, idx: number) => idx % 3 === 0);
                    }
                }
                return obj;
            });

            await new Promise<void>((resolve) => {
                canvas.loadFromJSON(parsed, () => {
                    const waitUntilLoaded = () => {
                        const objects = canvas.getObjects();
                        if (objects.length > 0) {
                            objects.forEach((obj) => {
                                obj.selectable = true;
                                obj.evented = true;
                                obj.hasControls = true;
                                obj.hasBorders = true;
                                applyFabricDefaultsToObject(obj);
                            });
                            canvas.requestRenderAll();
                            resolve();
                        } else {
                            setTimeout(waitUntilLoaded, 100);
                        }
                    };
                    waitUntilLoaded();
                });
            });

            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;
            canvas.renderAll();
            setUnSaved(false); // ✅ 저장 후 변경사항 없음으로 간주

            if (stringifiedData || savedJSON) {
                setSavedJson(stringifiedData || savedJSON);
                removeAllStack();
            } else reset(); // ← 기본 배경 상태를 저장 (isRestoring 사용됨)
        } catch (error) {
            alert('JSON 파싱에 실패했습니다.');
            console.error('[importJSON 에러]', error);
        }
    };
    useEffect(() => {
        if (savedJSON) {
            // const parsed = JSON.parse(savedJSON);
            setTimeout(() => {
                saveHistory(savedJSON);
            }, 100);
            setUnSaved(false); // ✅ 저장 후 변경사항 없음으로 간주
        }
    }, [savedJSON]);
    useEffect(() => {
        if (clickState !== 'draw' && clickState !== 'eraser') {
            disableDrawingMode();
        }
    }, [clickState]);

    useEffect(() => {
        if (clickState === 'draw') {
            enableDrawingMode(colorState, lineWidthState); // ✅ 선 다시 적용
        }
        if (clickState === 'eraser') {
            enableEraserMode(lineWidthState); // ✅ 지우개도 다시 적용
        }
        if (
            clickState === 'arrow_curve' ||
            clickState === 'arrow_dot' ||
            clickState === 'arrow_straight' ||
            clickState === 'arrow_tip'
        ) {
            const arrowType = (clickState?.slice(6) || 'straight') as 'curve' | 'straight' | 'dot' | 'tip';
        }
    }, [lineWidthState]);
    // 감지하기
    useEffect(() => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        const handleUserChange = () => {
            if (!focusedSessionData?.id && !isFirstRender) {
                showToast('훈련 일지 세션을 추가해주세요', 'warning');
                return;
            }
        };

        canvas.on('object:added', handleUserChange);
        canvas.on('object:modified', handleUserChange);
        canvas.on('object:removed', handleUserChange);
        canvas.on('path:created', handleUserChange);

        return () => {
            canvas.off('object:added', handleUserChange);
            canvas.off('object:modified', handleUserChange);
            canvas.off('object:removed', handleUserChange);
            canvas.off('path:created', handleUserChange);
        };
    }, [fabricRef.current]);

    useEffect(() => {
        if (saveCommand) {
            exportJSON();
        }
    }, [saveCommand]);

    return (
        <div className="text-center mt-[18px] border-2 relative">
            <ConfirmModal
                isShow={deleteModal}
                setIsShow={() => setDeleteModal(!deleteModal)}
                title={
                    <div>
                        해당 세션 초기화시, <span className="font-bold text-status-error">복구할 수 없습니다.</span>
                    </div>
                }
                activeFunc={importJSON}
                deleteBtnText={'초기화'}
            />
            <div className="flex items-center justify-end gap-[8px] absolute top-[-70px] right-0">
                <button
                    onClick={undo}
                    className="w-[40px] hover:border hover:rounded-[4px] h-[40px] flex justify-center items-center"
                >
                    <CircleBackArrowIcon />
                </button>
                <button
                    onClick={redo}
                    className="w-[40px] h-[40px] hover:border hover:rounded-[4px] flex justify-center items-center"
                >
                    <CircleNextArrowIcon />
                </button>
                <button
                    onClick={() => setDeleteModal(true)}
                    className="w-[40px] h-[40px] hover:border hover:rounded-[4px] flex justify-center items-center"
                >
                    <CircleResetArrowIcon />
                </button>
                <button
                    onClick={() => {
                        if (focusedSessionState) openDeleteModal();
                    }}
                    className="w-[40px] h-[40px] text-status-error flex justify-center items-center"
                >
                    <DeleteIcon />
                </button>

                <button
                    onClick={() => exportJSON()}
                    className="bg-primary-purple w-[80px] h-[44px] rounded-[8px] p-[12px] text-white text-context-bold"
                >
                    저장하기
                </button>
            </div>

            <div className="relative  w-[1142px] h-[808.34px]">
                <canvas ref={canvasDomRef} />
                <div className="absolute bottom-0 flex gap-[10px] justify-center items-center  text-[#ffffff] bg-gradient-to-b from-[#939393] to-[#1C1C1C] border border-[#ffffff] rounded-t-[30px] m-auto h-[94px] w-[1142px]">
                    <ItemButton
                        state="move"
                        isActive={clickState === 'move'}
                        icon={<Move className=" w-[26px] h-[36px]" />}
                        onClick={() => setClickState('move')}
                    />

                    <ColorSelector
                        onClick={() => {
                            setClickState('draw');
                            enableDrawingMode(colorState, lineWidthState);
                        }}
                        state="draw"
                        isActive={clickState === 'draw'}
                        colorState={colorState}
                        lineWidthState={lineWidthState}
                        setColorState={setColorState}
                        enableDrawingMode={enableDrawingMode}
                    />
                    <ItemButton
                        state="eraser"
                        isActive={clickState === 'eraser'}
                        icon={<Eraser className=" w-[25px] h-[26px]" />}
                        onClick={() => {
                            setClickState('eraser');
                            enableEraserMode();
                        }}
                    />
                    <div className="grid grid-cols-2 grid-rows-2 gap-[5px]">
                        <LineButton
                            state="line_staright"
                            isActive={clickState === 'line_staright'}
                            icon={<LineStaright className="" />}
                            onClick={() => {
                                setClickState('line_staright');
                                addLine('straight', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="line_curve"
                            isActive={clickState === 'line_curve'}
                            icon={<LineCurve className="" />}
                            onClick={() => {
                                setClickState('line_curve');
                                addLine('curve', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="line_oneCurve"
                            isActive={clickState === 'line_oneCurve'}
                            icon={<LineOneCurve className="" />}
                            onClick={() => {
                                setClickState('line_oneCurve');
                                addLine('oneCurve', colorState, lineWidthState);
                            }}
                        />
                        <DiagramSelector
                            state={'diagram'}
                            isActive={clickState === 'diagram'}
                            onClick={() => {
                                setClickState('diagram');
                            }}
                            addLine={addLine}
                            // colorState={colorState}
                            lineWidthState={lineWidthState}
                        />
                    </div>
                    <div className="flex flex-col gap-[5px] ">
                        <button
                            onClick={() => {
                                setLineWidthState(2);
                                updateActiveObjectLineWidth(2);
                            }}
                            className={`${
                                lineWidthState === 2 && 'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleSmall />
                        </button>
                        <button
                            onClick={() => {
                                setLineWidthState(5);
                                updateActiveObjectLineWidth(5);
                            }}
                            className={`${
                                lineWidthState === 5 && 'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleMideum />
                        </button>
                        <button
                            onClick={() => {
                                setLineWidthState(8);
                                updateActiveObjectLineWidth(8);
                            }}
                            className={`${
                                lineWidthState === 8 && 'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleBig />
                        </button>
                    </div>
                    <div className="grid grid-cols-2 grid-rows-2 gap-[5px] ">
                        <LineButton
                            state="arrow_curve"
                            isActive={clickState === 'arrow_curve'}
                            icon={<ArrowCurve />} // ← UI용
                            onClick={() => {
                                setClickState('arrow_curve');
                                addArrow('curve', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_dot"
                            isActive={clickState === 'arrow_dot'}
                            icon={<ArrowDot className="" />}
                            onClick={() => {
                                setClickState('arrow_dot');
                                addArrow('dot', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_straight"
                            isActive={clickState === 'arrow_straight'}
                            icon={<ArrowStraight className="" />}
                            onClick={() => {
                                setClickState('arrow_straight');
                                addArrow('straight', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_tip"
                            isActive={clickState === 'arrow_tip'}
                            icon={<ArrowTip className="" />}
                            onClick={() => {
                                setClickState('arrow_tip');
                                addArrow('tip', colorState, lineWidthState);
                            }}
                        />
                    </div>

                    <ItemSelector
                        state={'item'}
                        isActive={clickState === 'item'}
                        label={'Item'}
                        onClick={() => setClickState('item')}
                        addImageFromUrl={addImageFromUrl}
                    />

                    <ItemButton
                        state="text"
                        isActive={clickState === 'text'}
                        icon={<Text className="text-white w-[20px] h-[20px]" />}
                        label="Text"
                        onClick={() => {
                            setClickState('text');
                            addText(colorState); // 텍스트 추가 실행
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TrainingCanvas;

interface TrainingCanvasProps {
    focusedSessionState: number | null;
    focusedSessionData: any;
    openDeleteModal: () => void;
    setUnSaved: (state: boolean) => void;
    saveCommand: boolean;
    setSaveCommand: (command: boolean) => void;
    editSessionName?: string;
    editSessionPoint?: string;
}
