// hooks
import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
interface ControlSubHeaderType {
    wins: number;
    draws: number;
    losses: number;
    winRate: number;
    winRateChange: number;
}
const ControlSubHeader = () => {
    const [searchParams] = useSearchParams();
    const handleParamNavigate = useParamNavigate();
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const navigate = useNavigate();
    const control = searchParams.get('control') || '';

    const handleHomeNavigate = (param: string) => {
        handleParamNavigate(
            {
                control: param,
            },
            false,
            `/admin/control`
        );
    };

    return (
        <div className="wrapper">
            <div className="flex items-center justify-between">
                <div className="flex items-start justify-center h-[79px] flex-col gap-[15px]">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={() => handleHomeNavigate('player')}>
                            <HomeIcon className="text-white mr-[6px]" />
                        </button>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <div className="text-white text-label-regular">
                            {control === 'player'
                                ? '선수관리'
                                : control === 'admin'
                                ? '어드민 관리'
                                : control === 'team'
                                ? '팀관리'
                                : '선수관리'}
                        </div>
                    </div>
                    <div className="flex items-center gap-[6px]">
                        <div className="text-[26px] text-white font-bold">
                            {control === 'player'
                                ? '선수관리'
                                : control === 'admin'
                                ? '어드민 관리'
                                : control === 'team'
                                ? '팀관리'
                                : '선수관리'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ControlSubHeader;
