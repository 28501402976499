// hooks
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// common
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';
import { dropDownFormChanger, dropDownFormChangerType } from 'utils/formChanger';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const TrainingDiarySubHeader = ({
    navigateTitle = '관리',
    title = '훈련 상세',
    handleNavigate,
}: {
    navigateTitle: string;
    title: string;
    handleNavigate?: () => void;
}) => {
    const handleParamNavigate = useParamNavigate();
    const navigate = useNavigate();

    const handleHomeNavigate = () => {
        handleParamNavigate({}, false, `/staff/control/lineUp`);
    };

    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between ">
                <div className="flex flex-col gap-[15px]">
                    <div className="flex items-center gap-[8px]">
                        <button onClick={handleHomeNavigate}>
                            <HomeIcon className="text-white" />
                        </button>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <span className="text-white text-label-regular">{navigateTitle}</span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <button onClick={() => (handleNavigate ? handleNavigate() : navigate(-1))}>
                            <CircleArrowIcon className="text-white" />
                        </button>
                        <div className="text-[26px] font-bold text-white">{title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingDiarySubHeader;
