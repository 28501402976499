// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useApiMutation } from 'hooks/useDynamicApi';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { debounce } from 'lodash';
// utils
import { ENDPOINT_TEAM_ATTENDANCE } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as MinusBox } from 'assets/svgs/minusBox.svg';
// commons
import { showToast } from 'components/toast/Default';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const AttendancePlayerEl = ({
    attendanceId,
    playerId,
    playerName,
    uniformNumber,
    attendanceStatus,
    trainingAttitudeScore,
    technicalSkillScore,
    playerDiary,
}: AttendancePlayerType) => {
    const handleParamNavigate = useParamNavigate();
    const { id } = useParams();
    const { staffRole, role } = useSelector((state: RootState) => state.userInfo);
    const [attendanceInfo, setAttendanceInfo] = useState({
        attendanceId: attendanceId,
        playerId: playerId,
        playerName: playerName,
        uniformNumber: uniformNumber,
        attendanceStatus: attendanceStatus,
        trainingAttitudeScore: trainingAttitudeScore,
        technicalSkillScore: technicalSkillScore,
    });
    const {
        playerDiaryId,
        goodPoints = '',
        lackingPoints = '',
        questionsPoints = '',
        personalTrainingIntensity = null,
    } = playerDiary || {};
    const handleInfo = (updateData: any) => {
        setAttendanceInfo((prev) => ({
            ...prev,
            ...updateData,
        }));
    };
    // useApiMutation
    const patchPlayerAttendance = useApiMutation(ENDPOINT_TEAM_ATTENDANCE.status(`${attendanceId}`), 'patch', {
        onSuccess: (data) => {
            // showToast('선수 출석상태가 수정되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '선수 출석상태 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updateAttendance = () => {
        if (!patchPlayerAttendance?.isPending && staffRole !== 'OFFICE') {
            showToast('선수 출석 상태를 수정하는 중입니다.', 'info');
            patchPlayerAttendance.mutate({
                attendanceStatus: 'ABSENT',
            });
        }
    };
    const handleNavigate = (url: string, data?: any) => {
        handleParamNavigate({}, false, `${url}`, data);
    };
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            if (document.activeElement === inputRef.current) {
                e.preventDefault(); // 기본 스크롤 방지
            }
        };
        window.addEventListener('wheel', handleWheel, { passive: false });
        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);
    // 자동저장
    const patchTrainingScore = useApiMutation(ENDPOINT_TEAM_ATTENDANCE.trainingScores(`${attendanceId}`), 'patch', {
        onSuccess: (data) => {},
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '훈련 평가 점수 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const submitTrainingScore = () => {
        if (!patchTrainingScore.isPending && staffRole !== 'OFFICE') {
            patchTrainingScore.mutate({
                trainingAttitudeScore: attendanceInfo?.trainingAttitudeScore,
                technicalSkillScore: attendanceInfo?.technicalSkillScore,
            });
        }
    };
    // debounce
    const debouncedSubmitTrainingScore = useMemo(
        () =>
            debounce(() => {
                submitTrainingScore();
            }, 1000), // 1초 뒤에 실행
        [patchTrainingScore] // 의존성에서 필요 요소만 포함
    );
    useEffect(() => {
        if (
            trainingAttitudeScore !== attendanceInfo?.trainingAttitudeScore ||
            technicalSkillScore !== attendanceInfo?.technicalSkillScore
        ) {
            debouncedSubmitTrainingScore();
        }
        return () => {
            debouncedSubmitTrainingScore.cancel();
        };
    }, [attendanceInfo?.trainingAttitudeScore, attendanceInfo?.technicalSkillScore]);
    return (
        <div className="flex gap-[10px] items-center h-[64px]">
            <div className="inputDefaultStyle p-[12px] w-[120px] h-[64px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                {`${uniformNumber}번 ${playerName}`}
            </div>
            <input
                readOnly={staffRole === 'OFFICE'}
                type="number"
                step="0.1"
                min={0.0}
                max={10.0}
                value={attendanceInfo?.trainingAttitudeScore}
                onChange={(e) => handleInfo({ trainingAttitudeScore: e.target.value })}
                className="inputDefaultStyle p-[12px] w-[65px] h-[64px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center"
            />
            <input
                readOnly={staffRole === 'OFFICE'}
                type="number"
                step="0.1"
                min={0.0}
                max={10.0}
                value={attendanceInfo?.technicalSkillScore}
                onChange={(e) => handleInfo({ technicalSkillScore: e.target.value })}
                className="inputDefaultStyle p-[12px] w-[65px]  h-[64px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center"
            />
            <div className="  p-[12px] w-[208px] h-[55px] line-clamp-2 text-context-regular text-secondary-lGrayDark rounded-[8px] ">
                {goodPoints}
            </div>
            <div className="  p-[12px] w-[208px] h-[55px] line-clamp-2 text-context-regular text-secondary-lGrayDark rounded-[8px] ">
                {lackingPoints}
            </div>
            <div className="  p-[12px] w-[208px] h-[55px] line-clamp-2 text-context-regular text-secondary-lGrayDark rounded-[8px] ">
                {questionsPoints}
            </div>
            <div className="  p-[12px] w-[100px] h-[55px] text-center line-clamp-2 text-context-regular text-secondary-lGrayDark rounded-[8px] ">
                {personalTrainingIntensity}
            </div>
            <button
                onClick={
                    () => playerDiaryId && handleNavigate(`/staff/control/training/diary/${id}/player/${playerDiaryId}`)
                    //   staff/control/training/diary/12/player/225?year=2025&ageGroup=U10
                }
                className="text-primary-purple border-b-2 border-primary-purple w-[60px] h-[20px] text-context-bold"
            >
                상세보기
            </button>

            {staffRole !== 'OFFICE' && (
                <button
                    onClick={updateAttendance}
                    type="button"
                    className="mr-[5px]"
                >
                    {<MinusBox />}
                </button>
            )}
        </div>
    );
};

export default AttendancePlayerEl;
interface PlayerDiary {
    playerDiaryId: number;
    goodPoints: null | number;
    lackingPoints: null | number;
    personalTrainingIntensity: null | number;
    playerId: number;
    playerName: string;
    questionsPoints: null | number;
}
interface AttendancePlayerType {
    attendanceId: number;
    playerId: number;
    playerName: string;
    uniformNumber: number;
    attendanceStatus: 'PARTICIPANT' | 'ABSENT';
    trainingAttitudeScore: number;
    technicalSkillScore: number;
    playerDiary?: PlayerDiary;
}
