// ✅ useHistoryTools.ts (안정화된 undo/redo with 정확한 restore control)
import { useEffect, useRef } from 'react';
import * as fabric from 'fabric';
import stadium from 'assets/imgs/trainingLogEl/stadium.png';
import { debounce } from 'lodash';

const MAX_HISTORY = 50;

export const useHistoryTools = (fabricRef: React.RefObject<fabric.Canvas | null>) => {
    const undoStack = useRef<string[]>([]);
    const redoStack = useRef<string[]>([]);
    const isRestoring = useRef(false);
    const isFirstImport = useRef(true); // ✅ 최초 importJSON 구분용
    const applyCanvasJSON = async (json: string) => {
        const canvas = fabricRef.current;
        if (!canvas) return;

        isRestoring.current = true;
        canvas.renderOnAddRemove = false;
        canvas.clear();

        await new Promise<void>((resolve) => {
            canvas.loadFromJSON(json, () => {
                canvas.renderAll();
                resolve();
            });
        });

        if (!canvas.backgroundImage) {
            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;
            canvas.renderAll();
        }

        setTimeout(() => {
            isRestoring.current = false;
        }, 100);
    };

    const saveState = (initState?: string) => {
        if (isRestoring.current) return;
        const canvas = fabricRef.current;

        if (!canvas || !canvas.backgroundImage) return;
        const json = initState || JSON.stringify(canvas.toJSON());
        try {
            const parsed = JSON.parse(json);

            if (!parsed.objects || parsed.objects.length === 0) {
                return;
            }
            const lasteObj = parsed?.objects?.pop();
            if (lasteObj?.stroke === '#90c434' && lasteObj?.type === 'Path') return;
            // ✅ 오직 eraser path만 있는 경우 저장하지 않음
            const hasRealObjects = parsed.objects.some((obj: any) => !obj.eraser);
            if (!hasRealObjects) return;
        } catch (e) {
            console.error('[saveState] Failed to parse JSON', e);
            return;
        }

        const last = undoStack.current[undoStack.current.length - 1];
        if (json === last) return;

        // ✅ 최초 import 후 자동 saveState 방지
        if (isFirstImport.current) {
            isFirstImport.current = false;
            undoStack.current = [json];
            redoStack.current = [];
            return;
        }

        undoStack.current.push(json);
        if (undoStack.current.length > MAX_HISTORY) undoStack.current.shift();
        redoStack.current = [];
    };

    const saveStateDebounced = debounce(() => {
        if (isRestoring.current) return;
        saveState();
    }, 300);

    const undo = async () => {
        if (undoStack.current.length < 1) return;

        const popped = undoStack.current.pop();
        if (popped) redoStack.current.push(popped);

        const prev = undoStack.current[undoStack.current.length - 1];
        if (prev) await applyCanvasJSON(prev);
    };

    const redo = async () => {
        if (redoStack.current.length === 0) return;
        const next = redoStack.current.pop();
        if (next) {
            undoStack.current.push(next);
            await applyCanvasJSON(next);
        }
    };

    const reset = async () => {
        const canvas = fabricRef.current;
        if (!canvas) return;

        isRestoring.current = true;
        canvas.clear();

        const bg = await fabric.Image.fromURL(stadium);
        bg.scaleToWidth(canvas.width ?? 1142);
        bg.scaleToHeight(canvas.height ?? 808.34);
        bg.selectable = false;
        canvas.backgroundImage = bg;
        canvas.renderAll();

        const cleanState = JSON.stringify(canvas.toJSON());
        undoStack.current = [cleanState];
        redoStack.current = [];

        setTimeout(() => {
            isRestoring.current = false;
        }, 100);
    };

    const removeAllStack = () => {
        const canvas = fabricRef.current;
        if (!canvas) return;
        const cleanState = JSON.stringify(canvas.toJSON());

        undoStack.current = [cleanState];
        redoStack.current = [];
    };

    useEffect(() => {
        const canvas = fabricRef.current;
        if (!canvas) return;
        const saveInstant = () => {
            if (isRestoring.current) return;
            saveState();
        };

        const saveDebounced = () => {
            if (isRestoring.current) return;

            saveStateDebounced();
        };

        canvas.on('path:created', saveDebounced);
        canvas.on('object:modified', saveDebounced);
        canvas.on('object:added', saveInstant);
        canvas.on('object:removed', saveInstant);

        const timeout = setTimeout(() => {
            saveState();
        }, 500);

        return () => {
            canvas.off('path:created', saveDebounced);
            canvas.off('object:modified', saveDebounced);
            canvas.off('object:added', saveInstant);
            canvas.off('object:removed', saveInstant);
            clearTimeout(timeout);
        };
    }, [fabricRef.current]);

    return {
        undo,
        redo,
        reset,
        saveHistory: (initState?: string) => saveState(initState),
        removeAllStack,
    };
};
