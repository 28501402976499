// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_MANAGEMENT } from 'utils/consts/apiEndpoint';
import { eventCategoryMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// training components
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const EventTable = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [isDeleteShow, setIsDeleteShow] = useState(false);
    const paramAgeGroup = searchParams.get('ageGroup') || '';

    // useApiQuery
    const getTeamLogs = useApiQuery(
        ['getTeamLogs', paramAgeGroup, `${page}`, `${pagInfo.size}`],
        `${ENDPOINT_MANAGEMENT.teamLog}?ageGroup=${staffRole === 'OFFICE' ? paramAgeGroup : ''}&page=${page}&size=${
            pagInfo.size
        }`,
        'get'
    );
    // useApiMutation
    const postTeamLog = useApiMutation(ENDPOINT_MANAGEMENT.teamLog, 'post', {
        onSuccess: (data) => {
            handleNavigate(`/staff/control/event/${data.id}`);
            showToast('기록이 생성되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '기록 생성에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const createTeamLog = () => {
        if (!postTeamLog?.isPending) {
            showToast('기록 생성중입니다.', 'info');
            postTeamLog.mutate({
                title: '새로운 기록',
                content: '새로운 내용',
                category: 'OTHER',
                fileIds: [],
            });
        }
    };
    const deleteMeasurement = useApiMutation((props) => ENDPOINT_MANAGEMENT.teamLogId(props?.logId), 'delete', {
        onSuccess: (data) => {
            showToast('기록을 삭제했습니다.', 'success');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '기록 삭제에 실패했습니다.';
            showToast(errorMessage, 'error');
        },
    });

    const removeDiary = (id: number) => {
        if (!deleteMeasurement?.isPending) {
            showToast('기록을 삭제중입니다.', 'info');
            deleteMeasurement?.mutate({ logId: id });
        }
    };
    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };
    useEffect(() => {
        if (getTeamLogs?.isSuccess && getTeamLogs?.data && getTeamLogs?.data?.pageInfo?.page === 1) {
            setPageInfo(getTeamLogs?.data?.pageInfo);
        }
    }, [getTeamLogs?.isSuccess, getTeamLogs?.data]);
    const customizedRow = handleRows(getTeamLogs?.data?.data, setDeleteId, setIsDeleteShow) || [];

    return (
        <div className="w-full h-fit ">
            <ConfirmModal
                isShow={isDeleteShow}
                setIsShow={setIsDeleteShow}
                title={
                    <div>
                        <div>
                            기록을
                            <span className="font-bold text-status-error">삭제하시겠습니까?</span>
                        </div>
                    </div>
                }
                content={<div></div>}
                deleteBtnText="삭제"
                activeFunc={() => deleteId && removeDiary(deleteId)}
            />
            <CustomTable
                columns={columns}
                rows={customizedRow}
                equalWidth={false}
            />
            {getTeamLogs?.isPending && <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>}
            <button
                onClick={createTeamLog}
                className="w-full h-[44px] my-[20px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center"
            >
                <PlusIcon />
                {'기록 추가'}
            </button>
            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default EventTable;

export const handleRows = (
    rows: any[],
    setDeleteId: (id: number | null) => void,
    setIsDeleteShow: (show: boolean) => void
) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        title: <div className="w-[400px] m-auto text-start truncate">{row?.title}</div>,
        category: `${eventCategoryMatcher(row?.category)}`,
        createdByName: row?.createdByName,
        createdAt: `${formatDateTimeMatcher(row?.createdAt)} `,
        link: `/staff/control/event/${row.id}`,
        delete: (
            <button
                onClick={() => {
                    setDeleteId(row.id);
                    setIsDeleteShow(true);
                }}
            >
                <DeleteIcon className="text-status-error" />
            </button>
        ),
    }));
};
export const columns = [
    { key: 'createdAt', label: '날짜' },
    {
        key: 'category',
        label: '카테고리',
    },
    { key: 'title', label: '제목' },

    {
        key: 'createdByName',
        label: '최초 작성자',
        sortable: false,
    },
    // { key: "fileCount", label: "훈련 강도" },

    { key: 'link', label: '상세보기' },
    { key: 'delete', label: '삭제' },
];
