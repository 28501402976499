// hooks
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// icons
import { ReactComponent as UsersIcon } from 'assets/svgs/users.svg';
import { ReactComponent as FeedbackIcon } from 'assets/svgs/feedback.svg';
import { ReactComponent as ReportIcon } from 'assets/svgs/report.svg';
import VideoIcon from 'assets/svgs/video.png';
import VideoActiveIcon from 'assets/svgs/videoActive.png';
import { ReactComponent as ProfileIcon } from 'assets/svgs/profile.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
// utils
import { roleMatcher } from 'utils/matchers/userInfoMatcher';
// common components
import MypageNavigateModal from 'components/modals/MypageNavigateModal';
interface selectedType {
    title: string;
    value: string;
    link?: string;
}
interface TabItem {
    title: string;
    link: string;
    value: string;
    icon: React.ReactNode;
    subTabOptions?: selectedType[];
}
const Header = () => {
    const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const control = searchParams.get('control');
    const { role, name } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단

    const location = useLocation().pathname;
    const locationTab = location.split('/');
    const [mypageModal, setMypageModal] = useState(false);
    const [activeTab, setActiveTab] = useState(locationTab[2]);

    const tabList: TabItem[] = [
        {
            title: '유저 관리',
            link: '/admin/control',
            value: 'control',
            icon: <UsersIcon />,
        },
        {
            title: '피드백 관리',
            link: '/admin/feedback',
            value: 'feedback',
            icon: <FeedbackIcon />,
        },
        {
            title: '리포트',
            link: '/admin/report',
            value: 'report',
            icon: <ReportIcon />,
        },
        {
            title: '추천 동영상 관리',
            link: '/admin/video',
            value: 'video',
            icon:
                activeTab === 'video' ? (
                    <img
                        src={VideoActiveIcon}
                        alt={'VideoActiveIcon'}
                    />
                ) : (
                    <img
                        src={VideoIcon}
                        alt="VideoIcon"
                    />
                ),
        },
    ];

    const handleMainNavigate = (selected: TabItem) => {
        handleParamNavigate(
            {
                control: control ? control : undefined,
            },
            false,
            selected.link
        );
    };
    useEffect(() => {
        if (locationTab[2]) setActiveTab(locationTab[2]);
    }, [location]);
    return (
        <header className="py-[30px] flex justify-between items-center">
            <button onClick={() => navigate('/admin/control')}>
                <img
                    src={aisoccerWhiteFont}
                    alt="aisoccerWhiteFont"
                    className="w-[173px]"
                />
            </button>
            <div className="flex items-center text-secondary-mGrayDark text-subtitle-small-bold gap-[40px]">
                {tabList.map((tab) => (
                    <div
                        key={tab.value}
                        className="flex gap-[20px]"
                    >
                        <button
                            className={`flex gap-[10px] items-center ${
                                tab.value === activeTab ? 'text-primary-green' : 'text-secondary-mGrayDark'
                            }`}
                            onClick={() => {
                                handleMainNavigate(tab);
                            }}
                        >
                            {tab.icon}
                            <span>{tab.title}</span>
                        </button>
                    </div>
                ))}
            </div>
            <div className="relative">
                <button
                    onClick={() => setMypageModal(!mypageModal)}
                    className="flex gap-[10px] items-center "
                >
                    <ProfileIcon className="w-[40px] h-[40px]" />
                    <div className="flex flex-col items-start font-bold">
                        <div className="text-[14px] text-primary-green">{roleMatcher(role || '')}</div>

                        <div className="text-[18px] text-white">{`${name}`}</div>
                    </div>
                    <ArrowIcon className={`text-white ${mypageModal && 'rotate-180'}`} />
                </button>
                <MypageNavigateModal
                    parentUrl="/admin"
                    isShow={mypageModal}
                    setIsShow={setMypageModal}
                />
            </div>
        </header>
    );
};

export default Header;
