import video_loading from 'assets/gif/video_loading.gif';

const VideoLoading = ({
    isFixed = true,
    textStyle = 'text-primary-black',
    content = 'Loading...',
    isLoadingBar = false,
}: {
    isFixed?: boolean;
    textStyle?: string;
    content?: string;
    isLoadingBar?: boolean;
}) => {
    return (
        <div
            className={`${
                isFixed ? 'fixed top-0 left-0 z-[10] w-screen h-screen' : ''
            } flex items-center justify-center `}
        >
            <div className="flex flex-col items-center justify-center space-y-5 w-[360px] h-[212px] bg-white rounded-[10px]">
                {/* 축구공 */}
                <img
                    src={video_loading}
                    alt="loadinBall"
                    className="w-[150px]"
                />
                {/* <img
                    src={soccerball_gradient}
                    alt="loadingBall"
                    className=" top-[-20px] ml-[-110px] w-[100px]  animate-soccer-roll "
                /> */}
                {/* Progress Bar */}
                {isLoadingBar && (
                    <div className="relative w-[150px] h-[10px] bg-gray-300 rounded-full overflow-hidden">
                        <div className="h-full bg-primary-purple animate-progress-bar"></div>
                    </div>
                )}
                {/* 로딩 텍스트 */}
                <div className={`flex justify-center space-x-1 text-[20px] font-bold`}>
                    <span className="text-primary-purple">영상</span>이 만들어지고 있습니다...
                </div>
                {/* <div className={`flex justify-center space-x-1 ${textStyle}`}>{content}</div> */}
            </div>
        </div>
    );
};

export default VideoLoading;
