import { useState } from 'react';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as DeleteBoxIcon } from 'assets/svgs/deleteBox.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
//utils
import { ENDPOINT_TEAM, ENDPOINT } from 'utils/consts/apiEndpoint';
import { regionOptions, ageGroupOptions, soccerTypeOptions } from 'utils/consts/userInfo';
// commons
import { showToast } from 'components/toast/Default';
import Dropdown from 'components/dropdown/Default';
import PlayerInfoForm from 'pages/staff/control/lineUp/PlayerInfoForm';
import ConfirmModal from 'components/modals/ConfirmModal';

// import TeamStaffSearchModal from 'components/modals/TeamStaffSearchModal';
interface PlayerAddModalType {
    modalBtn?: string | React.ReactNode;
}
const PlayerAddModal = ({ modalBtn }: PlayerAddModalType) => {
    const [isShow, setIsShow] = useState(false);
    const [stopModalShow, setStopModalShow] = useState(false);

    const [newPlayerInfo, setNewPlayerInfo] = useState<PlayerInfo>({
        email: '',
        name: '',
        password: 'test1234!',
        passwordRepeat: '',
        isVerified: true,
    });
    const [signedUserInfo, setSignedUserInfo] = useState({
        id: '',
        name: '',
    });

    const handleInfo = (updateData: any) => {
        setNewPlayerInfo((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    const postSignUpMutation = useApiMutation(ENDPOINT.user.register, 'post', {
        onSuccess: (data) => {
            showToast(`선수를 생성습니다. 정보를 기입해 팀에 추가해주세요.`, 'success');
            setNewPlayerInfo({
                email: '',
                name: '',
                password: 'test1234!',
                passwordRepeat: '',
                isVerified: true,
            });
            setSignedUserInfo(data);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '회원가입 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    // handler
    const createPlyaer = () => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex) {
            showToast('올바른 이메일을 입력해주세요.', 'warning');
            return;
        }
        if (!postSignUpMutation?.isPending) {
            if (
                !newPlayerInfo?.name ||
                !newPlayerInfo?.email ||
                !newPlayerInfo?.password ||
                !newPlayerInfo?.passwordRepeat
            ) {
                showToast('필수 값을 입력해주세요.', 'warning');
                return;
            }
            if (newPlayerInfo?.password !== newPlayerInfo?.passwordRepeat) {
                showToast('비밀번호가 일치하지 않습니다.', 'warning');
                return;
            }
            showToast('선수를 생성 중입니다.', 'info');
            postSignUpMutation.mutate({
                name: newPlayerInfo?.name,
                email: newPlayerInfo?.email,
                password: newPlayerInfo?.password,
                passwordRepeat: newPlayerInfo?.passwordRepeat,
                isVerified: true,
            });
        }
    };
    const handleInit = () => {
        if (stopModalShow && signedUserInfo?.id) {
            alert(
                '프로필 입력 단계를 중단하셨습니다! * 중단된 선수의 email/pw로 로그인하여, 마저 선수 프로필 입력을 진행해야 활성화됩니다!'
            );
        }
        setIsShow(false);
        setSignedUserInfo({
            id: '',
            name: '',
        });
    };
    return (
        <>
            <div
                onClick={() => {
                    setIsShow(true);
                }}
                className="cursor-pointer"
            >
                {modalBtn}
            </div>
            {isShow && (
                <div className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]">
                    <div
                        className=" bg-white rounded-[10px] max-h-[700px] overflow-y-auto overflow-x-clip items-center p-[50px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex justify-between text-secondary-dGrayMid mb-[14px] text-subtitle-medium-bold">
                            <span>선수 회원가입</span>
                            <button onClick={() => (signedUserInfo?.id ? setStopModalShow(true) : handleInit())}>
                                <CloseIcon className="text-secondary-lGrayDark" />
                            </button>
                        </div>

                        {signedUserInfo?.id ? (
                            <PlayerInfoForm
                                handleInit={handleInit}
                                signedId={signedUserInfo?.id}
                                signedName={signedUserInfo?.name}
                            />
                        ) : (
                            <div className="w-[400px]  flex flex-col justify-center gap-[14px]">
                                <div>
                                    <input
                                        value={newPlayerInfo?.name}
                                        onChange={(e) => handleInfo({ name: e.target.value })}
                                        placeholder="선수명"
                                        maxLength={50}
                                        className="inputDefaultStyle inputCommonStyle"
                                    />
                                </div>
                                <div>
                                    <input
                                        value={newPlayerInfo?.email}
                                        onChange={(e) => handleInfo({ email: e.target.value })}
                                        placeholder="이메일"
                                        maxLength={50}
                                        className="inputDefaultStyle inputCommonStyle"
                                    />
                                </div>
                                <div>
                                    <input
                                        value={newPlayerInfo?.password}
                                        onChange={(e) => handleInfo({ password: e.target.value })}
                                        placeholder="비밀번호"
                                        maxLength={50}
                                        className="inputDefaultStyle inputCommonStyle"
                                    />
                                </div>
                                <div>
                                    <input
                                        value={newPlayerInfo?.passwordRepeat}
                                        onChange={(e) => handleInfo({ passwordRepeat: e.target.value })}
                                        placeholder="비밀번호 확인"
                                        maxLength={50}
                                        className="inputDefaultStyle inputCommonStyle"
                                    />
                                </div>
                                <button
                                    className="p-[12px] w-full text-white bg-primary-purple rounded-[8px]"
                                    onClick={createPlyaer}
                                >
                                    다음
                                </button>
                            </div>
                        )}
                    </div>
                    <ConfirmModal
                        isShow={stopModalShow}
                        setIsShow={setStopModalShow}
                        title="이 단계는 끝까지 진행하는 것이 좋습니다. 선수 프로필 입력을 중단하시면, 해당 선수 ID/PW로 로그인 하여 정보 입력을 마저 진행해야 선수가 활성화 됩니다."
                        deleteBtnText="중단"
                        activeFunc={handleInit}
                    />
                </div>
            )}
        </>
    );
};

export default PlayerAddModal;
interface TeamStaff {
    userId: number;
    email: string | null;
    name: string | null;
    lastFourDigits: string | null;
}

interface PlayerInfo {
    email: string;
    name: string;
    password: string;
    passwordRepeat: string;
    isVerified: boolean;
}
