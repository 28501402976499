// icons
import { ReactComponent as LineChartIcon } from 'assets/svgs/lineChart.svg';
// commons
import Lock from 'components/lock/Default';
import EmptyBar from 'components/emptyBar';
// player component
import ProgressBarCard from 'pages/player/report/personalReport/firstTouchCard/ProgressBarCard';

const FirstTouchCard = ({ isPrint, firstTouchAnalysis, firstTouchScoreDiff, firstTouchScore }: any) => {
    return (
        <div className={`relative p-[25px] rounded-[14px] border-2 border-secondary-dGrayMid`}>
            <div className="absolute top-[-32px] w-full">
                <div className="w-[426px] rounded-[80px] text-context-bold  bg-secondary-dGrayMid h-[64px] text-primary-white py-[8px] gap-[40px] justify-center text-center top-0 m-auto flex  items-center">
                    <span className="text-subtitle-large-bold ">퍼스트 터치</span>
                    <div className="flex items-center gap-[8px] ">
                        <div className="flex items-center gap-[5px]">
                            <LineChartIcon />
                            <span className="text-context-regular">평균 점수</span>
                        </div>
                        <div className="flex items-center gap-[5px]">
                            <span className="text-subtitle-large-bold ">{firstTouchScore?.toFixed(2) || '-'}</span>
                            <span className="text-[#868686]">점</span>
                            <span
                                className={`text-context-bold ${
                                    firstTouchScoreDiff >= 0 ? 'text-[#19DE93]' : 'text-[#B4B4B4]'
                                }`}
                            >
                                {firstTouchScoreDiff > 0
                                    ? `+${firstTouchScoreDiff}`
                                    : firstTouchScoreDiff < 0
                                    ? `${firstTouchScoreDiff}`
                                    : ''}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <EmptyBar customStyle="h-[21px]" />
            <ProgressBarCard
                isPrint={isPrint}
                firstTouchAnalysis={firstTouchAnalysis}
            />
            {!firstTouchAnalysis && (
                <Lock
                    content="측정이 필요합니다."
                    customStyle="top-[35px] rounded-[30px] text-primary-purple"
                />
            )}
        </div>
    );
};

export default FirstTouchCard;
