export function skillStateBgMatcher(skill: string) {
    switch (skill) {
        case 'dribble':
            return '드리블';
        case 'pass':
            return '패스';
        case 'shooting':
            return '슈팅';
        case '퍼스트 터치':
            return 'firstTouch';
        case '드리블':
            return 'dribble';
        case '패스':
            return 'pass';
        case '슈팅':
            return 'shooting';
        default:
            return skill;
    }
}
export function skillStateMeasurementMatcher(skill: string) {
    switch (skill) {
        case 'dribbleOne':
            return '드리블1';
        case 'dribbleTwo':
            return '드리블2';
        case 'shootingOne':
            return '슈팅1';
        case 'shootingTwo':
            return '슈팅2';
        case '퍼스트 터치':
            return 'firstTouch';
        case 'passOne':
            return '패스1';
        case 'passTwo':
            return '패스2';
        default:
            return skill;
    }
}
export function skillStateMatcher(skill: string) {
    switch (skill) {
        case 'DRIBBLING':
            return '드리블';
        case 'PASSING':
            return '패스';
        case 'SHOOTING':
            return '슈팅';
        case 'FIRSTTOUCH':
            return '퍼스트 터치';
        default:
            return skill;
    }
}
export function departmentMatcher(department: string) {
    switch (department) {
        case 'PANGYO':
            return '판교점';
        case 'SUWON':
            return '수원점';
        case '판교점':
            return 'PANGYO';
        case '수원점':
            return 'SUWON';
        default:
            return department;
    }
}
export function firstTouchStateMatcher(state: string) {
    switch (state) {
        case 'FAILED':
            return '실패';
        case 'TOUCH_ONLY':
            return '터치';
        case 'TOUCH_AND_TURN':
            return '턴';
    }
}

export function passTwoStateMatcher(state: string) {
    switch (state) {
        case 'FAILED':
            return '실패';
        case 'OUTSIDE_SUCCESS':
            return '밖 패스';
        case 'INSIDE_SUCCESS':
            return '안 패스';
    }
}

export function shootingOneStateMatcher(state: string) {
    switch (state) {
        case 'SIGNAL_FAIL':
            return '인지 실패';
        case 'ATTEMPT':
            return '슈팅 시도';
        case 'TAPE_HIT':
            return '테이프';
        case 'SUCCESS':
            return '득점';
    }
}

export function shootingTwoStateMatcher(state: string) {
    switch (state) {
        case 'FAILED':
            return '실패';
        case 'OUTSIDE_TAPE_HIT':
            return '라인 이탈 테이프';
        case 'OUTSIDE_SUCCESS':
            return '라인 이탈 성공';
        case 'INSIDE_TAPE_HIT':
            return '라인 이내 테이프';
        case 'INSIDE_SUCCESS':
            return '라인 이내 성공';
    }
}

export function dribbleCardColorMatcher(label: string) {
    // 각 구간의 범위는 8.33입니다.
    const range = 100 / 3;

    if (label === 'Worst') return 'bg-[#C4C3D2] text-[#00000] '; // 0~8.33
    else if (label === 'Best') return 'bg-[#5651E3] text-[#ffffff]'; // 16.67~24.99
    else return 'bg-[#E3E2EF] text-[#00000]'; //
    // else if (score <= range * 4) return 'bg-[#FFCD35] text-[#111111]'; // 25~33.33
    // else if (score <= range * 5) return 'bg-[#FEE632] text-[#111111]'; // 33.34~41.66
    // else if (score <= range * 6) return 'bg-[#BBFE32] text-[#111111]'; // 41.67~49.99
    // else if (score <= range * 7) return 'bg-[#86EE2B] text-[#111111]'; // 50~58.33
    // else if (score <= range * 8) return 'bg-[#32EE3E] text-white'; // 58.34~66.66
    // else if (score <= range * 9) return 'bg-[#00D20E] text-white'; // 66.67~74.99
    // else if (score <= range * 10) return 'bg-[#00B60C] text-white'; // 75~83.33
    // else if (score <= range * 11) return 'bg-[#008B54] text-white'; // 83.34~91.66
    // else if (score <= range * 12) return 'bg-[#005A3C] text-white'; // 91.67~100
}
