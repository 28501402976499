// hooks
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_PLAYER_DIARY } from 'utils/consts/apiEndpoint';
// training components
import TrainingDiarySubHeader from 'pages/player/control/training/[id]/TrainingDiarySubHeader';
import SessionTemplateInfo from 'pages/staff/control/training/session/[id]/SessionTemplateInfo';
import TrainingInfo from 'pages/player/control/training/[id]/TrainingInfo';
import TrainingSession from 'pages/player/control/training/[id]/TrainingSession';
import TrainingEvaluation from 'pages/player/control/training/[id]//TrainingEvalutaion';
import SoccerDiary from 'pages/player/control/training/[id]/SoccerDiary';

const TrainingDetail = () => {
    const { id } = useParams();

    const handleParamNavigate = useParamNavigate();

    // useApiQuery
    const getPlayerDiaryId = useApiQuery(['getPlayerDiaryId', `${id}`], ENDPOINT_PLAYER_DIARY.diaryId(id), 'get', {
        enabled: !!id,
    });
    // 업로드
    const [editableData, setEditableData] = useState({
        personalTrainingIntensity: null,
        goodPoints: '',
        lackingPoints: '',
        questionsPoints: '',
        footballDiary: '',
    });
    useEffect(() => {
        if (getPlayerDiaryId?.data) {
            setEditableData({
                personalTrainingIntensity: getPlayerDiaryId?.data?.personalTrainingIntensity,
                goodPoints: getPlayerDiaryId?.data?.goodPoints,
                lackingPoints: getPlayerDiaryId?.data?.lackingPoints,
                questionsPoints: getPlayerDiaryId?.data?.questionsPoints,
                footballDiary: getPlayerDiaryId?.data?.footballDiary,
            });
        }
    }, [getPlayerDiaryId?.data]);
    const patchPlayerDiaryId = useApiMutation(ENDPOINT_PLAYER_DIARY.diaryId(id), 'patch', {
        onSuccess: (data) => {
            showToast('훈련 일지가 수정되었습니다', 'success');
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '훈련 일지 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const updatePlayerDiaryId = () => {
        if (!patchPlayerDiaryId?.isPending) {
            showToast('훈련 일지를 수정하는 중입니다.', 'info');
            patchPlayerDiaryId.mutate(editableData);
        }
    };

    const handleNavigate = (url: string, data?: any) => {
        handleParamNavigate({}, false, `${url}`, data);
    };
    return (
        <div className="wrapper">
            <TrainingDiarySubHeader
                navigateTitle="관리"
                title="훈련 상세"
                handleNavigate={() => handleNavigate('/staff/control/training')}
            />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <TrainingInfo playerDiaryIdData={getPlayerDiaryId?.data} />
                <TrainingSession trainingSession={getPlayerDiaryId?.data?.trainingSessions} />
                <TrainingEvaluation
                    editableData={editableData}
                    setEditableData={setEditableData}
                />
                <SoccerDiary
                    editableData={editableData}
                    setEditableData={setEditableData}
                />
                <div className="mt-[20px] justify-center gap-[10px] flex text-context-bold">
                    <button
                        onClick={() => handleNavigate('/player/control/training')}
                        className="rounded-[8px] text-primary-white bg-secondary-dGrayLight px-[20px] py-[12px]"
                    >
                        취소
                    </button>
                    <button
                        onClick={updatePlayerDiaryId}
                        className="rounded-[8px] text-primary-white bg-primary-purple px-[20px] py-[12px]"
                    >
                        업로드
                    </button>
                </div>
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default TrainingDetail;

export const handleRows = (rows: any[]) => {
    return rows?.map((row, idx) => ({
        index: idx + 1,
        name: `${row?.name} `,
        sessionPoint: row?.sessionPoint,
        createdAt: row?.createdAt,
        updatedAt: row?.updatedAt,
        link: `/admin/feedback/${row?.measurementId}?measurementId=${row?.measurementId}&dateTime=${row?.measuredAt}`,
    }));
};

interface PlayerDiaryInfoType {
    id: number | null;
    trainingDateTime: string | null;
    location?: string | null;
    weather: string | null;
    trainingIntensity: number | null;
    trainingSessions: {
        id: number;
        tacticalBoardJson: string | null;
        sessionPoint: string | null;
        sequence: 1;
    }[];
    personalTrainingIntensity: 3;
    goodPoints: string | null;
    lackingPoints: string | null;
    questionsPoints: string | null;
    footballDiary: string | null;
}
