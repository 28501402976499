export function weatherMatcher(weather: string) {
  switch (weather) {
    case "CLEAR":
      return "맑음";
    case "CLOUDY":
      return "흐림";
    case "RAIN":
      return "비";
    case "SNOW":
      return "눈";
    case "INDOOR":
      return "실내";
    case "맑음":
      return "CLEAR";
    case "흐림":
      return "CLOUDY";
    case "비":
      return "RAIN";
    case "눈":
      return "SNOW";
    case "실내":
      return "INDOOR";
    default:
      return weather;
  }
}

export function eventCategoryMatcher(category: string) {
  switch (category) {
    case "CONSULTATION":
      return "상담";
    case "INCIDENT":
      return "사건";
    case "MEETING":
      return "회의";
    case "OTHER":
      return "기타";
    case "기타":
      return "OTHER";
    case "회의":
      return "MEETING";
    case "사건":
      return "INCIDENT";
    case "상담":
      return "CONSULTATION";
    default:
      return category;
  }
}
