// commons
import { trainingIntensityOptions } from 'utils/consts/diaryInfo';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const TrainingEvaluation = ({ editableData, setEditableData }: EvaluationPropsType) => {
    const { role } = useSelector((state: RootState) => state.userInfo);

    const handleInfo = (updateData: any) => {
        setEditableData((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    return (
        <div className="flex border-b border-secondary-lGrayMid">
            <div className="w-[150px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                훈련평가
            </div>
            <div className="p-[24px] flex flex-col gap-[18px]">
                <div className="grid grid-cols-3 gap-[18px]">
                    <div>
                        <div className="mb-[10px] text-secondary-dGrayMid text-subtitle-medium-bold">잘한 점</div>
                        <textarea
                            readOnly={role !== 'PLAYER'}
                            className="p-[12px] rounded-[8px] w-[363.3px] h-[210px] resize-none bg-secondary-mGrayLight text-secondary-dGrayDark placeholder:text-secondary-lGrayDark"
                            onChange={(e) => handleInfo({ goodPoints: e.target.value })}
                            value={editableData?.goodPoints || ''}
                            placeholder="오늘 훈련에서 잘 한 점은 무엇인가요?"
                        />
                    </div>
                    <div>
                        <div className="mb-[10px] text-secondary-dGrayMid text-subtitle-medium-bold">
                            부족한 점 / 보완계획
                        </div>
                        <textarea
                            readOnly={role !== 'PLAYER'}
                            className="p-[12px] rounded-[8px] w-[363.3px] h-[210px] resize-none bg-secondary-mGrayLight text-secondary-dGrayDark placeholder:text-secondary-lGrayDark"
                            onChange={(e) => handleInfo({ lackingPoints: e.target.value })}
                            value={editableData?.lackingPoints || ''}
                            placeholder={`- 오늘 훈련에서 부족했던 점은 무엇인가요?\n\n- 다음 훈련 시 어떻게 보완해 볼 건가요? \n(최선을 다한다, 적극적으로 한다 같은 모호한 표현보다, 보완 결과를 알 수 있는 실행 목표를 적어보세요.)`}
                        />
                    </div>
                    <div>
                        <div className="mb-[10px] text-secondary-dGrayMid text-subtitle-medium-bold">궁금한 점</div>
                        <textarea
                            readOnly={role !== 'PLAYER'}
                            className="p-[12px] rounded-[8px] w-[363.3px] h-[210px] resize-none bg-secondary-mGrayLight text-secondary-dGrayDark placeholder:text-secondary-lGrayDark"
                            onChange={(e) => handleInfo({ questionsPoints: e.target.value })}
                            value={editableData?.questionsPoints || ''}
                            placeholder="훈련을 하며 이해가 되지 않았거나 어려웠던 점은 무엇인가요?"
                        />
                    </div>
                </div>
                <div className="flex items-center gap-[20px]">
                    <div className="mb-[10px] h-[30px] text-secondary-dGrayMid text-subtitle-medium-bold">
                        본인이 느끼는 훈련 강도
                    </div>
                    <div className="flex items-center gap-[14px]">
                        {trainingIntensityOptions.map((el) => (
                            <label
                                className="h-[24px] flex items-center gap-[14px]"
                                key={el.value}
                                onClick={() => role === 'PLAYER' && handleInfo({ personalTrainingIntensity: el.value })}
                            >
                                <div
                                    className={` relative text-secondary-lGrayDark w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                        el.value === `${editableData?.personalTrainingIntensity}`
                                            ? 'border-2 border-primary-purple bg-primary-white'
                                            : 'border-primary-black bg-primary-white'
                                    } `}
                                >
                                    {el.value === `${editableData?.personalTrainingIntensity}` && (
                                        <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                                    )}
                                </div>
                                {el.label}
                            </label>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrainingEvaluation;

interface EvaluationType {
    personalTrainingIntensity: number | null;
    goodPoints?: string | null;
    lackingPoints?: string | null;
    questionsPoints?: string | null;
    footballDiary?: string | null;
}

interface EvaluationPropsType {
    editableData: EvaluationType;
    setEditableData: (data: any) => void;
}
