// hooks
import { useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';
// utils
import { ENDPOINT_TEAM_TRAINING_SESSION, ENDPOINT_TEAM_SESSION_TEMPLATES } from 'utils/consts/apiEndpoint';
// commons
import CustomTable from 'components/table/CustomTable';
import { showToast } from 'components/toast/Default';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

interface SessionAddModalType {
    modalBtn?: string | React.ReactNode;
}
const SessionAddModal = ({ modalBtn }: SessionAddModalType) => {
    const { id } = useParams();
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [checkedSession, setCheckedSession] = useState<number | null>(null);
    const [isShow, setIsShow] = useState(false);
    // useApiQuery
    const getDiarySession = useApiQuery(
        ['getDiarySession', `${id}`, `${paramAgeGroup}`],
        `${ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplate}?${`ageGroup=${
            staffRole === 'OFFICE' ? paramAgeGroup : ''
        }`}`,
        'get',
        {
            enabled: !!id,
        }
    );
    const customizedRow = handleRows(getDiarySession?.data?.data, checkedSession, setCheckedSession) || [];

    // useApiMutation
    const postTeamSession = useApiMutation(ENDPOINT_TEAM_TRAINING_SESSION.session(id), 'post', {
        onSuccess: (data) => {
            showToast('팀 훈련 세션이 추가되었습니다.', 'success');
            setIsShow(false);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const errorMessage = error?.data?.detailedMessage || error || '팀 훈련 세션 추가에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });
    const createTeamSession = () => {
        if (!postTeamSession?.isPending && checkedSession) {
            showToast('팀 훈련 세션 추가중입니다.', 'info');
            postTeamSession.mutate({
                templateId: checkedSession,
            });
        }
    };

    return (
        <>
            <button onClick={() => setIsShow(true)}>{modalBtn}</button>
            {isShow && (
                <div
                    onClick={() => setIsShow(false)}
                    className="fixed left-0 z-[100] top-0 flex justify-center items-center w-full h-full bg-[#00000050]"
                >
                    <div
                        className=" bg-white rounded-[10px]  items-center w-[682px] py-[50px] px-[50px]"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="flex flex-col justify-center gap-[14px] ">
                            <div className="flex justify-between text-secondary-dGrayMid text-subtitle-medium-bold">
                                <span>순서 추가</span>
                                <button onClick={() => setIsShow(false)}>
                                    <CloseIcon className="text-secondary-lGrayDark" />
                                </button>
                            </div>

                            <div className="w-full max-h-[500px] overflow-y-scroll">
                                <CustomTable
                                    columns={columns}
                                    rows={customizedRow}
                                    equalWidth={false}
                                />
                            </div>
                            <button
                                onClick={createTeamSession}
                                className="p-[12px] w-full text-white bg-primary-purple font-bold rounded-[8px]"
                            >
                                세션 추가
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SessionAddModal;

export const columns = [
    { key: 'id', label: '' },
    { key: 'index', label: '번호' },
    { key: 'name', label: '이름' },
    { key: 'sessionPoint', label: '세션포인트' },
];
export const handleRows = (
    rows: any[],
    checkedSession: number | null,
    setCheckedSession: (sessionId: number | null) => void
) => {
    return rows?.map((row, idx) => ({
        id: (
            <div
                onClick={() => setCheckedSession(row?.id)}
                className={`
                cursor-pointer w-[24px] ml-[18px] h-[24px] flex items-center justify-center rounded-[6px]
                border transition-colors duration-200
                ${
                    row?.id === checkedSession
                        ? 'bg-primary-black border border-primary-black'
                        : 'border border-primary-black bg-primary-white'
                }
                } `}
            >
                {row.id === checkedSession && (
                    <span className="text-primary-white w-[14.4px] h-[10.8px] ">
                        <CheckIcon />
                    </span>
                )}
            </div>
        ),
        index: idx + 1,
        name: row?.name,
        sessionPoint: row?.sessionPoint,
    }));
};
