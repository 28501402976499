export const roles = ['PLAYER', 'TEAM_STAFF', 'SYSTEM_ADMIN', 'DATA_ANALYST', 'SUPER_ADMIN'];
export const roleOptions = [
    { value: 'TEAM_STAFF', label: '팀스태프' },
    { value: 'SYSTEM_ADMIN', label: '시스템 어드민' },
    { value: 'DATA_ANALYST', label: '데이터 분석관' },
    { value: 'SUPER_ADMIN', label: '슈퍼 어드민' },
];
export const staffRoles = ['COACH', 'MANAGER', 'OFFICE'];
export const staffRoleOptions = [
    { value: 'COACH', label: '코치' },
    { value: 'MANAGER', label: '감독' },
    { value: 'OFFICE', label: '사무국' },
];

export const genders = ['MALE', 'FEMALE'];

export const positions = ['GK', 'CB', 'LB', 'RB', 'CDM', 'CAM', 'CM', 'LW', 'RW', 'CF'];

export const majorFoots = ['RIGHT', 'LEFT', 'BOTH'];

export const regions = [
    'SEOUL',
    'INCHEON',
    'GYEONGGI',
    'DAEJEON',
    'DAEGU',
    'BUSAN',
    'GANGWON',
    'GWANGJU',
    'ULSAN',
    'GYEONGNAM',
    'GYEONGBUK',
    'JEONNAM',
    'JEONBUK',
    'JEJU',
    'CHUNGCHEONGBUK',
    'CHUNGCHEONGNAM',
    'SEJONG',
    'NONE',
];

export const status = [
    'PLAYER_ACTIVE',
    'PLAYER_PENDING_INFO',
    'PLAYER_PENDING_PASSWORD',
    'TEAM_STAFF_ACTIVE',
    'TEAM_STAFF_PENDING',
    'TEAM_STAFF_PENDING_PASSWORD',
    'SYSTEM_ADMIN_ACTIVE',
    'SYSTEM_ADMIN_PENDING',
    'SYSTEM_ADMIN_PENDING_PASSWORD',
    'DATA_ANALYST_ACTIVE',
    'DATA_ANALYST_PENDING',
    'DATA_ANALYST_PENDING_PASSWORD',
    'SUPER_ADMIN_ACTIVE',
    'SUPER_ADMIN_PENDING_PASSWORD',
];

export const genderOptions = [
    { value: 'MALE', label: '남자' },
    { value: 'FEMALE', label: '여자' },
];

export const majorFootOptions = [
    { value: 'RIGHT', label: '오른발' },
    { value: 'LEFT', label: '왼발' },
    { value: 'BOTH', label: '양발' },
    { value: '', label: '선택안함' },
];

export const positionOptions = [
    { value: 'GK', label: '골키퍼(GK)' },
    { value: 'CB', label: '센터백(CB)' },
    { value: 'LB', label: '레프트 윙백(LB)' },
    { value: 'RB', label: '라이트 윙백(RB)' },
    { value: 'CDM', label: '수비형 미드필더(CDM)' },
    { value: 'CM', label: '중앙 미드필더(CM)' },
    { value: 'CAM', label: '공격형 미드필더(CAM)' },
    { value: 'LW', label: '레프트 윙(LW)' },
    { value: 'RW', label: '라이트 윙(RW)' },
    { value: 'CF', label: '센터 포워드(CF)' },
];
export const positionShortOptions = [
    { value: 'GK', label: 'GK' },
    { value: 'CB', label: 'CB' },
    { value: 'LB', label: 'LB' },
    { value: 'RB', label: 'RB' },
    { value: 'CDM', label: 'CDM' },
    { value: 'CM', label: 'CM' },
    { value: 'CAM', label: 'CAM' },
    { value: 'LW', label: 'LW' },
    { value: 'RW', label: 'RW' },
    { value: 'CF', label: 'CF' },
];

export const regionOptions = [
    { value: 'SEOUL', label: '서울' },
    { value: 'INCHEON', label: '인천' },
    { value: 'GYEONGGI', label: '경기' },
    { value: 'DAEJEON', label: '대전' },
    { value: 'DAEGU', label: '대구' },
    { value: 'BUSAN', label: '부산' },
    { value: 'GANGWON', label: '강원' },
    { value: 'GWANGJU', label: '광주' },
    { value: 'ULSAN', label: '울산' },
    { value: 'GYEONGNAM', label: '경남' },
    { value: 'GYEONGBUK', label: '경북' },
    { value: 'JEONNAM', label: '전남' },
    { value: 'JEONBUK', label: '전북' },
    { value: 'JEJU', label: '제주' },
    { value: 'CHUNGCHEONGBUK', label: '충북' },
    { value: 'CHUNGCHEONGNAM', label: '충남' },
    { value: 'SEJONG', label: '세종' },
    { value: 'NONE', label: 'NONE' },
];

export const ageGroupOptions = [
    { value: 'U10', label: 'U10' },
    { value: 'U12', label: 'U12' },
    { value: 'U15', label: 'U15' },
    { value: 'U18', label: 'U18' },
    { value: 'UNIVERSITY', label: '대학' },
    { value: 'ADULT', label: '성인' },
    { value: 'PRO', label: '프로' },
    { value: 'NONE', label: 'NONE' },
];

export const soccerTypeOptions = [
    { value: 'HOBBY', label: '취미' },
    { value: 'ELITE', label: '엘리트' },
    { value: 'NONE', label: 'NONE' },
];

export const skillOptions = [
    { value: 'DRIBBLING', label: '드리블' },
    { value: 'FIRSTTOUCH', label: '퍼스트 터치' },
    { value: 'PASSING', label: '패스' },
    { value: 'SHOOTING', label: '슈팅' },
];
