import React, { useState, useEffect, memo } from 'react';

import ApexChart from 'components/chart/ApexChart';
import { areaOptions } from 'pages/player/measurement/analysis/analysisCard/analysisAreaOpt';
import { measurementWordMatcher } from 'utils/matchers/iconMatcher';

const AnalysisChart = ({ category, chartData, progress }: any) => {
    const progressIndex = progress + 1 ?? 0; // 현재 progress (x축 값)
    const progressValue = chartData[progress] ?? 0; // 해당 progress 위치의 y축 값
    const isValidProgress = progress >= 0 && progress < chartData.length && chartData[progress] !== undefined;

    // console.log(progressIndex, chartData.length);
    const customizedAreaOption = {
        ...areaOptions,

        annotations: isValidProgress
            ? {
                  xaxis: [
                      {
                          x: progressIndex, // progress 값에 따라 세로선 이동
                          borderColor: '#5e6eff',
                          strokeDashArray: 4,
                          // label: {
                          //     text: `Time: ${progressIndex}s`,
                          //     style: {
                          //         color: '#fff',
                          //         background: '#5e6eff',
                          //     },
                          // },
                      },
                  ],
                  points: [
                      {
                          x: progressIndex,
                          y: progressValue,
                          marker: {
                              size: 8,
                              fillColor: '#5e6eff',
                              strokeColor: '#FFFFFF',
                          },
                          label: {
                              borderColor: '#5e6eff',
                              offsetY: -10,
                              style: {
                                  color: '#fff',
                                  background: '#5e6eff',
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                              },
                              text: `${progressValue ? progressValue : '-'}`, // 점수 표시
                          },
                      },
                  ],
              }
            : {},
    };
    return (
        <div className="w-full">
            <ApexChart
                customType={'area'}
                customOptions={customizedAreaOption}
                customSeries={[
                    {
                        name: measurementWordMatcher(category),
                        data: chartData,
                    },
                ]}
                height={435}
            />
        </div>
    );
};

export default memo(AnalysisChart);
