import React, { useState, useRef, useEffect } from 'react';
interface selectedType {
    title: string;
    value: string;
    link?: string;
}
interface GreenToggleProps {
    options: selectedType[];
    selected: selectedType;
    setSelected?: (selected: selectedType) => void;
    className?: string;
}

const GreenToggle: React.FC<GreenToggleProps> = ({ options, selected, setSelected, className = '' }) => {
    const [maxWidth, setMaxWidth] = useState(0);
    const optionRefs = useRef<(HTMLButtonElement | null)[]>([]);

    useEffect(() => {
        // Find the maximum width among all options
        const widths = optionRefs.current.map((ref) => ref?.offsetWidth || 0);
        const maxOptionWidth = Math.max(...widths);
        setMaxWidth(maxOptionWidth);
    }, [options]);

    const handleOptionClick = (option: selectedType) => {
        setSelected?.(option);
    };
    return (
        <div
            className={`bg-[#272727] text-[#8B8B8B]
                w-fit overflow-hidden
                rounded-[12px]
                relative inline-flex items-center justify-center
                ${className}
            `}
        >
            <div
                className="
                    absolute
                    top-0
                    bottom-0
                    bg-primary-green
                    transition-all
                    duration-300
                    ease-in-out
                    z-0
                    rounded-[12px]
                "
                style={{
                    left: `${(options.findIndex((option) => option.value === selected.value) / options.length) * 100}%`,
                    width: `${(1 / options.length) * 100}%`,
                }}
            />
            {options.map((option, index) => (
                <button
                    ref={(el) => (optionRefs.current[index] = el)}
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                    style={{ minWidth: `${maxWidth}px` }}
                    className={`
                        relative z-10 flex items-center justify-center
                        px-[20px] py-[10px] text-center
                        transition-all duration-200
                        ${
                            selected.value === option.value
                                ? 'text-primary-black text-context-bold'
                                : 'text-[#8B8B8B] text-context-regular'
                        }
                    `}
                >
                    {option.title}
                </button>
            ))}
        </div>
    );
};

export default GreenToggle;
