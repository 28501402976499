// hooks
import { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// assets
import aisoccerWhiteFont from 'assets/logos/font-logo/aisoccer-white-font.webp';
// icons
import { ReactComponent as FileIcon } from 'assets/svgs/file.svg';
import { ReactComponent as BarChartIcon } from 'assets/svgs/barChart.svg';
import { ReactComponent as ProfileIcon } from 'assets/svgs/profile.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
// utils
import { staffRoleMatcher } from 'utils/matchers/userInfoMatcher';
// common components
import GreenToggle from 'components/toggle/GreenToggle';
import MypageNavigateModal from 'components/modals/MypageNavigateModal';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
interface selectedType {
    title: string;
    value: string;
    link?: string;
}
interface TabItem {
    title: string;
    link: string;
    value: string;
    icon: React.ReactNode;
    subTabOptions?: selectedType[];
}
const Header = () => {
    const handleParamNavigate = useParamNavigate();
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const locationList = location?.split('/');
    const [searchParams] = useSearchParams();
    const paramYear = searchParams.get('year');
    const paramPlayerId = searchParams.get('player') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';

    const TEAM_NAME = localStorage.getItem('teamName');
    const { role, staffRole, name } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단

    const tabList: TabItem[] = [
        {
            title: '관리',
            link: '/staff/control/lineUp',
            value: 'control',
            icon: <FileIcon />,
            subTabOptions: [
                { title: '선수단', value: 'lineUp', link: '/staff/control/lineUp' },
                { title: '선수평가', value: 'rating', link: '/staff/control/rating' },
                {
                    title: '훈련',
                    value: 'training',
                    link: '/staff/control/training',
                },
                {
                    title: '경기',
                    value: 'schedule',
                    link: '/staff/control/schedule',
                },
                {
                    title: '기록',
                    value: 'event',
                    link: '/staff/control/event',
                },
                { title: '의료센터', value: 'medical', link: '/staff/control/medical' },
                {
                    title: '선수 가입',
                    value: 'registration',
                    link: '/staff/control/registration',
                },
            ],
        },
        {
            title: '측정',
            link: '/staff/measurement/control',
            value: 'measurement',
            icon: <BarChartIcon />,
            subTabOptions: [
                {
                    title: '선수단',
                    value: 'control',
                    link: '/staff/measurement/control',
                },
                { title: '리포트', value: 'report', link: '/staff/measurement/report' },
                {
                    title: '선수 가입',
                    value: 'registration',
                    link: '/staff/measurement/registration',
                },
            ],
        },
    ];

    const [mypageModal, setMypageModal] = useState(false);
    const [activeTab, setActiveTab] = useState('control');
    const [selectedSubTab, setSelectedSubTab] = useState<selectedType>({
        title: '선수단',
        value: 'lineUp',
        link: '/staff/control/lineUp',
    });

    useEffect(() => {
        const path = location.split('/');
        const currentTab = path[2] || 'control';
        setActiveTab(currentTab);

        if (path[3]) {
            const activeTabData = tabList?.find((tab) => tab.value === currentTab);
            const currentSubTab = activeTabData?.subTabOptions?.find((option) => option.value === path[3]);
            if (currentSubTab) {
                setSelectedSubTab(currentSubTab);
            }
        } else if (currentTab === 'control') {
            setSelectedSubTab({
                title: '선수단',
                value: 'lineUp',
                link: '/staff/control/lineUp',
            });
        } else if (currentTab === 'measurement') {
            setSelectedSubTab({
                title: '측정',
                value: 'measurement',
                link: '/staff/measurement/control',
            });
        } else {
            setSelectedSubTab({
                title: '',
                value: '',
                link: '',
            });
        }
    }, [location]);
    const handleHome = () => {
        handleParamNavigate({}, false, '/staff/control/lineUp');
    };
    const handleMainNavigate = (selected: TabItem) => {
        if (selected.value === 'control' && paramYear && paramAgeGroup) {
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    year: paramYear,
                },
                false,
                selected.link
            );
        } else if (selected.value === 'measurement' && paramYear && paramAgeGroup) {
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    year: paramYear,
                },
                false,
                selected.link
            );
        }
    };

    const handleSubNavigate = (selected: selectedType) => {
        handleParamNavigate(
            {
                ageGroup: paramAgeGroup,
                year: paramYear || undefined,
            },
            false,
            selected.link
        );
    };

    return (
        <header className="h-[100px] flex justify-between items-center">
            <img
                onClick={handleHome}
                src={aisoccerWhiteFont}
                alt="aisoccerWhiteFont"
                className="w-[173px] cursor-pointer"
            />
            <div className="flex items-center text-secondary-mGrayDark text-subtitle-small-bold gap-[40px]">
                {tabList.map((tab) => (
                    <div
                        key={tab.value}
                        className="flex gap-[20px]"
                    >
                        <button
                            className={`flex gap-[10px] items-center ${
                                tab.value === activeTab ? 'text-primary-green' : 'text-secondary-mGrayDark'
                            }`}
                            onClick={() => {
                                setActiveTab(tab.value);
                                handleMainNavigate(tab);
                            }}
                        >
                            {tab.icon}
                            <span>{tab.title}</span>
                        </button>
                        {tab.subTabOptions && locationList[2] === tab?.value && (
                            <GreenToggle
                                options={tab.subTabOptions}
                                selected={selectedSubTab}
                                setSelected={(selected) => {
                                    setSelectedSubTab(selected);
                                    if (selected.link) {
                                        handleSubNavigate(selected);
                                    }
                                }}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="relative">
                <button
                    onClick={() => setMypageModal(!mypageModal)}
                    className="flex gap-[10px] items-center "
                >
                    <ProfileIcon className="w-[40px] h-[40px]" />
                    <div className="flex flex-col items-start font-bold">
                        <div className="text-[14px] text-primary-green">{TEAM_NAME}</div>
                        <div className="text-[18px] text-white">
                            <span>{name}</span>
                            {staffRole && <span>{`(${staffRoleMatcher(staffRole || '')})`}</span>}
                        </div>
                    </div>
                    <ArrowIcon className={`text-white ${mypageModal && 'rotate-180'}`} />
                </button>
                <MypageNavigateModal
                    parentUrl="/staff"
                    isShow={mypageModal}
                    setIsShow={setMypageModal}
                />
            </div>
        </header>
    );
};

export default Header;
