// training components
import AttendancePlayerEl from 'pages/staff/control/training/diary/[id]/AttendancePlayerEl';

const AttendancePlayer = ({ attendances, playerDiaries = [] }: AttendancePlayerListType) => {
    const diaryMap = new Map(playerDiaries?.map((diary) => [diary.playerId, diary]));
    return (
        <div className="flex h-full border-b border-secondary-lGrayMid">
            <div className="w-[150px] p-[24px] border border-3 shrink-0  bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                훈련 참가 선수
            </div>
            <div className="p-[24px]">
                <div className="grid grid-cols-1 gap-y-[10px] text-context-regular">
                    <div className="flex items-center w-full text-center gap-[10px]">
                        <div className="w-[110px] ">선수</div>
                        <div className="w-[65px] ">훈련태도</div> <div className="w-[65px] ">기술기량</div>
                        <div className="w-[208px] ">잘한 점</div>
                        <div className="w-[208px] ">부족한 점</div>
                        <div className="w-[208px] ">궁금한 점</div>
                        <div className="w-[100px]">본인이 느끼는 훈련 강도</div>
                        <div className="w-[60px] ml-[10px]">상세보기</div>
                    </div>
                    <div className="grid grid-cols-1 gap-y-[10px] h-[730px] overflow-y-scroll py-[5px]">
                        {attendances?.map((el) => {
                            const diary = diaryMap.get(el.playerId);
                            return (
                                <AttendancePlayerEl
                                    key={el.attendanceId}
                                    {...el}
                                    playerDiary={diary} // 병합된 playerDiary를 props로 넘김
                                />
                            );
                        })}
                    </div>
                </div>
                <div className="text-[14px] text-end text-primary-purple mt-[20px]">
                    *전체 선수의 점수는 10점 만점에 5점으로 기본 세팅 되어있습니다. 훈련 중 기억에 남았던 선수의 점수를
                    상향/하향 조정해 보세요!
                </div>
            </div>
        </div>
    );
};

export default AttendancePlayer;
interface AttendancePlayerType {
    attendanceId: number;
    playerId: number;
    playerName: string;
    uniformNumber: number;
    attendanceStatus: 'PARTICIPANT' | 'ABSENT';
    trainingAttitudeScore: number;
    technicalSkillScore: number;
}
interface PlayerDiaries {
    playerDiaryId: number;
    goodPoints: null | number;
    lackingPoints: null | number;
    personalTrainingIntensity: null | number;
    playerId: number;
    playerName: string;
    questionsPoints: null | number;
}
interface AttendancePlayerListType {
    attendances: AttendancePlayerType[];
    playerDiaries: PlayerDiaries[];
}
