import React from 'react'; // ✅ 꼭 필요합니다!
// hooks
import { useEffect, useState } from 'react';
// kakao
import { Map, MapMarker } from 'react-kakao-maps-sdk';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
// common
interface SearchMapType {
    isShow: boolean;
    setIsShow: (show: boolean) => void;
    setSelectAddress?: (address: any) => void;
}
export interface MarkerType {
    place_name: string;
    road_address_name: string;
}
const SearchMap = ({ setIsShow, setSelectAddress }: SearchMapType) => {
    const { kakao } = window;

    const [keyword, setKeyword] = useState('');
    const [serchKeyword, setSerchKeyword] = useState('');
    const [info, setInfo] = useState();
    const [markers, setMarkers] = useState<MarkerType[]>([]);
    const [selectMarker, setSelectMarker] = useState<MarkerType>();

    const searchFunc = () => {
        setSerchKeyword(keyword);
    };

    const saveAddress = () => {
        if (setSelectAddress) setSelectAddress(selectMarker);
        setIsShow(false);
    };
    useEffect(() => {
        if (!kakao || !serchKeyword) return;

        const ps = new kakao.maps.services.Places();
        const geocoder = new kakao.maps.services.Geocoder();
        const bounds = new kakao.maps.LatLngBounds();

        let combinedMarkers: MarkerType[] = [];

        // 1️⃣ 장소 검색 (keywordSearch)
        ps.keywordSearch(serchKeyword, (placeData, placeStatus) => {
            if (placeStatus === kakao.maps.services.Status.OK && placeData) {
                const placeMarkers = placeData.map((item: any) => {
                    bounds.extend(new kakao.maps.LatLng(item.y, item.x));
                    return {
                        place_name: item.place_name,
                        road_address_name: item.road_address_name || item.address_name,
                    };
                });

                combinedMarkers = [...combinedMarkers, ...placeMarkers];

                // 2️⃣ 주소 검색 (addressSearch)
                geocoder.addressSearch(serchKeyword, (addrData, addrStatus) => {
                    if (addrStatus === kakao.maps.services.Status.OK && addrData) {
                        const addrMarkers = addrData.map((item: any) => {
                            bounds.extend(new kakao.maps.LatLng(item.y, item.x));
                            return {
                                place_name: item.address.address_name,
                                road_address_name: item.road_address?.address_name || item.address.address_name,
                            };
                        });

                        combinedMarkers = [...combinedMarkers, ...addrMarkers];

                        setMarkers(combinedMarkers);
                    } else {
                        setMarkers(combinedMarkers); // 주소검색 결과 없음 → 장소검색만
                    }
                });
            } else {
                // 장소검색 결과 없음 → 주소검색만
                geocoder.addressSearch(serchKeyword, (addrData, addrStatus) => {
                    if (addrStatus === kakao.maps.services.Status.OK && addrData) {
                        const addrMarkers = addrData.map((item: any) => {
                            bounds.extend(new kakao.maps.LatLng(item.y, item.x));
                            return {
                                place_name: item.address.address_name,
                                road_address_name: item.road_address?.address_name || item.address.address_name,
                            };
                        });

                        setMarkers(addrMarkers);
                    } else {
                        setMarkers([]); // 완전 검색 실패
                    }
                });
            }
        });
    }, [serchKeyword]);

    return (
        <div>
            <div className="fixed top-0 left-0 z-10 flex items-center justify-center w-screen h-screen bg-[#00000050]">
                <div
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            searchFunc();
                        }
                    }}
                    className="bg-primary-white w-[540px] h-[548px] py-[50px]  px-[60px] rounded-[10px]"
                >
                    <div className="flex items-center text-secondary-lGrayDark mb-[10px] text-context-bold">
                        <button onClick={() => setIsShow(false)}>
                            <CircleArrowIcon />
                        </button>
                        <div>주소 검색</div>
                    </div>

                    <div className="flex gap-[5px] relative">
                        <input
                            type="text"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                            placeholder="주소를 검색해주세요"
                            className="inputDefaultStyle inputCommonStyle"
                        />
                        <button
                            type="button"
                            onClick={() => searchFunc()}
                            className="absolute right-[20px] top-[11px]"
                        >
                            <SearchIcon />
                        </button>
                    </div>
                    <div className={`mt-[22px] h-[300px] ${markers.length > 0 && 'overflow-y-scroll pb-[10px]'}`}>
                        <div className="flex items-center text-secondary-lGrayDark mb-[10px] text-context-bold px-[2px]">
                            검색 결과
                        </div>
                        {markers.map((marker, idx) => (
                            <div
                                onClick={() => setSelectMarker(marker)}
                                key={idx}
                                className={`   ${
                                    idx % 2 === 0 ? 'p-[12px]' : ' bg-secondary-mGrayLight p-[12px] h-[65px]'
                                } rounded-[8px] group ${
                                    selectMarker === marker && 'border border-primary-purple w-full p-[12px]  h-[65px]'
                                }`}
                            >
                                <div>
                                    <div
                                        className={`text-context-bold ${
                                            selectMarker === marker && 'text-primary-purple'
                                        } `}
                                    >
                                        {marker.place_name}
                                    </div>
                                    <div className="mt-1 text-label-regular">
                                        {marker.road_address_name
                                            ? marker.road_address_name
                                            : '정확한 주소가 없으면 선택하실 수 없습니다.'}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={selectMarker && saveAddress}
                        className=" mt-[20px] w-full p-[12px] rounded-[8px] bg-primary-purple text-primary-white text-context-bold"
                    >
                        주소추가
                    </button>
                </div>
            </div>
        </div>
    );
};
export default SearchMap;
