// hooks
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axiosInstance';
// commons
import CustomTable from 'components/table/CustomTable';
import ControlSeasonSubHeader from 'pages/player/control/main/season/ControlSeasonSubHeader';
import EmptyBar from 'components/emptyBar';
// utils
import { ENDPOINT_MANAGEMENT_PROFILE, ENDPOINT_MATCH_RECORD } from 'utils/consts/apiEndpoint';
import { matchTypeMatcher, resultMatcher, formatDateTimeMatcher } from 'utils/matchers/reportMatcher';

// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';
// redux
import { RootState } from 'store'; // Redux Store 타입 정의
import { useSelector } from 'react-redux';
const ControlMainSeason = () => {
    const { ref, inView } = useInView();
    const columns = [
        { key: 'summary', label: '', sortable: false },
        { key: 'position', label: '포지션', sortable: false },
        { key: 'playingTime', label: '출전 시간', sortable: false },
        { key: 'goals', label: '득점', sortable: false },
        { key: 'assists', label: '도움', sortable: false },
        { key: 'rating', label: '평점', sortable: false },
        { key: 'managerComment', label: '코멘트', sortable: false },
    ];
    const { id } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [searchParams] = useSearchParams();
    const curYear = new Date().getFullYear();
    const [season, setSeason] = useState<string>(`${curYear || 2025}`);
    const getMyProfile = useApiQuery(['getMyProfile', `${id}`], ENDPOINT_MANAGEMENT_PROFILE.my, 'get', {
        enabled: !!id,
    });

    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, status } = useInfiniteQuery({
        queryKey: ['getMatchRecord', id, season],
        queryFn: ({ pageParam = 1 }) =>
            axiosInstance
                .get(`${ENDPOINT_MATCH_RECORD.season(`${id}`, `${season}`)}?page=${pageParam}&size=10`)
                .then((response) => response.data),
        initialPageParam: 1,
        getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
    });

    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage]);

    const flattenedRows = data?.pages.flatMap((page) => page.data) || [];
    const tournamentStyle =
        'min-w-[49px] px-[12px] h-[29px] rounded-[80px] text-[14px] font-bold bg-status-correct flex justify-center items-center';
    const leagueStyle =
        'min-w-[49px] px-[12px] h-[29px] rounded-[80px] text-[14px] font-bold bg-primary-green flex justify-center items-center';
    const friendStyle =
        'min-w-[49px] px-[12px] h-[29px] rounded-[80px] text-[14px] font-bold bg-status-yellow flex justify-center items-center';
    return (
        <div className="wrapper">
            <ControlSeasonSubHeader
                season={season}
                setSeason={setSeason}
            />

            {/* 경력정보 */}

            <div className="flex gap-[20px]">
                <div className="w-[335px] h-fit bg-primary-white rounded-[10px] py-[30px] px-[28px]">
                    <div className="flex justify-center items-center rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight m-auto">
                        <img
                            src={getMyProfile?.data?.playerInfo?.profileImageUrl || graySymbol}
                            alt="graySymbol"
                            className={`${
                                getMyProfile?.data?.playerInfo?.profileImageUrl
                                    ? 'w-[230px] h-[280px] object-cover rounded-[8px]'
                                    : 'w-[80px]'
                            } cursor-pointer`}
                        />
                    </div>
                    <div className="flex gap-[10px] items-center mt-[30px]">
                        <div className="text-title-small-bold">{getMyProfile?.data?.playerInfo?.name}</div>
                        <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
                            등번호 {getMyProfile?.data?.playerInfo?.uniformNumber}
                        </span>
                    </div>
                    <div className="h-[240px] flex flex-col items-start justify-center gap-[20px] m-auto ">
                        <div className="flex ">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="flex  py-[8px]">
                                <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686] ">
                                    포지션
                                </div>
                                <div className="flex gap-[5px] flex-wrap mr-[10px] font-bold ">
                                    {getMyProfile?.data?.playerInfo?.positions?.map((el: string) => (
                                        <span
                                            key={el}
                                            className="rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold bg-[#d2d2d2] text-[#111111]"
                                        >
                                            {el}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">신장</div>
                            <div className="text-title-small-bold mr-[10px] font-bold">
                                {getMyProfile?.data?.playerInfo?.height}
                            </div>
                            <div className="text-[22px] text-[#7c7c7c]">cm</div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">몸무게</div>
                            <div className="text-title-small-bold mr-[10px] font-bold">
                                {' '}
                                {getMyProfile?.data?.playerInfo?.weight}
                            </div>
                            <div className="text-[22px] text-[#7c7c7c]">kg</div>
                        </div>
                        <div className="h-[38px] flex items-center">
                            <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                            <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">주발</div>
                            <div
                                className={`flex items-center text-subtitle-large-regular ${
                                    getMyProfile?.data?.playerInfo?.majorFoot === 'RIGHT'
                                        ? 'text-secondary-lGrayMid'
                                        : 'text-primary-purple'
                                }`}
                            >
                                <LFootIcon />
                                <div>L</div>
                            </div>
                            <div className="w-[10px]"></div>
                            <div
                                className={`flex items-center text-subtitle-large-regular ${
                                    getMyProfile?.data?.playerInfo?.majorFoot === 'LEFT'
                                        ? 'text-secondary-lGrayMid'
                                        : 'text-primary-purple'
                                }`}
                            >
                                <div>R</div>

                                <RFootIcon />
                            </div>
                        </div>
                    </div>
                </div>
                {/* overflow-y-scroll */}
                <div>
                    {flattenedRows?.length > 0 && (
                        <div className="w-[1045px] flex flex-col gap-[20px]">
                            {flattenedRows?.map((rows: SeasonType) =>
                                rows?.competitions?.map((competitions: competitionsType) => {
                                    const { competitionName, matchType, matches } = competitions || {};
                                    return (
                                        <div className="w-[1045px] p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                                            <div className="text-subtitle-medium-bold h-[40px] gap-[10px] flex items-center">
                                                <div>{competitionName}</div>
                                                <span
                                                    className={`  ${
                                                        matchType === 'LEAGUE'
                                                            ? leagueStyle
                                                            : matchType === 'TOURNAMENT'
                                                            ? tournamentStyle
                                                            : friendStyle
                                                    }`}
                                                >
                                                    {matchTypeMatcher(matchType)}
                                                </span>
                                            </div>
                                            <CustomTable
                                                columns={columns}
                                                rows={handleRows(matches)}
                                                equalWidth={false}
                                                rowHeight={'h-[36px]'}
                                            />
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    )}
                    <div className="w-[1045px] p-[30px] rounded-[10px] flex flex-col gap-[20px]">
                        {isFetching && !isFetchingNextPage && (
                            <div className="py-2 text-center text-secondary-lGrayDark">Loading...</div>
                        )}
                        {isFetchingNextPage && (
                            <div className="py-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                        )}
                        {!isFetching && !isFetchingNextPage && !hasNextPage && (
                            <div className="py-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                        )}
                    </div>
                </div>
            </div>

            <div
                ref={ref}
                className="h-4"
            />

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default ControlMainSeason;

interface SeasonType {
    competitions: competitionsType[];
}

interface competitionsType {
    competitionName: string;
    matchType: string;
    matches: [
        {
            matchReportId: string;
            matchDateTime: string;
            opponentTeam: string;
            teamScore: string;
            opponentScore: string;
            matchResult: string;
            recordId: string;
            playingTime: string;
            playerId: string;
            playerName: string;
            position: string;
            goals: string;
            assists: string;
            rating: string;
            managerComment: string;
            competitionName: string;
            matchType: string;
        }
    ];
}
export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        summary: `${row?.opponentTeam}(${row?.teamScore}:${row?.opponentScore} ${resultMatcher(
            row?.matchResult
        )}, ${formatDateTimeMatcher(row?.matchDateTime)})`,
        position: row?.position,
        playingTime: `${row?.playingTime} 분`,
        goals: row?.goals,
        assists: row?.assists,
        rating: row?.rating,
        managerComment: row?.managerComment,
    }));
};
