import * as fabric from 'fabric';
import { MutableRefObject } from 'react';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';
export const useLineTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const addLine = (
        type: 'straight' | 'curve' | 'oneCurve' | 'diagram',
        colorState: string = '#ffffff',
        lineWidthState: number = 2,
        shapeType: 'circle' | 'rect' = 'circle', // only used for diagram
        padding: number = 10 // 새로운 패딩 파라미터 추가
    ) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;

        let shape: fabric.Object | fabric.Group;

        if (type === 'straight') {
            const backgroundHeight = 40;
            const centerY = backgroundHeight / 2;
            const path = new fabric.Path(`M0 ${centerY} H200`, {
                stroke: colorState,
                strokeWidth: lineWidthState,
                fill: '',
                left: 0,
                top: 0,
            });
            const background = new fabric.Rect({
                width: 200,
                height: backgroundHeight,
                left: -padding / 2,
                top: -backgroundHeight / 2,
                fill: 'transparent',
                selectable: false,
                evented: false,
            });

            shape = new fabric.Group([background, path], {
                left: 150,
                top: 150,
                hasControls: true,
                hasBorders: true,
                selectable: true,
            });

            shape.set({
                borderColor: '#ac14ff',
                cornerColor: '#ffffff',
                cornerStrokeColor: '#ac14ff',
                cornerSize: 7,
                transparentCorners: false,
                borderScaleFactor: 2,
            });
        } else if (type === 'curve') {
            shape = new fabric.Path('M0 30 Q20 0, 40 30 Q60 60, 80 30 Q100 0, 120 30 Q140 60, 160 30 Q180 0, 200 30', {
                stroke: colorState,
                strokeWidth: lineWidthState,
                fill: '',
                left: 150,
                top: 150,
                padding: padding, // 패딩 추가
            });
        } else if (type === 'oneCurve') {
            shape = new fabric.Path('M0 30 Q100 0, 200 30', {
                stroke: colorState,
                strokeWidth: lineWidthState,
                fill: '',
                left: 150,
                top: 150,
                padding: padding, // 패딩 추가
            });
        } else if (type === 'diagram') {
            if (shapeType === 'circle') {
                shape = new fabric.Circle({
                    radius: 30,
                    left: 150,
                    top: 150,
                    fill: 'transparent',
                    stroke: colorState,
                    strokeWidth: lineWidthState,
                    padding: padding, // 패딩 추가
                });
            } else {
                shape = new fabric.Rect({
                    width: 60,
                    height: 60,
                    left: 150,
                    top: 150,
                    fill: 'transparent',
                    stroke: colorState,
                    strokeWidth: lineWidthState,
                    padding: padding, // 패딩 추가
                });
            }
        } else return;

        applyFabricDefaultsToObject(shape); // shape: fabric.Object 또는 fabric.Group
        canvas.add(shape);
        canvas.setActiveObject(shape);
        canvas.renderAll();
        if (setUnSaved) setUnSaved(true);
    };

    return {
        addLine,
    };
};
