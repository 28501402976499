// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// commons
import Dropdown from 'components/dropdown/Default';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
// tuils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
const GoalsElement = ({ goalId, goalTime, scorer, assistProvider, lineupOptions }: GoalsElementType) => {
    const { id } = useParams();

    const [newSoccerId, setNewSoccerId] = useState(scorer?.playerId);
    const [newAssistProviderId, setNewAssistProviderId] = useState(assistProvider?.playerId);
    const [newGoalTime, setNewGoalTime] = useState(goalTime);

    // useApiMutation
    const deleteGoal = useApiMutation(ENDPOINT_REPORT.goalId(id, goalId), 'delete', {
        onSuccess: (data) => {
            showToast('득점 정보를 삭제하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
        },
    });
    const patchGoal = useApiMutation(ENDPOINT_REPORT.goalId(id, goalId), 'patch', {
        onSuccess: (data) => {
            showToast('득점 정보를 수정하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
            setNewSoccerId(scorer?.playerId);
            setNewAssistProviderId(assistProvider?.playerId);
            setNewGoalTime(goalTime);
        },
    });
    const editGoal = () => {
        if (newGoalTime && newSoccerId && newAssistProviderId && !patchGoal?.isPending) {
            showToast('교체 정보를 수정하는중입니다', 'info');
            patchGoal.mutate({
                scorerId: newSoccerId,
                assistProviderId: newAssistProviderId,
                goalTime: newGoalTime,
            });
        }
    };
    useEffect(() => {
        if (newGoalTime && newSoccerId && newAssistProviderId && !patchGoal?.isPending) {
            if (
                newGoalTime === goalTime &&
                newSoccerId === scorer?.playerId &&
                newAssistProviderId === assistProvider?.playerId
            ) {
                return;
            } else {
                editGoal();
            }
        }
    }, [newGoalTime, newSoccerId, newAssistProviderId]);
    const removeGoal = () => {
        if (!deleteGoal?.isPending) {
            showToast('득점 정보를 삭제하는 중입니다', 'info');
            deleteGoal.mutate({});
        }
    };
    return (
        <div className="">
            <div className="flex gap-[10px]">
                <div className="w-[110px] rounded-[8px] bg-primary-black flex  text-white items-center">
                    <input
                        type="text"
                        value={newGoalTime}
                        onChange={(e) => setNewGoalTime(e.target.value)}
                        className="px-[12px] w-full h-[44px] text-white bg-transparent focus:outline-none"
                        style={{
                            colorScheme: 'dark', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                        }}
                    />
                    <div className="pr-[10px] text-white">분</div>
                </div>

                <Dropdown
                    placeholder="득점자"
                    options={lineupOptions?.filter(
                        (el: { label: string; value: string }) => el.value !== newAssistProviderId
                    )}
                    value={newSoccerId}
                    onChange={setNewSoccerId}
                    className="w-[381px]"
                />

                <Dropdown
                    placeholder="어시스트"
                    options={lineupOptions?.filter((el: { label: string; value: string }) => el.value !== newSoccerId)}
                    value={newAssistProviderId}
                    onChange={setNewAssistProviderId}
                    className="w-[381px]"
                />
                <button
                    onClick={removeGoal}
                    className="text-status-error p-[6px]"
                >
                    <DeleteIcon />
                </button>
            </div>
        </div>
    );
};

export default GoalsElement;

export interface GoalsElementType {
    goalId: string;
    goalTime: string;
    scorer: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    assistProvider: {
        playerId: string;
        playerName: string;
        uniformNumber: string;
        position: string;
    };
    lineupOptions: { label: string; value: string }[];
}
