// hooks
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// staff component
import TeamDeailSubHeader from 'pages/admin/mypage/teamDetail/TeamDeailSubHeader';
import MeasurementDataSummary from 'pages/staff/measurement/control/MeasurementDataSummary';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { majorFootMatcher, ageMatcher } from 'utils/matchers/userInfoMatcher';
import { ENDPOINT_MASUREMENT_STATS } from 'utils/consts/apiEndpoint';
import { playerMeasurementSortMatcher } from 'utils/matchers/sortInfoMatcher';
// components
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import Tooltip from 'components/tooltip';
const TeamDetail = () => {
    const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();

    const { teamId = '' } = useParams();
    const handleNavigate = (navigateUrl: string) => {
        handleParamNavigate({ teamId: teamId }, false, navigateUrl);
    };
    // react qury

    const getTeamMeasurement = useApiQuery(
        ['getTeamMeasurement', teamId],
        ENDPOINT_MASUREMENT_STATS.team(teamId),
        'get',
        { enabled: !!teamId }
    );
    const { teamInfo, rankingInfo, skillAnalysis, topPlayers, lastCalculatedAt } = getTeamMeasurement?.data || {};
    const [sortType, setSortType] = useState('');
    const getMeasurementPlayers = useApiQuery(
        ['getMeasurementPlayers', teamId, sortType],
        ENDPOINT_MASUREMENT_STATS.players(teamId, { sortType: sortType }),
        'get',
        { enabled: !!teamId }
    );

    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = playerMeasurementSortMatcher(key, sort);
        setSortType(customizedSort);
    };
    const columns = [
        { key: 'uniformNumber', label: '등번호', sortable: true },
        { key: 'playerName', label: '이름' },
        { key: 'age', label: '나이', sortable: true },
        { key: 'overallScore', label: '종합점수' },
        { key: 'majorFoot', label: '주 발' },
        {
            key: 'dribbleOneScore',
            label: (
                <div className="group">
                    {/* 툴팁 */}
                    <div className="fixed hidden group-hover:block mt-[-65px] ml-[-40px]  z-[10]">
                        <Tooltip tip="에잇라운드 상세 정보 조회" />
                    </div>
                    {/* 버튼 */}
                    <button onClick={() => handleNavigate(`/admin/control/teamDetail/dribbleOne`)}>에잇라운드</button>
                </div>
            ),
            sortable: true,
        },
        {
            key: 'dribbleTwoScore',
            label: '드리블2',
            // <button onClick={() => handleNavigate('/staff/measurement/control/dribble')}></button>,
            sortable: true,
        },
        {
            key: 'passOneScore',
            label: '패스1',
            // <button onClick={() => handleNavigate('/staff/measurement/control/pass')}></button>,
            sortable: true,
        },
        {
            key: 'passTwoScore',
            label: '패스2',
            // <button onClick={() => handleNavigate('/staff/measurement/control/pass')}></button>,
            sortable: true,
        },
        {
            key: 'shootingOneScore',
            label: '슈팅1',
            // <button onClick={() => handleNavigate('/staff/measurement/control/shooting')}></button>,
            sortable: true,
        },
        {
            key: 'shootingTwoScore',
            label: '슈팅2',
            // <button onClick={() => handleNavigate('/staff/measurement/control/shooting')}></button>,
            sortable: true,
        },
        {
            key: 'firstTouchScore',
            label: '퍼스트터치',
            // <button onClick={() => handleNavigate('/staff/measurement/control/firstTouch')}></button>,
            sortable: true,
        },
    ];
    const customizedRows = handleRows(getMeasurementPlayers?.data);

    return (
        <div className="wrapper">
            {getMeasurementPlayers?.isPending && <SoccerBallLodaing />}
            <TeamDeailSubHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="flex flex-col gap-[20px]">
                <MeasurementDataSummary
                    rankingInfo={rankingInfo}
                    lastCalculatedAt={lastCalculatedAt}
                    topPlayers={topPlayers}
                    skillAnalysis={skillAnalysis}
                />
                <div className="bg-primary-white rounded-[10px] p-[30px] flex flex-col gap-[10px]">
                    <div className="text-subtitle-medium-bold">점수 확인</div>
                    <CustomTable
                        columns={columns}
                        rows={customizedRows}
                        sortInfoFunc={handleSort}
                    />
                    {customizedRows?.length < 1 && (
                        <div className="text-center text-secondary-lGrayDark py-[10px]">측정 기록이 없습니다.</div>
                    )}
                </div>
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default TeamDetail;

export const statsData = {
    physicalStats: {
        averageHeight: 169.33333333333334,
        averageWeight: 53.333333333333336,
    },
    seasonStats: {
        wins: 0,
        draws: 0,
        losses: 1,
        winRate: 0,
        winRateChange: 0,
    },
    teamInfo: {
        teamId: 1,
        teamName: '갤로핑FC',
        ageGroup: 'U10',
    },
};

export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        uniformNumber: row?.uniformNumber,
        playerName: row?.playerName,
        age: `${row?.age}세 (${ageMatcher(row?.age)})`,
        majorFoot: majorFootMatcher(row?.majorFoot),
        overallScore: row?.overallScore?.toFixed(2),
        dribbleOneScore: row?.dribbleOneScore,
        dribbleTwoScore: row?.dribbleTwoScore,
        firstTouchScore: row?.firstTouchScore,
        passOneScore: row?.passOneScore,
        passTwoScore: row?.passTwoScore,
        shootingOneScore: row?.shootingOneScore,
        shootingTwoScore: row?.shootingTwoScore,
    }));
};
