const LineupEditElement = ({
    position,
    playerName,
    uniformNumber,
}: {
    position: string;
    playerName: string;
    uniformNumber: string;
}) => {
    return (
        <div className="flex gap-[10px]">
            <div className="w-[100px]">
                <input
                    value={position}
                    readOnly
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
            <div className="w-[390px]">
                <input
                    value={`${uniformNumber}. ${playerName}`}
                    readOnly
                    className="inputDefaultStyle inputCommonStyle"
                />
            </div>
        </div>
    );
};

export default LineupEditElement;

interface LineupType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string;
}
interface inactiveLineup {
    playerId: string;
    playerName: string;
    uniformNumber: string;
    positions: string[];
}
export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};
