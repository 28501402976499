// hooks
import { useEffect, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
import SortableSessionItem from 'pages/staff/control/training/diary/[id]/SortableSessionItem';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { ENDPOINT_TEAM_TRAINING_SESSION } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// dnd
import { DndContext, PointerSensor, useSensor, useSensors, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
// diary component
import SessionAddModal from 'pages/staff/control/training/diary/[id]/SessionAddModal';
import TrainingCanvas from 'pages/staff/control/training/components/fabricCanvas/TrainingCanvas';

const TrainingSession = ({
    saveCommand,
    setSaveCommand,
}: {
    saveCommand: boolean;
    setSaveCommand: (command: boolean) => void;
}) => {
    const { id } = useParams();
    // useApiQuery
    const getDiarySession = useApiQuery(
        ['getDiarySession', `${id}`],
        ENDPOINT_TEAM_TRAINING_SESSION.session(id),
        'get',
        {
            enabled: !!id,
        }
    );
    const [isFirstRendering, setIsFirstRendering] = useState(true);
    const [focusedSessionState, setFocuedSessionState] = useState<number | null>(null);
    const [unSaved, setUnSaved] = useState(false);
    const [focusedSessionData, setFocusedSessionData] = useState<any>(null);
    const [editSessionName, setEditSessionName] = useState<string>('');
    const [editSessionPoint, setEditSessionPoint] = useState<string>('');
    const [sessionSequence, setSessionSequence] = useState<SessionItem[]>([]);
    const [curDragedSession, setDragedPocusedSession] = useState<number | null>(null);
    const [deleteModalState, setDeleteModalState] = useState<{
        isShow: boolean;
        title: React.ReactNode;
        activeFunc: (data?: any) => void;
        deleteBtnText?: string;
        isBtnReverse?: boolean;
    }>({
        isShow: false,
        title: null,
        activeFunc: () => {},
    });
    // init
    useEffect(() => {
        if (isFirstRendering && getDiarySession?.data && getDiarySession?.data[0]) {
            setFocuedSessionState(getDiarySession?.data[0].id);
            setFocusedSessionData(getDiarySession?.data[0]);
            setEditSessionName(getDiarySession?.data[0]?.name);
            setEditSessionPoint(getDiarySession?.data[0]?.sessionPoint);
            setUnSaved(false);
            setIsFirstRendering(false);
        }
    }, [getDiarySession?.data]);
    const patchSessionSequence = useApiMutation(
        ENDPOINT_TEAM_TRAINING_SESSION.sequence(`${curDragedSession}`),
        'patch',
        {
            onSuccess: (data) => {
                showToast('세션 순서가 변경되었습니다.', 'success');
            },
            onError: (error) => {
                // 에러 메시지 추출
                const errorMessage = error?.data?.detailedMessage || error || '세션 순서 변경 실패했습니다.';
                showToast(`${errorMessage}`, 'error');
            },
            enabled: !!curDragedSession,
        }
    );
    const updateSessionSequence = (newSequence: number) => {
        if (!patchSessionSequence.isPending) {
            patchSessionSequence.mutate({
                newSequence,
            });
        }
    };
    useEffect(() => {
        if (getDiarySession?.data) setSessionSequence(getDiarySession?.data);
    }, [getDiarySession?.data]);
    // 드래그 이동
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );
    const handleDragStart = (event: any) => {
        const { active } = event;
        if (!patchSessionSequence?.isPending && active?.id) {
            setDragedPocusedSession(active.id);
        }
    };
    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (!patchSessionSequence?.isPending) {
            if (active.id !== over.id) {
                setSessionSequence((items) => {
                    const oldIndex = items.findIndex((item) => item.id === active.id);
                    const newIndex = items.findIndex((item) => item.id === over.id);
                    const reorderedItems = arrayMove(items, oldIndex, newIndex);
                    // ✅ 실제로 바뀐 세션 ID의 순서를 update
                    const draggedItem = reorderedItems[newIndex];
                    if (draggedItem.sequence !== newIndex + 1) {
                        updateSessionSequence(newIndex + 1);
                    }
                    return reorderedItems;
                });
            }
        }
    };
    const deleteSessionSequence = useApiMutation(
        ENDPOINT_TEAM_TRAINING_SESSION.sessionId(`${focusedSessionState}`),
        'delete',
        {
            onSuccess: (data) => {
                showToast('세션이 삭제되었습니다.', 'success');
                setFocuedSessionState(null);
                setFocusedSessionData(null);
                setEditSessionName('');
                setEditSessionPoint('');
            },
            onError: (error) => {
                // 에러 메시지 추출
                const errorMessage = error?.data?.detailedMessage || error || '세션이 삭제 실패했습니다.';
                showToast(`${errorMessage}`, 'error');
            },
        }
    );
    const removeSessionSequence = () => {
        if (!deleteSessionSequence?.isPending) {
            showToast('세션을 삭제하는 중입니다.', 'info');
            deleteSessionSequence.mutate({});
        }
    };
    const handleTabMove = (data: any) => {
        setFocuedSessionState(data.id);
        setFocusedSessionData(data);
        setEditSessionName(data?.name);
        setEditSessionPoint(data?.sessionPoint);
        setUnSaved(false);
    };
    const openNavigateModal = (el: any) => {
        setDeleteModalState({
            isShow: true,
            title: (
                <div>
                    <div className="text-center"> 탭 이동시 편집중인 훈련세션은 저장해주세요.</div>
                    <div className="font-bold text-center text-status-error">복구할 수 없습니다.</div>
                </div>
            ),
            activeFunc: () => {
                handleTabMove(el);
            },
            deleteBtnText: '삭제 및 이동',
        });
    };
    const openDeleteModal = () => {
        setDeleteModalState({
            isShow: true,
            title: (
                <div>
                    해당 세션 삭제시, <span className="font-bold text-status-error">복구할 수 없습니다.</span>
                </div>
            ),
            activeFunc: () => {
                removeSessionSequence();
            },
        });
    };

    return (
        <div>
            <div className="flex border-b border-secondary-lGrayMid">
                <ConfirmModal
                    isShow={deleteModalState.isShow}
                    setIsShow={(v) => setDeleteModalState((prev) => ({ ...prev, isShow: v }))}
                    title={deleteModalState.title}
                    activeFunc={deleteModalState.activeFunc}
                    deleteBtnText={deleteModalState.deleteBtnText}
                    isBtnReverse={deleteModalState.isBtnReverse}
                />
                <div className="w-[150px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    훈련 세션
                </div>
                <div className="p-[24px] ">
                    <div className="flex items-center justify-between w-[1142px]">
                        <div className="w-[850px] flex gap-[8px] items-end flex-wrap">
                            <DndContext
                                sensors={sensors}
                                collisionDetection={closestCenter}
                                onDragStart={handleDragStart}
                                onDragEnd={handleDragEnd}
                            >
                                <SortableContext
                                    items={sessionSequence.map((item) => item.id)}
                                    strategy={verticalListSortingStrategy}
                                >
                                    {sessionSequence?.map((el: any, idx: number) => (
                                        <div
                                            onClick={() =>
                                                focusedSessionState !== el.id && unSaved
                                                    ? openNavigateModal(el)
                                                    : handleTabMove(el)
                                            }
                                            key={el.id}
                                            className="flex flex-col gap-[4px]"
                                        >
                                            <div className="text-label-regular text-secondary-mGrayDark">{`순서 ${
                                                idx + 1
                                            }`}</div>
                                            <div className="flex gap-[8px] items-center">
                                                <SortableSessionItem
                                                    key={el.id}
                                                    session={el}
                                                    index={idx}
                                                    isActive={focusedSessionState === el.id}
                                                    onClick={() => handleDragStart(el.id)}
                                                    editSessionName={editSessionName}
                                                    setEditSessionName={setEditSessionName}
                                                />
                                                {idx !== sessionSequence?.length - 1 && (
                                                    <CircleArrowIcon className="rotate-180 text-secondary-mGrayDark" />
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </SortableContext>
                            </DndContext>
                            <SessionAddModal
                                modalBtn={
                                    <div className="w-[126px] text-context-regular h-[56px] rounded-[8px] text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center">
                                        <PlusIcon />
                                        {'순서추가'}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <TrainingCanvas
                            focusedSessionState={focusedSessionState}
                            focusedSessionData={focusedSessionData}
                            openDeleteModal={openDeleteModal}
                            editSessionName={editSessionName}
                            editSessionPoint={editSessionPoint}
                            setUnSaved={setUnSaved}
                            saveCommand={saveCommand}
                            setSaveCommand={setSaveCommand}
                        />
                        <div className="mt-[10px] text-end text-primary-purple">
                            * 훈련세션이 보이지 않을 시, 새로고침해주세요
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex border-b border-secondary-lGrayMid">
                <div className="w-[150px] p-[24px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    세션 포인트
                </div>
                <div className="p-[24px] w-full ">
                    {focusedSessionData && (
                        <textarea
                            className="w-full resize-none focus:outline-none text-context-regular text-secondary-dGrayMid "
                            placeholder="세션 포인트를 입력해주세요"
                            value={editSessionPoint}
                            onChange={(e) => setEditSessionPoint((e: any) => e.target.value)}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default TrainingSession;

interface SessionItem {
    createdAt: string;
    id: number;
    name: string | null;
    sequence: number | null;
    sessionPoint: string | null;
    sourceTemplateId: number | null;
    tacticalBoardJson: string | null;
}
