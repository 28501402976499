import { ball_black, post } from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import { useState } from 'react';
// assets
import {
    Diagram,
    TriangleTip,
} from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';

interface DiagramSelectorProps {
    state: string;
    isActive?: boolean;
    addLine: (
        type: 'straight' | 'curve' | 'oneCurve' | 'diagram',
        colorState: string,
        lineWidthState: number,
        shapeType: 'circle' | 'rect'
    ) => void;
    onClick?: () => void;
    lineWidthState: number;
}

const DiagramSelector = ({ state, isActive, onClick, addLine, lineWidthState }: DiagramSelectorProps) => {
    const [isSelectorShow, setSelectorShow] = useState(false);
    const [shapeType, setShapeType] = useState('redCircle');
    const handleOpenModal = () => {
        setSelectorShow(true);
        if (onClick) onClick(); //
    };
    return (
        <div className="relative ">
            <button
                onClick={handleOpenModal}
                className={`rounded-[8px] bg-[#4E4E4E] w-[56px] h-[30px] flex justify-center items-center ${
                    isActive ? 'text-primary-green border border-primary-green' : 'text-white'
                }`}
            >
                <Diagram className="" />
            </button>
            {isSelectorShow && (
                <div
                    className="fixed left-0 top-0 z-[0]  w-full h-full"
                    onClick={() => setSelectorShow(false)}
                ></div>
            )}
            {isSelectorShow && (
                <div className="absolute z-[10] gap-[8px] w-[208px] grid grid-cols-3  h-[272px] p-[12px] bg-[#212121] rounded-[8px] top-[-335px] left-[-75px]">
                    {imageList?.map((el: any) => (
                        <div
                            key={el.shapeType}
                            onClick={() => {
                                setShapeType(el.shapeType);
                                setSelectorShow(false);
                                addLine('diagram', el.color, lineWidthState, el.shapeType);
                            }}
                            style={{ color: el.color }} // ✅ 여기!
                            className={`
                ${shapeType === el.shapeType ? 'border border-primary-green' : 'text-white'}
                w-[56px] h-[56px] bg-[#4E4E4E] rounded-[8px] flex justify-center items-center`}
                        >
                            {el.svg}
                        </div>
                    ))}
                </div>
            )}
            {isSelectorShow && (
                <div className="absolute  left-0 flex justify-center w-full  h-fit top-[-80px]">
                    <TriangleTip className="" />
                </div>
            )}
        </div>
    );
};

export default DiagramSelector;

export const CircleIcon = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
    >
        <circle
            cx="20"
            cy="20"
            r="12"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);
export const RectIcon = () => (
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
    >
        <rect
            x="10"
            y="10"
            width="20"
            height="20"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);
export const imageList = [
    {
        title: 'circle',
        shapeType: 'whiteCircle',
        size: 'w-[40px] h-[40px]',
        color: '#ffffff',
        svg: <CircleIcon />,
    },
    {
        title: 'circle',
        shapeType: 'redCircle',
        size: 'w-[40px] h-[40px]',
        color: '#ff1414',
        svg: <CircleIcon />,
    },
    {
        title: 'circle',
        shapeType: 'yellowCircle',
        size: 'w-[40px] h-[40px]',
        color: '#fff95e',
        svg: <CircleIcon />,
    },
    {
        title: 'circle',
        shapeType: 'greenCircle',
        size: 'w-[40px] h-[40px]',
        color: '#08dd0c',
        svg: <CircleIcon />,
    },
    {
        title: 'circle',
        shapeType: 'blueCircle',
        size: 'w-[40px] h-[40px]',
        color: '#218cff',
        svg: <CircleIcon />,
    },
    {
        title: 'circle',
        shapeType: 'pinkCircle',
        size: 'w-[40px] h-[40px]',
        color: '#ff44e9',
        svg: <CircleIcon />,
    },
    {
        title: 'rect',
        shapeType: 'whiteRect',
        size: 'w-[40px] h-[40px]',
        color: '#ffffff',
        svg: <RectIcon />,
    },
    {
        title: 'rect',
        shapeType: 'redRect',
        size: 'w-[40px] h-[40px]',
        color: '#ff1414',
        svg: <RectIcon />,
    },
    {
        title: 'rect',
        shapeType: 'yellowRect',
        size: 'w-[40px] h-[40px]',
        color: '#fff95e',
        svg: <RectIcon />,
    },
    {
        title: 'rect',
        shapeType: 'greenRect',
        size: 'w-[40px] h-[40px]',
        color: '#08dd0c',
        svg: <RectIcon />,
    },

    {
        title: 'rect',
        shapeType: 'blueRect',
        size: 'w-[40px] h-[40px]',
        color: '#218cff',
        svg: <RectIcon />,
    },
    {
        title: 'rect',
        shapeType: 'pinkRect',
        size: 'w-[40px] h-[40px]',
        color: '#ff44e9',
        svg: <RectIcon />,
    },
];
