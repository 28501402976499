// hooks
import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
// common
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { ENDPOINT_TEAM } from 'utils/consts/apiEndpoint';
import { dropDownFormChanger, dropDownFormChangerType } from 'utils/formChanger';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const EventSubHeader = ({ title = '기록', handleNavigate }: { title: string; handleNavigate?: () => void }) => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);

    const handleParamNavigate = useParamNavigate();
    const navigate = useNavigate();
    const curYear = new Date().getFullYear();

    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramYear = searchParams.get('year') || `${curYear}`;

    const [year, setYear] = useState(paramYear);
    const [ageGroup, setAgeGroup] = useState(paramAgeGroup);
    const [ageGroupOpts, setAgeGroupsOpts] = useState<dropDownFormChangerType[]>([]);
    // react useApiQuery
    const { data, isLoading, isSuccess, isError, error, refetch } = useApiQuery(
        ['ageGroup'],
        ENDPOINT_TEAM.staff.team.ageGroups,
        'get'
    );

    useEffect(() => {
        if (isSuccess && data) {
            const { teamName, ageGroups } = data;
            localStorage.setItem('teamName', teamName);
            setAgeGroup(ageGroups[0]);
            const newAgeGroupOpts = dropDownFormChanger(ageGroups);
            setAgeGroupsOpts(newAgeGroupOpts);
        }
    }, [data]);
    useEffect(() => {
        // 그냥 이렇게 해도 파라미터 값만 바뀜
        if (ageGroup)
            handleParamNavigate({
                ageGroup: ageGroup,
            });
    }, [ageGroup]);
    useEffect(() => {
        // 그냥 이렇게 해도 파라미터 값만 바뀜
        if (year)
            handleParamNavigate({
                year: year,
            });
    }, [year]);
    const handleHomeNavigate = () => {
        if (paramAgeGroup && paramYear)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    year: paramYear,
                },
                false,
                `/staff/control/lineUp`
            );
    };

    return (
        <div className="h-[80px]">
            <div className="flex items-center justify-between ">
                <div className="flex flex-col gap-[15px]">
                    <div className="flex items-center gap-[8px]">
                        <button onClick={handleHomeNavigate}>
                            <HomeIcon className="text-white" />
                        </button>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <span className="text-white text-label-regular">기록</span>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <button onClick={() => (handleNavigate ? handleNavigate() : navigate(-1))}>
                            <CircleArrowIcon className="text-white" />
                        </button>
                        <div className="text-[26px] font-bold text-white">{title}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventSubHeader;
