import React, { useState } from 'react';

interface TabProps {
    label: string;
    // content: React.ReactNode;
}

interface TabsProps {
    tabs: TabProps[];
    tabClassName?: string;
    defaultActiveTab?: number;
    onTabChange?: (index: number) => void;
    className?: string;
    colorType?: 'black' | 'green';
}

const Tabs: React.FC<TabsProps> = ({
    tabs,
    tabClassName,
    defaultActiveTab = 0,
    onTabChange,
    className = '',
    colorType = 'black',
}) => {
    const [activeTab, setActiveTab] = useState(defaultActiveTab);
    const blackType = {
        baseBg: 'flex bg-secondary-mGrayLight rounded-[10px]',
        activeTab: 'bg-primary-black text-primary-white text-context-bold',
        defaultTab: 'bg-secondary-mGrayLight text-[#8B8B8B] text-context-regular',
    };
    const greenType = {
        baseBg: 'flex bg-[#272727] rounded-[10px]',
        activeTab: 'bg-primary-green  text-context-bold',
        defaultTab: 'bg-[#272727] text-[#8B8B8B] text-context-regular',
    };
    const currentType = colorType === 'black' ? blackType : colorType === 'green' ? greenType : blackType;
    const handleTabChange = (index: number) => {
        setActiveTab(index);
        onTabChange?.(index);
    };

    return (
        <div>
            <div className={`${className} ${currentType?.baseBg}`}>
                {tabs.map((tab, index) => (
                    <button
                        key={index}
                        onClick={() => handleTabChange(index)}
                        className={` px-[20px] py-[10px] rounded-[10px] ${tabClassName}
              ${activeTab === index ? currentType?.activeTab : currentType?.defaultTab}
              transition-all duration-200
              hover:opacity-80
            `}
                    >
                        {tab.label}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Tabs;
// 사용방법
// const tabData = [
//     {
//         label: '첫 번째 탭',
//         content: <div>첫 번째 탭의 내용</div>,
//     },
//     {
//         label: '두 번째 탭',
//         content: <div>두 번째 탭의 내용</div>,
//     },
//     {
//         label: '세 번째 탭',
//         content: <div>세 번째 탭의 내용</div>,
//     },
// ];
// <Tabs
// tabs={tabData}
// defaultActiveTab={0}
// onTabChange={(index) => console.log(`Selected tab: ${index}`)}
// />
