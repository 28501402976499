// hooks
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// icons
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// api
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import Dropdown from 'components/dropdown/Default';
import { showToast } from 'components/toast/Default';
import LineupEditElement from 'pages/staff/control/schedule/report/read/reportPlayer/LineupEditElement';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { positionShortOptions } from 'utils/consts/userInfo';
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';

const Starting = ({ startingPlayers }: any) => {
    const { id } = useParams();
    return (
        <div className="p-[24px] flex items-center w-full border-b border-secondary-lGrayMid">
            <div className="text-center shrink-0 w-[100px] h-full text-context-regular text-secondary-dGrayMid">
                선발
            </div>
            <div className="flex flex-col gap-[10px]">
                <div className="flex flex-col gap-[10px]">
                    {startingPlayers &&
                        startingPlayers?.map((el: any) => (
                            <LineupEditElement
                                {...el}
                                key={el?.lineupId}
                            />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default Starting;

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr.playerId,
        label: `${arr.uniformNumber}. ${arr.playerName}`,
    }));
};
