import * as fabric from 'fabric';

export const setFabricDefaults = () => {
    const commonStyle = {
        borderColor: '#ac14ff',
        cornerColor: '#ffffff',
        cornerStrokeColor: '#ac14ff',
        cornerSize: 7,
        transparentCorners: false,
        borderScaleFactor: 2,
    };

    // const deleteControl = new fabric.Control({
    //     x: 0.5,
    //     y: -0.5,
    //     offsetY: -10,
    //     offsetX: 10,
    //     cursorStyle: 'pointer',
    //     mouseUpHandler: (eventData, transform) => {
    //         const target = transform.target;
    //         const canvas = target.canvas;
    //         canvas?.remove(target);
    //         canvas?.requestRenderAll();
    //         return true;
    //     },
    //     render: (ctx, left, top) => {
    //         const size = 20;
    //         ctx.save();
    //         ctx.fillStyle = '#ff5b74';
    //         ctx.beginPath();
    //         ctx.arc(left, top, size / 2, 0, 2 * Math.PI, false);
    //         ctx.fill();

    //         ctx.strokeStyle = 'white';
    //         ctx.lineWidth = 2;
    //         ctx.beginPath();
    //         ctx.moveTo(left - 4, top - 4);
    //         ctx.lineTo(left + 4, top + 4);
    //         ctx.moveTo(left + 4, top - 4);
    //         ctx.lineTo(left - 4, top + 4);
    //         ctx.stroke();
    //         ctx.restore();
    //     },
    // });

    const applyDefaults = (fabricClass: any) => {
        const originalInitialize = fabricClass.prototype.initialize;

        fabricClass.prototype.initialize = function (...args: any[]) {
            originalInitialize.call(this, ...args);
            this.set(commonStyle);

            // 삭제 버튼 추가
            // this.controls.deleteControl = deleteControl;
        };

        const originalToObject = fabricClass.prototype.toObject;
        fabricClass.prototype.toObject = function (propertiesToInclude?: string[]) {
            return Object.assign(originalToObject.call(this, propertiesToInclude), commonStyle);
        };
    };

    const targets = [fabric.Image, fabric.Path, fabric.Circle, fabric.Rect, fabric.Line, fabric.Group, fabric.Text];

    targets.forEach(applyDefaults);
};
