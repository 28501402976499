import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store'; // Redux Store 타입 정의

interface ProtectedRouteProps {
    allowedRoles?: string[];
    allowedStaffRoles?: string[];
}
// role과 staffRole을 함께 사용하여 사용자의 권한을 정확히 구분합니다.

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, allowedStaffRoles }) => {
    // Redux에서 사용자 인증 상태 및 역할 가져오기
    const { refreshToken, accessToken } = useSelector((state: RootState) => state.auth); // 인증 상태를 token 유무로 판단
    const { role, staffRole } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단

    const isAuthenticated = !!accessToken; // 사용자가 인증되지 않았을 경우

    if (!isAuthenticated) {
        return (
            <Navigate
                to="/"
                replace
            />
        );
    }

    // 역할 검증
    if (role && allowedRoles && !allowedRoles.includes(role)) {
        return (
            <Navigate
                to="/unauthorized"
                replace
            />
        );
    }

    // 서브 역할 검증
    if (allowedStaffRoles && role !== 'TEAM_STAFF' && staffRole && !allowedStaffRoles.includes(staffRole)) {
        return (
            <Navigate
                to="/unauthorized"
                replace
            />
        );
    }
    // 조건을 만족하면 Outlet을 통해 자식 라우트 렌더링
    return <Outlet />;
};

export default ProtectedRoute;
