import * as fabric from 'fabric';
import { MutableRefObject } from 'react';
import { applyFabricDefaultsToObject } from 'pages/staff/control/training/components/fabricCanvas/hooks/applyFabricDefaultToObject';

export const useArrowTools = (
    fabricRef: MutableRefObject<fabric.Canvas | null>,
    setUnSaved?: (state: boolean) => void
) => {
    const positionHandler: fabric.Control['positionHandler'] = (dim, finalMatrix, fabricObject, control) => {
        return fabric.util.transformPoint(
            {
                x: control.x * fabricObject.width!,
                y: control.y * fabricObject.height!,
            },
            fabric.util.multiplyTransformMatrices(
                fabricObject.calcTransformMatrix(),
                fabric.util.invertTransform(fabricObject.group?.calcTransformMatrix() || fabric.iMatrix)
            )
        );
    };

    const addArrow = (
        type: keyof typeof ArrowPaths = 'curve',
        colorState: string = '#ffffff',
        lineWidthState: number = 2
    ) => {
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;

        let pathString = ArrowPaths[type];
        if (!pathString) return;

        const path = new fabric.Path(pathString, {
            stroke: colorState,
            strokeWidth: lineWidthState,
            fill: '',
        });

        const arrowHeadPath = ArrowPaths[`${type}ArrowHead` as keyof typeof ArrowPaths];
        let arrowHead: fabric.Path | undefined;

        if (arrowHeadPath) {
            arrowHead = new fabric.Path(arrowHeadPath, {
                fill: colorState,
                stroke: colorState,
                originX: 'left',
                originY: 'center',
            });

            const scaleFactor = lineWidthState <= 2 ? 0.8 : lineWidthState <= 5 ? 1.3 : 2.0; // ✨ 2배 확대 // ✨ 3배 확대
            arrowHead.scaleX = scaleFactor;
            arrowHead.scaleY = scaleFactor;

            const pathRight = path.left! + path.getScaledWidth();
            const arrowOffset = -arrowHead.getScaledWidth() * 0.4;
            const pathCenterY = path.top! + path.getScaledHeight() / 2;

            arrowHead.left = pathRight + arrowOffset;
            arrowHead.top = type === 'curve' ? pathCenterY - 5 : pathCenterY;
        }

        const padding = 40;
        const background = new fabric.Rect({
            width: path.width! + padding,
            height: path.height! + padding,
            left: path.left! - padding / 2,
            top: path.top! - padding / 2,
            fill: 'transparent',
            selectable: false,
            evented: false,
        });

        const objectsInGroup = arrowHead ? [background, path, arrowHead] : [background, path];

        const group = new fabric.Group(objectsInGroup, {
            left: 150,
            top: 150,
            hasControls: true,
            hasBorders: true,
            selectable: true,
        });

        group.borderColor = '#ac14ff';
        group.cornerColor = '#ffffff';
        group.cornerStrokeColor = '#ac14ff';
        group.cornerSize = 7;
        group.transparentCorners = false;
        group.borderScaleFactor = 2;

        applyFabricDefaultsToObject(group); // shape: fabric.Object 또는 fabric.Group

        canvas.add(group);
        canvas.setActiveObject(group);
        canvas.requestRenderAll(); // ✅ 바로 렌더링되도록 호출 추가
        if (setUnSaved) setUnSaved(true);
    };

    return {
        addArrow,
    };
};

export const ArrowPaths = {
    curve: `
    M0 30 
    Q40 0, 80 30 
    Q120 60, 160 30 
    Q200 0, 240 30 
    Q280 60, 320 30 
    Q360 0, 400 30
  `,
    curveArrowHead: 'M0 0 L-14 -7 L-14 7 Z',

    straight: 'M0 30 H200',
    straightArrowHead: 'M0 0 L-14 -7 L-14 7 Z',

    dot: 'M0 30 H10 M20 30 H30 M40 30 H50 M60 30 H70 M80 30 H90 M100 30 H110 M120 30 H130 M140 30 H150 M160 30 H170 M180 30 H190',

    tip: 'M0 30 H180 M180 18 V42',
};
