// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import { regionOptions, ageGroupOptions, soccerTypeOptions, skillOptions } from 'utils/consts/userInfo';
import { classTypeMatcher, regionMatcher } from 'utils/matchers/userInfoMatcher';
import { showToast } from 'components/toast/Default';
import CustomTable from 'components/table/CustomTable';
import Pagination from 'components/pagenation';
import DatePicker from 'components/datePicker';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// utils
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
import { teamSortMatcher } from 'utils/matchers/sortInfoMatcher';
import Dropdown from 'components/dropdown/Default';
import { paramFormChanger } from 'utils/formChanger';
import { skillStateMatcher } from 'utils/matchers/measurementMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as ResetIcon } from 'assets/svgs/reset.svg';
// admin components
import TeamAddModal from 'pages/admin/userControl/teamControl/TeamAddModal';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
const TeamControl = () => {
    const {
        role, // null 허용
    } = useSelector((state: RootState) => state.userInfo); // 인증 상태를 token 유무로 판단
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 20,
        totalElements: 1,
        totalPages: 1,
    });
    const [sortType, setSortType] = useState('');
    const [teamNameInput, setTeamNameInput] = useState('');
    const [options, setOptions] = useState<OptionsType>({
        region: '',
        ageGroup: '',
        type: '',
        teamName: '',
        bestSkill: '',
        worstSkill: '',
        measuredAtFrom: '',
        measuredAtTo: '',
        overallScoreFrom: '',
        overallScoreTo: '',
        sortType: '',
    });
    const initOptions = () => {
        showToast('옵션을 초기화합니다.', 'info');
        setOptions({
            region: '',
            ageGroup: '',
            type: '',
            teamName: '',
            bestSkill: '',
            worstSkill: '',
            measuredAtFrom: '',
            measuredAtTo: '',
            overallScoreFrom: '',
            overallScoreTo: '',
            sortType: '',
        });
        setPage(1);
        setTeamNameInput('');
        setSortType('');
    };
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = teamSortMatcher(key, sort);
        setSortType(customizedSort);
    };

    const handleOptions = (updateData: any) => {
        setOptions((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    // useApiMutation
    const getAdminTeam = useApiQuery(
        [
            'getAdminTeam',
            sortType,
            options?.region,
            options?.ageGroup,
            options?.type,
            options?.bestSkill,
            options?.worstSkill,
            `${page}`,
            `${pagInfo.size}`,
        ],
        ENDPOINT_ADMIN.teamParams(
            paramFormChanger({
                ...options,
                sortType: sortType,
                page: page,
                size: pagInfo?.size,
            })
        ),
        'get'
    );
    // Debounced function
    const [isFirstRender, setIsFirstRender] = useState(true);
    const debouncedAdminRequest = useMemo(
        () =>
            debounce(() => {
                if (!isFirstRender) {
                    // showToast('조건 검색중입니다.');
                    getAdminTeam.refetch();
                }
            }, 300), // 3초 딜레이
        [getAdminTeam, isFirstRender] // 의존성으로 리팩쳐 사용
    );
    useEffect(() => {
        setIsFirstRender(false);
    }, []);
    useEffect(() => {
        debouncedAdminRequest();
        return () => {
            debouncedAdminRequest.cancel(); // 컴포넌트가 언마운트되면 디바운스 취소
        };
    }, [
        options?.teamName,
        options?.measuredAtFrom,
        options?.measuredAtTo,
        options?.overallScoreFrom,
        options?.overallScoreTo,
    ]);

    useEffect(() => {
        if (getAdminTeam?.isSuccess && getAdminTeam?.data && getAdminTeam?.data?.pageInfo?.page === 1) {
            setPageInfo(getAdminTeam?.data?.pageInfo);
        }
    }, [getAdminTeam?.isSuccess, getAdminTeam?.data]);

    const customizedRow = handleRows(getAdminTeam?.data?.data) || [];
    const searchTeam = () => {
        showToast('팀 검색중입니다', 'info');
        setPage(1);
        handleOptions({ teamName: teamNameInput });
    };
    return (
        <div className="flex flex-col gap-[30px] w-[1340px] m-auto">
            {getAdminTeam?.isPending && <SoccerBallLodaing />}
            <div className="flex gap-x-[10px] gap-y-[20px] flex-wrap">
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">지역</div>
                    <Dropdown
                        placeholder="지역"
                        options={regionOptions}
                        value={options?.region}
                        onChange={(value) => handleOptions({ region: value })}
                        className="w-[250px]"
                    />
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">연령</div>
                    <Dropdown
                        placeholder="연령"
                        options={ageGroupOptions}
                        value={options?.ageGroup}
                        onChange={(value) => handleOptions({ ageGroup: value })}
                        className="w-[250px]"
                    />
                </div>

                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">강점 기술</div>
                    <Dropdown
                        placeholder="강점 기술"
                        options={skillOptions}
                        value={options?.bestSkill}
                        onChange={(value) => handleOptions({ bestSkill: value })}
                        className="w-[250px]"
                    />
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">약점 기술</div>
                    <Dropdown
                        placeholder="약점 기술"
                        options={skillOptions}
                        value={options?.worstSkill}
                        onChange={(value) => handleOptions({ worstSkill: value })}
                        className="w-[250px]"
                    />
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">팀 유형</div>
                    <Dropdown
                        placeholder="팀 유형"
                        options={soccerTypeOptions}
                        value={options?.type}
                        onChange={(value) => handleOptions({ type: value })}
                        className="w-[250px]"
                    />
                </div>
                <div className="flex items-center w-[610px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">측정일자</div>

                    <div className="items-center flex gap-[10px]">
                        <input
                            type="datetime-local"
                            placeholder="시작 날짜"
                            className="flex items-center justify-between w-[250px] p-[10px] gap-[14px] bg-secondary-mGrayLight rounded-[8px]"
                            value={options?.measuredAtFrom}
                            onChange={(e) => handleOptions({ measuredAtFrom: e.target.value })}
                        />
                        <span>~</span>
                        <input
                            type="datetime-local"
                            placeholder="종료 날짜"
                            className="flex items-center justify-between  w-[250px] p-[10px] gap-[14px] bg-secondary-mGrayLight rounded-[8px]"
                            value={options?.measuredAtTo}
                            onChange={(e) => handleOptions({ measuredAtTo: e.target.value })}
                        />
                    </div>
                </div>
                <div className="flex items-center w-[300px] gap-[10px]">
                    <div className="shrink-0 text-context-bold text-center w-[60px]">종합점수</div>
                    <div className="items-center flex gap-[5px]">
                        <input
                            placeholder="종합점수"
                            value={options?.overallScoreFrom}
                            onChange={(e) => handleOptions({ overallScoreFrom: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                        <span>~</span>
                        <input
                            placeholder="종합점수"
                            value={options?.overallScoreTo}
                            onChange={(e) => handleOptions({ overallScoreTo: e.target.value })}
                            className="inputDefaultStyle p-[12px] w-full h-[44px] text-context-regular rounded-[8px] text-black"
                        />
                    </div>
                </div>
            </div>
            <div className="flex gap-[20px] items-center">
                <div
                    className="w-[500px] relative"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            searchTeam();
                        }
                    }}
                >
                    <input
                        value={teamNameInput}
                        onChange={(e) => setTeamNameInput(e.target.value)}
                        placeholder="팀명을 검색해주세요"
                        className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                    />
                    <button
                        onClick={searchTeam}
                        className="absolute right-[12px] top-[12px]"
                    >
                        <SearchIcon />
                    </button>
                </div>
                <button
                    onClick={initOptions}
                    className="flex gap-[5px] items-center text-secondary-lGrayDark border-secondary-mGrayMid hover:border-primary-purple hover:text-primary-purple rounded-[10px] border px-[10px] py-[6px]"
                >
                    <span>초기화</span>
                    <ResetIcon className="w-[14px] h-[14px]" />
                </button>
            </div>
            <CustomTable
                columns={columns}
                rows={customizedRow}
                equalWidth={false}
                minWidth={'max-w-[100px]'}
                sortInfoFunc={handleSort}
            />
            {(role === 'SYSTEM_ADMIN' || role === 'SUPER_ADMIN') && (
                <TeamAddModal
                    modalBtn={
                        <button className="w-full h-[44px] mt-[10px] rounded-[8px] text-context-regular text-secondary-lGrayDark gap-[10px] border border-secondary-lGrayMid flex items-center justify-center">
                            <PlusIcon />팀 생성
                        </button>
                    }
                />
            )}
            <Pagination
                page={page}
                size={pagInfo?.size}
                totalElement={pagInfo?.totalElements}
                totalPages={pagInfo?.totalPages}
                onPageChange={setPage}
            />
        </div>
    );
};

export default TeamControl;

export const columns = [
    { key: 'teamName', label: '팀 이름', sortable: true },
    { key: 'type', label: '팀 유형', sortable: true },
    { key: 'ageGroup', label: '연령', sortable: true },
    { key: 'region', label: '지역', sortable: true },

    { key: 'memberCount', label: '팀원 수', sortable: true },
    { key: 'bestSkill', label: '강점 기술', sortable: true },
    { key: 'worstSkill', label: '약점 기술', sortable: true },

    { key: 'lastMeasuredAt', label: '측정 일자', sortable: true },
    { key: 'overallScore', label: '종합점수', sortable: true },

    { key: 'link', label: '상세보기' },
];

interface OptionsType {
    region: string;
    ageGroup: string;
    type: string;
    teamName: string;

    bestSkill: string;
    worstSkill: string;

    measuredAtFrom: string;
    measuredAtTo: string;
    overallScoreFrom: string;
    overallScoreTo: string;

    sortType?: string;
}

export const handleRows = (rows: any[], paramYear?: string, paramAgeGroup?: string) => {
    return rows?.map((row) => ({
        teamName: row?.teamName,
        ageGroup: row?.ageGroup,
        type: classTypeMatcher(row?.type),
        memberCount: row?.memberCount,
        region: regionMatcher(row?.region),
        bestSkill: skillStateMatcher(row?.bestSkill),
        worstSkill: skillStateMatcher(row?.worstSkill),
        lastMeasuredAt: formatDateTimeMatcher(row?.lastMeasuredAt),
        overallScore: row?.overallScore?.toFixed(2),
        link: `/admin/control/teamDetail/${row?.teamId}`, // 상세보기 링크
    }));
};

export const handleDropdownOpts = (arr: any[]) => {
    return arr?.map((arr) => ({
        value: arr?.teamId,
        label: `${arr?.teamName}${arr?.ageGroup ? `(${arr?.ageGroup})` : ''} `, //${arr?.type}
    }));
};
