// hooks
import { useEffect, useState, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
import SortableSessionItem from 'pages/staff/control/training/diary/[id]/SortableSessionItem';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { ENDPOINT_TEAM_TRAINING_SESSION } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { ReactComponent as PlusIcon } from 'assets/svgs/plus.svg';
// diary component
import PlayerTrainingCanvas from 'pages/staff/control/training/components/fabricCanvas/PlayerTrainingCanvas';
const TrainingSession = ({ trainingSession }: any) => {
    const [curSession, setCurSession] = useState({});
    const [sessionSequence, setSessionSequence] = useState(0);

    useEffect(() => {
        if (trainingSession && trainingSession[sessionSequence]) {
            setCurSession(trainingSession[sessionSequence]);
        }
    }, [trainingSession, sessionSequence]);
    return (
        <div>
            <div className="flex border-b border-secondary-lGrayMid">
                <div className="w-[150px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    훈련 세션
                </div>
                <div className="p-[24px] ">
                    <div className="w-[1142px] gap-[12px] flex flex-wrap">
                        {trainingSession &&
                            trainingSession?.map(
                                (el: any, idx: number) => (
                                    <button
                                        key={el?.id}
                                        className={`w-[120px] h-[56px] rounded-[14px] text-[#111111] flex justify-center items-center ${
                                            idx === sessionSequence
                                                ? 'bg-primary-white border-2 border-primary-purple text-context-bold'
                                                : 'bg-[#F8F8F8] text-context-regular'
                                        }`}
                                        onClick={() => {
                                            setSessionSequence(idx);
                                        }}
                                    >{`세선${el?.sequence}`}</button>
                                ),
                                []
                            )}
                    </div>
                    <PlayerTrainingCanvas focusedSessionData={curSession} />
                </div>
            </div>
            <div className="flex border-b border-secondary-lGrayMid">
                <div className="w-[150px] p-[24px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                    세션 포인트
                </div>
                <div className="p-[24px] w-full ">
                    <div>{trainingSession?.sessionPoint}</div>
                </div>
            </div>
        </div>
    );
};

export default TrainingSession;

interface SessionItem {
    trainingSessions: {
        id: number;
        tacticalBoardJson: string | null;
        sessionPoint: string | null;
        sequence: 1;
    }[];
}
