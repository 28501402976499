// hooks
import { useEffect, useState } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
import { useApiMutation } from 'hooks/useDynamicApi';
// utils
import { handleDateTime } from 'utils/inputHandleFunc/handleTimeValue';
import {
    gradeDescriptionMatcher,
    gradeMatcher,
    VALID_GRADES,
    VALID_DESCRIPTION_GRADES,
} from 'utils/matchers/reportMatcher';
// commons
import EmptyBar from 'components/emptyBar';
import ConfirmModal from 'components/modals/ConfirmModal';
import RatingIdSubHeader from 'pages/staff/control/rating/[id]/RatingIdSubHeader';
import { PlayerInfoType } from 'pages/staff/control/rating/[id]/ProfileCard';
import ProfileCard from 'pages/staff/control/rating/[id]/ProfileCard';
import HistoryCard from 'pages/staff/control/rating/[id]/HistoryCard';
import SeasonCard from 'pages/staff/control/rating/[id]/SeasonCard';
import RatingCard from 'pages/staff/control/rating/[id]/RatingCard';
import { showToast } from 'components/toast/Default';
import { EvaluationDetailInfoType } from 'pages/staff/control/rating/[id]/RatingCard';
// icons
import { ReactComponent as EditPenIcon } from 'assets/svgs/editPen.svg';
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
import { ENDPOINT_MANAGEMENT_PROFILE, ENDPOINT_EVALUATION } from 'utils/consts/apiEndpoint';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const RatingId = () => {
    const navigate = useNavigate();
    const location = useLocation().pathname;
    const periodId = location.split('/')[4];
    const [searchParams] = useSearchParams();
    const paramPlayerId = searchParams.get('player') || '';
    const paramEvaluationId = searchParams.get('evaluation') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const [deleteModalShow, setDeleteModalShow] = useState(false);
    const { staffRole } = useSelector((state: RootState) => state.userInfo);

    const [isEdit, setIsEdit] = useState(false || staffRole !== 'OFFICE');
    const [auditInfo, setAuditInfo] = useState({
        createdAt: '',
        createdByName: '',
        lastModifiedAt: '',
        lastModifiedByName: '',
    });

    const [evaluationDetail, setEvaluationDetail] = useState<EvaluationDetailInfoType>({
        overallReview: '',
        personality: '',
        strengths: '',
        weaknesses: '',
    });
    const [aiAnalysisInfo, setAiAnalysisInfo] = useState(null);
    const [grade, setGrade] = useState('');
    const [periodInfo, setPeriodInfo] = useState({
        id: '',
        inputEndDate: '',
        inputStartDate: '',
        periodEndDate: '',
        periodNumber: '',
        periodStartDate: '',
        year: '',
    });
    const [playerInfo, setPlayerInfo] = useState<PlayerInfoType>({
        age: '',
        birthday: '',
        height: '',
        id: '',
        majorFoot: '',
        name: '',
        positions: [],
        previousCareers: [],

        profileImageUrl: '',
        uniformNumber: '',
        weight: '',
    });

    // useApiQuery
    const getPlayerProfile = useApiQuery(
        ['getPlayerProfile', paramPlayerId],
        ENDPOINT_MANAGEMENT_PROFILE.player(paramPlayerId),
        'get'
    );

    const getEvaluationId = useApiQuery(
        ['getEvaluationId', paramEvaluationId],
        ENDPOINT_EVALUATION.evaluation(`${paramEvaluationId}`),
        'get',
        {
            enabled: !!paramEvaluationId, // falsy 값이면 쿼리 실행 안 함
        }
    );
    useEffect(() => {
        if (getEvaluationId?.data && getEvaluationId?.isSuccess) {
            const period = getEvaluationId?.data?.periodInfo?.id;
            sessionStorage?.setItem('period', period);
        }
    }, [getEvaluationId?.data, getEvaluationId?.isSuccess]);
    // useApiMutation
    const postEvaluationId = useApiMutation(ENDPOINT_EVALUATION.evaluation(), 'post', {
        onSuccess: (data) => {
            setIsEdit(false);
            showToast('선수 평가가 등록되었습니다.', 'success');
            navigate(`${location}?player=${paramPlayerId}&ageGroup=${paramAgeGroup}&evaluation=${data.id}`);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error || '선수 평가 등록을 실패했습니다.';
            showToast(`${detailError}`, 'error');
        },
    });

    const patchEvaluationId = useApiMutation(ENDPOINT_EVALUATION.evaluation(paramEvaluationId), 'patch', {
        onSuccess: (data) => {
            setIsEdit(false);
            showToast('선수 평가가 수정되었습니다.', 'success');
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError || error?.detailedMessage || '선수 평가 수정을 실패했습니다.'}`, 'error');
        },
    });

    const deleteEvaluationId = useApiMutation(ENDPOINT_EVALUATION.evaluation(paramEvaluationId), 'delete', {
        onSuccess: (data) => {
            setIsEdit(false);
            showToast('선수 평가가 삭제되었습니다.', 'success');

            navigate(`${location}?player=${paramPlayerId}&ageGroup=${paramAgeGroup}`);
        },
        onError: (error) => {
            // 에러 메시지 추출
            const detailError = error?.data?.detailedMessage || error || '선수 평가 삭제를 실패했습니다.';

            showToast(`${detailError}`, 'error');
        },
    });
    useEffect(() => {
        if (getPlayerProfile?.data && getPlayerProfile?.isSuccess) {
            const { playerInfo, careerInfo } = getPlayerProfile?.data || {};
            setPlayerInfo((prev) => ({
                ...prev,
                age: playerInfo?.age,
                birthday: playerInfo?.birthday,
                id: playerInfo?.id,
                name: playerInfo?.name,
                height: playerInfo?.height,
                majorFoot: playerInfo?.majorFoot,
                positions: playerInfo?.positions,
                uniformNumber: playerInfo?.uniformNumber,
                profileImageUrl: playerInfo?.profileImageUrl,
                weight: playerInfo?.weight,
                previousCareers: careerInfo,
            }));
        }
    }, [getPlayerProfile?.data, getPlayerProfile?.isSuccess]);
    useEffect(() => {
        if (deleteEvaluationId?.data && deleteEvaluationId.isSuccess) {
            const { playerInfo, careerInfo } = getPlayerProfile?.data || {};
            setPlayerInfo((prev) => ({
                ...prev,
                age: playerInfo?.age,
                birthday: playerInfo?.birthday,
                id: playerInfo?.id,
                name: playerInfo?.name,
                height: playerInfo?.height,
                majorFoot: playerInfo?.majorFoot,
                positions: playerInfo?.positions,
                uniformNumber: playerInfo?.uniformNumber,
                weight: playerInfo?.weight,
                previousCareers: careerInfo,
            }));
            setEvaluationDetail({
                overallReview: '',
                personality: '',
                strengths: '',
                weaknesses: '',
            });
        }
    }, [deleteEvaluationId?.data, deleteEvaluationId.isSuccess]);

    useEffect(() => {
        if (getEvaluationId?.isSuccess && getEvaluationId?.data) {
            setAuditInfo(getEvaluationId?.data?.auditInfo);
            setEvaluationDetail(getEvaluationId?.data?.evaluationDetail);
            setAiAnalysisInfo(getEvaluationId?.data?.aiAnalysisInfo);
            setGrade(getEvaluationId?.data?.grade);
            setPeriodInfo(getEvaluationId?.data?.periodInfo);
            setPlayerInfo(getEvaluationId?.data?.playerInfo);
        }
    }, [getEvaluationId?.data, getEvaluationId?.isSuccess]);

    // handler
    const handlePlayerInfo = (updates: Partial<PlayerInfoType>) => {
        setPlayerInfo((prev) => ({
            ...prev,
            ...updates,
        }));
        // 사용법:  handlePlayerInfo({ name: "New Name" });
    };

    const handleEvaluationDetail = (updates: Partial<EvaluationDetailInfoType>) => {
        setEvaluationDetail((prev) => ({
            ...prev,
            ...updates,
        }));
    };

    const createEvaluationId = () => {
        if (
            !paramPlayerId ||
            !periodId ||
            !playerInfo?.uniformNumber ||
            !playerInfo?.positions ||
            !playerInfo?.height ||
            !playerInfo?.weight ||
            !playerInfo?.majorFoot ||
            !grade
        ) {
            showToast('선수 필수 프로필 항목들을 전부 입력하세요.', 'warning');
        } else if (
            !evaluationDetail?.strengths ||
            !evaluationDetail?.weaknesses ||
            !evaluationDetail?.personality ||
            !evaluationDetail?.overallReview
        ) {
            showToast('선수 필수 평가를 전부 입력하세요.', 'warning');
        } else {
            if (!postEvaluationId?.isPending) {
                const VALID_GRADE = gradeMatcher(grade);
                if (!VALID_DESCRIPTION_GRADES.includes(VALID_GRADE)) {
                    showToast(`S,A,A',B,B',D,C,C',F 등급만 입력 가능합니다`, 'warning');
                    return;
                }
                showToast('선수 평가 등록중입니다.', 'info');
                postEvaluationId.mutate({
                    playerId: paramPlayerId,
                    periodId: periodId,
                    uniformNumber: playerInfo?.uniformNumber,
                    positions: playerInfo?.positions,
                    height: playerInfo?.height,
                    weight: playerInfo?.weight,
                    majorFoot: playerInfo?.majorFoot,
                    grade: VALID_GRADE,
                    strengths: evaluationDetail?.strengths,
                    weaknesses: evaluationDetail?.weaknesses,
                    personality: evaluationDetail?.personality,
                    overallReview: evaluationDetail?.overallReview,
                });
            }
        }
    };

    const updateEvaluationId = () => {
        if (!playerInfo?.positions || !playerInfo?.height || !playerInfo?.weight || !playerInfo?.majorFoot || !grade) {
            showToast('선수 필수 항목들을 입력하세요.', 'warning');
        } else if (
            !evaluationDetail?.strengths ||
            !evaluationDetail?.weaknesses ||
            !evaluationDetail?.personality ||
            !evaluationDetail?.overallReview
        ) {
            showToast('선수 평가를 입력하세요.', 'warning');
        } else {
            if (!patchEvaluationId.isPending) {
                const VALID_GRADE = gradeMatcher(grade);
                if (!VALID_DESCRIPTION_GRADES.includes(VALID_GRADE)) {
                    showToast(`S,A,A',B,B',D,C,C',F 등급만 입력 가능합니다`, 'warning');
                    return;
                }
                showToast('선수 평가 수정중입니다.', 'info');
                patchEvaluationId.mutate({
                    positions: playerInfo?.positions,
                    height: playerInfo?.height,
                    weight: playerInfo?.weight,
                    majorFoot: playerInfo?.majorFoot,
                    grade: VALID_GRADE,
                    strengths: evaluationDetail?.strengths,
                    weaknesses: evaluationDetail?.weaknesses,
                    personality: evaluationDetail?.personality,
                    overallReview: evaluationDetail?.overallReview,
                });
            }
        }
    };

    const removeEvaluationId = () => {
        if (!deleteEvaluationId?.isPending) deleteEvaluationId.mutate({});
    };
    return (
        <div className="wrapper">
            <ConfirmModal
                isShow={deleteModalShow}
                setIsShow={setDeleteModalShow}
                title="평가정보를 전체 삭제하시겠습니까?"
                activeFunc={removeEvaluationId}
            />
            <RatingIdSubHeader
                playerName={playerInfo?.name}
                uniformNumber={playerInfo?.uniformNumber}
            />
            <EmptyBar customStyle={'h-[35px]'} />
            <div className="flex gap-[20px]">
                <ProfileCard
                    playerInfo={playerInfo}
                    handlePlayerInfo={handlePlayerInfo}
                    grade={grade}
                    setGrade={setGrade}
                    isEdit={isEdit}
                />
                {/* <HistoryCard previousCareers={playerInfo?.previousCareers} /> */}
                <SeasonCard seasonStats={getPlayerProfile?.data?.seasonStats} />
            </div>
            <EmptyBar customStyle={'h-[20px]'} />
            <div className="w-full bg-white max-h-[690px] rounded-[10px] p-[30px] flex flex-col gap-[20px]">
                <div className="flex items-center justify-between">
                    <div className="text-subtitle-medium-bold text-secondary-dGrayMid">분석 및 평가</div>
                    {(staffRole === 'COACH' || staffRole === 'MANAGER') && isEdit ? (
                        <div className="flex items-center gap-[10px]">
                            {paramEvaluationId && (
                                <button
                                    onClick={() => setDeleteModalShow(true)}
                                    className="text-secondary-lGrayDark text-context-bold"
                                >
                                    <DeleteIcon className="w-[30px] h-[30px] mb-[5px]" />
                                </button>
                            )}
                            <button
                                onClick={paramEvaluationId ? updateEvaluationId : createEvaluationId}
                                className="w-[84px] h-[44px] bg-primary-purple rounded-[10px] text-primary-white text-context-bold"
                            >
                                {paramEvaluationId ? '평가 수정' : '평가 반영'}
                            </button>
                        </div>
                    ) : (
                        (staffRole === 'COACH' || staffRole === 'MANAGER') && (
                            <button
                                onClick={() => setIsEdit(!isEdit)}
                                className="text-primary-purple"
                            >
                                <EditPenIcon />
                            </button>
                        )
                    )}
                </div>
                <div className="w-full ">
                    <RatingCard
                        evaluationDetail={evaluationDetail}
                        handleEvaluationDetail={handleEvaluationDetail}
                        aiAnalysisInfo={aiAnalysisInfo}
                        isEdit={isEdit}
                    />
                </div>
                {auditInfo?.lastModifiedAt && (
                    <div className="gap-[10px] flex justify-end text-secondary-lGrayDark">
                        <div>{handleDateTime(auditInfo?.lastModifiedAt)}</div>
                        <div>최종수정: {auditInfo?.lastModifiedByName}</div>
                    </div>
                )}
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default RatingId;
