// hooks
import { useSearchParams, useLocation } from 'react-router-dom';
import { useParamNavigate } from 'hooks/useDynamicNavigate';
// assets
import graySymbol from 'assets/logos/symbol/graySymbol.png';
// icons
import { ReactComponent as LFootIcon } from 'assets/svgs/lFoot.svg';
import { ReactComponent as RFootIcon } from 'assets/svgs/rFoot.svg';
// utils
import { positionMatcher } from 'utils/matchers/userInfoMatcher';
// commons
import MoreNavBtn from 'components/button/MoreNavBtn';
interface ProfileCardType {
    id: number;
    name: string;
    profileImageUrl: string | null;
    birthday: string;
    age: number;
    uniformNumber: number;
    positions: string[];
    height: number;
    weight: number;
    majorFoot: string;
}
interface ProfileCardProps {
    playerInfo: ProfileCardType;
    detailNavigateUrl?: string;
}
const ProfileCard = ({ playerInfo, detailNavigateUrl }: ProfileCardProps) => {
    const { id, name, profileImageUrl, birthday, age, uniformNumber, positions, height, weight, majorFoot } =
        playerInfo || {};
    const [searchParams] = useSearchParams();
    const location = useLocation().pathname;
    const locationList = location.split('/');
    const handleParamNavigate = useParamNavigate();
    const paramYear = searchParams.get('year') || '';
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramPlayerId = searchParams.get('player') || '';
    const handleMoreBtnNavigate = (childRoute: string) => {
        if (detailNavigateUrl) {
            handleParamNavigate({}, false, detailNavigateUrl);
            return;
        }
        if (paramAgeGroup && paramPlayerId && paramYear)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                    player: paramPlayerId,
                    year: paramYear,
                },
                false,
                `${location}/${childRoute}/${id}`
            );
    };

    return (
        <div className="w-[690px] h-[370px] bg-primary-white rounded-[10px] p-[30px] flex gap-[30px]">
            <div className="flex justify-center items-center shrink-0 rounded-[8px] w-[230px] h-[280px] bg-secondary-mGrayLight">
                <img
                    src={profileImageUrl || graySymbol}
                    alt="graySymbol"
                    className={`${
                        profileImageUrl ? 'w-[230px] h-[280px] object-cover rounded-[8px]' : 'w-[80px]'
                    } cursor-pointer`}
                />
            </div>
            <div className="w-full">
                <div className="flex items-center justify-between">
                    <div className="flex gap-[10px] items-center">
                        <div className="text-title-small-bold">{name}</div>
                        <span className="w-[79px] h-[29px] bg-[#5E6EFF] rounded-[80px] text-[14px] font-bold flex justify-center items-center text-primary-white">
                            등번호 {uniformNumber}
                        </span>
                    </div>
                    <MoreNavBtn
                        type="purple"
                        activeFunc={() => handleMoreBtnNavigate('playerDetail')}
                    />
                </div>
                <div className="mt-[20px] flex flex-col items-start justify-center gap-[20px]">
                    <div className="flex ">
                        <div className="h-[38px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                        <div className="flex  py-[8px]">
                            <div className="text-[20px] w-[70px] flex-shrink-0 font-semibold mr-[12px] text-[#868686] ">
                                포지션
                            </div>
                            <div className="flex gap-[5px] flex-wrap mr-[10px] font-bold ">
                                {positions?.map((el: string) => (
                                    <span
                                        key={el}
                                        className="rounded-[80px] px-[12px] py-[4px] text-[14px] font-bold bg-[#d2d2d2] text-[#111111]"
                                    >
                                        {el}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="h-[38px] flex items-center">
                        <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                        <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">신장</div>
                        <div className="text-title-small-bold mr-[10px] font-bold">{height}</div>
                        <div className="text-[22px] text-[#7c7c7c]">cm</div>
                    </div>
                    <div className="h-[38px] flex items-center">
                        <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                        <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">체중</div>
                        <div className="text-title-small-bold mr-[10px] font-bold">{weight}</div>
                        <div className="text-[22px] text-[#7c7c7c]">kg</div>
                    </div>
                    <div className="h-[38px] flex items-center">
                        <div className="h-[40px] rounded-[80px] border-l border-primary-purple border-[5px] mr-[18px]"></div>
                        <div className="text-[20px] w-[70px] font-semibold mr-[12px] text-[#868686]">주발</div>
                        <div
                            className={`flex items-center text-subtitle-large-regular ${
                                majorFoot === 'RIGHT' ? 'text-secondary-lGrayMid' : 'text-primary-purple'
                            }`}
                        >
                            <LFootIcon />
                            <div>L</div>
                        </div>
                        <div className="w-[10px]"></div>
                        <div
                            className={`flex items-center text-subtitle-large-regular ${
                                majorFoot === 'LEFT' ? 'text-secondary-lGrayMid' : 'text-primary-purple'
                            }`}
                        >
                            <div>R</div>

                            <RFootIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
