// commons
import EmptyBar from 'components/emptyBar';
import Dropdown from 'components/dropdown/Default';
// utils
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { trainingIntensityOptions } from 'utils/consts/diaryInfo';
const TrainingInfo = ({ playerDiaryIdData }: PlayerInfoPropsType) => {
    return (
        <div className="h-[154px] border-y border-secondary-lGrayMid flex">
            <div className="w-[150px] shrink-0 h-full bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
                경기 정보
            </div>
            <div className="p-[24px]">
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] ">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            일시
                        </div>

                        <div className="inputDefaultStyle p-[12px] w-[310px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                            <input
                                readOnly
                                type="datetime-local"
                                value={playerDiaryIdData?.trainingDateTime || ''}
                                className="py-[12px] w-[310px] h-[44px] bg-transparent focus:outline-none"
                                style={{
                                    colorScheme: 'white', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            경기장소
                        </div>

                        <div className="inputDefaultStyle p-[12px] w-[310px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                            {playerDiaryIdData?.location || ''}
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />
                <div className="flex gap-[24px]">
                    <div className="flex items-center gap-[10px] w-[420px]">
                        <div className="text-center shrink-0 w-[100px] text-context-regular text-secondary-dGrayMid">
                            날씨
                        </div>
                        <div className="w-[310px]">
                            <div className="inputDefaultStyle p-[12px] w-[310px] h-[44px] text-context-regular text-secondary-lGrayDark rounded-[8px] flex justify-between items-center">
                                {weatherMatcher(playerDiaryIdData?.weather || '')}
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center gap-[10px]">
                        <div className="text-center w-[100px] flex-shrink-0 text-context-regular text-secondary-dGrayMid">
                            훈련 강도
                        </div>

                        <div className="flex items-center gap-[14px]">
                            {trainingIntensityOptions.map((el) => (
                                <label
                                    className="h-[24px] flex items-center gap-[14px]"
                                    key={el.value}
                                >
                                    <div
                                        className={` relative text-secondary-lGrayDark w-[24px] h-[24px] rounded-full border flex items-center justify-center transition-all duration-200 ${
                                            el.value === `${playerDiaryIdData?.trainingIntensity}`
                                                ? 'border-2 border-primary-purple bg-primary-white'
                                                : 'border-primary-black bg-primary-white'
                                        } `}
                                    >
                                        {el.value === `${playerDiaryIdData?.trainingIntensity}` && (
                                            <div className="absolute w-[12px] h-[12px] rounded-full bg-primary-purple" />
                                        )}
                                    </div>
                                    {el.label}
                                </label>
                            ))}
                        </div>
                    </div>
                </div>
                <EmptyBar customStyle="h-[18px]" />
            </div>
        </div>
    );
};

export default TrainingInfo;

interface PlayerIdInfoType {
    id: number | null;
    trainingDateTime: string | null;
    location?: string | null;
    weather: string | null;
    trainingIntensity: number | null;
    trainingSessions: {
        id: number;
        tacticalBoardJson: string | null;
        sessionPoint: string | null;
        sequence: 1;
    }[];
    personalTrainingIntensity: 3;
    goodPoints: string | null;
    lackingPoints: string | null;
    questionsPoints: string | null;
    footballDiary: string | null;
}

interface PlayerInfoPropsType {
    playerDiaryIdData: PlayerIdInfoType;
}
