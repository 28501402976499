// hooks
import { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// commons
import Dropdown from 'components/dropdown/Default';
import ConfirmModal from 'components/modals/ConfirmModal';
// utils
import { positionShortOptions } from 'utils/consts/userInfo';
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';
const SubstitutionEditElement = ({
    activeOptions,
    inactiveOptions,
    substitutionId,
    playerInId,
    playerInName,
    playerInUniformNumber,
    playerInPosition,
    playerOutId,
    playerOutName,
    playerOutUniformNumber,
    playerOutPosition,
    substitutionTime,
    getSubstitutionLength,
    idx,
}: SubstitutionType) => {
    const { id } = useParams();

    const [newSubstitutionTime, setNewSubstitutionTime] = useState(substitutionTime);
    const [newInPlayer, setNewInPlayer] = useState(playerInId);
    const [newInPlayerPos, setNewInPlayerPos] = useState(playerInPosition);
    const [newOutPlayer, setNewOutPlayer] = useState(playerOutId);
    const [newOutPlayerPos, setNewOutPlayerPos] = useState(playerOutPosition);
    const [isDeleteModal, setIsDeleteModal] = useState(false);

    const deleteSubstitution = useApiMutation(ENDPOINT_REPORT.substitutionId(id, substitutionId), 'delete', {
        onSuccess: (data) => {
            showToast('교체 정보를 삭제하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
        },
    });
    const patchSubstitution = useApiMutation(ENDPOINT_REPORT.substitutionId(id, substitutionId), 'patch', {
        onSuccess: (data) => {
            showToast('교체 정보를 수정하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
            setNewSubstitutionTime(substitutionTime);
            setNewInPlayer(playerInId);
            setNewInPlayerPos(playerInPosition);
            setNewOutPlayer(playerOutId);
            setNewOutPlayerPos(playerOutPosition);
        },
    });

    const editSubstitutionPlayer = () => {
        if (
            !patchSubstitution?.isPending &&
            newInPlayer &&
            newInPlayerPos &&
            newOutPlayer &&
            newOutPlayerPos &&
            newSubstitutionTime
        ) {
            showToast('교체 정보를 수정하는중입니다', 'info');
            patchSubstitution.mutate({
                playerInId: newInPlayer,
                playerInPosition: newInPlayerPos,
                playerOutId: newOutPlayer,
                playerOutPosition: newOutPlayerPos,
                substitutionTime: newSubstitutionTime,
            });
        }
    };

    const removeSubstitution = () => {
        if (!deleteSubstitution?.isPending) {
            showToast('교체 정보를 삭제하는 중입니다', 'info');
            deleteSubstitution.mutate({});
        }
    };

    useEffect(() => {
        if (newInPlayer && newInPlayerPos && newOutPlayer && newOutPlayerPos && newSubstitutionTime) {
            if (
                newSubstitutionTime === substitutionTime &&
                newInPlayerPos === playerInPosition &&
                newInPlayer === playerInId &&
                newOutPlayer === playerOutId &&
                newOutPlayerPos === playerOutPosition
            ) {
                return;
            } else {
                editSubstitutionPlayer();
            }
        }
    }, [newInPlayer, newInPlayerPos, newOutPlayer, newOutPlayerPos, newSubstitutionTime]);

    return (
        <div className="flex gap-[10px]">
            <ConfirmModal
                isShow={isDeleteModal}
                setIsShow={setIsDeleteModal}
                title={
                    <div className="text-center">
                        <div>골/어시/승부차기 기록이 있으면</div>
                        <div className="font-bold text-status-error">삭제할 수 없습니다</div>
                    </div>
                }
                activeFunc={removeSubstitution}
            />
            <div className="w-[110px] rounded-[8px] bg-primary-black flex justify-between text-white items-center">
                <input
                    type="text"
                    value={newSubstitutionTime}
                    onChange={(e) => setNewSubstitutionTime(e.target.value)}
                    className="px-[12px] w-full  h-[44px] text-white bg-transparent focus:outline-none"
                    style={{
                        colorScheme: 'dark', // 아이콘과 드롭다운 UI를 다크 테마처럼 변경
                    }}
                />
                <div className="pr-[10px]">분</div>
            </div>

            <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={newInPlayerPos}
                onChange={setNewInPlayerPos}
                className="w-[100px]"
            />
            <Dropdown
                placeholder={playerInName}
                options={inactiveOptions?.filter((el: { label: string; value: string }) => el.value !== newOutPlayer)}
                value={newInPlayer}
                onChange={setNewInPlayer}
                className="w-[270px]"
            />
            <Dropdown
                placeholder="포지션"
                options={positionShortOptions}
                value={newOutPlayerPos}
                onChange={setNewOutPlayerPos}
                className="w-[100px]"
            />
            <Dropdown
                placeholder={playerOutName}
                options={activeOptions?.filter((el: { label: string; value: string }) => el.value !== newInPlayer)}
                value={newOutPlayer}
                onChange={setNewOutPlayer}
                className="w-[270px]"
            />
            {Number(getSubstitutionLength) === idx + 1 && (
                <button
                    onClick={() => setIsDeleteModal(true)}
                    className="text-status-error p-[6px]"
                >
                    <DeleteIcon />
                </button>
            )}
        </div>
    );
};

export default SubstitutionEditElement;

interface SubstitutionType {
    activeOptions: any;
    inactiveOptions: any;

    substitutionId: string;
    playerInId: string;
    playerInName: string;
    playerInUniformNumber: string;
    playerInPosition: string;
    playerOutId: string;
    playerOutName: string;
    playerOutUniformNumber: string;
    playerOutPosition: string;
    substitutionTime: string;

    getSubstitutionLength: string;
    idx: number;
}
