import React, { useImperativeHandle, useState, forwardRef, useEffect, useRef } from 'react';
import * as fabric from 'fabric';
// hooks
import { useDrawTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useDrawTools';
import { useHistoryTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useHistoryTools'; // 경로는 맞게 설정!
import stadium from 'assets/imgs/trainingLogEl/stadium.png';

const PlayerTrainingCanvas = ({ focusedSessionData }: PlayerTrainingCanvasProps) => {
    const canvasDomRef = useRef<HTMLCanvasElement | null>(null);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const [savedJSON, setSavedJson] = useState<string>('');

    const { reset, saveHistory } = useHistoryTools(fabricRef);

    useEffect(() => {
        const canvasEl = canvasDomRef.current;
        if (!canvasEl || fabricRef.current) return;

        let isCancelled = false;

        const ensureCanvasReady = () =>
            new Promise<void>((resolve) => {
                const check = () => {
                    const context = canvasEl.getContext?.('2d');
                    if (context) resolve();
                    else requestAnimationFrame(check);
                };
                check();
            });

        const optimizePath = (parsed: any) => {
            if (parsed?.objects && Array.isArray(parsed.objects)) {
                parsed.objects = parsed.objects.map((obj: any) => {
                    if (obj.type === 'path' && Array.isArray(obj.path)) {
                        if (obj.path.length > 300) {
                            obj.path = obj.path.filter((_: any, idx: number) => idx % 3 === 0);
                        }
                    }
                    obj.selectable = false;
                    obj.evented = false;
                    return obj;
                });
            }
            return parsed;
        };

        const drawBackground = async (canvas: fabric.Canvas) => {
            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;
            canvas.requestRenderAll();
            requestAnimationFrame(() => {
                canvas.requestRenderAll(); // ✅ 추가 호출
            });
        };

        const initializeCanvas = async () => {
            await ensureCanvasReady();
            if (isCancelled || fabricRef.current) return;

            const canvas = new fabric.Canvas(canvasEl, {
                width: 1142,
                height: 808.34,
                selection: false,
                skipTargetFind: true,
            });
            fabricRef.current = canvas;

            await drawBackground(canvas);

            try {
                const json = focusedSessionData?.tacticalBoardJson;
                if (json) {
                    const parsed = optimizePath(JSON.parse(json));
                    if (parsed?.objects?.length) {
                        await new Promise<void>((resolve) => {
                            canvas.loadFromJSON(parsed, () => {
                                canvas.requestRenderAll();
                                requestAnimationFrame(() => {
                                    canvas.requestRenderAll(); // ✅ 추가 호출로 확실히 렌더
                                    resolve();
                                });
                            });
                        });
                    }
                }
            } catch (e) {
                console.warn('⚠️ JSON 파싱 실패', e);
            }
        };

        if (focusedSessionData) {
            initializeCanvas();
        }

        return () => {
            isCancelled = true;
            fabricRef.current?.dispose();
            fabricRef.current = null;
        };
    }, [focusedSessionData]);

    // const exportJSON = () => {
    //     if (!fabricRef.current) return;
    //     const json = JSON.stringify(fabricRef.current.toJSON(), null, 2);
    //     updateTemplate(json);
    //     setSavedJson(json);
    // };
    const importJSON = async (stringifiedData?: string) => {
        // DB 저장된것 불러오는 용도
        if (!fabricRef.current) return;
        const canvas = fabricRef.current;
        if (!stringifiedData && !savedJSON) return;
        try {
            const parsed = JSON.parse(stringifiedData || savedJSON);
            if (!parsed.objects || !Array.isArray(parsed.objects)) {
                alert('올바른 JSON 형식이 아닙니다.');
                return;
            }
            canvas.getObjects().forEach((obj) => canvas.remove(obj));

            // 1️⃣ loadFromJSON
            await new Promise<void>((resolve) => {
                canvas.loadFromJSON(parsed, () => {
                    canvas.getObjects().forEach((obj) => {
                        obj.selectable = false;
                        obj.evented = false;
                    });

                    canvas.selection = false;
                    canvas.skipTargetFind = true;

                    canvas.renderAll();
                    resolve();
                });
            });

            // 2️⃣ 배경 설정
            const bg = await fabric.Image.fromURL(stadium);
            bg.scaleToWidth(canvas.width ?? 1142);
            bg.scaleToHeight(canvas.height ?? 808.34);
            bg.selectable = false;
            canvas.backgroundImage = bg;
            canvas.renderAll();
            if (stringifiedData || savedJSON) setSavedJson(stringifiedData || savedJSON);
            else reset(); // ← 기본 배경 상태를 저장 (isRestoring 사용됨)
        } catch (error) {
            alert('JSON 파싱에 실패했습니다.');
            console.error('[importJSON 에러]', error);
        }
    };
    useEffect(() => {
        if (savedJSON) {
            // const parsed = JSON.parse(savedJSON);
            setTimeout(() => {
                saveHistory(savedJSON);
            }, 100);
        }
    }, [savedJSON]);

    return (
        <div className="text-center mt-[18px] border-2 relative">
            <div className="relative  w-[1142px] h-[808.34px]">
                <canvas ref={canvasDomRef} />
                {/* <div className="absolute bottom-0 flex gap-[10px] justify-center items-center  text-[#ffffff] bg-gradient-to-b from-[#939393] to-[#1C1C1C] border border-[#ffffff] rounded-t-[30px] m-auto h-[94px] w-[1142px]">
            <ItemButton
              state="move"
              isActive={clickState === "move"}
              icon={<Move className=" w-[26px] h-[36px]" />}
              onClick={() => setClickState("move")}
            />
  
            <ColorSelector
              onClick={() => {
                setClickState("draw");
                enableDrawingMode(colorState, lineWidthState);
              }}
              state="draw"
              isActive={clickState === "draw"}
              colorState={colorState}
              lineWidthState={lineWidthState}
              setColorState={setColorState}
              enableDrawingMode={enableDrawingMode}
            />
            <ItemButton
              state="eraser"
              isActive={clickState === "eraser"}
              icon={<Eraser className=" w-[25px] h-[26px]" />}
              onClick={() => {
                setClickState("eraser");
                enableEraserMode();
              }}
            />
            <div className="grid grid-cols-2 grid-rows-2 gap-[5px]">
              <LineButton
                state="line_staright"
                isActive={clickState === "line_staright"}
                icon={<LineStaright className="" />}
                onClick={() => {
                  setClickState("line_staright");
                  addLine("straight", colorState, lineWidthState);
                }}
              />
              <LineButton
                state="line_curve"
                isActive={clickState === "line_curve"}
                icon={<LineCurve className="" />}
                onClick={() => {
                  setClickState("line_curve");
                  addLine("curve", colorState, lineWidthState);
                }}
              />
              <LineButton
                state="line_oneCurve"
                isActive={clickState === "line_oneCurve"}
                icon={<LineOneCurve className="" />}
                onClick={() => {
                  setClickState("line_oneCurve");
                  addLine("oneCurve", colorState, lineWidthState);
                }}
              />
              <DiagramSelector
                state={"diagram"}
                isActive={clickState === "diagram"}
                onClick={() => {
                  setClickState("diagram");
                }}
                addLine={addLine}
                colorState={colorState}
                lineWidthState={lineWidthState}
              />
            </div>
            <div className="flex flex-col gap-[5px] ">
              <button
                onClick={() => setLineWidthState(2)}
                className={`${
                  clickState !== "text" &&
                  clickState !== "item" &&
                  clickState !== "move" &&
                  lineWidthState === 2 &&
                  "text-primary-green border border-primary-green"
                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
              >
                <CircleSmall />
              </button>
              <button
                onClick={() => setLineWidthState(5)}
                className={`${
                  clickState !== "text" &&
                  clickState !== "item" &&
                  clickState !== "move" &&
                  lineWidthState === 5 &&
                  "text-primary-green border border-primary-green"
                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
              >
                <CircleMideum />
              </button>
              <button
                onClick={() => setLineWidthState(8)}
                className={`${
                  clickState !== "text" &&
                  clickState !== "item" &&
                  clickState !== "move" &&
                  lineWidthState === 8 &&
                  "text-primary-green border border-primary-green"
                } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
              >
                <CircleBig />
              </button>
            </div>
            <div className="grid grid-cols-2 grid-rows-2 gap-[5px] ">
              <LineButton
                state="arrow_curve"
                isActive={clickState === "arrow_curve"}
                icon={<ArrowCurve />} // ← UI용
                onClick={() => {
                  setClickState("arrow_curve");
                  addArrow("curve", colorState, lineWidthState);
                }}
              />
              <LineButton
                state="arrow_dot"
                isActive={clickState === "arrow_dot"}
                icon={<ArrowDot className="" />}
                onClick={() => {
                  setClickState("arrow_curve");
                  addArrow("dot", colorState, lineWidthState);
                }}
              />
              <LineButton
                state="arrow_straight"
                isActive={clickState === "arrow_straight"}
                icon={<ArrowStraight className="" />}
                onClick={() => {
                  setClickState("arrow_straight");
                  addArrow("straight", colorState, lineWidthState);
                }}
              />
              <LineButton
                state="arrow_tip"
                isActive={clickState === "arrow_tip"}
                icon={<ArrowTip className="" />}
                onClick={() => {
                  setClickState("arrow_tip");
                  addArrow("tip", colorState, lineWidthState);
                }}
              />
            </div>
  
            <ItemSelector
              state={"item"}
              isActive={clickState === "item"}
              label={"Item"}
              onClick={() => setClickState("item")}
              addImageFromUrl={addImageFromUrl}
            />
  
            <ItemButton
              state="text"
              isActive={clickState === "text"}
              icon={<Text className="text-white w-[20px] h-[20px]" />}
              label="Text"
              onClick={() => {
                setClickState("text");
                addText(colorState); // 텍스트 추가 실행
              }}
            />
          </div> */}
            </div>
        </div>
    );
};

export default PlayerTrainingCanvas;

interface PlayerTrainingCanvasProps {
    focusedSessionData: any;
}
