import React, { useEffect, useRef, useState } from 'react';
import * as fabric from 'fabric';
// hooks
import { useImageTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useImageTools';
import { useTextTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useTextTools';
import { useDrawTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useDrawTools';
import { useArrowTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useArrowTools';
import { useLineTools } from 'pages/staff/control/training/components/fabricCanvas/hooks/useLineTools';

// assets
import {
    ArrowCurve,
    ArrowStraight,
    ArrowTip,
    CircleBig,
    CircleMideum,
    CircleSmall,
    Eraser,
    LineCurve,
    ArrowDot,
    LineOneCurve,
    LineStaright,
    Move,
    Text,
    // ball_black,
} from 'pages/staff/control/training/components/fabricCanvas/components/TrainingLogAssets';
import stadium from 'assets/imgs/trainingLogEl/stadium.png';
// components
import ItemButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/ItemButton';
import LineButton from 'pages/staff/control/training/components/fabricCanvas/components/buttons/LineButton';
import ItemSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ItemSelector';
import ColorSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/ColorSelector';
import DiagramSelector from 'pages/staff/control/training/components/fabricCanvas/components/modals/DiagramSelector';
const FabricCanvas: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const fabricRef = useRef<fabric.Canvas | null>(null);
    const [jsonInput, setJsonInput] = useState('');
    const [clickState, setClickState] = useState('move');
    const [colorState, setColorState] = useState('#000000');
    const [lineWidthState, setLineWidthState] = useState(2);

    const { addImageFromFile, addImageFromUrl } = useImageTools(fabricRef);
    const { addText } = useTextTools(fabricRef);
    const { enableDrawingMode, enableEraserMode, disableDrawingMode } = useDrawTools(fabricRef);
    const { addArrow } = useArrowTools(fabricRef);
    const { addLine } = useLineTools(fabricRef);

    useEffect(() => {
        if (!canvasRef.current) return;
        if (fabricRef.current) {
            fabricRef.current.dispose();
        }
        const canvas = new fabric.Canvas(canvasRef.current, {
            width: 1142,
            height: 800,
        });

        fabricRef.current = canvas;
        // ✔️ Fabric v6에서는 fromURL이 Promise를 반환함!
        fabric.Image.fromURL(stadium).then((img) => {
            img.scaleToWidth(canvas.width ?? 1142);
            img.scaleToHeight(canvas.height ?? 800);
            img.selectable = false;

            canvas.backgroundImage = img;
            canvas.requestRenderAll();
        });

        return () => {
            canvas.dispose();
        };
    }, []);

    const exportMinimalJSON = () => {
        if (!fabricRef.current) return;

        const objects = fabricRef.current.getObjects();

        const minimalObjects = objects.map((obj) => {
            if (obj.type === 'image') {
                const image = obj as fabric.Image;

                return {
                    type: 'image',
                    src: image.getSrc(),
                    left: image.left,
                    top: image.top,
                    scaleX: image.scaleX,
                    scaleY: image.scaleY,
                    angle: image.angle,
                };
            }

            return {};
        });

        const minimalJSON = {
            version: 'minimal',
            objects: minimalObjects,
        };
    };

    const importMinimalJSON = () => {
        if (!fabricRef.current) return;

        try {
            const parsed = JSON.parse(jsonInput);

            if (!parsed.objects || !Array.isArray(parsed.objects)) {
                alert('올바른 JSON 형식이 아닙니다.');
                return;
            }

            fabricRef.current.clear();

            parsed.objects.forEach((obj: any) => {
                if (obj.type === 'image' && obj.src) {
                    fabric.Image.fromURL(obj.src).then((img: fabric.Image) => {
                        img.set({
                            left: obj.left || 0,
                            top: obj.top || 0,
                            scaleX: obj.scaleX || 1,
                            scaleY: obj.scaleY || 1,
                            angle: obj.angle || 0,
                            selectable: true,
                            hasControls: true,
                            hasBorders: true,
                        });

                        fabricRef.current?.setActiveObject(img);
                        fabricRef.current?.add(img);
                    });
                }
            });
        } catch (error) {
            alert('JSON 파싱에 실패했습니다.');
            console.error(error);
        }
    };
    useEffect(() => {
        if (clickState !== 'draw' && clickState !== 'eraser') {
            disableDrawingMode();
        }
    }, [clickState]);

    useEffect(() => {
        if (clickState === 'draw') {
            enableDrawingMode(colorState, lineWidthState); // ✅ 선 다시 적용
        }
        if (clickState === 'eraser') {
            enableEraserMode(lineWidthState); // ✅ 지우개도 다시 적용
        }
        if (
            clickState === 'arrow_curve' ||
            clickState === 'arrow_dot' ||
            clickState === 'arrow_straight' ||
            clickState === 'arrow_tip'
        ) {
            const arrowType = (clickState?.slice(6) || 'straight') as 'curve' | 'straight' | 'dot' | 'tip';
        }
    }, [lineWidthState]);
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <h2>🖼 이미지 추가 & JSON 추출 / 불러오기</h2>
            <div className="relative m-auto border w-fit border-red-50">
                <canvas ref={canvasRef} />
                <div className="absolute bottom-0 flex gap-[10px] justify-center items-center  text-[#ffffff] bg-gradient-to-b from-[#939393] to-[#1C1C1C] border border-[#ffffff] rounded-t-[30px] m-auto h-[94px] w-[1130px]">
                    <ItemButton
                        state="move"
                        isActive={clickState === 'move'}
                        icon={<Move className=" w-[26px] h-[36px]" />}
                        onClick={() => setClickState('move')}
                    />

                    <ColorSelector
                        onClick={() => {
                            setClickState('draw');
                            enableDrawingMode(colorState, lineWidthState);
                        }}
                        state="draw"
                        isActive={clickState === 'draw'}
                        colorState={colorState}
                        lineWidthState={lineWidthState}
                        setColorState={setColorState}
                        enableDrawingMode={enableDrawingMode}
                    />
                    <ItemButton
                        state="eraser"
                        isActive={clickState === 'eraser'}
                        icon={<Eraser className=" w-[25px] h-[26px]" />}
                        onClick={() => {
                            setClickState('eraser');
                            enableEraserMode();
                        }}
                    />
                    <div className="grid grid-cols-2 grid-rows-2 gap-[5px]">
                        <LineButton
                            state="line_staright"
                            isActive={clickState === 'line_staright'}
                            icon={<LineStaright className="" />}
                            onClick={() => {
                                setClickState('line_staright');
                                addLine('straight', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="line_curve"
                            isActive={clickState === 'line_curve'}
                            icon={<LineCurve className="" />}
                            onClick={() => {
                                setClickState('line_curve');
                                addLine('curve', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="line_oneCurve"
                            isActive={clickState === 'line_oneCurve'}
                            icon={<LineOneCurve className="" />}
                            onClick={() => {
                                setClickState('line_oneCurve');
                                addLine('oneCurve', colorState, lineWidthState);
                            }}
                        />
                        <DiagramSelector
                            state={'diagram'}
                            isActive={clickState === 'diagram'}
                            onClick={() => {
                                setClickState('diagram');
                            }}
                            addLine={addLine}
                            // colorState={colorState}
                            lineWidthState={lineWidthState}
                        />
                    </div>
                    <div className="flex flex-col gap-[5px] ">
                        <button
                            onClick={() => setLineWidthState(2)}
                            className={`${
                                clickState !== 'text' &&
                                clickState !== 'item' &&
                                clickState !== 'move' &&
                                lineWidthState === 2 &&
                                'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleSmall />
                        </button>
                        <button
                            onClick={() => setLineWidthState(5)}
                            className={`${
                                clickState !== 'text' &&
                                clickState !== 'item' &&
                                clickState !== 'move' &&
                                lineWidthState === 5 &&
                                'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleMideum />
                        </button>
                        <button
                            onClick={() => setLineWidthState(8)}
                            className={`${
                                clickState !== 'text' &&
                                clickState !== 'item' &&
                                clickState !== 'move' &&
                                lineWidthState === 8 &&
                                'text-primary-green border border-primary-green'
                            } w-[22px] h-[18px] rounded-[5px] flex justify-center items-center bg-[#4E4E4E]`}
                        >
                            <CircleBig />
                        </button>
                    </div>
                    <div className="grid grid-cols-2 grid-rows-2 gap-[5px] ">
                        <LineButton
                            state="arrow_curve"
                            isActive={clickState === 'arrow_curve'}
                            icon={<ArrowCurve />} // ← UI용
                            onClick={() => {
                                setClickState('arrow_curve');
                                addArrow('curve', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_dot"
                            isActive={clickState === 'arrow_dot'}
                            icon={<ArrowDot className="" />}
                            onClick={() => {
                                setClickState('arrow_curve');
                                addArrow('dot', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_straight"
                            isActive={clickState === 'arrow_straight'}
                            icon={<ArrowStraight className="" />}
                            onClick={() => {
                                setClickState('arrow_straight');
                                addArrow('straight', colorState, lineWidthState);
                            }}
                        />
                        <LineButton
                            state="arrow_tip"
                            isActive={clickState === 'arrow_tip'}
                            icon={<ArrowTip className="" />}
                            onClick={() => {
                                setClickState('arrow_tip');
                                addArrow('tip', colorState, lineWidthState);
                            }}
                        />
                    </div>

                    <ItemSelector
                        state={'item'}
                        isActive={clickState === 'item'}
                        label={'Item'}
                        onClick={() => setClickState('item')}
                        addImageFromUrl={addImageFromUrl}
                    />

                    <ItemButton
                        state="text"
                        isActive={clickState === 'text'}
                        icon={<Text className="text-white w-[20px] h-[20px]" />}
                        label="Text"
                        onClick={() => {
                            setClickState('text');
                            addText(colorState); // 텍스트 추가 실행
                        }}
                    />
                </div>
            </div>

            <button
                onClick={exportMinimalJSON}
                style={{
                    fontSize: '20px',
                    padding: '12px 24px',
                    backgroundColor: '#2196F3',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    marginRight: '10px',
                }}
            >
                📄 최소 JSON 추출
            </button>

            <button
                onClick={importMinimalJSON}
                style={{
                    fontSize: '20px',
                    padding: '12px 24px',
                    backgroundColor: '#FF9800',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '8px',
                    cursor: 'pointer',
                }}
            >
                🔄 JSON 불러오기
            </button>
            <div style={{ marginTop: '20px' }}>
                <textarea
                    value={jsonInput}
                    onChange={(e) => setJsonInput(e.target.value)}
                    placeholder="여기에 JSON 붙여넣기"
                    style={{
                        width: '80%',
                        height: '200px',
                        fontSize: '16px',
                        padding: '12px',
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        marginTop: '20px',
                    }}
                />
            </div>
        </div>
    );
};

export default FabricCanvas;
