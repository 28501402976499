// utils
import { dribbleCardColorMatcher } from 'utils/matchers/measurementMatcher';

const SkillAvgCard = ({ averageScores, isPrint }: any) => {
    const scoreData = [
        { skill: '상체 밸런스', score: averageScores?.armScore },
        { skill: '시야', score: averageScores?.chinScore },
        { skill: '무게중심', score: averageScores?.bendKneeScore },
        { skill: '무게축', score: averageScores?.upperBodyScore },
        { skill: '뒤꿈치', score: averageScores?.steppingScore },
        { skill: '무릎거리', score: averageScores?.kneeScore },
        { skill: '잔발', score: averageScores?.stepScore },
        { skill: '탄력성', score: averageScores?.dashScore },
        { skill: '최고속도', score: averageScores?.lapMaxSpeedScore },
        { skill: '평균속도', score: averageScores?.lapAvgSpeedScore },
        { skill: '정확도', score: averageScores?.lineDeviationScore },
        { skill: '볼터치', score: averageScores?.ballTouchScore },
    ];
    const scores = scoreData.map((el) => el.score).filter((score) => score !== undefined);
    const [maxScore, minScore] = [Math.max(...scores), Math.min(...scores)];
    const labeledScoreData = scoreData.map((el) => ({
        ...el,
        label: el.score === maxScore ? 'Best' : el.score === minScore ? 'Worst' : undefined,
    }));
    return (
        <div
            className={isPrint ? 'flex-wrap flex gap-[10px] justify-start' : 'flex-wrap flex gap-[10px] justify-center'}
        >
            {labeledScoreData?.map((el: any) => (
                <div key={el?.skill}>
                    <div
                        className={`h-[20px] mb-[10px] text-center font-bold ${
                            el?.label === 'Best' ? 'text-[#5651E3]' : el?.label === 'West' ? 'text-[#C4C3D2]' : ''
                        }`}
                    >
                        {el?.label}
                    </div>
                    <div
                        className={`${
                            isPrint ? 'h-[90px] w-[90px]' : 'h-[100px] w-[100px]'
                        } rounded-[8px]  flex flex-col justify-center gap-[4px] items-center ${dribbleCardColorMatcher(
                            el.label
                        )}`}
                    >
                        <div className="text-[14px]">{el?.skill}</div>
                        <div className="text-[24px]">{el?.score}</div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SkillAvgCard;
