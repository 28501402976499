// hooks
import { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SessionCanvas from "pages/staff/control/training/components/fabricCanvas/SessionCanvas";
import { useApiQuery, useApiMutation } from "hooks/useDynamicApi";
import { ENDPOINT_TEAM_SESSION_TEMPLATES } from "utils/consts/apiEndpoint";
import SoccerBallLodaing from "components/loading/SoccerBallLodaing";
// commons
import { showToast } from "components/toast/Default";

const TemplateSession = ({
  sessionTemplateJSON,
  setSessionTemplateJSON,
  handleNavigate,
  sessionTemplateInfo,
  setSessionTemplateInfo,
}: SessionTemplateType) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editSessionData, setEditSessionData] = useState(sessionTemplateJSON);

  const editSessionRef = useRef(editSessionData);

  useEffect(() => {
    editSessionRef.current = editSessionData;
  }, [editSessionData]);
  const [saveCommand, setSaveCommand] = useState(false);

  // init
  useEffect(() => {
    if (sessionTemplateJSON) setEditSessionData(sessionTemplateJSON);
  }, [sessionTemplateJSON]);
  // 업로드
  const patchSessionTemplateId = useApiMutation(
    ENDPOINT_TEAM_SESSION_TEMPLATES.sessionTemplateId(id),
    "patch",
    {
      onSuccess: (data) => {
        showToast("훈련 세션이 수정되었습니다", "success");
        setSaveCommand(false);
      },
      onError: (error) => {
        const errorMessage =
          error?.data?.detailedMessage ||
          error ||
          "훈련 세션 수정에 실패했습니다.";
        showToast(`${errorMessage}`, "error");
        setSaveCommand(false);
      },
    }
  );
  useEffect(() => {
    if (saveCommand) {
      if (!patchSessionTemplateId.isPending) {
        showToast("훈련 세션을 수정하는 중입니다", "info");
        setTimeout(() => {
          patchSessionTemplateId.mutate({
            ...sessionTemplateInfo,
            ...editSessionRef.current,
          });
          setSaveCommand(false);
        }, 500);
      }
    }
  }, [saveCommand]);
  return (
    <div>
      {!sessionTemplateJSON && <SoccerBallLodaing />}
      <div className="flex border-b border-secondary-lGrayMid">
        <div className="w-[150px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
          훈련 세션
        </div>
        <div className="px-[24px] pt-[82px] pb-[24px]">
          <SessionCanvas
            editSessionData={editSessionData}
            setEditSessionData={setEditSessionData}
            saveCommand={saveCommand}
          />
          <div className="mt-[10px] text-end text-primary-purple">
            * 훈련세션이 보이지 않을 시, 새로고침해주세요
          </div>
        </div>
      </div>
      <div className="flex border-b border-secondary-lGrayMid">
        <div className="w-[150px] p-[24px] shrink-0 bg-secondary-mGrayLight text-secondary-lGrayDark text-context-regular flex justify-center items-center">
          세션 포인트
        </div>
        <div className="p-[24px] w-full ">
          <textarea
            className="w-full resize-none text-context-regular text-secondary-dGrayMid focus:outline-none"
            placeholder="세션 포인트를 입력해주세요"
            value={sessionTemplateInfo?.sessionPoint || ""}
            onChange={(e) =>
              setSessionTemplateInfo((prev: any) => ({
                ...prev,
                sessionPoint: e.target.value,
              }))
            }
          />
        </div>
      </div>
      <div className="my-[20px] justify-center gap-[10px] flex text-context-bold">
        <button
          onClick={() => navigate(-1)}
          className="rounded-[8px] text-primary-white bg-secondary-dGrayLight px-[20px] py-[12px]"
        >
          취소
        </button>
        <button
          onClick={() => setSaveCommand(true)}
          className="rounded-[8px] text-primary-white bg-primary-purple px-[20px] py-[12px]"
        >
          업로드
        </button>
      </div>
    </div>
  );
};

export default TemplateSession;
interface SessionTemplateInfoType {
  name: string | null;
  sessionPoint: string | null;
}
interface SessionJSONInfoType {
  id: string | null;
  tacticalBoardJson: string | null;
}
interface SessionTemplateType {
  sessionTemplateJSON: SessionJSONInfoType;
  setSessionTemplateJSON: (info: any) => void;
  handleNavigate: (url: string, data?: any) => void;
  sessionTemplateInfo: SessionTemplateInfoType;
  setSessionTemplateInfo: (info: any) => void;
}
