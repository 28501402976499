export function playerSortMatcher(
    key:
        | 'playerName'
        | 'birthYear'
        | 'height'
        | 'weight'
        | 'majorFoot'
        | 'measurementDate'
        | 'overallScore'
        | 'teamId'
        | 'teamName'
        | 'region'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'playerName':
            return sort === 'ASC' ? 'NAME_ASC' : 'NAME_DESC';
        case 'birthYear':
            return sort === 'ASC' ? 'BIRTH_YEAR_ASC' : 'BIRTH_YEAR_DESC';
        case 'height':
            return sort === 'ASC' ? 'HEIGHT_ASC' : 'HEIGHT_DESC';
        case 'weight':
            return sort === 'ASC' ? 'WEIGHT_ASC' : 'WEIGHT_DESC';
        case 'majorFoot':
            return sort === 'ASC' ? 'MAJOR_FOOT_ASC' : 'MAJOR_FOOT_DESC';
        case 'measurementDate':
            return sort === 'ASC' ? 'MEASURED_AT_ASC' : 'MEASURED_AT_DESC';
        case 'lastMeasuredAt':
            return sort === 'ASC' ? 'MEASURED_AT_ASC' : 'MEASURED_AT_DESC';
        case 'overallScore':
            return sort === 'ASC' ? 'OVERALL_SCORE_ASC' : 'OVERALL_SCORE_DESC';
        case 'teamId':
            return sort === 'ASC' ? 'TEAM_NAME_ASC' : 'TEAM_NAME_DESC';
        case 'teamName':
            return sort === 'ASC' ? 'TEAM_NAME_ASC' : 'TEAM_NAME_DESC';
        case 'region':
            return sort === 'ASC' ? 'REGION_ASC' : 'REGION_DESC';

        default:
            throw new Error('Invalid key');
    }
}

export function teamSortMatcher(
    key:
        | 'teamName'
        | 'ageGroup'
        | 'region'
        | 'type'
        | 'memberCount'
        | 'bestSkill'
        | 'worstSkill'
        | 'lastMeasuredAt'
        | 'overallScore'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'teamName':
            return sort === 'ASC' ? 'NAME_ASC' : 'NAME_DESC';
        case 'ageGroup':
            return sort === 'ASC' ? 'AGE_GROUP_ASC' : 'AGE_GROUP_DESC';
        case 'region':
            return sort === 'ASC' ? 'REGION_ASC' : 'REGION_DESC';
        case 'type':
            return sort === 'ASC' ? 'TEAM_TYPE_ASC' : 'TEAM_TYPE_DESC';
        case 'memberCount':
            return sort === 'ASC' ? 'MEMBER_COUNT_ASC' : 'MEMBER_COUNT_DESC';
        case 'bestSkill':
            return sort === 'ASC' ? 'BEST_SKILL_ASC' : 'BEST_SKILL_DESC';
        case 'worstSkill':
            return sort === 'ASC' ? 'WORST_SKILL_ASC' : 'WORST_SKILL_DESC';
        case 'lastMeasuredAt':
            return sort === 'ASC' ? 'MEASURED_AT_ASC' : 'MEASURED_AT_DESC';
        case 'overallScore':
            return sort === 'ASC' ? 'OVERALL_SCORE_ASC' : 'OVERALL_SCORE_DESC';

        default:
            throw new Error('Invalid key');
    }
}

export function adminSortMatcher(key: 'name' | string, sort: 'ASC' | 'DESC'): string {
    switch (key) {
        case 'name':
            return sort === 'ASC' ? 'NAME_ASC' : 'NAME_DESC';

        default:
            throw new Error('Invalid key');
    }
}

export function playerMembershipSortMatcher(
    key: 'playerName' | 'age' | 'uniformNumber' | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'playerName':
            return sort === 'ASC' ? 'PLAYER_NAME_ASC' : 'PLAYER_NAME_DESC';
        case 'age':
            return sort === 'ASC' ? 'AGE_ASC' : 'AGE_DESC';
        case 'uniformNumber':
            return sort === 'ASC' ? 'UNIFORM_NUMBER_ASC' : 'UNIFORM_NUMBER_DESC';
        default:
            throw new Error('Invalid key');
    }
}

export function feedbackSortMatcher(
    key:
        | 'uniformNumber'
        | 'playerName'
        | 'age'
        | 'dribbleOneScore'
        | 'dribbleTwoScore'
        | 'firstTouchScore'
        | 'passOneScore'
        | 'passTwoScore'
        | 'shootingOneScore'
        | 'shootingTwoScore'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'uniformNumber':
            return sort === 'ASC' ? 'UNIFORM_NUMBER_ASC' : 'UNIFORM_NUMBER_DESC';
        case 'playerName':
            return sort === 'ASC' ? 'PLAYER_NAME_ASC' : 'PLAYER_NAME_DESC';
        case 'age':
            return sort === 'ASC' ? 'PLAYER_AGE_ASC' : 'PLAYER_AGE_DESC';
        case 'overallScore':
            return sort === 'ASC' ? 'OVERALL_SCORE_ASC' : 'OVERALL_SCORE_DESC';
        case 'dribbleOneScore':
            return sort === 'ASC' ? 'DRIBBLE_ONE_SCORE_ASC' : 'DRIBBLE_ONE_SCORE_DESC';
        case 'dribbleTwoScore':
            return sort === 'ASC' ? 'DRIBBLE_TWO_SCORE_ASC' : 'DRIBBLE_TWO_SCORE_DESC';
        case 'firstTouchScore':
            return sort === 'ASC' ? 'FIRST_TOUCH_SCORE_ASC' : 'FIRST_TOUCH_SCORE_DESC';
        case 'passOneScore':
            return sort === 'ASC' ? 'PASS_ONE_SCORE_ASC' : 'PASS_ONE_SCORE_DESC';
        case 'passTwoScore':
            return sort === 'ASC' ? 'PASS_TWO_SCORE_ASC' : 'PASS_TWO_SCORE_DESC';
        case 'shootingOneScore':
            return sort === 'ASC' ? 'SHOOTING_ONE_SCORE_ASC' : 'SHOOTING_ONE_SCORE_DESC';
        case 'shootingTwoScore':
            return sort === 'ASC' ? 'SHOOTING_TWO_SCORE_ASC' : 'SHOOTING_TWO_SCORE_DESC';
        default:
            throw new Error('Invalid key');
    }
}

export function playerMeasurementSortMatcher(
    key:
        | 'playerName'
        | 'measuredAt'
        | 'feedbackCreatedAt'
        | 'feedbackCreatorName'
        | 'feedbackModifiedAt'
        | 'feedbackModifierName'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'playerName':
            return sort === 'ASC' ? 'PLAYER_NAME_ASC' : 'PLAYER_NAME_DESC';
        case 'measuredAt':
            return sort === 'ASC' ? 'MEASURED_AT_ASC' : 'MEASURED_AT_DESC';
        case 'feedbackCreatedAt':
            return sort === 'ASC' ? 'FEEDBACK_CREATED_AT_ASC' : 'FEEDBACK_CREATED_AT_DESC';
        case 'feedbackCreatorName':
            return sort === 'ASC' ? 'FEEDBACK_CREATOR_NAME_ASC' : 'FEEDBACK_CREATOR_NAME_DESC';
        case 'feedbackModifiedAt':
            return sort === 'ASC' ? 'FEEDBACK_MODIFIED_AT_ASC' : 'FEEDBACK_MODIFIED_AT_DESC';
        case 'feedbackModifierName':
            return sort === 'ASC' ? 'FEEDBACK_MODIFIER_NAME_ASC' : 'FEEDBACK_MODIFIER_NAME_DESC';

        default:
            throw new Error('Invalid key');
    }
}

export function DribbleOneSortMatcher(
    key:
        | 'UNIFORM_NUMBER_ASC'
        | 'UNIFORM_NUMBER_DESC'
        | 'ARM_SCORE_ASC'
        | 'ARM_SCORE_DESC'
        | 'KNEE_SCORE_ASC'
        | 'KNEE_SCORE_DESC'
        | 'UPPER_BODY_SCORE_ASC'
        | 'UPPER_BODY_SCORE_DESC'
        | 'BEND_KNEE_SCORE_ASC'
        | 'BEND_KNEE_SCORE_DESC'
        | 'CHIN_SCORE_ASC'
        | 'CHIN_SCORE_DESC'
        | 'STEP_SCORE_ASC'
        | 'STEP_SCORE_DESC'
        | 'STEPPING_SCORE_ASC'
        | 'STEPPING_SCORE_DESC'
        | 'DASH_SCORE_ASC'
        | 'DASH_SCORE_DESC'
        | 'LAP_MAX_SPEED_SCORE_ASC'
        | 'LAP_MAX_SPEED_SCORE_DESC'
        | 'LAP_AVG_SPEED_SCORE_ASC'
        | 'LAP_AVG_SPEED_SCORE_DESC'
        | 'LINE_DEVIATION_SCORE_ASC'
        | 'LINE_DEVIATION_SCORE_DESC'
        | 'BALL_TOUCH_SCORE_ASC'
        | 'BALL_TOUCH_SCORE_DESC'
        | 'AVERAGE_SCORE_ASC'
        | 'AVERAGE_SCORE_DESC'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'uniformNumber':
            return sort === 'ASC' ? 'UNIFORM_NUMBER_ASC' : 'UNIFORM_NUMBER_DESC';
        case 'armScore':
            return sort === 'ASC' ? 'ARM_SCORE_ASC' : 'ARM_SCORE_DESC';
        case 'kneeScore':
            return sort === 'ASC' ? 'KNEE_SCORE_ASC' : 'KNEE_SCORE_DESC';
        case 'upperBodyScore':
            return sort === 'ASC' ? 'UPPER_BODY_SCORE_ASC' : 'UPPER_BODY_SCORE_DESC';
        case 'bendKneeScore':
            return sort === 'ASC' ? 'BEND_KNEE_SCORE_ASC' : 'BEND_KNEE_SCORE_DESC';
        case 'chinScore':
            return sort === 'ASC' ? 'CHIN_SCORE_ASC' : 'CHIN_SCORE_DESC';
        case 'stepScore':
            return sort === 'ASC' ? 'STEP_SCORE_ASC' : 'STEP_SCORE_DESC';
        case 'steppingScore':
            return sort === 'ASC' ? 'STEPPING_SCORE_ASC' : 'STEPPING_SCORE_DESC';
        case 'dashScore':
            return sort === 'ASC' ? 'DASH_SCORE_ASC' : 'DASH_SCORE_DESC';
        case 'lapMaxSpeedScore':
            return sort === 'ASC' ? 'LAP_MAX_SPEED_SCORE_ASC' : 'LAP_MAX_SPEED_SCORE_DESC';
        case 'lapAvgSpeedScore':
            return sort === 'ASC' ? 'LAP_AVG_SPEED_SCORE_ASC' : 'LAP_AVG_SPEED_SCORE_DESC';
        case 'lineDeviationScore':
            return sort === 'ASC' ? 'LINE_DEVIATION_SCORE_ASC' : 'LINE_DEVIATION_SCORE_DESC';
        case 'ballTouchScore':
            return sort === 'ASC' ? 'BALL_TOUCH_SCORE_ASC' : 'BALL_TOUCH_SCORE_DESC';
        case 'averageScore':
            return sort === 'ASC' ? 'AVERAGE_SCORE_ASC' : 'AVERAGE_SCORE_DESC';
        default:
            throw new Error('Invalid key');
    }
}

export function MeasurementDataSortMatcher(
    key:
        | 'uniformNumber'
        | 'playerName'
        | 'age'
        | 'overallScore'
        | 'dribbleAvgScore'
        | 'firstTouchScore'
        | 'passAvgScore'
        | 'shootingAvgScore'
        | string,
    sort: 'ASC' | 'DESC'
): string {
    switch (key) {
        case 'uniformNumber':
            return sort === 'ASC' ? 'UNIFORM_NUMBER_ASC' : 'UNIFORM_NUMBER_DESC';
        case 'playerName':
            return sort === 'ASC' ? 'PLAYER_NAME_ASC' : 'PLAYER_NAME_DESC';
        case 'age':
            return sort === 'ASC' ? 'PLAYER_AGE_ASC' : 'PLAYER_AGE_DESC';
        case 'overallScore':
            return sort === 'ASC' ? 'OVERALL_SCORE_ASC' : 'OVERALL_SCORE_DESC';
        case 'dribbleAvgScore':
            return sort === 'ASC' ? 'DRIBBLE_AVG_ASC' : 'DRIBBLE_AVG_DESC';
        case 'firstTouchScore':
            return sort === 'ASC' ? 'FIRST_TOUCH_SCORE_ASC' : 'FIRST_TOUCH_SCORE_DESC';
        case 'passAvgScore':
            return sort === 'ASC' ? 'PASS_AVG_ASC' : 'PASS_AVG_DESC';
        case 'shootingAvgScore':
            return sort === 'ASC' ? 'SHOOTING_AVG_ASC' : 'SHOOTING_AVG_DESC';

        default:
            throw new Error('Invalid key');
    }
}
