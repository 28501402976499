import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { motion } from 'framer-motion';
import { ReactComponent as ImageUpload } from 'assets/svgs/imageUpload.svg';
import { ReactComponent as FileUpload } from 'assets/svgs/fileUpload.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
export interface UploadFile {
    id: string;
    file: File | null;
    fileName: string;
    url?: string;
    progress: number;
    isUploaded?: boolean;
}

interface FileUploadBoxProps {
    files: UploadFile[];
    setFiles: React.Dispatch<React.SetStateAction<UploadFile[]>>;
    onUpload: (file: File, fileIdCallback: (id: string) => void) => void;
}

const FileUploadBox: React.FC<FileUploadBoxProps> = ({ files, setFiles, onUpload }) => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const isUploadDisabled = staffRole === 'OFFICE';
    const simulateUpload = (fileObj: UploadFile) => {
        const interval = setInterval(() => {
            setFiles((prev) =>
                prev.map((f) => (f.id === fileObj.id ? { ...f, progress: Math.min(f.progress + 10, 100) } : f))
            );
        }, 300);
        setTimeout(() => clearInterval(interval), 3000);
    };

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            const newFiles: UploadFile[] = acceptedFiles.map((file) => ({
                id: `${file.name}-${Date.now()}`,
                file,
                fileName: file.name,
                progress: 0,
            }));

            setFiles((prev) => [...prev, ...newFiles]);

            newFiles.forEach((fileObj) => {
                simulateUpload(fileObj);
                onUpload(fileObj.file!, (fileId: string) => {
                    setFiles((prev) =>
                        prev.map((f) =>
                            f.id === fileObj.id
                                ? {
                                      ...f,
                                      id: fileId,
                                      progress: 100,
                                  }
                                : f
                        )
                    );
                });
            });
        },
        [onUpload, setFiles]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        maxSize: 10 * 1024 * 1024, // 10MB
        disabled: isUploadDisabled, // 여기 추가
    });

    const getFileIcon = (file: File | null, url?: string) => {
        const imagePattern = /\.(png|jpe?g|gif|webp)$/i;
        if (file?.type?.startsWith('image/') || url?.match(imagePattern)) {
            return <ImageUpload className="w-[19.5px] h-[19.5px]" />;
        }
        return <FileUpload className="w-[19.5px] h-[19.5px]" />;
    };

    return (
        <div className="flex flex-col md:flex-row gap-[16px]">
            <div
                {...getRootProps()}
                className={`w-[500px] h-[180px] border-[3px] border-dashed rounded-[8px] bg-secondary-mGrayLight flex flex-col items-center justify-center cursor-pointer transition ${
                    isDragActive ? 'border-indigo-600 bg-indigo-50' : 'border-primary-purple'
                }`}
            >
                <input {...getInputProps()} />
                <div className="text-center flex flex-col gap-[5px]">
                    <FileUpload className="m-auto" />
                    <p className="text-secondary-dGrayMid text-context-regular">
                        {isUploadDisabled ? '감독/코치만 동록 가능합니다.' : '파일을 업로드해주세요'}
                    </p>
                </div>
            </div>

            <div className="h-[147px] overflow-x-scroll w-[824px] flex flex-col">
                <p className="flex gap-[10px] mb-[10px]">
                    <span className="text-label-regular text-[#000000]">파일업로드</span>
                    <span className="text-label-regular text-secondary-lGrayDark">용량은 10mb로 제한됩니다.</span>
                </p>
                <div className="grid grid-cols-2 gap-[20px]">
                    {files.map(({ id, file, fileName, progress, url }) => (
                        <div
                            key={id}
                            className="flex gap-[10px] items-center h-[45px] cursor-pointer"
                            onClick={() => {
                                if (url) {
                                    window.open(url, '_blank');
                                }
                            }}
                        >
                            <div className="w-[45px] h-[45px] bg-secondary-mGrayLight rounded-[8px] flex justify-center items-center">
                                {getFileIcon(file, url)}
                            </div>
                            <div className="w-[336px]">
                                <div className="flex items-center justify-between mb-1">
                                    <span className="truncate w-[280px]">{fileName}</span>
                                    <span className="text-indigo-500">{url ? '열기' : `${progress}%`}</span>
                                </div>
                                {!url && (
                                    <div className="w-full h-2 overflow-hidden bg-gray-200 rounded">
                                        <motion.div
                                            className="h-full bg-indigo-500"
                                            initial={{ width: 0 }}
                                            animate={{ width: `${progress}%` }}
                                            transition={{ duration: 0.3 }}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FileUploadBox;
