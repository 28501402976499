// hooks
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
// api
// common
import Dropdown from 'components/dropdown/Default';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';

// icons
import { ReactComponent as HomeIcon } from 'assets/svgs/home.svg';
import { ReactComponent as CircleArrowIcon } from 'assets/svgs/circleArrow.svg';
import { yearOptions } from 'utils/consts/years';
import { useState } from 'react';
const ControlSeasonSubHeader = ({ season, setSeason }: { season: string; setSeason: (year: string) => void }) => {
    const navigate = useNavigate();
    const handleParamNavigate = useParamNavigate();

    const handleHomeNavigate = () => {
        handleParamNavigate({}, false, `/player/measurement`);
    };

    return (
        <div className="mb-[40px]">
            <div className="flex items-center justify-between">
                <div className="flex flex-col gap-[15px]">
                    <div className="flex gap-[8px] items-center">
                        <button onClick={handleHomeNavigate}>
                            <HomeIcon className="text-white" />
                        </button>
                        <div className="w-[3px] h-[3px] rounded-full bg-secondary-lGrayDark"></div>
                        <Dropdown
                            placeholder="시즌"
                            options={yearOptions}
                            value={season}
                            onChange={(value) => setSeason(value)}
                            className={' text-label-regular w-[110px] border rounded-[8px] border-primary-white'}
                            innerClassName="px-[6px] py-[8px] text-label-regular"
                        />
                    </div>
                    <div className="flex items-center gap-[10px]">
                        <button onClick={() => navigate(-1)}>
                            <CircleArrowIcon className="text-white" />
                        </button>
                        <div className="text-[26px] font-bold text-white">시즌통계</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ControlSeasonSubHeader;

export const handlePlayerDropdownOpts = (rows: any[]) => {
    return rows?.map((row) => ({
        value: row?.playerId,
        label: `${row?.uniformNumber}. ${row?.name}`,
    }));
};
