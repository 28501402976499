import { useNavigate, useParams } from 'react-router-dom';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// commons
import Footer from 'components/footer/Default';
import MainSubHeader from 'pages/player/components/MainSubHeader';
import EmptyBar from 'components/emptyBar';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
import Dashboards from 'pages/player/measurement/main/Dashboards';
// utils
import { DASHBOARD } from 'utils/consts/apiEndpoint';

const MeasurementMain = () => {
    const navigate = useNavigate();
    const { playerId = '' } = useParams();

    const getPlayerDashboard = useApiQuery(
        ['getPlayerDashboard', `${playerId}`],
        DASHBOARD.player(`${playerId}`),
        'get',
        {
            enabled: !!playerId,
        }
    );
    const getDashboardDetail = useApiQuery(
        ['getDashboardDetail', `${playerId}`],
        DASHBOARD.playerDetail(`${playerId}`),
        'get',
        {
            enabled: !!playerId,
        }
    );
    const { playerInfo, rankingInfo } = getPlayerDashboard?.data || {};

    return (
        <div className="wrapper wrapperHeight">
            {(getPlayerDashboard?.isPending || getDashboardDetail?.isPending) && <SoccerBallLodaing />}
            <MainSubHeader
                playerInfo={playerInfo}
                rankingInfo={rankingInfo}
            />
            <EmptyBar customStyle={'h-[35px]'} />
            <Dashboards
                dashboardData={getPlayerDashboard?.data}
                dashboardDetailData={getDashboardDetail?.data}
            />

            <EmptyBar customStyle="h-[20px]" />
            <Footer />
        </div>
    );
};

export default MeasurementMain;
