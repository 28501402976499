export const weatherOptions = [
    { value: 'CLEAR', label: '맑음' },
    { value: 'CLOUDY', label: '흐림' },
    { value: 'RAIN', label: '비' },
    { value: 'SNOW', label: '눈' },
    { value: 'INDOOR', label: '실내' },
];

export const trainingIntensityOptions = [
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
];

export const eventOptions = [
    { value: 'CONSULTATION', label: '상담' },
    { value: 'INCIDENT', label: '사건' },
    { value: 'MEETING', label: '회의' },
    { value: 'OTHER', label: '기타' },
];
