// hooks
import { useState, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
// api
import { useApiMutation, useApiQuery } from 'hooks/useDynamicApi';
// commons
import EmptyBar from 'components/emptyBar';
import { showToast } from 'components/toast/Default';
// report components
import ReportPostSubHear from 'pages/staff/control/schedule/report/read/ReportPostSubHear';
import ReportProfile from 'pages/staff/control/schedule/report/read/ReportProfile';
import ReportPlayer from 'pages/staff/control/schedule/report/read/reportPlayer';
import ReportRating from 'pages/staff/control/schedule/report/read/reportRating';
import ReportReview from 'pages/staff/control/schedule/report/read/ReportReview';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';

const ReportRead = () => {
    const [searchParams] = useSearchParams();
    const location = useLocation();
    const {
        id,
        matchDateTime = '',
        matchType,
        competitionName,
        opponentTeam,
        teamScore,
        opponentScore,
        penaltyShootoutTeamScore,
        penaltyShootoutOpponentScore,
        matchResult,
    } = location?.state || {};

    // api
    const getReport = useApiQuery(['getReport', id], ENDPOINT_REPORT.report(id), 'get');
    const today = new Date();
    const koreaTime = today.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });
    const [now, setNow] = useState(koreaTime);
    // 전달된 state 데이터
    const paramAgeGroup = searchParams.get('ageGroup') || '';
    const paramYear = searchParams.get('year') || '';
    const [reportProfile, setReportProfile] = useState({
        matchDateTime: matchDateTime,
        opponentTeam: opponentTeam,
        matchType: matchType,
        competitionName: competitionName,
        teamScore: teamScore,
        opponentScore: opponentScore,
        penaltyShootoutTeamScore: penaltyShootoutTeamScore,
        penaltyShootoutOpponentScore: penaltyShootoutOpponentScore,
        matchDuration: '',
        matchResult: matchResult,
        formation: '',
        overallReview: '',
        seasonYear: '',
    });
    useEffect(() => {
        if (getReport?.data && getReport?.isSuccess) {
            setReportProfile({
                matchDateTime: getReport?.data?.matchDateTime,
                opponentTeam: getReport?.data?.opponentTeam,
                matchType: getReport?.data?.matchType,
                competitionName: getReport?.data?.competitionName,
                teamScore: getReport?.data?.teamScore,
                opponentScore: getReport?.data?.opponentScore,
                penaltyShootoutTeamScore: getReport?.data?.penaltyShootoutTeamScore,
                penaltyShootoutOpponentScore: getReport?.data?.penaltyShootoutOpponentScore,
                matchResult: getReport?.data?.matchResult,
                matchDuration: getReport?.data?.matchDuration,
                formation: getReport?.data?.formation,
                overallReview: getReport?.data?.overallReview,
                seasonYear: getReport?.data?.seasonYear,
            });
        }
    }, [getReport?.data, getReport?.isSuccess]);
    const handleInfo = (type: string, updateData: any) => {
        if (type === 'profile') {
            setReportProfile((prev) => ({
                ...prev,
                ...updateData,
            }));
        }
    };

    const getLineup = useApiQuery(['getLineup'], ENDPOINT_REPORT.lineup(id), 'get');
    // useApiMutation
    const patchMatchReport = useApiMutation(ENDPOINT_REPORT.report(id), 'patch', {
        onSuccess: (data) => {
            // showToast('리포트가 수정되었습니다', 'success');
            const now = new Date();
            const koreaTime = now.toLocaleString('ko-KR', { timeZone: 'Asia/Seoul' });

            setNow(koreaTime);
        },
        onError: (error) => {
            const errorMessage = error?.data?.detailedMessage || error || '리포트 수정에 실패했습니다.';
            showToast(`${errorMessage}`, 'error');
        },
    });

    // 스크롤 이벤트 처리
    const [isFixed, setIsFixed] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 270) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };
        // scroll 이벤트 리스너 추가
        window.addEventListener('scroll', handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="wrapper">
            <ReportPostSubHear />
            <EmptyBar customStyle="h-[38px]" />
            <div className="bg-primary-white rounded-[10px] p-[30px]">
                <div
                    className={`mb-[12px] flex justify-between ${
                        isFixed && 'bg-white pb-[10px] pt-[30px] z-[10] fixed w-[1340px] top-0'
                    }`}
                >
                    <div className="text-secondary-dGrayMid text-subtitle-medium-bold">{paramYear} 일정</div>
                </div>
                <div>
                    <ReportProfile
                        stateInfo={location?.state}
                        reportProfile={reportProfile}
                        handleInfo={handleInfo}
                    />
                </div>
                <ReportPlayer lineup={getLineup?.data} />
                <ReportRating lineup={getLineup?.data} />
                <ReportReview overallReview={reportProfile?.overallReview} />
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default ReportRead;
