// hooks
import { useState, useEffect } from 'react';
// admin components
import FeedbackHeader from 'pages/admin/feedback/FeedbackHeader';
// api
import { useApiQuery } from 'hooks/useDynamicApi';
// common
import EmptyBar from 'components/emptyBar';
import CustomTable from 'components/table/CustomTable';
import DatePicker from 'components/datePicker';
import Pagination from 'components/pagenation';
import SoccerBallLodaing from 'components/loading/SoccerBallLodaing';
// icons
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
// utils
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { playerMeasurementSortMatcher } from 'utils/matchers/sortInfoMatcher';
import { ageMatcher } from 'utils/matchers/userInfoMatcher';
const Feedback = () => {
    const [page, setPage] = useState(1);
    const [pagInfo, setPageInfo] = useState({
        page: 1,
        size: 10,
        totalElements: 1,
        totalPages: 1,
    });
    const [sortType, setSortType] = useState('');
    const [measurementDate, setMeasurementDate] = useState({ startDate: '', endDate: '' });
    const [feedbackDate, setFeedbackDate] = useState({ startDate: '', endDate: '' });
    const handleSort = (key: string, sort: 'ASC' | 'DESC') => {
        const customizedSort = playerMeasurementSortMatcher(key, sort);
        setSortType(customizedSort);
    };

    const handleMeasurementDate = (updateData: any) => {
        setMeasurementDate((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };
    const handleFeedbackDate = (updateData: any) => {
        setFeedbackDate((prev: any) => ({
            ...prev,
            ...updateData,
        }));
    };

    // useApiQuery
    const getAdminMeasurement = useApiQuery(
        ['getAdminMeasurement', sortType, `${page}`, `${pagInfo.size}`],
        `${ENDPOINT_ADMIN.measurement}?sortType=${sortType}&page=${page}&size=${pagInfo.size}`,
        'get'
    );
    useEffect(() => {
        if (
            getAdminMeasurement?.isSuccess &&
            getAdminMeasurement?.data &&
            getAdminMeasurement?.data?.pageInfo?.page === 1
        ) {
            setPageInfo(getAdminMeasurement?.data?.pageInfo);
        }
    }, [getAdminMeasurement?.isSuccess, getAdminMeasurement?.data]);
    const customizedRow = handleRows(getAdminMeasurement?.data?.data) || [];
    const searchName = () => {
        // 20240205 수정필요
        // showToast('이름 검색중입니다', 'info');
        // handleOptions({ name: nameInput });
    };
    return (
        <div className="wrapper">
            {getAdminMeasurement?.isPending && <SoccerBallLodaing />}
            <FeedbackHeader />
            <EmptyBar customStyle="h-[35px]" />
            <div className="p-[30px] bg-primary-white rounded-[10px] flex flex-col gap-[20px]">
                <div className="w-[500px] relative">
                    <input
                        placeholder="이름을 검색해주세요"
                        className="inputDefaultStyle pl-[12px] pr-[30px]  w-full h-[44px] text-context-regular rounded-[8px] text-black"
                    />
                    <button className="absolute right-[12px] top-[12px]">
                        <SearchIcon />
                    </button>
                </div>
                <div>
                    <div className="flex justify-between">
                        <div className="w-[650px] flex gap-[20px] items-center">
                            <div className="text-context-bold text-secondary-lGrayDark w-[60px]">측정날짜</div>
                            <DatePicker
                                modalBtn={
                                    <div className="flex items-center justify-between p-[10px] gap-[14px] w-[260px] bg-secondary-mGrayLight rounded-[8px]">
                                        {measurementDate.startDate || '시작날짜'}
                                        <CalendarIcon />
                                    </div>
                                }
                                selected={measurementDate.startDate}
                                setSelected={(value) => handleMeasurementDate({ startDate: value })}
                            />
                            <DatePicker
                                modalBtn={
                                    <div className="flex items-center justify-between p-[10px] gap-[14px] w-[260px] bg-secondary-mGrayLight rounded-[8px]">
                                        {measurementDate.endDate || '종료날짜'}
                                        <CalendarIcon />
                                    </div>
                                }
                                selected={measurementDate.endDate}
                                setSelected={(value) => handleMeasurementDate({ endDate: value })}
                            />
                        </div>
                        <div className="w-[650px] flex gap-[20px] items-center">
                            <div className="text-context-bold text-secondary-lGrayDark w-[74px]">피드백 작성</div>

                            <DatePicker
                                modalBtn={
                                    <div className="flex items-center justify-between p-[10px] gap-[14px] w-[260px] bg-secondary-mGrayLight rounded-[8px]">
                                        {feedbackDate.startDate || '시작날짜'}
                                        <CalendarIcon />
                                    </div>
                                }
                                selected={feedbackDate.startDate}
                                setSelected={(value) => handleFeedbackDate({ startDate: value })}
                            />
                            <DatePicker
                                modalBtn={
                                    <div className="flex items-center justify-between p-[10px] gap-[14px] w-[260px] bg-secondary-mGrayLight rounded-[8px]">
                                        {feedbackDate.endDate || '종료날짜'}
                                        <CalendarIcon />
                                    </div>
                                }
                                selected={feedbackDate.endDate}
                                setSelected={(value) => handleFeedbackDate({ endDate: value })}
                            />
                        </div>
                    </div>
                </div>
                <CustomTable
                    columns={columns}
                    rows={customizedRow}
                    equalWidth={false}
                    sortInfoFunc={handleSort}
                />
                <Pagination
                    page={page}
                    size={pagInfo?.size}
                    totalElement={pagInfo?.totalElements}
                    totalPages={pagInfo?.totalPages}
                    onPageChange={setPage}
                />
            </div>
            <EmptyBar customStyle="h-[20px]" />
        </div>
    );
};

export default Feedback;

export const columns = [
    { key: 'playerName', label: '이름', sortable: true },
    { key: 'age', label: '나이' },
    { key: 'measuredAt', label: '측정일', sortable: true },
    { key: 'feedbackCreatedAt', label: '작성일', sortable: true },
    { key: 'feedbackCreatorName', label: '작성자', sortable: true },
    { key: 'feedbackModifiedAt', label: '수정일', sortable: true },
    { key: 'feedbackModifierName', label: '수정자', sortable: true },
    { key: 'link', label: '상세보기', sortable: false },
];

interface OptionsType {
    name: string;
    sortType?: string;
}
export const handleRows = (rows: any[]) => {
    return rows?.map((row) => ({
        playerName: row?.playerName,
        age: `${row?.age}세 (${ageMatcher(row?.age)})`,
        measuredAt: formatDateTimeMatcher(row?.measuredAt),
        feedbackCreatedAt: formatDateTimeMatcher(row?.feedbackCreatedAt),
        feedbackCreatorName: row?.feedbackCreatorName,
        feedbackModifiedAt: formatDateTimeMatcher(row?.feedbackModifiedAt),
        feedbackModifierName: row?.feedbackModifierName,
        link: `/admin/feedback/${row?.measurementId}?measurementId=${row?.measurementId}&dateTime=${row?.measuredAt}`,
    }));
};
