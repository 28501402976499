// hooks
import { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
// utils
import { ENDPOINT_REPORT } from 'utils/consts/apiEndpoint';
// api
import { useApiMutation } from 'hooks/useDynamicApi';
import { showToast } from 'components/toast/Default';

const StartingRating = ({
    lineupId,
    playerId,
    playerName,
    uniformNumber,
    position,
    rating,
    managerComment,
}: StartingRatingType) => {
    const { id } = useParams();
    const [editPlayerName, setEditPlayerName] = useState(playerName);
    const [editManagerComment, setEditManagerComment] = useState(managerComment || '');
    const [editRating, setEditRating] = useState(rating);

    const patchRatingReview = useApiMutation(ENDPOINT_REPORT.record(id, playerId), 'patch', {
        onSuccess: (data) => {
            // showToast('선수 평가를 수정하였습니다', 'success');
        },
        onError: (error) => {
            const detailError = error?.data?.detailedMessage || error;
            showToast(`${detailError}`, 'error');
        },
    });

    const editRatingReview = () => {
        patchRatingReview.mutate({
            rating: editRating,
            managerComment: editManagerComment,
        });
    };

    // `editRatingReview`를 debounced로 생성
    const debouncedEditRatingReview = useMemo(
        () =>
            debounce(() => {
                editRatingReview();
            }, 1000), // 1초 뒤에 실행
        [patchRatingReview] // 의존성에서 필요 요소만 포함
    );

    useEffect(() => {
        // `editManagerComment` 또는 `editRating` 변경 시 debounce 호출
        if (editRating === rating && editManagerComment === managerComment) return;
        if (editRating || editManagerComment) {
            debouncedEditRatingReview();
        }

        // 컴포넌트 언마운트 시 debounce 정리
        return () => {
            debouncedEditRatingReview.cancel();
        };
    }, [editRating, editManagerComment]);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleWheel = (e: WheelEvent) => {
            if (document.activeElement === inputRef.current) {
                e.preventDefault(); // 기본 스크롤 방지
            }
        };

        window.addEventListener('wheel', handleWheel, { passive: false });

        return () => {
            window.removeEventListener('wheel', handleWheel);
        };
    }, []);

    return (
        <div className="flex gap-[10px]">
            <div className="w-[200px]">
                <input
                    value={editPlayerName}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
            <div className="w-[569px]">
                <input
                    type="text"
                    maxLength={500}
                    value={editManagerComment}
                    onChange={(e) => setEditManagerComment(e.target.value)}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
            <div className="w-[200px]">
                <input
                    type="number"
                    max={10}
                    min={0}
                    step="0.1"
                    value={editRating}
                    onChange={(e) => setEditRating(e.target.value)}
                    className="inputCommonStyle inputDefaultStyle"
                />
            </div>
        </div>
    );
};

export default StartingRating;

interface StartingRatingType {
    lineupId: string;
    playerId: string;
    playerName: string;
    uniformNumber: string;
    position: string;
    rating: string;
    managerComment: string | null;
}
