import { useEffect, useState } from 'react';
// common
import DatePicker from 'components/datePicker';
import { showToast } from 'components/toast/Default';
// icons
import { ReactComponent as CalendarIcon } from 'assets/svgs/calendar.svg';
import { ReactComponent as CloseIcon } from 'assets/svgs/close.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';
interface PeriodAddModalType {
    modalBtn: React.ReactNode;
    editValue: any;
    activeFunc: (value: any) => void;
}
const PeriodAddModal = ({ modalBtn, activeFunc }: PeriodAddModalType) => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);

    const [isShow, setIsShow] = useState(false);
    const [year, setYear] = useState('');
    const [periodNumber, setPeriodNumber] = useState('');
    const [periodStartDate, setPeriodStartDate] = useState('');
    const [periodEndDate, setPeriodEndDate] = useState('');
    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const submitAdd = () => {
        setIsShow(false);
        activeFunc({
            newInputEndDate: inputEndDate,
            newInputStartDate: inputStartDate,
            newPeriodEndDate: periodEndDate,
            newPeriodStartDate: periodStartDate,
            newPeriodNumber: periodNumber,
            newYear: year,
        });
    };
    const openModal = () => {
        if (staffRole !== 'OFFICE') {
            showToast('사무국 직원만 차수를 생성할 수 있습니다.', 'error');

            return;
        } else {
            setIsShow(true);
        }
    };

    return (
        <div>
            <div onClick={openModal}>{modalBtn}</div>
            {isShow && (
                <div className="bg-[#00000050] overflow-y-scroll fixed top-0 z-[10] flex justify-center items-center w-screen h-screen left-0">
                    <div className="text-context-regular text-primary-black w-[360px] rounded-[10px] bg-white p-[30px] flex flex-col gap-[10px]">
                        <div className="flex items-center justify-between">
                            <div className="text-subtitle-medium-bold">차수 생성</div>
                            <button onClick={() => setIsShow(false)}>
                                <CloseIcon className="text-secondary-lGrayDark w-[30px] h-[30px]" />
                            </button>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">평가연도</div>
                            <div className="w-[150px]">
                                <input
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    className={`${'inputDefaultStyle'} inputCommonStyle `}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">차수</div>
                            <div className="w-[150px]">
                                <input
                                    type="number"
                                    value={periodNumber}
                                    onChange={(e) => setPeriodNumber(e.target.value)}
                                    className={`${'inputDefaultStyle'} inputCommonStyle `}
                                />
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">차수 시작일</div>
                            <DatePicker
                                modalBtn={
                                    <div className=" flex items-center p-[10px] gap-[14px] w-full inputDefaultStyle inputCommonStyle">
                                        {periodStartDate ? periodStartDate : '차수 시작일'}
                                        <CalendarIcon />
                                    </div>
                                }
                                defaultDate={periodStartDate || ''}
                                selected={periodStartDate}
                                setSelected={setPeriodStartDate}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">차수 종료일</div>
                            <DatePicker
                                modalBtn={
                                    <div className=" flex items-center p-[10px] gap-[14px] w-full inputDefaultStyle inputCommonStyle">
                                        {periodEndDate ? periodEndDate : '차수 종료일'}
                                        <CalendarIcon />
                                    </div>
                                }
                                defaultDate={periodEndDate || ''}
                                selected={periodEndDate}
                                setSelected={setPeriodEndDate}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">평가 입력 시작일</div>
                            <DatePicker
                                modalBtn={
                                    <div className=" flex items-center p-[10px] gap-[14px] w-full inputDefaultStyle inputCommonStyle">
                                        {inputStartDate ? inputStartDate : '평가 입력 시작일'}
                                        <CalendarIcon />
                                    </div>
                                }
                                defaultDate={inputStartDate || ''}
                                selected={inputStartDate}
                                setSelected={setInputStartDate}
                            />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="w-[120px]">평가 입력 종료일</div>
                            <DatePicker
                                modalBtn={
                                    <div className=" flex items-center p-[10px] gap-[14px] w-full inputDefaultStyle inputCommonStyle">
                                        {inputEndDate ? inputEndDate : '평가 입력 종료일'}
                                        <CalendarIcon />
                                    </div>
                                }
                                defaultDate={inputEndDate || ''}
                                selected={inputEndDate}
                                setSelected={setInputEndDate}
                            />
                        </div>
                        <div className="flex gap-[4px] justify-end">
                            <button
                                onClick={() => setIsShow(false)}
                                className="p-[10px] bg-secondary-lGrayLight  rounded-[8px]"
                            >
                                취소
                            </button>
                            <button
                                onClick={submitAdd}
                                className="p-[10px] bg-primary-purple text-white rounded-[8px]"
                            >
                                생성
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default PeriodAddModal;
