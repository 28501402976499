// hooks
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApiQuery, useApiMutation } from 'hooks/useDynamicApi';
// commons
import { showToast } from 'components/toast/Default';
import EmptyBar from 'components/emptyBar';
import Tabs from 'components/tab/Default';
import Diary from 'pages/staff/control/training/diary';
import Session from 'pages/staff/control/training/session';
import EventTable from 'pages/staff/control/event/components/EventTable';
import EventSubHeader from 'pages/staff/control/event/components/EventSubHeader';
// utils
import { useParamNavigate } from 'hooks/useDynamicNavigate';
import { weatherMatcher } from 'utils/matchers/diaryMatcher';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';
import { ENDPOINT_MANAGEMENT } from 'utils/consts/apiEndpoint';
// icons
import { ReactComponent as DeleteIcon } from 'assets/svgs/delete.svg';
// redux
import { RootState } from 'store';
import { useSelector } from 'react-redux';

const Event = () => {
    const { staffRole } = useSelector((state: RootState) => state.userInfo);
    const handleParamNavigate = useParamNavigate();
    const [searchParams] = useSearchParams();
    const paramAgeGroup = searchParams.get('office') || '';

    const handleNavigate = (url: string, data?: any) => {
        if (paramAgeGroup)
            handleParamNavigate(
                {
                    ageGroup: paramAgeGroup,
                },
                false,
                `${url}`,
                data
            );
    };

    return (
        <div className="wrapper">
            <EventSubHeader title={'이벤트'} />
            <EmptyBar customStyle={'h-[35px]'} />

            <div className="w-full  bg-white rounded-[10px] p-[30px] ">
                <div className="flex gap-[20px] items-center justify-between">
                    <div className="flex gap-[5px] text-[20px] font-semibold">
                        <span className="text-secondary-dGrayMid text-subtitle-medium-bold">{'기록'}</span>
                    </div>
                </div>
                <EmptyBar customStyle={'h-[20px]'} />

                <EventTable />
            </div>

            <EmptyBar customStyle={'h-[20px]'} />
        </div>
    );
};

export default Event;
