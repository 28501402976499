// hooks
import React, { useState, useRef, useEffect } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import axiosInstance from 'api/axiosInstance';
import { useInView } from 'react-intersection-observer';
// icons
import { ReactComponent as CheckIcon } from 'assets/svgs/check.svg';
import { ReactComponent as ArrowIcon } from 'assets/svgs/arrow.svg';
import { ReactComponent as SearchIcon } from 'assets/svgs/search.svg';
// utils
import { paramFormChanger } from 'utils/formChanger';
import { ENDPOINT_ADMIN } from 'utils/consts/apiEndpoint';
import { formatDateTimeMatcher } from 'utils/matchers/reportMatcher';

interface InfiniteDropdownProps {
    requestUrl: string;
    btn: string | React.ReactNode;
    value?: string;
    defaultValue?: string;
    paramKey?: string;
    onChange: (value: string) => void;
    activeFunc?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    innerClassName?: string;
}

const InfiniteDropdown: React.FC<InfiniteDropdownProps> = ({
    requestUrl,
    btn,
    value,
    defaultValue,
    onChange,
    activeFunc,
    placeholder = '선택해주세요',
    disabled = false,
    className = '',
    innerClassName = ' px-[12px] py-[12px]',
}) => {
    const { ref, inView } = useInView();
    const [isOpen, setIsOpen] = useState(false);
    const PAGE_SIZE = 5;

    const InfiniteDropdownRef = useRef<HTMLDivElement>(null);
    // 외부 클릭 시 드롭다운 닫기
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (InfiniteDropdownRef.current && !InfiniteDropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // useInfiniteQuery
    const { data, error, fetchNextPage, hasNextPage, isFetching, isSuccess, isFetchingNextPage, status } =
        useInfiniteQuery({
            queryKey: ['InfiniteDropdown'],
            queryFn: ({ pageParam = 1 }) =>
                axiosInstance
                    .get(`${requestUrl}?page=${pageParam}&size=${PAGE_SIZE}&sortType=ID_ASC`)
                    .then((response) => response.data),
            initialPageParam: 1,
            getNextPageParam: (lastPage) => (lastPage?.sliceInfo?.hasNext ? lastPage?.sliceInfo?.page + 1 : undefined),
        });
    // useInView로 무한 스크롤
    useEffect(() => {
        if (inView && hasNextPage && !isFetchingNextPage) {
            fetchNextPage();
        }
    }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

    const flattenedOptions = handleDropdownOpts(data?.pages.flatMap((page) => page.data) || []);

    // ✅ measurementId 기반으로 선택된 값 찾기
    const selectedOption = flattenedOptions.find((option) => option.value === value);

    // ✅ measurementId를 사용하여 선택한 옵션 저장
    const handleOptionSelect = (option: any) => {
        if (!disabled) {
            onChange(option.value); // measurementId 저장
            if (activeFunc) activeFunc(option.fullData);
            setIsOpen(false);
        }
    };
    const handleToggle = () => {
        if (!disabled) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <div
            ref={InfiniteDropdownRef}
            className={`relative ${className ? className : ''}`}
        >
            {/* 드롭다운 헤더 */}
            <div
                onClick={handleToggle}
                className={`
          cursor-pointer
          transition-all duration-200
        `}
            >
                <div>{btn}</div>
            </div>

            {/* 드롭다운 리스트 */}
            {isOpen && (
                <div
                    className=" absolute left-0 z-10  w-full mt-[5px] border border-primary-purple rounded-[10px]
            max-h-60 overflow-y-auto 
          "
                >
                    {flattenedOptions.map((option) => (
                        <div
                            key={option?.value}
                            onClick={() => handleOptionSelect(option)}
                            className={`
                ${innerClassName ? innerClassName : 'px-[12px] py-[12px]'}  text-context-regular
                flex justify-between items-center gap-[10px]
                cursor-pointer
                hover:bg-[#EDECFF] text-primary-purple
                transition-colors duration-200
                ${value === option.value ? 'bg-[#EDECFF]' : 'bg-primary-white'}
              `}
                        >
                            {option?.label}
                            {value === option.value && <CheckIcon className="flex-shrink-0 " />}
                        </div>
                    ))}
                    <div className="bg-primary-white">
                        <div
                            ref={ref}
                            className="h-4"
                        />
                        {isFetching && !isFetchingNextPage && (
                            <div className="pb-2 text-center text-secondary-lGrayDark">Loading...</div>
                        )}
                        {isFetchingNextPage && (
                            <div className="pb-2 text-center text-secondary-lGrayDark">다음 페이지 로딩 중...</div>
                        )}
                        {!isFetching && !isFetchingNextPage && !hasNextPage && (
                            <div className="pb-2 text-center text-secondary-lGrayDark">마지막 데이터 입니다.</div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default InfiniteDropdown;

export const handleDropdownOpts = (arr: any[]) => {
    return arr
        ?.filter((item) => item.measurementId !== null) // measurementId가 존재하는 경우만 포함
        ?.map((item) => ({
            value: item?.measuredAt, // ✅ measurementId를 value로 설정
            label: formatDateTimeMatcher(item?.measuredAt || ''), // 표시용 라벨
            fullData: item, // 원래 데이터를 저장
        }));
};
